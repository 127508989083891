import React from 'react'
import { Box, Toolbar } from '@mui/material'

import Header, { DRAWER_WIDTH } from './Header'
import Main from './Main'
import Footer from './Footer'

type LayoutProps = {
  children: JSX.Element
}

const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  return (
    <>
      <Header />
      <Toolbar />
      <Box
        display='flex'
        p={2}
        flexDirection='column'
        flex={1}
        sx={{
          marginLeft: {
            sm: `${DRAWER_WIDTH}px`
          }
        }}
      >
        <Main display='flex' flexDirection='column'>
          {children}
        </Main>
        <Footer />
      </Box>
    </>
  )
}

export default Layout
