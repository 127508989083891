import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  date: { input: any; output: any }
  geography: { input: any; output: any }
  geometry: { input: any; output: any }
  json: { input: any; output: any }
  jsonb: { input: any; output: any }
  numeric: { input: any; output: any }
  timestamp: { input: any; output: any }
  timestamptz: { input: any; output: any }
  uuid: { input: any; output: any }
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>
  _gt?: InputMaybe<Scalars['Boolean']['input']>
  _gte?: InputMaybe<Scalars['Boolean']['input']>
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['Boolean']['input']>
  _lte?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Scalars['Boolean']['input']>
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>
}

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Float']['input']>
  _gt?: InputMaybe<Scalars['Float']['input']>
  _gte?: InputMaybe<Scalars['Float']['input']>
  _in?: InputMaybe<Array<Scalars['Float']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['Float']['input']>
  _lte?: InputMaybe<Scalars['Float']['input']>
  _neq?: InputMaybe<Scalars['Float']['input']>
  _nin?: InputMaybe<Array<Scalars['Float']['input']>>
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>
  _gt?: InputMaybe<Scalars['Int']['input']>
  _gte?: InputMaybe<Scalars['Int']['input']>
  _in?: InputMaybe<Array<Scalars['Int']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['Int']['input']>
  _lte?: InputMaybe<Scalars['Int']['input']>
  _neq?: InputMaybe<Scalars['Int']['input']>
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>
  _gt?: InputMaybe<Scalars['String']['input']>
  _gte?: InputMaybe<Scalars['String']['input']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>
  _in?: InputMaybe<Array<Scalars['String']['input']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>
  _lt?: InputMaybe<Scalars['String']['input']>
  _lte?: InputMaybe<Scalars['String']['input']>
  _neq?: InputMaybe<Scalars['String']['input']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>
  _nin?: InputMaybe<Array<Scalars['String']['input']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>
}

/** columns and relationships of "apartment" */
export type Apartment = {
  __typename?: 'apartment'
  apartment_number: Scalars['String']['output']
  /** An object relationship */
  apartment_rental_type: Apartment_Rental_Type
  /** An object relationship */
  building: Building
  building_id: Scalars['uuid']['output']
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description: Scalars['String']['output']
  /** An array relationship */
  devices: Array<Device>
  /** An aggregate relationship */
  devices_aggregate: Device_Aggregate
  floor_number: Scalars['String']['output']
  id: Scalars['uuid']['output']
  ip_address?: Maybe<Scalars['String']['output']>
  monthly_rent?: Maybe<Scalars['Float']['output']>
  name: Scalars['String']['output']
  profile_photo?: Maybe<Scalars['String']['output']>
  rental_type: Apartment_Rental_Type_Enum
  /** An array relationship */
  scenes: Array<Apartment_Scene>
  /** An aggregate relationship */
  scenes_aggregate: Apartment_Scene_Aggregate
  security_deposit?: Maybe<Scalars['Float']['output']>
  /** An object relationship */
  type?: Maybe<Apartment_Type>
  type_id?: Maybe<Scalars['uuid']['output']>
  updated_at: Scalars['timestamptz']['output']
  /** An array relationship */
  user_applications: Array<User_Application>
  /** An aggregate relationship */
  user_applications_aggregate: User_Application_Aggregate
  /** An array relationship */
  users: Array<User_Apartment>
  /** An aggregate relationship */
  users_aggregate: User_Apartment_Aggregate
}

/** columns and relationships of "apartment" */
export type ApartmentDevicesArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

/** columns and relationships of "apartment" */
export type ApartmentDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

/** columns and relationships of "apartment" */
export type ApartmentScenesArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Scene_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Scene_Order_By>>
  where?: InputMaybe<Apartment_Scene_Bool_Exp>
}

/** columns and relationships of "apartment" */
export type ApartmentScenes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Scene_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Scene_Order_By>>
  where?: InputMaybe<Apartment_Scene_Bool_Exp>
}

/** columns and relationships of "apartment" */
export type ApartmentUser_ApplicationsArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Order_By>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

/** columns and relationships of "apartment" */
export type ApartmentUser_Applications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Order_By>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

/** columns and relationships of "apartment" */
export type ApartmentUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Apartment_Order_By>>
  where?: InputMaybe<User_Apartment_Bool_Exp>
}

/** columns and relationships of "apartment" */
export type ApartmentUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Apartment_Order_By>>
  where?: InputMaybe<User_Apartment_Bool_Exp>
}

/** aggregated selection of "apartment" */
export type Apartment_Aggregate = {
  __typename?: 'apartment_aggregate'
  aggregate?: Maybe<Apartment_Aggregate_Fields>
  nodes: Array<Apartment>
}

export type Apartment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Apartment_Aggregate_Bool_Exp_Count>
}

export type Apartment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Apartment_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Apartment_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "apartment" */
export type Apartment_Aggregate_Fields = {
  __typename?: 'apartment_aggregate_fields'
  avg?: Maybe<Apartment_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Apartment_Max_Fields>
  min?: Maybe<Apartment_Min_Fields>
  stddev?: Maybe<Apartment_Stddev_Fields>
  stddev_pop?: Maybe<Apartment_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Apartment_Stddev_Samp_Fields>
  sum?: Maybe<Apartment_Sum_Fields>
  var_pop?: Maybe<Apartment_Var_Pop_Fields>
  var_samp?: Maybe<Apartment_Var_Samp_Fields>
  variance?: Maybe<Apartment_Variance_Fields>
}

/** aggregate fields of "apartment" */
export type Apartment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apartment_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "apartment" */
export type Apartment_Aggregate_Order_By = {
  avg?: InputMaybe<Apartment_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Apartment_Max_Order_By>
  min?: InputMaybe<Apartment_Min_Order_By>
  stddev?: InputMaybe<Apartment_Stddev_Order_By>
  stddev_pop?: InputMaybe<Apartment_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Apartment_Stddev_Samp_Order_By>
  sum?: InputMaybe<Apartment_Sum_Order_By>
  var_pop?: InputMaybe<Apartment_Var_Pop_Order_By>
  var_samp?: InputMaybe<Apartment_Var_Samp_Order_By>
  variance?: InputMaybe<Apartment_Variance_Order_By>
}

/** input type for inserting array relation for remote table "apartment" */
export type Apartment_Arr_Rel_Insert_Input = {
  data: Array<Apartment_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Apartment_On_Conflict>
}

/** aggregate avg on columns */
export type Apartment_Avg_Fields = {
  __typename?: 'apartment_avg_fields'
  monthly_rent?: Maybe<Scalars['Float']['output']>
  security_deposit?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "apartment" */
export type Apartment_Avg_Order_By = {
  monthly_rent?: InputMaybe<Order_By>
  security_deposit?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "apartment". All fields are combined with a logical 'AND'. */
export type Apartment_Bool_Exp = {
  _and?: InputMaybe<Array<Apartment_Bool_Exp>>
  _not?: InputMaybe<Apartment_Bool_Exp>
  _or?: InputMaybe<Array<Apartment_Bool_Exp>>
  apartment_number?: InputMaybe<String_Comparison_Exp>
  apartment_rental_type?: InputMaybe<Apartment_Rental_Type_Bool_Exp>
  building?: InputMaybe<Building_Bool_Exp>
  building_id?: InputMaybe<Uuid_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  devices?: InputMaybe<Device_Bool_Exp>
  devices_aggregate?: InputMaybe<Device_Aggregate_Bool_Exp>
  floor_number?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  ip_address?: InputMaybe<String_Comparison_Exp>
  monthly_rent?: InputMaybe<Float_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  profile_photo?: InputMaybe<String_Comparison_Exp>
  rental_type?: InputMaybe<Apartment_Rental_Type_Enum_Comparison_Exp>
  scenes?: InputMaybe<Apartment_Scene_Bool_Exp>
  scenes_aggregate?: InputMaybe<Apartment_Scene_Aggregate_Bool_Exp>
  security_deposit?: InputMaybe<Float_Comparison_Exp>
  type?: InputMaybe<Apartment_Type_Bool_Exp>
  type_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user_applications?: InputMaybe<User_Application_Bool_Exp>
  user_applications_aggregate?: InputMaybe<User_Application_Aggregate_Bool_Exp>
  users?: InputMaybe<User_Apartment_Bool_Exp>
  users_aggregate?: InputMaybe<User_Apartment_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "apartment" */
export enum Apartment_Constraint {
  /** unique or primary key constraint on columns "id" */
  ApartmentPkey = 'apartment_pkey'
}

/** input type for incrementing numeric columns in table "apartment" */
export type Apartment_Inc_Input = {
  monthly_rent?: InputMaybe<Scalars['Float']['input']>
  security_deposit?: InputMaybe<Scalars['Float']['input']>
}

/** input type for inserting data into table "apartment" */
export type Apartment_Insert_Input = {
  apartment_number?: InputMaybe<Scalars['String']['input']>
  apartment_rental_type?: InputMaybe<Apartment_Rental_Type_Obj_Rel_Insert_Input>
  building?: InputMaybe<Building_Obj_Rel_Insert_Input>
  building_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  devices?: InputMaybe<Device_Arr_Rel_Insert_Input>
  floor_number?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  ip_address?: InputMaybe<Scalars['String']['input']>
  monthly_rent?: InputMaybe<Scalars['Float']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  profile_photo?: InputMaybe<Scalars['String']['input']>
  rental_type?: InputMaybe<Apartment_Rental_Type_Enum>
  scenes?: InputMaybe<Apartment_Scene_Arr_Rel_Insert_Input>
  security_deposit?: InputMaybe<Scalars['Float']['input']>
  type?: InputMaybe<Apartment_Type_Obj_Rel_Insert_Input>
  type_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_applications?: InputMaybe<User_Application_Arr_Rel_Insert_Input>
  users?: InputMaybe<User_Apartment_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Apartment_Max_Fields = {
  __typename?: 'apartment_max_fields'
  apartment_number?: Maybe<Scalars['String']['output']>
  building_id?: Maybe<Scalars['uuid']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  floor_number?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  ip_address?: Maybe<Scalars['String']['output']>
  monthly_rent?: Maybe<Scalars['Float']['output']>
  name?: Maybe<Scalars['String']['output']>
  profile_photo?: Maybe<Scalars['String']['output']>
  security_deposit?: Maybe<Scalars['Float']['output']>
  type_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "apartment" */
export type Apartment_Max_Order_By = {
  apartment_number?: InputMaybe<Order_By>
  building_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  floor_number?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  ip_address?: InputMaybe<Order_By>
  monthly_rent?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  profile_photo?: InputMaybe<Order_By>
  security_deposit?: InputMaybe<Order_By>
  type_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Apartment_Min_Fields = {
  __typename?: 'apartment_min_fields'
  apartment_number?: Maybe<Scalars['String']['output']>
  building_id?: Maybe<Scalars['uuid']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  floor_number?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  ip_address?: Maybe<Scalars['String']['output']>
  monthly_rent?: Maybe<Scalars['Float']['output']>
  name?: Maybe<Scalars['String']['output']>
  profile_photo?: Maybe<Scalars['String']['output']>
  security_deposit?: Maybe<Scalars['Float']['output']>
  type_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "apartment" */
export type Apartment_Min_Order_By = {
  apartment_number?: InputMaybe<Order_By>
  building_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  floor_number?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  ip_address?: InputMaybe<Order_By>
  monthly_rent?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  profile_photo?: InputMaybe<Order_By>
  security_deposit?: InputMaybe<Order_By>
  type_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "apartment" */
export type Apartment_Mutation_Response = {
  __typename?: 'apartment_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Apartment>
}

/** input type for inserting object relation for remote table "apartment" */
export type Apartment_Obj_Rel_Insert_Input = {
  data: Apartment_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Apartment_On_Conflict>
}

/** on_conflict condition type for table "apartment" */
export type Apartment_On_Conflict = {
  constraint: Apartment_Constraint
  update_columns?: Array<Apartment_Update_Column>
  where?: InputMaybe<Apartment_Bool_Exp>
}

/** Ordering options when selecting data from "apartment". */
export type Apartment_Order_By = {
  apartment_number?: InputMaybe<Order_By>
  apartment_rental_type?: InputMaybe<Apartment_Rental_Type_Order_By>
  building?: InputMaybe<Building_Order_By>
  building_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  devices_aggregate?: InputMaybe<Device_Aggregate_Order_By>
  floor_number?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  ip_address?: InputMaybe<Order_By>
  monthly_rent?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  profile_photo?: InputMaybe<Order_By>
  rental_type?: InputMaybe<Order_By>
  scenes_aggregate?: InputMaybe<Apartment_Scene_Aggregate_Order_By>
  security_deposit?: InputMaybe<Order_By>
  type?: InputMaybe<Apartment_Type_Order_By>
  type_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_applications_aggregate?: InputMaybe<User_Application_Aggregate_Order_By>
  users_aggregate?: InputMaybe<User_Apartment_Aggregate_Order_By>
}

/** primary key columns input for table: apartment */
export type Apartment_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** columns and relationships of "apartment_rental_type" */
export type Apartment_Rental_Type = {
  __typename?: 'apartment_rental_type'
  /** An array relationship */
  apartments: Array<Apartment>
  /** An aggregate relationship */
  apartments_aggregate: Apartment_Aggregate
  comment: Scalars['String']['output']
  /** An array relationship */
  user_applications: Array<User_Application>
  /** An aggregate relationship */
  user_applications_aggregate: User_Application_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "apartment_rental_type" */
export type Apartment_Rental_TypeApartmentsArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Order_By>>
  where?: InputMaybe<Apartment_Bool_Exp>
}

/** columns and relationships of "apartment_rental_type" */
export type Apartment_Rental_TypeApartments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Order_By>>
  where?: InputMaybe<Apartment_Bool_Exp>
}

/** columns and relationships of "apartment_rental_type" */
export type Apartment_Rental_TypeUser_ApplicationsArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Order_By>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

/** columns and relationships of "apartment_rental_type" */
export type Apartment_Rental_TypeUser_Applications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Order_By>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

/** aggregated selection of "apartment_rental_type" */
export type Apartment_Rental_Type_Aggregate = {
  __typename?: 'apartment_rental_type_aggregate'
  aggregate?: Maybe<Apartment_Rental_Type_Aggregate_Fields>
  nodes: Array<Apartment_Rental_Type>
}

/** aggregate fields of "apartment_rental_type" */
export type Apartment_Rental_Type_Aggregate_Fields = {
  __typename?: 'apartment_rental_type_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Apartment_Rental_Type_Max_Fields>
  min?: Maybe<Apartment_Rental_Type_Min_Fields>
}

/** aggregate fields of "apartment_rental_type" */
export type Apartment_Rental_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apartment_Rental_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "apartment_rental_type". All fields are combined with a logical 'AND'. */
export type Apartment_Rental_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Apartment_Rental_Type_Bool_Exp>>
  _not?: InputMaybe<Apartment_Rental_Type_Bool_Exp>
  _or?: InputMaybe<Array<Apartment_Rental_Type_Bool_Exp>>
  apartments?: InputMaybe<Apartment_Bool_Exp>
  apartments_aggregate?: InputMaybe<Apartment_Aggregate_Bool_Exp>
  comment?: InputMaybe<String_Comparison_Exp>
  user_applications?: InputMaybe<User_Application_Bool_Exp>
  user_applications_aggregate?: InputMaybe<User_Application_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "apartment_rental_type" */
export enum Apartment_Rental_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  ApartmentRentalTypePkey = 'apartment_rental_type_pkey'
}

export enum Apartment_Rental_Type_Enum {
  /** LONG_THERM */
  LongTherm = 'LONG_THERM',
  /** SHORT_AND_LONG_THERM */
  ShortAndLongTherm = 'SHORT_AND_LONG_THERM',
  /** SHORT_THERM */
  ShortTherm = 'SHORT_THERM'
}

/** Boolean expression to compare columns of type "apartment_rental_type_enum". All fields are combined with logical 'AND'. */
export type Apartment_Rental_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Apartment_Rental_Type_Enum>
  _in?: InputMaybe<Array<Apartment_Rental_Type_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Apartment_Rental_Type_Enum>
  _nin?: InputMaybe<Array<Apartment_Rental_Type_Enum>>
}

/** input type for inserting data into table "apartment_rental_type" */
export type Apartment_Rental_Type_Insert_Input = {
  apartments?: InputMaybe<Apartment_Arr_Rel_Insert_Input>
  comment?: InputMaybe<Scalars['String']['input']>
  user_applications?: InputMaybe<User_Application_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Apartment_Rental_Type_Max_Fields = {
  __typename?: 'apartment_rental_type_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Apartment_Rental_Type_Min_Fields = {
  __typename?: 'apartment_rental_type_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "apartment_rental_type" */
export type Apartment_Rental_Type_Mutation_Response = {
  __typename?: 'apartment_rental_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Apartment_Rental_Type>
}

/** input type for inserting object relation for remote table "apartment_rental_type" */
export type Apartment_Rental_Type_Obj_Rel_Insert_Input = {
  data: Apartment_Rental_Type_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Apartment_Rental_Type_On_Conflict>
}

/** on_conflict condition type for table "apartment_rental_type" */
export type Apartment_Rental_Type_On_Conflict = {
  constraint: Apartment_Rental_Type_Constraint
  update_columns?: Array<Apartment_Rental_Type_Update_Column>
  where?: InputMaybe<Apartment_Rental_Type_Bool_Exp>
}

/** Ordering options when selecting data from "apartment_rental_type". */
export type Apartment_Rental_Type_Order_By = {
  apartments_aggregate?: InputMaybe<Apartment_Aggregate_Order_By>
  comment?: InputMaybe<Order_By>
  user_applications_aggregate?: InputMaybe<User_Application_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: apartment_rental_type */
export type Apartment_Rental_Type_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "apartment_rental_type" */
export enum Apartment_Rental_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "apartment_rental_type" */
export type Apartment_Rental_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "apartment_rental_type" */
export type Apartment_Rental_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apartment_Rental_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Apartment_Rental_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "apartment_rental_type" */
export enum Apartment_Rental_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Apartment_Rental_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apartment_Rental_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Apartment_Rental_Type_Bool_Exp
}

/** columns and relationships of "apartment_scene" */
export type Apartment_Scene = {
  __typename?: 'apartment_scene'
  /** An object relationship */
  apartment: Apartment
  apartment_id: Scalars['uuid']['output']
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  /** An array relationship */
  devices: Array<Device_Scene>
  /** An aggregate relationship */
  devices_aggregate: Device_Scene_Aggregate
  icon_name: Scalars['String']['output']
  id: Scalars['uuid']['output']
  name: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** columns and relationships of "apartment_scene" */
export type Apartment_SceneDevicesArgs = {
  distinct_on?: InputMaybe<Array<Device_Scene_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Scene_Order_By>>
  where?: InputMaybe<Device_Scene_Bool_Exp>
}

/** columns and relationships of "apartment_scene" */
export type Apartment_SceneDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Scene_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Scene_Order_By>>
  where?: InputMaybe<Device_Scene_Bool_Exp>
}

/** aggregated selection of "apartment_scene" */
export type Apartment_Scene_Aggregate = {
  __typename?: 'apartment_scene_aggregate'
  aggregate?: Maybe<Apartment_Scene_Aggregate_Fields>
  nodes: Array<Apartment_Scene>
}

export type Apartment_Scene_Aggregate_Bool_Exp = {
  count?: InputMaybe<Apartment_Scene_Aggregate_Bool_Exp_Count>
}

export type Apartment_Scene_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Apartment_Scene_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Apartment_Scene_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "apartment_scene" */
export type Apartment_Scene_Aggregate_Fields = {
  __typename?: 'apartment_scene_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Apartment_Scene_Max_Fields>
  min?: Maybe<Apartment_Scene_Min_Fields>
}

/** aggregate fields of "apartment_scene" */
export type Apartment_Scene_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apartment_Scene_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "apartment_scene" */
export type Apartment_Scene_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Apartment_Scene_Max_Order_By>
  min?: InputMaybe<Apartment_Scene_Min_Order_By>
}

/** input type for inserting array relation for remote table "apartment_scene" */
export type Apartment_Scene_Arr_Rel_Insert_Input = {
  data: Array<Apartment_Scene_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Apartment_Scene_On_Conflict>
}

/** Boolean expression to filter rows from the table "apartment_scene". All fields are combined with a logical 'AND'. */
export type Apartment_Scene_Bool_Exp = {
  _and?: InputMaybe<Array<Apartment_Scene_Bool_Exp>>
  _not?: InputMaybe<Apartment_Scene_Bool_Exp>
  _or?: InputMaybe<Array<Apartment_Scene_Bool_Exp>>
  apartment?: InputMaybe<Apartment_Bool_Exp>
  apartment_id?: InputMaybe<Uuid_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  devices?: InputMaybe<Device_Scene_Bool_Exp>
  devices_aggregate?: InputMaybe<Device_Scene_Aggregate_Bool_Exp>
  icon_name?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "apartment_scene" */
export enum Apartment_Scene_Constraint {
  /** unique or primary key constraint on columns "id" */
  ApartmentScenePkey = 'apartment_scene_pkey'
}

/** input type for inserting data into table "apartment_scene" */
export type Apartment_Scene_Insert_Input = {
  apartment?: InputMaybe<Apartment_Obj_Rel_Insert_Input>
  apartment_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  devices?: InputMaybe<Device_Scene_Arr_Rel_Insert_Input>
  icon_name?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Apartment_Scene_Max_Fields = {
  __typename?: 'apartment_scene_max_fields'
  apartment_id?: Maybe<Scalars['uuid']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  icon_name?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "apartment_scene" */
export type Apartment_Scene_Max_Order_By = {
  apartment_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  icon_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Apartment_Scene_Min_Fields = {
  __typename?: 'apartment_scene_min_fields'
  apartment_id?: Maybe<Scalars['uuid']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  icon_name?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "apartment_scene" */
export type Apartment_Scene_Min_Order_By = {
  apartment_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  icon_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "apartment_scene" */
export type Apartment_Scene_Mutation_Response = {
  __typename?: 'apartment_scene_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Apartment_Scene>
}

/** input type for inserting object relation for remote table "apartment_scene" */
export type Apartment_Scene_Obj_Rel_Insert_Input = {
  data: Apartment_Scene_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Apartment_Scene_On_Conflict>
}

/** on_conflict condition type for table "apartment_scene" */
export type Apartment_Scene_On_Conflict = {
  constraint: Apartment_Scene_Constraint
  update_columns?: Array<Apartment_Scene_Update_Column>
  where?: InputMaybe<Apartment_Scene_Bool_Exp>
}

/** Ordering options when selecting data from "apartment_scene". */
export type Apartment_Scene_Order_By = {
  apartment?: InputMaybe<Apartment_Order_By>
  apartment_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  devices_aggregate?: InputMaybe<Device_Scene_Aggregate_Order_By>
  icon_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: apartment_scene */
export type Apartment_Scene_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "apartment_scene" */
export enum Apartment_Scene_Select_Column {
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  IconName = 'icon_name',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "apartment_scene" */
export type Apartment_Scene_Set_Input = {
  apartment_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  icon_name?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "apartment_scene" */
export type Apartment_Scene_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apartment_Scene_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Apartment_Scene_Stream_Cursor_Value_Input = {
  apartment_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  icon_name?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "apartment_scene" */
export enum Apartment_Scene_Update_Column {
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  IconName = 'icon_name',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Apartment_Scene_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apartment_Scene_Set_Input>
  /** filter the rows which have to be updated */
  where: Apartment_Scene_Bool_Exp
}

/** select columns of table "apartment" */
export enum Apartment_Select_Column {
  /** column name */
  ApartmentNumber = 'apartment_number',
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  FloorNumber = 'floor_number',
  /** column name */
  Id = 'id',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  MonthlyRent = 'monthly_rent',
  /** column name */
  Name = 'name',
  /** column name */
  ProfilePhoto = 'profile_photo',
  /** column name */
  RentalType = 'rental_type',
  /** column name */
  SecurityDeposit = 'security_deposit',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "apartment" */
export type Apartment_Set_Input = {
  apartment_number?: InputMaybe<Scalars['String']['input']>
  building_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  floor_number?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  ip_address?: InputMaybe<Scalars['String']['input']>
  monthly_rent?: InputMaybe<Scalars['Float']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  profile_photo?: InputMaybe<Scalars['String']['input']>
  rental_type?: InputMaybe<Apartment_Rental_Type_Enum>
  security_deposit?: InputMaybe<Scalars['Float']['input']>
  type_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Apartment_Stddev_Fields = {
  __typename?: 'apartment_stddev_fields'
  monthly_rent?: Maybe<Scalars['Float']['output']>
  security_deposit?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "apartment" */
export type Apartment_Stddev_Order_By = {
  monthly_rent?: InputMaybe<Order_By>
  security_deposit?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Apartment_Stddev_Pop_Fields = {
  __typename?: 'apartment_stddev_pop_fields'
  monthly_rent?: Maybe<Scalars['Float']['output']>
  security_deposit?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "apartment" */
export type Apartment_Stddev_Pop_Order_By = {
  monthly_rent?: InputMaybe<Order_By>
  security_deposit?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Apartment_Stddev_Samp_Fields = {
  __typename?: 'apartment_stddev_samp_fields'
  monthly_rent?: Maybe<Scalars['Float']['output']>
  security_deposit?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "apartment" */
export type Apartment_Stddev_Samp_Order_By = {
  monthly_rent?: InputMaybe<Order_By>
  security_deposit?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "apartment" */
export type Apartment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apartment_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Apartment_Stream_Cursor_Value_Input = {
  apartment_number?: InputMaybe<Scalars['String']['input']>
  building_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  floor_number?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  ip_address?: InputMaybe<Scalars['String']['input']>
  monthly_rent?: InputMaybe<Scalars['Float']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  profile_photo?: InputMaybe<Scalars['String']['input']>
  rental_type?: InputMaybe<Apartment_Rental_Type_Enum>
  security_deposit?: InputMaybe<Scalars['Float']['input']>
  type_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Apartment_Sum_Fields = {
  __typename?: 'apartment_sum_fields'
  monthly_rent?: Maybe<Scalars['Float']['output']>
  security_deposit?: Maybe<Scalars['Float']['output']>
}

/** order by sum() on columns of table "apartment" */
export type Apartment_Sum_Order_By = {
  monthly_rent?: InputMaybe<Order_By>
  security_deposit?: InputMaybe<Order_By>
}

/** columns and relationships of "apartment_type" */
export type Apartment_Type = {
  __typename?: 'apartment_type'
  /** An array relationship */
  apartments: Array<Apartment>
  /** An aggregate relationship */
  apartments_aggregate: Apartment_Aggregate
  comments: Scalars['String']['output']
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id: Scalars['uuid']['output']
  label: Scalars['String']['output']
  metadata: Scalars['jsonb']['output']
  number_of_bathrooms: Scalars['Float']['output']
  number_of_bedrooms: Scalars['Float']['output']
  order?: Maybe<Scalars['numeric']['output']>
  /** An array relationship */
  photos: Array<Apartment_Type_Photo>
  /** An aggregate relationship */
  photos_aggregate: Apartment_Type_Photo_Aggregate
  updated_at: Scalars['timestamptz']['output']
  value: Scalars['String']['output']
  webview_preview_url: Scalars['String']['output']
}

/** columns and relationships of "apartment_type" */
export type Apartment_TypeApartmentsArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Order_By>>
  where?: InputMaybe<Apartment_Bool_Exp>
}

/** columns and relationships of "apartment_type" */
export type Apartment_TypeApartments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Order_By>>
  where?: InputMaybe<Apartment_Bool_Exp>
}

/** columns and relationships of "apartment_type" */
export type Apartment_TypeMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** columns and relationships of "apartment_type" */
export type Apartment_TypePhotosArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Type_Photo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Type_Photo_Order_By>>
  where?: InputMaybe<Apartment_Type_Photo_Bool_Exp>
}

/** columns and relationships of "apartment_type" */
export type Apartment_TypePhotos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Type_Photo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Type_Photo_Order_By>>
  where?: InputMaybe<Apartment_Type_Photo_Bool_Exp>
}

/** aggregated selection of "apartment_type" */
export type Apartment_Type_Aggregate = {
  __typename?: 'apartment_type_aggregate'
  aggregate?: Maybe<Apartment_Type_Aggregate_Fields>
  nodes: Array<Apartment_Type>
}

/** aggregate fields of "apartment_type" */
export type Apartment_Type_Aggregate_Fields = {
  __typename?: 'apartment_type_aggregate_fields'
  avg?: Maybe<Apartment_Type_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Apartment_Type_Max_Fields>
  min?: Maybe<Apartment_Type_Min_Fields>
  stddev?: Maybe<Apartment_Type_Stddev_Fields>
  stddev_pop?: Maybe<Apartment_Type_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Apartment_Type_Stddev_Samp_Fields>
  sum?: Maybe<Apartment_Type_Sum_Fields>
  var_pop?: Maybe<Apartment_Type_Var_Pop_Fields>
  var_samp?: Maybe<Apartment_Type_Var_Samp_Fields>
  variance?: Maybe<Apartment_Type_Variance_Fields>
}

/** aggregate fields of "apartment_type" */
export type Apartment_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apartment_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Apartment_Type_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>
}

/** aggregate avg on columns */
export type Apartment_Type_Avg_Fields = {
  __typename?: 'apartment_type_avg_fields'
  number_of_bathrooms?: Maybe<Scalars['Float']['output']>
  number_of_bedrooms?: Maybe<Scalars['Float']['output']>
  order?: Maybe<Scalars['Float']['output']>
}

/** Boolean expression to filter rows from the table "apartment_type". All fields are combined with a logical 'AND'. */
export type Apartment_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Apartment_Type_Bool_Exp>>
  _not?: InputMaybe<Apartment_Type_Bool_Exp>
  _or?: InputMaybe<Array<Apartment_Type_Bool_Exp>>
  apartments?: InputMaybe<Apartment_Bool_Exp>
  apartments_aggregate?: InputMaybe<Apartment_Aggregate_Bool_Exp>
  comments?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  label?: InputMaybe<String_Comparison_Exp>
  metadata?: InputMaybe<Jsonb_Comparison_Exp>
  number_of_bathrooms?: InputMaybe<Float_Comparison_Exp>
  number_of_bedrooms?: InputMaybe<Float_Comparison_Exp>
  order?: InputMaybe<Numeric_Comparison_Exp>
  photos?: InputMaybe<Apartment_Type_Photo_Bool_Exp>
  photos_aggregate?: InputMaybe<Apartment_Type_Photo_Aggregate_Bool_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  value?: InputMaybe<String_Comparison_Exp>
  webview_preview_url?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "apartment_type" */
export enum Apartment_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  ApartmentTypePkey = 'apartment_type_pkey',
  /** unique or primary key constraint on columns "value" */
  ApartmentTypeValueKey = 'apartment_type_value_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Apartment_Type_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Apartment_Type_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Apartment_Type_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>
}

/** input type for incrementing numeric columns in table "apartment_type" */
export type Apartment_Type_Inc_Input = {
  number_of_bathrooms?: InputMaybe<Scalars['Float']['input']>
  number_of_bedrooms?: InputMaybe<Scalars['Float']['input']>
  order?: InputMaybe<Scalars['numeric']['input']>
}

/** input type for inserting data into table "apartment_type" */
export type Apartment_Type_Insert_Input = {
  apartments?: InputMaybe<Apartment_Arr_Rel_Insert_Input>
  comments?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  label?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['jsonb']['input']>
  number_of_bathrooms?: InputMaybe<Scalars['Float']['input']>
  number_of_bedrooms?: InputMaybe<Scalars['Float']['input']>
  order?: InputMaybe<Scalars['numeric']['input']>
  photos?: InputMaybe<Apartment_Type_Photo_Arr_Rel_Insert_Input>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
  webview_preview_url?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Apartment_Type_Max_Fields = {
  __typename?: 'apartment_type_max_fields'
  comments?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  label?: Maybe<Scalars['String']['output']>
  number_of_bathrooms?: Maybe<Scalars['Float']['output']>
  number_of_bedrooms?: Maybe<Scalars['Float']['output']>
  order?: Maybe<Scalars['numeric']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  value?: Maybe<Scalars['String']['output']>
  webview_preview_url?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Apartment_Type_Min_Fields = {
  __typename?: 'apartment_type_min_fields'
  comments?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  label?: Maybe<Scalars['String']['output']>
  number_of_bathrooms?: Maybe<Scalars['Float']['output']>
  number_of_bedrooms?: Maybe<Scalars['Float']['output']>
  order?: Maybe<Scalars['numeric']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  value?: Maybe<Scalars['String']['output']>
  webview_preview_url?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "apartment_type" */
export type Apartment_Type_Mutation_Response = {
  __typename?: 'apartment_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Apartment_Type>
}

/** input type for inserting object relation for remote table "apartment_type" */
export type Apartment_Type_Obj_Rel_Insert_Input = {
  data: Apartment_Type_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Apartment_Type_On_Conflict>
}

/** on_conflict condition type for table "apartment_type" */
export type Apartment_Type_On_Conflict = {
  constraint: Apartment_Type_Constraint
  update_columns?: Array<Apartment_Type_Update_Column>
  where?: InputMaybe<Apartment_Type_Bool_Exp>
}

/** Ordering options when selecting data from "apartment_type". */
export type Apartment_Type_Order_By = {
  apartments_aggregate?: InputMaybe<Apartment_Aggregate_Order_By>
  comments?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  label?: InputMaybe<Order_By>
  metadata?: InputMaybe<Order_By>
  number_of_bathrooms?: InputMaybe<Order_By>
  number_of_bedrooms?: InputMaybe<Order_By>
  order?: InputMaybe<Order_By>
  photos_aggregate?: InputMaybe<Apartment_Type_Photo_Aggregate_Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
  webview_preview_url?: InputMaybe<Order_By>
}

/** columns and relationships of "apartment_type_photo" */
export type Apartment_Type_Photo = {
  __typename?: 'apartment_type_photo'
  /** An object relationship */
  apartment_type: Apartment_Type
  apartment_type_id: Scalars['uuid']['output']
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  folder: Scalars['String']['output']
  id: Scalars['uuid']['output']
  path: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
}

/** aggregated selection of "apartment_type_photo" */
export type Apartment_Type_Photo_Aggregate = {
  __typename?: 'apartment_type_photo_aggregate'
  aggregate?: Maybe<Apartment_Type_Photo_Aggregate_Fields>
  nodes: Array<Apartment_Type_Photo>
}

export type Apartment_Type_Photo_Aggregate_Bool_Exp = {
  count?: InputMaybe<Apartment_Type_Photo_Aggregate_Bool_Exp_Count>
}

export type Apartment_Type_Photo_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Apartment_Type_Photo_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Apartment_Type_Photo_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "apartment_type_photo" */
export type Apartment_Type_Photo_Aggregate_Fields = {
  __typename?: 'apartment_type_photo_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Apartment_Type_Photo_Max_Fields>
  min?: Maybe<Apartment_Type_Photo_Min_Fields>
}

/** aggregate fields of "apartment_type_photo" */
export type Apartment_Type_Photo_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apartment_Type_Photo_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "apartment_type_photo" */
export type Apartment_Type_Photo_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Apartment_Type_Photo_Max_Order_By>
  min?: InputMaybe<Apartment_Type_Photo_Min_Order_By>
}

/** input type for inserting array relation for remote table "apartment_type_photo" */
export type Apartment_Type_Photo_Arr_Rel_Insert_Input = {
  data: Array<Apartment_Type_Photo_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Apartment_Type_Photo_On_Conflict>
}

/** Boolean expression to filter rows from the table "apartment_type_photo". All fields are combined with a logical 'AND'. */
export type Apartment_Type_Photo_Bool_Exp = {
  _and?: InputMaybe<Array<Apartment_Type_Photo_Bool_Exp>>
  _not?: InputMaybe<Apartment_Type_Photo_Bool_Exp>
  _or?: InputMaybe<Array<Apartment_Type_Photo_Bool_Exp>>
  apartment_type?: InputMaybe<Apartment_Type_Bool_Exp>
  apartment_type_id?: InputMaybe<Uuid_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  folder?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  path?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "apartment_type_photo" */
export enum Apartment_Type_Photo_Constraint {
  /** unique or primary key constraint on columns "id" */
  ApartmentTypePhotosPkey = 'apartment_type_photos_pkey'
}

/** input type for inserting data into table "apartment_type_photo" */
export type Apartment_Type_Photo_Insert_Input = {
  apartment_type?: InputMaybe<Apartment_Type_Obj_Rel_Insert_Input>
  apartment_type_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  folder?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  path?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate max on columns */
export type Apartment_Type_Photo_Max_Fields = {
  __typename?: 'apartment_type_photo_max_fields'
  apartment_type_id?: Maybe<Scalars['uuid']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  folder?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  path?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "apartment_type_photo" */
export type Apartment_Type_Photo_Max_Order_By = {
  apartment_type_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  folder?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  path?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Apartment_Type_Photo_Min_Fields = {
  __typename?: 'apartment_type_photo_min_fields'
  apartment_type_id?: Maybe<Scalars['uuid']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  folder?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  path?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "apartment_type_photo" */
export type Apartment_Type_Photo_Min_Order_By = {
  apartment_type_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  folder?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  path?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "apartment_type_photo" */
export type Apartment_Type_Photo_Mutation_Response = {
  __typename?: 'apartment_type_photo_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Apartment_Type_Photo>
}

/** on_conflict condition type for table "apartment_type_photo" */
export type Apartment_Type_Photo_On_Conflict = {
  constraint: Apartment_Type_Photo_Constraint
  update_columns?: Array<Apartment_Type_Photo_Update_Column>
  where?: InputMaybe<Apartment_Type_Photo_Bool_Exp>
}

/** Ordering options when selecting data from "apartment_type_photo". */
export type Apartment_Type_Photo_Order_By = {
  apartment_type?: InputMaybe<Apartment_Type_Order_By>
  apartment_type_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  folder?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  path?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: apartment_type_photo */
export type Apartment_Type_Photo_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "apartment_type_photo" */
export enum Apartment_Type_Photo_Select_Column {
  /** column name */
  ApartmentTypeId = 'apartment_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Folder = 'folder',
  /** column name */
  Id = 'id',
  /** column name */
  Path = 'path',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "apartment_type_photo" */
export type Apartment_Type_Photo_Set_Input = {
  apartment_type_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  folder?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  path?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "apartment_type_photo" */
export type Apartment_Type_Photo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apartment_Type_Photo_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Apartment_Type_Photo_Stream_Cursor_Value_Input = {
  apartment_type_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  folder?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  path?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "apartment_type_photo" */
export enum Apartment_Type_Photo_Update_Column {
  /** column name */
  ApartmentTypeId = 'apartment_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Folder = 'folder',
  /** column name */
  Id = 'id',
  /** column name */
  Path = 'path',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Apartment_Type_Photo_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apartment_Type_Photo_Set_Input>
  /** filter the rows which have to be updated */
  where: Apartment_Type_Photo_Bool_Exp
}

/** primary key columns input for table: apartment_type */
export type Apartment_Type_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Apartment_Type_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "apartment_type" */
export enum Apartment_Type_Select_Column {
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NumberOfBathrooms = 'number_of_bathrooms',
  /** column name */
  NumberOfBedrooms = 'number_of_bedrooms',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  WebviewPreviewUrl = 'webview_preview_url'
}

/** input type for updating data in table "apartment_type" */
export type Apartment_Type_Set_Input = {
  comments?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  label?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['jsonb']['input']>
  number_of_bathrooms?: InputMaybe<Scalars['Float']['input']>
  number_of_bedrooms?: InputMaybe<Scalars['Float']['input']>
  order?: InputMaybe<Scalars['numeric']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
  webview_preview_url?: InputMaybe<Scalars['String']['input']>
}

/** aggregate stddev on columns */
export type Apartment_Type_Stddev_Fields = {
  __typename?: 'apartment_type_stddev_fields'
  number_of_bathrooms?: Maybe<Scalars['Float']['output']>
  number_of_bedrooms?: Maybe<Scalars['Float']['output']>
  order?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_pop on columns */
export type Apartment_Type_Stddev_Pop_Fields = {
  __typename?: 'apartment_type_stddev_pop_fields'
  number_of_bathrooms?: Maybe<Scalars['Float']['output']>
  number_of_bedrooms?: Maybe<Scalars['Float']['output']>
  order?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_samp on columns */
export type Apartment_Type_Stddev_Samp_Fields = {
  __typename?: 'apartment_type_stddev_samp_fields'
  number_of_bathrooms?: Maybe<Scalars['Float']['output']>
  number_of_bedrooms?: Maybe<Scalars['Float']['output']>
  order?: Maybe<Scalars['Float']['output']>
}

/** Streaming cursor of the table "apartment_type" */
export type Apartment_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apartment_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Apartment_Type_Stream_Cursor_Value_Input = {
  comments?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  label?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['jsonb']['input']>
  number_of_bathrooms?: InputMaybe<Scalars['Float']['input']>
  number_of_bedrooms?: InputMaybe<Scalars['Float']['input']>
  order?: InputMaybe<Scalars['numeric']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
  webview_preview_url?: InputMaybe<Scalars['String']['input']>
}

/** aggregate sum on columns */
export type Apartment_Type_Sum_Fields = {
  __typename?: 'apartment_type_sum_fields'
  number_of_bathrooms?: Maybe<Scalars['Float']['output']>
  number_of_bedrooms?: Maybe<Scalars['Float']['output']>
  order?: Maybe<Scalars['numeric']['output']>
}

/** update columns of table "apartment_type" */
export enum Apartment_Type_Update_Column {
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NumberOfBathrooms = 'number_of_bathrooms',
  /** column name */
  NumberOfBedrooms = 'number_of_bedrooms',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  WebviewPreviewUrl = 'webview_preview_url'
}

export type Apartment_Type_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Apartment_Type_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Apartment_Type_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Apartment_Type_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Apartment_Type_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apartment_Type_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Apartment_Type_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apartment_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Apartment_Type_Bool_Exp
}

/** aggregate var_pop on columns */
export type Apartment_Type_Var_Pop_Fields = {
  __typename?: 'apartment_type_var_pop_fields'
  number_of_bathrooms?: Maybe<Scalars['Float']['output']>
  number_of_bedrooms?: Maybe<Scalars['Float']['output']>
  order?: Maybe<Scalars['Float']['output']>
}

/** aggregate var_samp on columns */
export type Apartment_Type_Var_Samp_Fields = {
  __typename?: 'apartment_type_var_samp_fields'
  number_of_bathrooms?: Maybe<Scalars['Float']['output']>
  number_of_bedrooms?: Maybe<Scalars['Float']['output']>
  order?: Maybe<Scalars['Float']['output']>
}

/** aggregate variance on columns */
export type Apartment_Type_Variance_Fields = {
  __typename?: 'apartment_type_variance_fields'
  number_of_bathrooms?: Maybe<Scalars['Float']['output']>
  number_of_bedrooms?: Maybe<Scalars['Float']['output']>
  order?: Maybe<Scalars['Float']['output']>
}

/** update columns of table "apartment" */
export enum Apartment_Update_Column {
  /** column name */
  ApartmentNumber = 'apartment_number',
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  FloorNumber = 'floor_number',
  /** column name */
  Id = 'id',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  MonthlyRent = 'monthly_rent',
  /** column name */
  Name = 'name',
  /** column name */
  ProfilePhoto = 'profile_photo',
  /** column name */
  RentalType = 'rental_type',
  /** column name */
  SecurityDeposit = 'security_deposit',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Apartment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Apartment_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apartment_Set_Input>
  /** filter the rows which have to be updated */
  where: Apartment_Bool_Exp
}

/** aggregate var_pop on columns */
export type Apartment_Var_Pop_Fields = {
  __typename?: 'apartment_var_pop_fields'
  monthly_rent?: Maybe<Scalars['Float']['output']>
  security_deposit?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "apartment" */
export type Apartment_Var_Pop_Order_By = {
  monthly_rent?: InputMaybe<Order_By>
  security_deposit?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Apartment_Var_Samp_Fields = {
  __typename?: 'apartment_var_samp_fields'
  monthly_rent?: Maybe<Scalars['Float']['output']>
  security_deposit?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "apartment" */
export type Apartment_Var_Samp_Order_By = {
  monthly_rent?: InputMaybe<Order_By>
  security_deposit?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Apartment_Variance_Fields = {
  __typename?: 'apartment_variance_fields'
  monthly_rent?: Maybe<Scalars['Float']['output']>
  security_deposit?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "apartment" */
export type Apartment_Variance_Order_By = {
  monthly_rent?: InputMaybe<Order_By>
  security_deposit?: InputMaybe<Order_By>
}

/** columns and relationships of "building" */
export type Building = {
  __typename?: 'building'
  /** An array relationship */
  apartments: Array<Apartment>
  /** An aggregate relationship */
  apartments_aggregate: Apartment_Aggregate
  /** An object relationship */
  building_status?: Maybe<Building_Status>
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description: Scalars['String']['output']
  /** An array relationship */
  devices: Array<Device>
  /** An aggregate relationship */
  devices_aggregate: Device_Aggregate
  geolocation?: Maybe<Scalars['geography']['output']>
  id: Scalars['uuid']['output']
  ip_address?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  number_of_apartments: Scalars['Float']['output']
  number_of_floors: Scalars['Float']['output']
  profile_photo: Scalars['String']['output']
  status?: Maybe<Building_Status_Enum>
  updated_at: Scalars['timestamptz']['output']
  /** An array relationship */
  user_applications: Array<User_Application>
  /** An aggregate relationship */
  user_applications_aggregate: User_Application_Aggregate
}

/** columns and relationships of "building" */
export type BuildingApartmentsArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Order_By>>
  where?: InputMaybe<Apartment_Bool_Exp>
}

/** columns and relationships of "building" */
export type BuildingApartments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Order_By>>
  where?: InputMaybe<Apartment_Bool_Exp>
}

/** columns and relationships of "building" */
export type BuildingDevicesArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

/** columns and relationships of "building" */
export type BuildingDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

/** columns and relationships of "building" */
export type BuildingUser_ApplicationsArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Order_By>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

/** columns and relationships of "building" */
export type BuildingUser_Applications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Order_By>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

/** aggregated selection of "building" */
export type Building_Aggregate = {
  __typename?: 'building_aggregate'
  aggregate?: Maybe<Building_Aggregate_Fields>
  nodes: Array<Building>
}

export type Building_Aggregate_Bool_Exp = {
  count?: InputMaybe<Building_Aggregate_Bool_Exp_Count>
}

export type Building_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Building_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Building_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "building" */
export type Building_Aggregate_Fields = {
  __typename?: 'building_aggregate_fields'
  avg?: Maybe<Building_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Building_Max_Fields>
  min?: Maybe<Building_Min_Fields>
  stddev?: Maybe<Building_Stddev_Fields>
  stddev_pop?: Maybe<Building_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Building_Stddev_Samp_Fields>
  sum?: Maybe<Building_Sum_Fields>
  var_pop?: Maybe<Building_Var_Pop_Fields>
  var_samp?: Maybe<Building_Var_Samp_Fields>
  variance?: Maybe<Building_Variance_Fields>
}

/** aggregate fields of "building" */
export type Building_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Building_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "building" */
export type Building_Aggregate_Order_By = {
  avg?: InputMaybe<Building_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Building_Max_Order_By>
  min?: InputMaybe<Building_Min_Order_By>
  stddev?: InputMaybe<Building_Stddev_Order_By>
  stddev_pop?: InputMaybe<Building_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Building_Stddev_Samp_Order_By>
  sum?: InputMaybe<Building_Sum_Order_By>
  var_pop?: InputMaybe<Building_Var_Pop_Order_By>
  var_samp?: InputMaybe<Building_Var_Samp_Order_By>
  variance?: InputMaybe<Building_Variance_Order_By>
}

/** input type for inserting array relation for remote table "building" */
export type Building_Arr_Rel_Insert_Input = {
  data: Array<Building_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Building_On_Conflict>
}

/** aggregate avg on columns */
export type Building_Avg_Fields = {
  __typename?: 'building_avg_fields'
  number_of_apartments?: Maybe<Scalars['Float']['output']>
  number_of_floors?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "building" */
export type Building_Avg_Order_By = {
  number_of_apartments?: InputMaybe<Order_By>
  number_of_floors?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "building". All fields are combined with a logical 'AND'. */
export type Building_Bool_Exp = {
  _and?: InputMaybe<Array<Building_Bool_Exp>>
  _not?: InputMaybe<Building_Bool_Exp>
  _or?: InputMaybe<Array<Building_Bool_Exp>>
  apartments?: InputMaybe<Apartment_Bool_Exp>
  apartments_aggregate?: InputMaybe<Apartment_Aggregate_Bool_Exp>
  building_status?: InputMaybe<Building_Status_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  devices?: InputMaybe<Device_Bool_Exp>
  devices_aggregate?: InputMaybe<Device_Aggregate_Bool_Exp>
  geolocation?: InputMaybe<Geography_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  ip_address?: InputMaybe<String_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  number_of_apartments?: InputMaybe<Float_Comparison_Exp>
  number_of_floors?: InputMaybe<Float_Comparison_Exp>
  profile_photo?: InputMaybe<String_Comparison_Exp>
  status?: InputMaybe<Building_Status_Enum_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user_applications?: InputMaybe<User_Application_Bool_Exp>
  user_applications_aggregate?: InputMaybe<User_Application_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "building" */
export enum Building_Constraint {
  /** unique or primary key constraint on columns "id" */
  BuildingPkey = 'building_pkey'
}

/** input type for incrementing numeric columns in table "building" */
export type Building_Inc_Input = {
  number_of_apartments?: InputMaybe<Scalars['Float']['input']>
  number_of_floors?: InputMaybe<Scalars['Float']['input']>
}

/** input type for inserting data into table "building" */
export type Building_Insert_Input = {
  apartments?: InputMaybe<Apartment_Arr_Rel_Insert_Input>
  building_status?: InputMaybe<Building_Status_Obj_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  devices?: InputMaybe<Device_Arr_Rel_Insert_Input>
  geolocation?: InputMaybe<Scalars['geography']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  ip_address?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  number_of_apartments?: InputMaybe<Scalars['Float']['input']>
  number_of_floors?: InputMaybe<Scalars['Float']['input']>
  profile_photo?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Building_Status_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_applications?: InputMaybe<User_Application_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Building_Max_Fields = {
  __typename?: 'building_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  ip_address?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  number_of_apartments?: Maybe<Scalars['Float']['output']>
  number_of_floors?: Maybe<Scalars['Float']['output']>
  profile_photo?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "building" */
export type Building_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  ip_address?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  number_of_apartments?: InputMaybe<Order_By>
  number_of_floors?: InputMaybe<Order_By>
  profile_photo?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Building_Min_Fields = {
  __typename?: 'building_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  ip_address?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  number_of_apartments?: Maybe<Scalars['Float']['output']>
  number_of_floors?: Maybe<Scalars['Float']['output']>
  profile_photo?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "building" */
export type Building_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  ip_address?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  number_of_apartments?: InputMaybe<Order_By>
  number_of_floors?: InputMaybe<Order_By>
  profile_photo?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "building" */
export type Building_Mutation_Response = {
  __typename?: 'building_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Building>
}

/** input type for inserting object relation for remote table "building" */
export type Building_Obj_Rel_Insert_Input = {
  data: Building_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Building_On_Conflict>
}

/** on_conflict condition type for table "building" */
export type Building_On_Conflict = {
  constraint: Building_Constraint
  update_columns?: Array<Building_Update_Column>
  where?: InputMaybe<Building_Bool_Exp>
}

/** Ordering options when selecting data from "building". */
export type Building_Order_By = {
  apartments_aggregate?: InputMaybe<Apartment_Aggregate_Order_By>
  building_status?: InputMaybe<Building_Status_Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  devices_aggregate?: InputMaybe<Device_Aggregate_Order_By>
  geolocation?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  ip_address?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  number_of_apartments?: InputMaybe<Order_By>
  number_of_floors?: InputMaybe<Order_By>
  profile_photo?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_applications_aggregate?: InputMaybe<User_Application_Aggregate_Order_By>
}

/** primary key columns input for table: building */
export type Building_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "building" */
export enum Building_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Geolocation = 'geolocation',
  /** column name */
  Id = 'id',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  Name = 'name',
  /** column name */
  NumberOfApartments = 'number_of_apartments',
  /** column name */
  NumberOfFloors = 'number_of_floors',
  /** column name */
  ProfilePhoto = 'profile_photo',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "building" */
export type Building_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  geolocation?: InputMaybe<Scalars['geography']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  ip_address?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  number_of_apartments?: InputMaybe<Scalars['Float']['input']>
  number_of_floors?: InputMaybe<Scalars['Float']['input']>
  profile_photo?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Building_Status_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** columns and relationships of "building_status" */
export type Building_Status = {
  __typename?: 'building_status'
  /** An array relationship */
  buildings: Array<Building>
  /** An aggregate relationship */
  buildings_aggregate: Building_Aggregate
  comment: Scalars['String']['output']
  value: Scalars['String']['output']
}

/** columns and relationships of "building_status" */
export type Building_StatusBuildingsArgs = {
  distinct_on?: InputMaybe<Array<Building_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Building_Order_By>>
  where?: InputMaybe<Building_Bool_Exp>
}

/** columns and relationships of "building_status" */
export type Building_StatusBuildings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Building_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Building_Order_By>>
  where?: InputMaybe<Building_Bool_Exp>
}

/** aggregated selection of "building_status" */
export type Building_Status_Aggregate = {
  __typename?: 'building_status_aggregate'
  aggregate?: Maybe<Building_Status_Aggregate_Fields>
  nodes: Array<Building_Status>
}

/** aggregate fields of "building_status" */
export type Building_Status_Aggregate_Fields = {
  __typename?: 'building_status_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Building_Status_Max_Fields>
  min?: Maybe<Building_Status_Min_Fields>
}

/** aggregate fields of "building_status" */
export type Building_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Building_Status_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "building_status". All fields are combined with a logical 'AND'. */
export type Building_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Building_Status_Bool_Exp>>
  _not?: InputMaybe<Building_Status_Bool_Exp>
  _or?: InputMaybe<Array<Building_Status_Bool_Exp>>
  buildings?: InputMaybe<Building_Bool_Exp>
  buildings_aggregate?: InputMaybe<Building_Aggregate_Bool_Exp>
  comment?: InputMaybe<String_Comparison_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "building_status" */
export enum Building_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  BuildingStatusPkey = 'building_status_pkey'
}

export enum Building_Status_Enum {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** IN_PROGRESS */
  InProgress = 'IN_PROGRESS'
}

/** Boolean expression to compare columns of type "building_status_enum". All fields are combined with logical 'AND'. */
export type Building_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Building_Status_Enum>
  _in?: InputMaybe<Array<Building_Status_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Building_Status_Enum>
  _nin?: InputMaybe<Array<Building_Status_Enum>>
}

/** input type for inserting data into table "building_status" */
export type Building_Status_Insert_Input = {
  buildings?: InputMaybe<Building_Arr_Rel_Insert_Input>
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Building_Status_Max_Fields = {
  __typename?: 'building_status_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Building_Status_Min_Fields = {
  __typename?: 'building_status_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "building_status" */
export type Building_Status_Mutation_Response = {
  __typename?: 'building_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Building_Status>
}

/** input type for inserting object relation for remote table "building_status" */
export type Building_Status_Obj_Rel_Insert_Input = {
  data: Building_Status_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Building_Status_On_Conflict>
}

/** on_conflict condition type for table "building_status" */
export type Building_Status_On_Conflict = {
  constraint: Building_Status_Constraint
  update_columns?: Array<Building_Status_Update_Column>
  where?: InputMaybe<Building_Status_Bool_Exp>
}

/** Ordering options when selecting data from "building_status". */
export type Building_Status_Order_By = {
  buildings_aggregate?: InputMaybe<Building_Aggregate_Order_By>
  comment?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: building_status */
export type Building_Status_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "building_status" */
export enum Building_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "building_status" */
export type Building_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "building_status" */
export type Building_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Building_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Building_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "building_status" */
export enum Building_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Building_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Building_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Building_Status_Bool_Exp
}

/** aggregate stddev on columns */
export type Building_Stddev_Fields = {
  __typename?: 'building_stddev_fields'
  number_of_apartments?: Maybe<Scalars['Float']['output']>
  number_of_floors?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "building" */
export type Building_Stddev_Order_By = {
  number_of_apartments?: InputMaybe<Order_By>
  number_of_floors?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Building_Stddev_Pop_Fields = {
  __typename?: 'building_stddev_pop_fields'
  number_of_apartments?: Maybe<Scalars['Float']['output']>
  number_of_floors?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "building" */
export type Building_Stddev_Pop_Order_By = {
  number_of_apartments?: InputMaybe<Order_By>
  number_of_floors?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Building_Stddev_Samp_Fields = {
  __typename?: 'building_stddev_samp_fields'
  number_of_apartments?: Maybe<Scalars['Float']['output']>
  number_of_floors?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "building" */
export type Building_Stddev_Samp_Order_By = {
  number_of_apartments?: InputMaybe<Order_By>
  number_of_floors?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "building" */
export type Building_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Building_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Building_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  geolocation?: InputMaybe<Scalars['geography']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  ip_address?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  number_of_apartments?: InputMaybe<Scalars['Float']['input']>
  number_of_floors?: InputMaybe<Scalars['Float']['input']>
  profile_photo?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Building_Status_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Building_Sum_Fields = {
  __typename?: 'building_sum_fields'
  number_of_apartments?: Maybe<Scalars['Float']['output']>
  number_of_floors?: Maybe<Scalars['Float']['output']>
}

/** order by sum() on columns of table "building" */
export type Building_Sum_Order_By = {
  number_of_apartments?: InputMaybe<Order_By>
  number_of_floors?: InputMaybe<Order_By>
}

/** update columns of table "building" */
export enum Building_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Geolocation = 'geolocation',
  /** column name */
  Id = 'id',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  Name = 'name',
  /** column name */
  NumberOfApartments = 'number_of_apartments',
  /** column name */
  NumberOfFloors = 'number_of_floors',
  /** column name */
  ProfilePhoto = 'profile_photo',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Building_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Building_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Building_Set_Input>
  /** filter the rows which have to be updated */
  where: Building_Bool_Exp
}

/** aggregate var_pop on columns */
export type Building_Var_Pop_Fields = {
  __typename?: 'building_var_pop_fields'
  number_of_apartments?: Maybe<Scalars['Float']['output']>
  number_of_floors?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "building" */
export type Building_Var_Pop_Order_By = {
  number_of_apartments?: InputMaybe<Order_By>
  number_of_floors?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Building_Var_Samp_Fields = {
  __typename?: 'building_var_samp_fields'
  number_of_apartments?: Maybe<Scalars['Float']['output']>
  number_of_floors?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "building" */
export type Building_Var_Samp_Order_By = {
  number_of_apartments?: InputMaybe<Order_By>
  number_of_floors?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Building_Variance_Fields = {
  __typename?: 'building_variance_fields'
  number_of_apartments?: Maybe<Scalars['Float']['output']>
  number_of_floors?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "building" */
export type Building_Variance_Order_By = {
  number_of_apartments?: InputMaybe<Order_By>
  number_of_floors?: InputMaybe<Order_By>
}

/** columns and relationships of "credential" */
export type Credential = {
  __typename?: 'credential'
  created_at: Scalars['timestamptz']['output']
  /** An object relationship */
  credential_type: Credential_Type
  id: Scalars['uuid']['output']
  type: Credential_Type_Enum
  updated_at: Scalars['timestamptz']['output']
  value: Scalars['jsonb']['output']
}

/** columns and relationships of "credential" */
export type CredentialValueArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "credential" */
export type Credential_Aggregate = {
  __typename?: 'credential_aggregate'
  aggregate?: Maybe<Credential_Aggregate_Fields>
  nodes: Array<Credential>
}

export type Credential_Aggregate_Bool_Exp = {
  count?: InputMaybe<Credential_Aggregate_Bool_Exp_Count>
}

export type Credential_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Credential_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Credential_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "credential" */
export type Credential_Aggregate_Fields = {
  __typename?: 'credential_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Credential_Max_Fields>
  min?: Maybe<Credential_Min_Fields>
}

/** aggregate fields of "credential" */
export type Credential_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Credential_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "credential" */
export type Credential_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Credential_Max_Order_By>
  min?: InputMaybe<Credential_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Credential_Append_Input = {
  value?: InputMaybe<Scalars['jsonb']['input']>
}

/** input type for inserting array relation for remote table "credential" */
export type Credential_Arr_Rel_Insert_Input = {
  data: Array<Credential_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Credential_On_Conflict>
}

/** Boolean expression to filter rows from the table "credential". All fields are combined with a logical 'AND'. */
export type Credential_Bool_Exp = {
  _and?: InputMaybe<Array<Credential_Bool_Exp>>
  _not?: InputMaybe<Credential_Bool_Exp>
  _or?: InputMaybe<Array<Credential_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  credential_type?: InputMaybe<Credential_Type_Bool_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  type?: InputMaybe<Credential_Type_Enum_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  value?: InputMaybe<Jsonb_Comparison_Exp>
}

/** unique or primary key constraints on table "credential" */
export enum Credential_Constraint {
  /** unique or primary key constraint on columns "id" */
  CredentialPkey = 'credential_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Credential_Delete_At_Path_Input = {
  value?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Credential_Delete_Elem_Input = {
  value?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Credential_Delete_Key_Input = {
  value?: InputMaybe<Scalars['String']['input']>
}

/** input type for inserting data into table "credential" */
export type Credential_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  credential_type?: InputMaybe<Credential_Type_Obj_Rel_Insert_Input>
  id?: InputMaybe<Scalars['uuid']['input']>
  type?: InputMaybe<Credential_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['jsonb']['input']>
}

/** aggregate max on columns */
export type Credential_Max_Fields = {
  __typename?: 'credential_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "credential" */
export type Credential_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Credential_Min_Fields = {
  __typename?: 'credential_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "credential" */
export type Credential_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "credential" */
export type Credential_Mutation_Response = {
  __typename?: 'credential_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Credential>
}

/** on_conflict condition type for table "credential" */
export type Credential_On_Conflict = {
  constraint: Credential_Constraint
  update_columns?: Array<Credential_Update_Column>
  where?: InputMaybe<Credential_Bool_Exp>
}

/** Ordering options when selecting data from "credential". */
export type Credential_Order_By = {
  created_at?: InputMaybe<Order_By>
  credential_type?: InputMaybe<Credential_Type_Order_By>
  id?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: credential */
export type Credential_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Credential_Prepend_Input = {
  value?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "credential" */
export enum Credential_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "credential" */
export type Credential_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  type?: InputMaybe<Credential_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['jsonb']['input']>
}

/** Streaming cursor of the table "credential" */
export type Credential_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Credential_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Credential_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  type?: InputMaybe<Credential_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['jsonb']['input']>
}

/** columns and relationships of "credential_type" */
export type Credential_Type = {
  __typename?: 'credential_type'
  comment: Scalars['String']['output']
  /** An array relationship */
  credentials: Array<Credential>
  /** An aggregate relationship */
  credentials_aggregate: Credential_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "credential_type" */
export type Credential_TypeCredentialsArgs = {
  distinct_on?: InputMaybe<Array<Credential_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Credential_Order_By>>
  where?: InputMaybe<Credential_Bool_Exp>
}

/** columns and relationships of "credential_type" */
export type Credential_TypeCredentials_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credential_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Credential_Order_By>>
  where?: InputMaybe<Credential_Bool_Exp>
}

/** aggregated selection of "credential_type" */
export type Credential_Type_Aggregate = {
  __typename?: 'credential_type_aggregate'
  aggregate?: Maybe<Credential_Type_Aggregate_Fields>
  nodes: Array<Credential_Type>
}

/** aggregate fields of "credential_type" */
export type Credential_Type_Aggregate_Fields = {
  __typename?: 'credential_type_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Credential_Type_Max_Fields>
  min?: Maybe<Credential_Type_Min_Fields>
}

/** aggregate fields of "credential_type" */
export type Credential_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Credential_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "credential_type". All fields are combined with a logical 'AND'. */
export type Credential_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Credential_Type_Bool_Exp>>
  _not?: InputMaybe<Credential_Type_Bool_Exp>
  _or?: InputMaybe<Array<Credential_Type_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  credentials?: InputMaybe<Credential_Bool_Exp>
  credentials_aggregate?: InputMaybe<Credential_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "credential_type" */
export enum Credential_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  CredentialTypePkey = 'credential_type_pkey'
}

export enum Credential_Type_Enum {
  /** BRIVO_API */
  BrivoApi = 'BRIVO_API',
  /** RESIDEO_API */
  ResideoApi = 'RESIDEO_API'
}

/** Boolean expression to compare columns of type "credential_type_enum". All fields are combined with logical 'AND'. */
export type Credential_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Credential_Type_Enum>
  _in?: InputMaybe<Array<Credential_Type_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Credential_Type_Enum>
  _nin?: InputMaybe<Array<Credential_Type_Enum>>
}

/** input type for inserting data into table "credential_type" */
export type Credential_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  credentials?: InputMaybe<Credential_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Credential_Type_Max_Fields = {
  __typename?: 'credential_type_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Credential_Type_Min_Fields = {
  __typename?: 'credential_type_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "credential_type" */
export type Credential_Type_Mutation_Response = {
  __typename?: 'credential_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Credential_Type>
}

/** input type for inserting object relation for remote table "credential_type" */
export type Credential_Type_Obj_Rel_Insert_Input = {
  data: Credential_Type_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Credential_Type_On_Conflict>
}

/** on_conflict condition type for table "credential_type" */
export type Credential_Type_On_Conflict = {
  constraint: Credential_Type_Constraint
  update_columns?: Array<Credential_Type_Update_Column>
  where?: InputMaybe<Credential_Type_Bool_Exp>
}

/** Ordering options when selecting data from "credential_type". */
export type Credential_Type_Order_By = {
  comment?: InputMaybe<Order_By>
  credentials_aggregate?: InputMaybe<Credential_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: credential_type */
export type Credential_Type_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "credential_type" */
export enum Credential_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "credential_type" */
export type Credential_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "credential_type" */
export type Credential_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Credential_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Credential_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "credential_type" */
export enum Credential_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Credential_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Credential_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Credential_Type_Bool_Exp
}

/** update columns of table "credential" */
export enum Credential_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Credential_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Credential_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Credential_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Credential_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Credential_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Credential_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Credential_Set_Input>
  /** filter the rows which have to be updated */
  where: Credential_Bool_Exp
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>
  _gt?: InputMaybe<Scalars['date']['input']>
  _gte?: InputMaybe<Scalars['date']['input']>
  _in?: InputMaybe<Array<Scalars['date']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['date']['input']>
  _lte?: InputMaybe<Scalars['date']['input']>
  _neq?: InputMaybe<Scalars['date']['input']>
  _nin?: InputMaybe<Array<Scalars['date']['input']>>
}

/** columns and relationships of "device" */
export type Device = {
  __typename?: 'device'
  /** An object relationship */
  apartment?: Maybe<Apartment>
  apartment_id?: Maybe<Scalars['uuid']['output']>
  /** An object relationship */
  bridge?: Maybe<Device>
  bridge_id?: Maybe<Scalars['uuid']['output']>
  /** An object relationship */
  building?: Maybe<Building>
  building_id?: Maybe<Scalars['uuid']['output']>
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  /** An object relationship */
  device_group?: Maybe<Device_Group>
  /** An array relationship */
  device_scenes: Array<Device_Scene>
  /** An aggregate relationship */
  device_scenes_aggregate: Device_Scene_Aggregate
  /** An object relationship */
  device_type: Device_Type
  /** An array relationship */
  devices: Array<Device>
  /** An aggregate relationship */
  devices_aggregate: Device_Aggregate
  group?: Maybe<Device_Group_Enum>
  id: Scalars['uuid']['output']
  ip_address?: Maybe<Scalars['String']['output']>
  metadata?: Maybe<Scalars['jsonb']['output']>
  name: Scalars['String']['output']
  port?: Maybe<Scalars['Int']['output']>
  state?: Maybe<Scalars['jsonb']['output']>
  status?: Maybe<Scalars['String']['output']>
  type: Device_Type_Enum
  updated_at: Scalars['timestamptz']['output']
  value?: Maybe<Scalars['jsonb']['output']>
  zone?: Maybe<Scalars['String']['output']>
}

/** columns and relationships of "device" */
export type DeviceDevice_ScenesArgs = {
  distinct_on?: InputMaybe<Array<Device_Scene_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Scene_Order_By>>
  where?: InputMaybe<Device_Scene_Bool_Exp>
}

/** columns and relationships of "device" */
export type DeviceDevice_Scenes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Scene_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Scene_Order_By>>
  where?: InputMaybe<Device_Scene_Bool_Exp>
}

/** columns and relationships of "device" */
export type DeviceDevicesArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

/** columns and relationships of "device" */
export type DeviceDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

/** columns and relationships of "device" */
export type DeviceMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** columns and relationships of "device" */
export type DeviceStateArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** columns and relationships of "device" */
export type DeviceValueArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

export type Device_Action_Type = {
  __typename?: 'device_action_type'
  apartment_id?: Maybe<Scalars['uuid']['output']>
  bridge?: Maybe<Device_Action_Type>
  bridge_id?: Maybe<Scalars['uuid']['output']>
  building_id?: Maybe<Scalars['uuid']['output']>
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id: Scalars['uuid']['output']
  ip_address?: Maybe<Scalars['String']['output']>
  metadata?: Maybe<Scalars['jsonb']['output']>
  name: Scalars['String']['output']
  port?: Maybe<Scalars['Int']['output']>
  status?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
  zone?: Maybe<Scalars['String']['output']>
}

/** aggregated selection of "device" */
export type Device_Aggregate = {
  __typename?: 'device_aggregate'
  aggregate?: Maybe<Device_Aggregate_Fields>
  nodes: Array<Device>
}

export type Device_Aggregate_Bool_Exp = {
  count?: InputMaybe<Device_Aggregate_Bool_Exp_Count>
}

export type Device_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Device_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Device_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "device" */
export type Device_Aggregate_Fields = {
  __typename?: 'device_aggregate_fields'
  avg?: Maybe<Device_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Device_Max_Fields>
  min?: Maybe<Device_Min_Fields>
  stddev?: Maybe<Device_Stddev_Fields>
  stddev_pop?: Maybe<Device_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Device_Stddev_Samp_Fields>
  sum?: Maybe<Device_Sum_Fields>
  var_pop?: Maybe<Device_Var_Pop_Fields>
  var_samp?: Maybe<Device_Var_Samp_Fields>
  variance?: Maybe<Device_Variance_Fields>
}

/** aggregate fields of "device" */
export type Device_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Device_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "device" */
export type Device_Aggregate_Order_By = {
  avg?: InputMaybe<Device_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Device_Max_Order_By>
  min?: InputMaybe<Device_Min_Order_By>
  stddev?: InputMaybe<Device_Stddev_Order_By>
  stddev_pop?: InputMaybe<Device_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Device_Stddev_Samp_Order_By>
  sum?: InputMaybe<Device_Sum_Order_By>
  var_pop?: InputMaybe<Device_Var_Pop_Order_By>
  var_samp?: InputMaybe<Device_Var_Samp_Order_By>
  variance?: InputMaybe<Device_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Device_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>
  state?: InputMaybe<Scalars['jsonb']['input']>
  value?: InputMaybe<Scalars['jsonb']['input']>
}

/** input type for inserting array relation for remote table "device" */
export type Device_Arr_Rel_Insert_Input = {
  data: Array<Device_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Device_On_Conflict>
}

/** aggregate avg on columns */
export type Device_Avg_Fields = {
  __typename?: 'device_avg_fields'
  port?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "device" */
export type Device_Avg_Order_By = {
  port?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "device". All fields are combined with a logical 'AND'. */
export type Device_Bool_Exp = {
  _and?: InputMaybe<Array<Device_Bool_Exp>>
  _not?: InputMaybe<Device_Bool_Exp>
  _or?: InputMaybe<Array<Device_Bool_Exp>>
  apartment?: InputMaybe<Apartment_Bool_Exp>
  apartment_id?: InputMaybe<Uuid_Comparison_Exp>
  bridge?: InputMaybe<Device_Bool_Exp>
  bridge_id?: InputMaybe<Uuid_Comparison_Exp>
  building?: InputMaybe<Building_Bool_Exp>
  building_id?: InputMaybe<Uuid_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  device_group?: InputMaybe<Device_Group_Bool_Exp>
  device_scenes?: InputMaybe<Device_Scene_Bool_Exp>
  device_scenes_aggregate?: InputMaybe<Device_Scene_Aggregate_Bool_Exp>
  device_type?: InputMaybe<Device_Type_Bool_Exp>
  devices?: InputMaybe<Device_Bool_Exp>
  devices_aggregate?: InputMaybe<Device_Aggregate_Bool_Exp>
  group?: InputMaybe<Device_Group_Enum_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  ip_address?: InputMaybe<String_Comparison_Exp>
  metadata?: InputMaybe<Jsonb_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  port?: InputMaybe<Int_Comparison_Exp>
  state?: InputMaybe<Jsonb_Comparison_Exp>
  status?: InputMaybe<String_Comparison_Exp>
  type?: InputMaybe<Device_Type_Enum_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  value?: InputMaybe<Jsonb_Comparison_Exp>
  zone?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "device" */
export enum Device_Constraint {
  /** unique or primary key constraint on columns "id" */
  DevicePkey = 'device_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Device_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>
  state?: InputMaybe<Array<Scalars['String']['input']>>
  value?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Device_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>
  state?: InputMaybe<Scalars['Int']['input']>
  value?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Device_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** columns and relationships of "device_group" */
export type Device_Group = {
  __typename?: 'device_group'
  comment: Scalars['String']['output']
  /** An array relationship */
  devices: Array<Device>
  /** An aggregate relationship */
  devices_aggregate: Device_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "device_group" */
export type Device_GroupDevicesArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

/** columns and relationships of "device_group" */
export type Device_GroupDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

/** aggregated selection of "device_group" */
export type Device_Group_Aggregate = {
  __typename?: 'device_group_aggregate'
  aggregate?: Maybe<Device_Group_Aggregate_Fields>
  nodes: Array<Device_Group>
}

/** aggregate fields of "device_group" */
export type Device_Group_Aggregate_Fields = {
  __typename?: 'device_group_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Device_Group_Max_Fields>
  min?: Maybe<Device_Group_Min_Fields>
}

/** aggregate fields of "device_group" */
export type Device_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Device_Group_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "device_group". All fields are combined with a logical 'AND'. */
export type Device_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Device_Group_Bool_Exp>>
  _not?: InputMaybe<Device_Group_Bool_Exp>
  _or?: InputMaybe<Array<Device_Group_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  devices?: InputMaybe<Device_Bool_Exp>
  devices_aggregate?: InputMaybe<Device_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "device_group" */
export enum Device_Group_Constraint {
  /** unique or primary key constraint on columns "value" */
  DeviceGroupPkey = 'device_group_pkey'
}

export enum Device_Group_Enum {
  /** BRIDGE */
  Bridge = 'BRIDGE',
  /** DOOR_LOCK */
  DoorLock = 'DOOR_LOCK',
  /** LIGHT */
  Light = 'LIGHT',
  /** PLUGS */
  Plugs = 'PLUGS',
  /** RELAYS */
  Relays = 'RELAYS',
  /** THERMOSTAT */
  Thermostat = 'THERMOSTAT'
}

/** Boolean expression to compare columns of type "device_group_enum". All fields are combined with logical 'AND'. */
export type Device_Group_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Device_Group_Enum>
  _in?: InputMaybe<Array<Device_Group_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Device_Group_Enum>
  _nin?: InputMaybe<Array<Device_Group_Enum>>
}

/** input type for inserting data into table "device_group" */
export type Device_Group_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  devices?: InputMaybe<Device_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Device_Group_Max_Fields = {
  __typename?: 'device_group_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Device_Group_Min_Fields = {
  __typename?: 'device_group_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "device_group" */
export type Device_Group_Mutation_Response = {
  __typename?: 'device_group_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Device_Group>
}

/** input type for inserting object relation for remote table "device_group" */
export type Device_Group_Obj_Rel_Insert_Input = {
  data: Device_Group_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Device_Group_On_Conflict>
}

/** on_conflict condition type for table "device_group" */
export type Device_Group_On_Conflict = {
  constraint: Device_Group_Constraint
  update_columns?: Array<Device_Group_Update_Column>
  where?: InputMaybe<Device_Group_Bool_Exp>
}

/** Ordering options when selecting data from "device_group". */
export type Device_Group_Order_By = {
  comment?: InputMaybe<Order_By>
  devices_aggregate?: InputMaybe<Device_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: device_group */
export type Device_Group_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "device_group" */
export enum Device_Group_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "device_group" */
export type Device_Group_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "device_group" */
export type Device_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Device_Group_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Device_Group_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "device_group" */
export enum Device_Group_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Device_Group_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Device_Group_Set_Input>
  /** filter the rows which have to be updated */
  where: Device_Group_Bool_Exp
}

/** input type for incrementing numeric columns in table "device" */
export type Device_Inc_Input = {
  port?: InputMaybe<Scalars['Int']['input']>
}

/** input type for inserting data into table "device" */
export type Device_Insert_Input = {
  apartment?: InputMaybe<Apartment_Obj_Rel_Insert_Input>
  apartment_id?: InputMaybe<Scalars['uuid']['input']>
  bridge?: InputMaybe<Device_Obj_Rel_Insert_Input>
  bridge_id?: InputMaybe<Scalars['uuid']['input']>
  building?: InputMaybe<Building_Obj_Rel_Insert_Input>
  building_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  device_group?: InputMaybe<Device_Group_Obj_Rel_Insert_Input>
  device_scenes?: InputMaybe<Device_Scene_Arr_Rel_Insert_Input>
  device_type?: InputMaybe<Device_Type_Obj_Rel_Insert_Input>
  devices?: InputMaybe<Device_Arr_Rel_Insert_Input>
  group?: InputMaybe<Device_Group_Enum>
  id?: InputMaybe<Scalars['uuid']['input']>
  ip_address?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['jsonb']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  port?: InputMaybe<Scalars['Int']['input']>
  state?: InputMaybe<Scalars['jsonb']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Device_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['jsonb']['input']>
  zone?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Device_Max_Fields = {
  __typename?: 'device_max_fields'
  apartment_id?: Maybe<Scalars['uuid']['output']>
  bridge_id?: Maybe<Scalars['uuid']['output']>
  building_id?: Maybe<Scalars['uuid']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  ip_address?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  port?: Maybe<Scalars['Int']['output']>
  status?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  zone?: Maybe<Scalars['String']['output']>
}

/** order by max() on columns of table "device" */
export type Device_Max_Order_By = {
  apartment_id?: InputMaybe<Order_By>
  bridge_id?: InputMaybe<Order_By>
  building_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  ip_address?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  port?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  zone?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Device_Min_Fields = {
  __typename?: 'device_min_fields'
  apartment_id?: Maybe<Scalars['uuid']['output']>
  bridge_id?: Maybe<Scalars['uuid']['output']>
  building_id?: Maybe<Scalars['uuid']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  ip_address?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  port?: Maybe<Scalars['Int']['output']>
  status?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  zone?: Maybe<Scalars['String']['output']>
}

/** order by min() on columns of table "device" */
export type Device_Min_Order_By = {
  apartment_id?: InputMaybe<Order_By>
  bridge_id?: InputMaybe<Order_By>
  building_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  ip_address?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  port?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  zone?: InputMaybe<Order_By>
}

/** response of any mutation on the table "device" */
export type Device_Mutation_Response = {
  __typename?: 'device_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Device>
}

/** input type for inserting object relation for remote table "device" */
export type Device_Obj_Rel_Insert_Input = {
  data: Device_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Device_On_Conflict>
}

/** on_conflict condition type for table "device" */
export type Device_On_Conflict = {
  constraint: Device_Constraint
  update_columns?: Array<Device_Update_Column>
  where?: InputMaybe<Device_Bool_Exp>
}

/** Ordering options when selecting data from "device". */
export type Device_Order_By = {
  apartment?: InputMaybe<Apartment_Order_By>
  apartment_id?: InputMaybe<Order_By>
  bridge?: InputMaybe<Device_Order_By>
  bridge_id?: InputMaybe<Order_By>
  building?: InputMaybe<Building_Order_By>
  building_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  device_group?: InputMaybe<Device_Group_Order_By>
  device_scenes_aggregate?: InputMaybe<Device_Scene_Aggregate_Order_By>
  device_type?: InputMaybe<Device_Type_Order_By>
  devices_aggregate?: InputMaybe<Device_Aggregate_Order_By>
  group?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  ip_address?: InputMaybe<Order_By>
  metadata?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  port?: InputMaybe<Order_By>
  state?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
  zone?: InputMaybe<Order_By>
}

/** primary key columns input for table: device */
export type Device_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Device_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>
  state?: InputMaybe<Scalars['jsonb']['input']>
  value?: InputMaybe<Scalars['jsonb']['input']>
}

/** columns and relationships of "device_scene" */
export type Device_Scene = {
  __typename?: 'device_scene'
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  /** An object relationship */
  device: Device
  device_id: Scalars['uuid']['output']
  /** An object relationship */
  scene: Apartment_Scene
  scene_id: Scalars['uuid']['output']
  updated_at: Scalars['timestamptz']['output']
  value: Scalars['jsonb']['output']
}

/** columns and relationships of "device_scene" */
export type Device_SceneValueArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "device_scene" */
export type Device_Scene_Aggregate = {
  __typename?: 'device_scene_aggregate'
  aggregate?: Maybe<Device_Scene_Aggregate_Fields>
  nodes: Array<Device_Scene>
}

export type Device_Scene_Aggregate_Bool_Exp = {
  count?: InputMaybe<Device_Scene_Aggregate_Bool_Exp_Count>
}

export type Device_Scene_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Device_Scene_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Device_Scene_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "device_scene" */
export type Device_Scene_Aggregate_Fields = {
  __typename?: 'device_scene_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Device_Scene_Max_Fields>
  min?: Maybe<Device_Scene_Min_Fields>
}

/** aggregate fields of "device_scene" */
export type Device_Scene_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Device_Scene_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "device_scene" */
export type Device_Scene_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Device_Scene_Max_Order_By>
  min?: InputMaybe<Device_Scene_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Device_Scene_Append_Input = {
  value?: InputMaybe<Scalars['jsonb']['input']>
}

/** input type for inserting array relation for remote table "device_scene" */
export type Device_Scene_Arr_Rel_Insert_Input = {
  data: Array<Device_Scene_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Device_Scene_On_Conflict>
}

/** Boolean expression to filter rows from the table "device_scene". All fields are combined with a logical 'AND'. */
export type Device_Scene_Bool_Exp = {
  _and?: InputMaybe<Array<Device_Scene_Bool_Exp>>
  _not?: InputMaybe<Device_Scene_Bool_Exp>
  _or?: InputMaybe<Array<Device_Scene_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  device?: InputMaybe<Device_Bool_Exp>
  device_id?: InputMaybe<Uuid_Comparison_Exp>
  scene?: InputMaybe<Apartment_Scene_Bool_Exp>
  scene_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  value?: InputMaybe<Jsonb_Comparison_Exp>
}

/** unique or primary key constraints on table "device_scene" */
export enum Device_Scene_Constraint {
  /** unique or primary key constraint on columns "scene_id", "device_id" */
  DeviceScenePkey = 'device_scene_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Device_Scene_Delete_At_Path_Input = {
  value?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Device_Scene_Delete_Elem_Input = {
  value?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Device_Scene_Delete_Key_Input = {
  value?: InputMaybe<Scalars['String']['input']>
}

/** input type for inserting data into table "device_scene" */
export type Device_Scene_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  device?: InputMaybe<Device_Obj_Rel_Insert_Input>
  device_id?: InputMaybe<Scalars['uuid']['input']>
  scene?: InputMaybe<Apartment_Scene_Obj_Rel_Insert_Input>
  scene_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['jsonb']['input']>
}

/** aggregate max on columns */
export type Device_Scene_Max_Fields = {
  __typename?: 'device_scene_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  device_id?: Maybe<Scalars['uuid']['output']>
  scene_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "device_scene" */
export type Device_Scene_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  device_id?: InputMaybe<Order_By>
  scene_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Device_Scene_Min_Fields = {
  __typename?: 'device_scene_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  device_id?: Maybe<Scalars['uuid']['output']>
  scene_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "device_scene" */
export type Device_Scene_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  device_id?: InputMaybe<Order_By>
  scene_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "device_scene" */
export type Device_Scene_Mutation_Response = {
  __typename?: 'device_scene_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Device_Scene>
}

/** on_conflict condition type for table "device_scene" */
export type Device_Scene_On_Conflict = {
  constraint: Device_Scene_Constraint
  update_columns?: Array<Device_Scene_Update_Column>
  where?: InputMaybe<Device_Scene_Bool_Exp>
}

/** Ordering options when selecting data from "device_scene". */
export type Device_Scene_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  device?: InputMaybe<Device_Order_By>
  device_id?: InputMaybe<Order_By>
  scene?: InputMaybe<Apartment_Scene_Order_By>
  scene_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: device_scene */
export type Device_Scene_Pk_Columns_Input = {
  device_id: Scalars['uuid']['input']
  scene_id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Device_Scene_Prepend_Input = {
  value?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "device_scene" */
export enum Device_Scene_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  SceneId = 'scene_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "device_scene" */
export type Device_Scene_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  device_id?: InputMaybe<Scalars['uuid']['input']>
  scene_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['jsonb']['input']>
}

/** Streaming cursor of the table "device_scene" */
export type Device_Scene_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Device_Scene_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Device_Scene_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  device_id?: InputMaybe<Scalars['uuid']['input']>
  scene_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['jsonb']['input']>
}

/** update columns of table "device_scene" */
export enum Device_Scene_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  SceneId = 'scene_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Device_Scene_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Device_Scene_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Device_Scene_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Device_Scene_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Device_Scene_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Device_Scene_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Device_Scene_Set_Input>
  /** filter the rows which have to be updated */
  where: Device_Scene_Bool_Exp
}

/** select columns of table "device" */
export enum Device_Select_Column {
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  BridgeId = 'bridge_id',
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Group = 'group',
  /** column name */
  Id = 'id',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  Port = 'port',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  Zone = 'zone'
}

/** input type for updating data in table "device" */
export type Device_Set_Input = {
  apartment_id?: InputMaybe<Scalars['uuid']['input']>
  bridge_id?: InputMaybe<Scalars['uuid']['input']>
  building_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  group?: InputMaybe<Device_Group_Enum>
  id?: InputMaybe<Scalars['uuid']['input']>
  ip_address?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['jsonb']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  port?: InputMaybe<Scalars['Int']['input']>
  state?: InputMaybe<Scalars['jsonb']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Device_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['jsonb']['input']>
  zone?: InputMaybe<Scalars['String']['input']>
}

/** aggregate stddev on columns */
export type Device_Stddev_Fields = {
  __typename?: 'device_stddev_fields'
  port?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "device" */
export type Device_Stddev_Order_By = {
  port?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Device_Stddev_Pop_Fields = {
  __typename?: 'device_stddev_pop_fields'
  port?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "device" */
export type Device_Stddev_Pop_Order_By = {
  port?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Device_Stddev_Samp_Fields = {
  __typename?: 'device_stddev_samp_fields'
  port?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "device" */
export type Device_Stddev_Samp_Order_By = {
  port?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "device" */
export type Device_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Device_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Device_Stream_Cursor_Value_Input = {
  apartment_id?: InputMaybe<Scalars['uuid']['input']>
  bridge_id?: InputMaybe<Scalars['uuid']['input']>
  building_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  group?: InputMaybe<Device_Group_Enum>
  id?: InputMaybe<Scalars['uuid']['input']>
  ip_address?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['jsonb']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  port?: InputMaybe<Scalars['Int']['input']>
  state?: InputMaybe<Scalars['jsonb']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Device_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['jsonb']['input']>
  zone?: InputMaybe<Scalars['String']['input']>
}

/** aggregate sum on columns */
export type Device_Sum_Fields = {
  __typename?: 'device_sum_fields'
  port?: Maybe<Scalars['Int']['output']>
}

/** order by sum() on columns of table "device" */
export type Device_Sum_Order_By = {
  port?: InputMaybe<Order_By>
}

/** columns and relationships of "device_type" */
export type Device_Type = {
  __typename?: 'device_type'
  comment: Scalars['String']['output']
  /** An array relationship */
  devices: Array<Device>
  /** An aggregate relationship */
  devices_aggregate: Device_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "device_type" */
export type Device_TypeDevicesArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

/** columns and relationships of "device_type" */
export type Device_TypeDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

/** aggregated selection of "device_type" */
export type Device_Type_Aggregate = {
  __typename?: 'device_type_aggregate'
  aggregate?: Maybe<Device_Type_Aggregate_Fields>
  nodes: Array<Device_Type>
}

/** aggregate fields of "device_type" */
export type Device_Type_Aggregate_Fields = {
  __typename?: 'device_type_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Device_Type_Max_Fields>
  min?: Maybe<Device_Type_Min_Fields>
}

/** aggregate fields of "device_type" */
export type Device_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Device_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "device_type". All fields are combined with a logical 'AND'. */
export type Device_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Device_Type_Bool_Exp>>
  _not?: InputMaybe<Device_Type_Bool_Exp>
  _or?: InputMaybe<Array<Device_Type_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  devices?: InputMaybe<Device_Bool_Exp>
  devices_aggregate?: InputMaybe<Device_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "device_type" */
export enum Device_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  DeviceTypePkey = 'device_type_pkey'
}

export enum Device_Type_Enum {
  /** CASETA_BRIDGE */
  CasetaBridge = 'CASETA_BRIDGE',
  /** CASETA_DIMMER_SWITCH */
  CasetaDimmerSwitch = 'CASETA_DIMMER_SWITCH',
  /** CASETA_SWITCH */
  CasetaSwitch = 'CASETA_SWITCH',
  /** DOOR_LOCK */
  DoorLock = 'DOOR_LOCK',
  /** HONEYWELL_T6_PRO_THERMOSTAT */
  HoneywellT6ProThermostat = 'HONEYWELL_T6_PRO_THERMOSTAT',
  /** KISI_DOOR_LOCK */
  KisiDoorLock = 'KISI_DOOR_LOCK',
  /** SHELLY_PLUG */
  ShellyPlug = 'SHELLY_PLUG',
  /** SHELLY_RELAY */
  ShellyRelay = 'SHELLY_RELAY',
  /** SHELLY_WALL_DIMMER */
  ShellyWallDimmer = 'SHELLY_WALL_DIMMER'
}

/** Boolean expression to compare columns of type "device_type_enum". All fields are combined with logical 'AND'. */
export type Device_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Device_Type_Enum>
  _in?: InputMaybe<Array<Device_Type_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Device_Type_Enum>
  _nin?: InputMaybe<Array<Device_Type_Enum>>
}

/** input type for inserting data into table "device_type" */
export type Device_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  devices?: InputMaybe<Device_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Device_Type_Max_Fields = {
  __typename?: 'device_type_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Device_Type_Min_Fields = {
  __typename?: 'device_type_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "device_type" */
export type Device_Type_Mutation_Response = {
  __typename?: 'device_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Device_Type>
}

/** input type for inserting object relation for remote table "device_type" */
export type Device_Type_Obj_Rel_Insert_Input = {
  data: Device_Type_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Device_Type_On_Conflict>
}

/** on_conflict condition type for table "device_type" */
export type Device_Type_On_Conflict = {
  constraint: Device_Type_Constraint
  update_columns?: Array<Device_Type_Update_Column>
  where?: InputMaybe<Device_Type_Bool_Exp>
}

/** Ordering options when selecting data from "device_type". */
export type Device_Type_Order_By = {
  comment?: InputMaybe<Order_By>
  devices_aggregate?: InputMaybe<Device_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: device_type */
export type Device_Type_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "device_type" */
export enum Device_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "device_type" */
export type Device_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "device_type" */
export type Device_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Device_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Device_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "device_type" */
export enum Device_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Device_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Device_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Device_Type_Bool_Exp
}

/** update columns of table "device" */
export enum Device_Update_Column {
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  BridgeId = 'bridge_id',
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Group = 'group',
  /** column name */
  Id = 'id',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  Port = 'port',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  Zone = 'zone'
}

export type Device_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Device_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Device_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Device_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Device_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Device_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Device_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Device_Set_Input>
  /** filter the rows which have to be updated */
  where: Device_Bool_Exp
}

/** aggregate var_pop on columns */
export type Device_Var_Pop_Fields = {
  __typename?: 'device_var_pop_fields'
  port?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "device" */
export type Device_Var_Pop_Order_By = {
  port?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Device_Var_Samp_Fields = {
  __typename?: 'device_var_samp_fields'
  port?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "device" */
export type Device_Var_Samp_Order_By = {
  port?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Device_Variance_Fields = {
  __typename?: 'device_variance_fields'
  port?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "device" */
export type Device_Variance_Order_By = {
  port?: InputMaybe<Order_By>
}

export type Geography_Cast_Exp = {
  geometry?: InputMaybe<Geometry_Comparison_Exp>
}

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: InputMaybe<Geography_Cast_Exp>
  _eq?: InputMaybe<Scalars['geography']['input']>
  _gt?: InputMaybe<Scalars['geography']['input']>
  _gte?: InputMaybe<Scalars['geography']['input']>
  _in?: InputMaybe<Array<Scalars['geography']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['geography']['input']>
  _lte?: InputMaybe<Scalars['geography']['input']>
  _neq?: InputMaybe<Scalars['geography']['input']>
  _nin?: InputMaybe<Array<Scalars['geography']['input']>>
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars['geography']['input']>
}

export type Geometry_Cast_Exp = {
  geography?: InputMaybe<Geography_Comparison_Exp>
}

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: InputMaybe<Geometry_Cast_Exp>
  _eq?: InputMaybe<Scalars['geometry']['input']>
  _gt?: InputMaybe<Scalars['geometry']['input']>
  _gte?: InputMaybe<Scalars['geometry']['input']>
  _in?: InputMaybe<Array<Scalars['geometry']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['geometry']['input']>
  _lte?: InputMaybe<Scalars['geometry']['input']>
  _neq?: InputMaybe<Scalars['geometry']['input']>
  _nin?: InputMaybe<Array<Scalars['geometry']['input']>>
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars['geometry']['input']>
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars['geometry']['input']>
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars['geometry']['input']>
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars['geometry']['input']>
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars['geometry']['input']>
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars['geometry']['input']>
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars['geometry']['input']>
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars['geometry']['input']>
}

export enum Income_Type {
  Bank = 'bank',
  Payroll = 'payroll'
}

export type Initial_User_Setup_Type = {
  __typename?: 'initial_user_setup_type'
  kisi_login?: Maybe<Kisi_Login_Type>
}

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>
  _eq?: InputMaybe<Scalars['jsonb']['input']>
  _gt?: InputMaybe<Scalars['jsonb']['input']>
  _gte?: InputMaybe<Scalars['jsonb']['input']>
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['jsonb']['input']>
  _lte?: InputMaybe<Scalars['jsonb']['input']>
  _neq?: InputMaybe<Scalars['jsonb']['input']>
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>
}

export type Kisi_Login_Type = {
  __typename?: 'kisi_login_type'
  id?: Maybe<Scalars['Int']['output']>
  scram_certificate?: Maybe<Scalars['String']['output']>
  scram_key?: Maybe<Scalars['String']['output']>
  secret?: Maybe<Scalars['String']['output']>
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root'
  activate_scene?: Maybe<Status_Response_Type>
  control_smart_device?: Maybe<Device_Action_Type>
  /** delete data from the table: "apartment" */
  delete_apartment?: Maybe<Apartment_Mutation_Response>
  /** delete single row from the table: "apartment" */
  delete_apartment_by_pk?: Maybe<Apartment>
  /** delete data from the table: "apartment_rental_type" */
  delete_apartment_rental_type?: Maybe<Apartment_Rental_Type_Mutation_Response>
  /** delete single row from the table: "apartment_rental_type" */
  delete_apartment_rental_type_by_pk?: Maybe<Apartment_Rental_Type>
  /** delete data from the table: "apartment_scene" */
  delete_apartment_scene?: Maybe<Apartment_Scene_Mutation_Response>
  /** delete single row from the table: "apartment_scene" */
  delete_apartment_scene_by_pk?: Maybe<Apartment_Scene>
  /** delete data from the table: "apartment_type" */
  delete_apartment_type?: Maybe<Apartment_Type_Mutation_Response>
  /** delete single row from the table: "apartment_type" */
  delete_apartment_type_by_pk?: Maybe<Apartment_Type>
  /** delete data from the table: "apartment_type_photo" */
  delete_apartment_type_photo?: Maybe<Apartment_Type_Photo_Mutation_Response>
  /** delete single row from the table: "apartment_type_photo" */
  delete_apartment_type_photo_by_pk?: Maybe<Apartment_Type_Photo>
  /** delete data from the table: "building" */
  delete_building?: Maybe<Building_Mutation_Response>
  /** delete single row from the table: "building" */
  delete_building_by_pk?: Maybe<Building>
  /** delete data from the table: "building_status" */
  delete_building_status?: Maybe<Building_Status_Mutation_Response>
  /** delete single row from the table: "building_status" */
  delete_building_status_by_pk?: Maybe<Building_Status>
  /** delete data from the table: "credential" */
  delete_credential?: Maybe<Credential_Mutation_Response>
  /** delete single row from the table: "credential" */
  delete_credential_by_pk?: Maybe<Credential>
  /** delete data from the table: "credential_type" */
  delete_credential_type?: Maybe<Credential_Type_Mutation_Response>
  /** delete single row from the table: "credential_type" */
  delete_credential_type_by_pk?: Maybe<Credential_Type>
  /** delete data from the table: "device" */
  delete_device?: Maybe<Device_Mutation_Response>
  /** delete single row from the table: "device" */
  delete_device_by_pk?: Maybe<Device>
  /** delete data from the table: "device_group" */
  delete_device_group?: Maybe<Device_Group_Mutation_Response>
  /** delete single row from the table: "device_group" */
  delete_device_group_by_pk?: Maybe<Device_Group>
  /** delete data from the table: "device_scene" */
  delete_device_scene?: Maybe<Device_Scene_Mutation_Response>
  /** delete single row from the table: "device_scene" */
  delete_device_scene_by_pk?: Maybe<Device_Scene>
  /** delete data from the table: "device_type" */
  delete_device_type?: Maybe<Device_Type_Mutation_Response>
  /** delete single row from the table: "device_type" */
  delete_device_type_by_pk?: Maybe<Device_Type>
  /** delete data from the table: "support_ticket" */
  delete_support_ticket?: Maybe<Support_Ticket_Mutation_Response>
  /** delete single row from the table: "support_ticket" */
  delete_support_ticket_by_pk?: Maybe<Support_Ticket>
  /** delete data from the table: "support_ticket_photo" */
  delete_support_ticket_photo?: Maybe<Support_Ticket_Photo_Mutation_Response>
  /** delete single row from the table: "support_ticket_photo" */
  delete_support_ticket_photo_by_pk?: Maybe<Support_Ticket_Photo>
  /** delete data from the table: "support_ticket_status" */
  delete_support_ticket_status?: Maybe<Support_Ticket_Status_Mutation_Response>
  /** delete single row from the table: "support_ticket_status" */
  delete_support_ticket_status_by_pk?: Maybe<Support_Ticket_Status>
  /** delete data from the table: "support_ticket_type" */
  delete_support_ticket_type?: Maybe<Support_Ticket_Type_Mutation_Response>
  /** delete single row from the table: "support_ticket_type" */
  delete_support_ticket_type_by_pk?: Maybe<Support_Ticket_Type>
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>
  /** delete data from the table: "user_aparment_status" */
  delete_user_aparment_status?: Maybe<User_Aparment_Status_Mutation_Response>
  /** delete single row from the table: "user_aparment_status" */
  delete_user_aparment_status_by_pk?: Maybe<User_Aparment_Status>
  /** delete data from the table: "user_apartment" */
  delete_user_apartment?: Maybe<User_Apartment_Mutation_Response>
  /** delete single row from the table: "user_apartment" */
  delete_user_apartment_by_pk?: Maybe<User_Apartment>
  /** delete data from the table: "user_application" */
  delete_user_application?: Maybe<User_Application_Mutation_Response>
  /** delete data from the table: "user_application_address" */
  delete_user_application_address?: Maybe<User_Application_Address_Mutation_Response>
  /** delete single row from the table: "user_application_address" */
  delete_user_application_address_by_pk?: Maybe<User_Application_Address>
  /** delete single row from the table: "user_application" */
  delete_user_application_by_pk?: Maybe<User_Application>
  /** delete data from the table: "user_application_income" */
  delete_user_application_income?: Maybe<User_Application_Income_Mutation_Response>
  /** delete single row from the table: "user_application_income" */
  delete_user_application_income_by_pk?: Maybe<User_Application_Income>
  /** delete data from the table: "user_application_income_document" */
  delete_user_application_income_document?: Maybe<User_Application_Income_Document_Mutation_Response>
  /** delete single row from the table: "user_application_income_document" */
  delete_user_application_income_document_by_pk?: Maybe<User_Application_Income_Document>
  /** delete data from the table: "user_application_status" */
  delete_user_application_status?: Maybe<User_Application_Status_Mutation_Response>
  /** delete single row from the table: "user_application_status" */
  delete_user_application_status_by_pk?: Maybe<User_Application_Status>
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>
  /** delete data from the table: "user_id_type" */
  delete_user_id_type?: Maybe<User_Id_Type_Mutation_Response>
  /** delete single row from the table: "user_id_type" */
  delete_user_id_type_by_pk?: Maybe<User_Id_Type>
  /** delete data from the table: "user_role" */
  delete_user_role?: Maybe<User_Role_Mutation_Response>
  /** delete single row from the table: "user_role" */
  delete_user_role_by_pk?: Maybe<User_Role>
  generate_plaid_link_token?: Maybe<Plaid_Link_Token_Create_Type>
  /** insert data into the table: "apartment" */
  insert_apartment?: Maybe<Apartment_Mutation_Response>
  /** insert a single row into the table: "apartment" */
  insert_apartment_one?: Maybe<Apartment>
  /** insert data into the table: "apartment_rental_type" */
  insert_apartment_rental_type?: Maybe<Apartment_Rental_Type_Mutation_Response>
  /** insert a single row into the table: "apartment_rental_type" */
  insert_apartment_rental_type_one?: Maybe<Apartment_Rental_Type>
  /** insert data into the table: "apartment_scene" */
  insert_apartment_scene?: Maybe<Apartment_Scene_Mutation_Response>
  /** insert a single row into the table: "apartment_scene" */
  insert_apartment_scene_one?: Maybe<Apartment_Scene>
  /** insert data into the table: "apartment_type" */
  insert_apartment_type?: Maybe<Apartment_Type_Mutation_Response>
  /** insert a single row into the table: "apartment_type" */
  insert_apartment_type_one?: Maybe<Apartment_Type>
  /** insert data into the table: "apartment_type_photo" */
  insert_apartment_type_photo?: Maybe<Apartment_Type_Photo_Mutation_Response>
  /** insert a single row into the table: "apartment_type_photo" */
  insert_apartment_type_photo_one?: Maybe<Apartment_Type_Photo>
  /** insert data into the table: "building" */
  insert_building?: Maybe<Building_Mutation_Response>
  /** insert a single row into the table: "building" */
  insert_building_one?: Maybe<Building>
  /** insert data into the table: "building_status" */
  insert_building_status?: Maybe<Building_Status_Mutation_Response>
  /** insert a single row into the table: "building_status" */
  insert_building_status_one?: Maybe<Building_Status>
  /** insert data into the table: "credential" */
  insert_credential?: Maybe<Credential_Mutation_Response>
  /** insert a single row into the table: "credential" */
  insert_credential_one?: Maybe<Credential>
  /** insert data into the table: "credential_type" */
  insert_credential_type?: Maybe<Credential_Type_Mutation_Response>
  /** insert a single row into the table: "credential_type" */
  insert_credential_type_one?: Maybe<Credential_Type>
  /** insert data into the table: "device" */
  insert_device?: Maybe<Device_Mutation_Response>
  /** insert data into the table: "device_group" */
  insert_device_group?: Maybe<Device_Group_Mutation_Response>
  /** insert a single row into the table: "device_group" */
  insert_device_group_one?: Maybe<Device_Group>
  /** insert a single row into the table: "device" */
  insert_device_one?: Maybe<Device>
  /** insert data into the table: "device_scene" */
  insert_device_scene?: Maybe<Device_Scene_Mutation_Response>
  /** insert a single row into the table: "device_scene" */
  insert_device_scene_one?: Maybe<Device_Scene>
  /** insert data into the table: "device_type" */
  insert_device_type?: Maybe<Device_Type_Mutation_Response>
  /** insert a single row into the table: "device_type" */
  insert_device_type_one?: Maybe<Device_Type>
  /** insert data into the table: "support_ticket" */
  insert_support_ticket?: Maybe<Support_Ticket_Mutation_Response>
  /** insert a single row into the table: "support_ticket" */
  insert_support_ticket_one?: Maybe<Support_Ticket>
  /** insert data into the table: "support_ticket_photo" */
  insert_support_ticket_photo?: Maybe<Support_Ticket_Photo_Mutation_Response>
  /** insert a single row into the table: "support_ticket_photo" */
  insert_support_ticket_photo_one?: Maybe<Support_Ticket_Photo>
  /** insert data into the table: "support_ticket_status" */
  insert_support_ticket_status?: Maybe<Support_Ticket_Status_Mutation_Response>
  /** insert a single row into the table: "support_ticket_status" */
  insert_support_ticket_status_one?: Maybe<Support_Ticket_Status>
  /** insert data into the table: "support_ticket_type" */
  insert_support_ticket_type?: Maybe<Support_Ticket_Type_Mutation_Response>
  /** insert a single row into the table: "support_ticket_type" */
  insert_support_ticket_type_one?: Maybe<Support_Ticket_Type>
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>
  /** insert data into the table: "user_aparment_status" */
  insert_user_aparment_status?: Maybe<User_Aparment_Status_Mutation_Response>
  /** insert a single row into the table: "user_aparment_status" */
  insert_user_aparment_status_one?: Maybe<User_Aparment_Status>
  /** insert data into the table: "user_apartment" */
  insert_user_apartment?: Maybe<User_Apartment_Mutation_Response>
  /** insert a single row into the table: "user_apartment" */
  insert_user_apartment_one?: Maybe<User_Apartment>
  /** insert data into the table: "user_application" */
  insert_user_application?: Maybe<User_Application_Mutation_Response>
  /** insert data into the table: "user_application_address" */
  insert_user_application_address?: Maybe<User_Application_Address_Mutation_Response>
  /** insert a single row into the table: "user_application_address" */
  insert_user_application_address_one?: Maybe<User_Application_Address>
  /** insert data into the table: "user_application_income" */
  insert_user_application_income?: Maybe<User_Application_Income_Mutation_Response>
  /** insert data into the table: "user_application_income_document" */
  insert_user_application_income_document?: Maybe<User_Application_Income_Document_Mutation_Response>
  /** insert a single row into the table: "user_application_income_document" */
  insert_user_application_income_document_one?: Maybe<User_Application_Income_Document>
  /** insert a single row into the table: "user_application_income" */
  insert_user_application_income_one?: Maybe<User_Application_Income>
  /** insert a single row into the table: "user_application" */
  insert_user_application_one?: Maybe<User_Application>
  /** insert data into the table: "user_application_status" */
  insert_user_application_status?: Maybe<User_Application_Status_Mutation_Response>
  /** insert a single row into the table: "user_application_status" */
  insert_user_application_status_one?: Maybe<User_Application_Status>
  /** insert data into the table: "user_id_type" */
  insert_user_id_type?: Maybe<User_Id_Type_Mutation_Response>
  /** insert a single row into the table: "user_id_type" */
  insert_user_id_type_one?: Maybe<User_Id_Type>
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>
  /** insert data into the table: "user_role" */
  insert_user_role?: Maybe<User_Role_Mutation_Response>
  /** insert a single row into the table: "user_role" */
  insert_user_role_one?: Maybe<User_Role>
  /** swap_plaid_token */
  swap_plaid_token?: Maybe<Plaid_Swap_Token_Response_Type>
  /** update data of the table: "apartment" */
  update_apartment?: Maybe<Apartment_Mutation_Response>
  /** update single row of the table: "apartment" */
  update_apartment_by_pk?: Maybe<Apartment>
  /** update multiples rows of table: "apartment" */
  update_apartment_many?: Maybe<Array<Maybe<Apartment_Mutation_Response>>>
  /** update data of the table: "apartment_rental_type" */
  update_apartment_rental_type?: Maybe<Apartment_Rental_Type_Mutation_Response>
  /** update single row of the table: "apartment_rental_type" */
  update_apartment_rental_type_by_pk?: Maybe<Apartment_Rental_Type>
  /** update multiples rows of table: "apartment_rental_type" */
  update_apartment_rental_type_many?: Maybe<
    Array<Maybe<Apartment_Rental_Type_Mutation_Response>>
  >
  /** update data of the table: "apartment_scene" */
  update_apartment_scene?: Maybe<Apartment_Scene_Mutation_Response>
  /** update single row of the table: "apartment_scene" */
  update_apartment_scene_by_pk?: Maybe<Apartment_Scene>
  /** update multiples rows of table: "apartment_scene" */
  update_apartment_scene_many?: Maybe<
    Array<Maybe<Apartment_Scene_Mutation_Response>>
  >
  /** update data of the table: "apartment_type" */
  update_apartment_type?: Maybe<Apartment_Type_Mutation_Response>
  /** update single row of the table: "apartment_type" */
  update_apartment_type_by_pk?: Maybe<Apartment_Type>
  /** update multiples rows of table: "apartment_type" */
  update_apartment_type_many?: Maybe<
    Array<Maybe<Apartment_Type_Mutation_Response>>
  >
  /** update data of the table: "apartment_type_photo" */
  update_apartment_type_photo?: Maybe<Apartment_Type_Photo_Mutation_Response>
  /** update single row of the table: "apartment_type_photo" */
  update_apartment_type_photo_by_pk?: Maybe<Apartment_Type_Photo>
  /** update multiples rows of table: "apartment_type_photo" */
  update_apartment_type_photo_many?: Maybe<
    Array<Maybe<Apartment_Type_Photo_Mutation_Response>>
  >
  /** update data of the table: "building" */
  update_building?: Maybe<Building_Mutation_Response>
  /** update single row of the table: "building" */
  update_building_by_pk?: Maybe<Building>
  /** update multiples rows of table: "building" */
  update_building_many?: Maybe<Array<Maybe<Building_Mutation_Response>>>
  /** update data of the table: "building_status" */
  update_building_status?: Maybe<Building_Status_Mutation_Response>
  /** update single row of the table: "building_status" */
  update_building_status_by_pk?: Maybe<Building_Status>
  /** update multiples rows of table: "building_status" */
  update_building_status_many?: Maybe<
    Array<Maybe<Building_Status_Mutation_Response>>
  >
  /** update data of the table: "credential" */
  update_credential?: Maybe<Credential_Mutation_Response>
  /** update single row of the table: "credential" */
  update_credential_by_pk?: Maybe<Credential>
  /** update multiples rows of table: "credential" */
  update_credential_many?: Maybe<Array<Maybe<Credential_Mutation_Response>>>
  /** update data of the table: "credential_type" */
  update_credential_type?: Maybe<Credential_Type_Mutation_Response>
  /** update single row of the table: "credential_type" */
  update_credential_type_by_pk?: Maybe<Credential_Type>
  /** update multiples rows of table: "credential_type" */
  update_credential_type_many?: Maybe<
    Array<Maybe<Credential_Type_Mutation_Response>>
  >
  /** update data of the table: "device" */
  update_device?: Maybe<Device_Mutation_Response>
  /** update single row of the table: "device" */
  update_device_by_pk?: Maybe<Device>
  /** update data of the table: "device_group" */
  update_device_group?: Maybe<Device_Group_Mutation_Response>
  /** update single row of the table: "device_group" */
  update_device_group_by_pk?: Maybe<Device_Group>
  /** update multiples rows of table: "device_group" */
  update_device_group_many?: Maybe<Array<Maybe<Device_Group_Mutation_Response>>>
  /** update multiples rows of table: "device" */
  update_device_many?: Maybe<Array<Maybe<Device_Mutation_Response>>>
  /** update data of the table: "device_scene" */
  update_device_scene?: Maybe<Device_Scene_Mutation_Response>
  /** update single row of the table: "device_scene" */
  update_device_scene_by_pk?: Maybe<Device_Scene>
  /** update multiples rows of table: "device_scene" */
  update_device_scene_many?: Maybe<Array<Maybe<Device_Scene_Mutation_Response>>>
  /** update data of the table: "device_type" */
  update_device_type?: Maybe<Device_Type_Mutation_Response>
  /** update single row of the table: "device_type" */
  update_device_type_by_pk?: Maybe<Device_Type>
  /** update multiples rows of table: "device_type" */
  update_device_type_many?: Maybe<Array<Maybe<Device_Type_Mutation_Response>>>
  /** update data of the table: "support_ticket" */
  update_support_ticket?: Maybe<Support_Ticket_Mutation_Response>
  /** update single row of the table: "support_ticket" */
  update_support_ticket_by_pk?: Maybe<Support_Ticket>
  /** update multiples rows of table: "support_ticket" */
  update_support_ticket_many?: Maybe<
    Array<Maybe<Support_Ticket_Mutation_Response>>
  >
  /** update data of the table: "support_ticket_photo" */
  update_support_ticket_photo?: Maybe<Support_Ticket_Photo_Mutation_Response>
  /** update single row of the table: "support_ticket_photo" */
  update_support_ticket_photo_by_pk?: Maybe<Support_Ticket_Photo>
  /** update multiples rows of table: "support_ticket_photo" */
  update_support_ticket_photo_many?: Maybe<
    Array<Maybe<Support_Ticket_Photo_Mutation_Response>>
  >
  /** update data of the table: "support_ticket_status" */
  update_support_ticket_status?: Maybe<Support_Ticket_Status_Mutation_Response>
  /** update single row of the table: "support_ticket_status" */
  update_support_ticket_status_by_pk?: Maybe<Support_Ticket_Status>
  /** update multiples rows of table: "support_ticket_status" */
  update_support_ticket_status_many?: Maybe<
    Array<Maybe<Support_Ticket_Status_Mutation_Response>>
  >
  /** update data of the table: "support_ticket_type" */
  update_support_ticket_type?: Maybe<Support_Ticket_Type_Mutation_Response>
  /** update single row of the table: "support_ticket_type" */
  update_support_ticket_type_by_pk?: Maybe<Support_Ticket_Type>
  /** update multiples rows of table: "support_ticket_type" */
  update_support_ticket_type_many?: Maybe<
    Array<Maybe<Support_Ticket_Type_Mutation_Response>>
  >
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>
  /** update data of the table: "user_aparment_status" */
  update_user_aparment_status?: Maybe<User_Aparment_Status_Mutation_Response>
  /** update single row of the table: "user_aparment_status" */
  update_user_aparment_status_by_pk?: Maybe<User_Aparment_Status>
  /** update multiples rows of table: "user_aparment_status" */
  update_user_aparment_status_many?: Maybe<
    Array<Maybe<User_Aparment_Status_Mutation_Response>>
  >
  /** update data of the table: "user_apartment" */
  update_user_apartment?: Maybe<User_Apartment_Mutation_Response>
  /** update single row of the table: "user_apartment" */
  update_user_apartment_by_pk?: Maybe<User_Apartment>
  /** update multiples rows of table: "user_apartment" */
  update_user_apartment_many?: Maybe<
    Array<Maybe<User_Apartment_Mutation_Response>>
  >
  /** update data of the table: "user_application" */
  update_user_application?: Maybe<User_Application_Mutation_Response>
  /** update data of the table: "user_application_address" */
  update_user_application_address?: Maybe<User_Application_Address_Mutation_Response>
  /** update single row of the table: "user_application_address" */
  update_user_application_address_by_pk?: Maybe<User_Application_Address>
  /** update multiples rows of table: "user_application_address" */
  update_user_application_address_many?: Maybe<
    Array<Maybe<User_Application_Address_Mutation_Response>>
  >
  /** update single row of the table: "user_application" */
  update_user_application_by_pk?: Maybe<User_Application>
  /** update data of the table: "user_application_income" */
  update_user_application_income?: Maybe<User_Application_Income_Mutation_Response>
  /** update single row of the table: "user_application_income" */
  update_user_application_income_by_pk?: Maybe<User_Application_Income>
  /** update data of the table: "user_application_income_document" */
  update_user_application_income_document?: Maybe<User_Application_Income_Document_Mutation_Response>
  /** update single row of the table: "user_application_income_document" */
  update_user_application_income_document_by_pk?: Maybe<User_Application_Income_Document>
  /** update multiples rows of table: "user_application_income_document" */
  update_user_application_income_document_many?: Maybe<
    Array<Maybe<User_Application_Income_Document_Mutation_Response>>
  >
  /** update multiples rows of table: "user_application_income" */
  update_user_application_income_many?: Maybe<
    Array<Maybe<User_Application_Income_Mutation_Response>>
  >
  /** update multiples rows of table: "user_application" */
  update_user_application_many?: Maybe<
    Array<Maybe<User_Application_Mutation_Response>>
  >
  /** update data of the table: "user_application_status" */
  update_user_application_status?: Maybe<User_Application_Status_Mutation_Response>
  /** update single row of the table: "user_application_status" */
  update_user_application_status_by_pk?: Maybe<User_Application_Status>
  /** update multiples rows of table: "user_application_status" */
  update_user_application_status_many?: Maybe<
    Array<Maybe<User_Application_Status_Mutation_Response>>
  >
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>
  /** update data of the table: "user_id_type" */
  update_user_id_type?: Maybe<User_Id_Type_Mutation_Response>
  /** update single row of the table: "user_id_type" */
  update_user_id_type_by_pk?: Maybe<User_Id_Type>
  /** update multiples rows of table: "user_id_type" */
  update_user_id_type_many?: Maybe<Array<Maybe<User_Id_Type_Mutation_Response>>>
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>
  /** update data of the table: "user_role" */
  update_user_role?: Maybe<User_Role_Mutation_Response>
  /** update single row of the table: "user_role" */
  update_user_role_by_pk?: Maybe<User_Role>
  /** update multiples rows of table: "user_role" */
  update_user_role_many?: Maybe<Array<Maybe<User_Role_Mutation_Response>>>
}

/** mutation root */
export type Mutation_RootActivate_SceneArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootControl_Smart_DeviceArgs = {
  data: Scalars['json']['input']
  deviceId: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_ApartmentArgs = {
  where: Apartment_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Apartment_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Apartment_Rental_TypeArgs = {
  where: Apartment_Rental_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Apartment_Rental_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Apartment_SceneArgs = {
  where: Apartment_Scene_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Apartment_Scene_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Apartment_TypeArgs = {
  where: Apartment_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Apartment_Type_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Apartment_Type_PhotoArgs = {
  where: Apartment_Type_Photo_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Apartment_Type_Photo_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_BuildingArgs = {
  where: Building_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Building_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Building_StatusArgs = {
  where: Building_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Building_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_CredentialArgs = {
  where: Credential_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Credential_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Credential_TypeArgs = {
  where: Credential_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Credential_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_DeviceArgs = {
  where: Device_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Device_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Device_GroupArgs = {
  where: Device_Group_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Device_Group_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Device_SceneArgs = {
  where: Device_Scene_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Device_Scene_By_PkArgs = {
  device_id: Scalars['uuid']['input']
  scene_id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Device_TypeArgs = {
  where: Device_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Device_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Support_TicketArgs = {
  where: Support_Ticket_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Support_Ticket_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Support_Ticket_PhotoArgs = {
  where: Support_Ticket_Photo_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Support_Ticket_Photo_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Support_Ticket_StatusArgs = {
  where: Support_Ticket_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Support_Ticket_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Support_Ticket_TypeArgs = {
  where: Support_Ticket_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Support_Ticket_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Aparment_StatusArgs = {
  where: User_Aparment_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Aparment_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_User_ApartmentArgs = {
  where: User_Apartment_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Apartment_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_User_ApplicationArgs = {
  where: User_Application_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Application_AddressArgs = {
  where: User_Application_Address_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Application_Address_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_User_Application_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_User_Application_IncomeArgs = {
  where: User_Application_Income_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Application_Income_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_User_Application_Income_DocumentArgs = {
  where: User_Application_Income_Document_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Application_Income_Document_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_User_Application_StatusArgs = {
  where: User_Application_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Application_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_User_Id_TypeArgs = {
  where: User_Id_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Id_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_User_RoleArgs = {
  where: User_Role_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Role_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootGenerate_Plaid_Link_TokenArgs = {
  incomeType?: InputMaybe<Income_Type>
  productType?: InputMaybe<Product_Type>
  userApplicationId: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootInsert_ApartmentArgs = {
  objects: Array<Apartment_Insert_Input>
  on_conflict?: InputMaybe<Apartment_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Apartment_OneArgs = {
  object: Apartment_Insert_Input
  on_conflict?: InputMaybe<Apartment_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Apartment_Rental_TypeArgs = {
  objects: Array<Apartment_Rental_Type_Insert_Input>
  on_conflict?: InputMaybe<Apartment_Rental_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Apartment_Rental_Type_OneArgs = {
  object: Apartment_Rental_Type_Insert_Input
  on_conflict?: InputMaybe<Apartment_Rental_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Apartment_SceneArgs = {
  objects: Array<Apartment_Scene_Insert_Input>
  on_conflict?: InputMaybe<Apartment_Scene_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Apartment_Scene_OneArgs = {
  object: Apartment_Scene_Insert_Input
  on_conflict?: InputMaybe<Apartment_Scene_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Apartment_TypeArgs = {
  objects: Array<Apartment_Type_Insert_Input>
  on_conflict?: InputMaybe<Apartment_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Apartment_Type_OneArgs = {
  object: Apartment_Type_Insert_Input
  on_conflict?: InputMaybe<Apartment_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Apartment_Type_PhotoArgs = {
  objects: Array<Apartment_Type_Photo_Insert_Input>
  on_conflict?: InputMaybe<Apartment_Type_Photo_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Apartment_Type_Photo_OneArgs = {
  object: Apartment_Type_Photo_Insert_Input
  on_conflict?: InputMaybe<Apartment_Type_Photo_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_BuildingArgs = {
  objects: Array<Building_Insert_Input>
  on_conflict?: InputMaybe<Building_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Building_OneArgs = {
  object: Building_Insert_Input
  on_conflict?: InputMaybe<Building_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Building_StatusArgs = {
  objects: Array<Building_Status_Insert_Input>
  on_conflict?: InputMaybe<Building_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Building_Status_OneArgs = {
  object: Building_Status_Insert_Input
  on_conflict?: InputMaybe<Building_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CredentialArgs = {
  objects: Array<Credential_Insert_Input>
  on_conflict?: InputMaybe<Credential_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Credential_OneArgs = {
  object: Credential_Insert_Input
  on_conflict?: InputMaybe<Credential_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Credential_TypeArgs = {
  objects: Array<Credential_Type_Insert_Input>
  on_conflict?: InputMaybe<Credential_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Credential_Type_OneArgs = {
  object: Credential_Type_Insert_Input
  on_conflict?: InputMaybe<Credential_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_DeviceArgs = {
  objects: Array<Device_Insert_Input>
  on_conflict?: InputMaybe<Device_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Device_GroupArgs = {
  objects: Array<Device_Group_Insert_Input>
  on_conflict?: InputMaybe<Device_Group_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Device_Group_OneArgs = {
  object: Device_Group_Insert_Input
  on_conflict?: InputMaybe<Device_Group_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Device_OneArgs = {
  object: Device_Insert_Input
  on_conflict?: InputMaybe<Device_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Device_SceneArgs = {
  objects: Array<Device_Scene_Insert_Input>
  on_conflict?: InputMaybe<Device_Scene_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Device_Scene_OneArgs = {
  object: Device_Scene_Insert_Input
  on_conflict?: InputMaybe<Device_Scene_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Device_TypeArgs = {
  objects: Array<Device_Type_Insert_Input>
  on_conflict?: InputMaybe<Device_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Device_Type_OneArgs = {
  object: Device_Type_Insert_Input
  on_conflict?: InputMaybe<Device_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Support_TicketArgs = {
  objects: Array<Support_Ticket_Insert_Input>
  on_conflict?: InputMaybe<Support_Ticket_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Support_Ticket_OneArgs = {
  object: Support_Ticket_Insert_Input
  on_conflict?: InputMaybe<Support_Ticket_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Support_Ticket_PhotoArgs = {
  objects: Array<Support_Ticket_Photo_Insert_Input>
  on_conflict?: InputMaybe<Support_Ticket_Photo_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Support_Ticket_Photo_OneArgs = {
  object: Support_Ticket_Photo_Insert_Input
  on_conflict?: InputMaybe<Support_Ticket_Photo_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Support_Ticket_StatusArgs = {
  objects: Array<Support_Ticket_Status_Insert_Input>
  on_conflict?: InputMaybe<Support_Ticket_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Support_Ticket_Status_OneArgs = {
  object: Support_Ticket_Status_Insert_Input
  on_conflict?: InputMaybe<Support_Ticket_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Support_Ticket_TypeArgs = {
  objects: Array<Support_Ticket_Type_Insert_Input>
  on_conflict?: InputMaybe<Support_Ticket_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Support_Ticket_Type_OneArgs = {
  object: Support_Ticket_Type_Insert_Input
  on_conflict?: InputMaybe<Support_Ticket_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Aparment_StatusArgs = {
  objects: Array<User_Aparment_Status_Insert_Input>
  on_conflict?: InputMaybe<User_Aparment_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Aparment_Status_OneArgs = {
  object: User_Aparment_Status_Insert_Input
  on_conflict?: InputMaybe<User_Aparment_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_ApartmentArgs = {
  objects: Array<User_Apartment_Insert_Input>
  on_conflict?: InputMaybe<User_Apartment_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Apartment_OneArgs = {
  object: User_Apartment_Insert_Input
  on_conflict?: InputMaybe<User_Apartment_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_ApplicationArgs = {
  objects: Array<User_Application_Insert_Input>
  on_conflict?: InputMaybe<User_Application_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Application_AddressArgs = {
  objects: Array<User_Application_Address_Insert_Input>
  on_conflict?: InputMaybe<User_Application_Address_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Application_Address_OneArgs = {
  object: User_Application_Address_Insert_Input
  on_conflict?: InputMaybe<User_Application_Address_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Application_IncomeArgs = {
  objects: Array<User_Application_Income_Insert_Input>
  on_conflict?: InputMaybe<User_Application_Income_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Application_Income_DocumentArgs = {
  objects: Array<User_Application_Income_Document_Insert_Input>
  on_conflict?: InputMaybe<User_Application_Income_Document_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Application_Income_Document_OneArgs = {
  object: User_Application_Income_Document_Insert_Input
  on_conflict?: InputMaybe<User_Application_Income_Document_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Application_Income_OneArgs = {
  object: User_Application_Income_Insert_Input
  on_conflict?: InputMaybe<User_Application_Income_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Application_OneArgs = {
  object: User_Application_Insert_Input
  on_conflict?: InputMaybe<User_Application_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Application_StatusArgs = {
  objects: Array<User_Application_Status_Insert_Input>
  on_conflict?: InputMaybe<User_Application_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Application_Status_OneArgs = {
  object: User_Application_Status_Insert_Input
  on_conflict?: InputMaybe<User_Application_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Id_TypeArgs = {
  objects: Array<User_Id_Type_Insert_Input>
  on_conflict?: InputMaybe<User_Id_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Id_Type_OneArgs = {
  object: User_Id_Type_Insert_Input
  on_conflict?: InputMaybe<User_Id_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_RoleArgs = {
  objects: Array<User_Role_Insert_Input>
  on_conflict?: InputMaybe<User_Role_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Role_OneArgs = {
  object: User_Role_Insert_Input
  on_conflict?: InputMaybe<User_Role_On_Conflict>
}

/** mutation root */
export type Mutation_RootSwap_Plaid_TokenArgs = {
  publicToken: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootUpdate_ApartmentArgs = {
  _inc?: InputMaybe<Apartment_Inc_Input>
  _set?: InputMaybe<Apartment_Set_Input>
  where: Apartment_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Apartment_By_PkArgs = {
  _inc?: InputMaybe<Apartment_Inc_Input>
  _set?: InputMaybe<Apartment_Set_Input>
  pk_columns: Apartment_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Apartment_ManyArgs = {
  updates: Array<Apartment_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Apartment_Rental_TypeArgs = {
  _set?: InputMaybe<Apartment_Rental_Type_Set_Input>
  where: Apartment_Rental_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Apartment_Rental_Type_By_PkArgs = {
  _set?: InputMaybe<Apartment_Rental_Type_Set_Input>
  pk_columns: Apartment_Rental_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Apartment_Rental_Type_ManyArgs = {
  updates: Array<Apartment_Rental_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Apartment_SceneArgs = {
  _set?: InputMaybe<Apartment_Scene_Set_Input>
  where: Apartment_Scene_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Apartment_Scene_By_PkArgs = {
  _set?: InputMaybe<Apartment_Scene_Set_Input>
  pk_columns: Apartment_Scene_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Apartment_Scene_ManyArgs = {
  updates: Array<Apartment_Scene_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Apartment_TypeArgs = {
  _append?: InputMaybe<Apartment_Type_Append_Input>
  _delete_at_path?: InputMaybe<Apartment_Type_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Apartment_Type_Delete_Elem_Input>
  _delete_key?: InputMaybe<Apartment_Type_Delete_Key_Input>
  _inc?: InputMaybe<Apartment_Type_Inc_Input>
  _prepend?: InputMaybe<Apartment_Type_Prepend_Input>
  _set?: InputMaybe<Apartment_Type_Set_Input>
  where: Apartment_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Apartment_Type_By_PkArgs = {
  _append?: InputMaybe<Apartment_Type_Append_Input>
  _delete_at_path?: InputMaybe<Apartment_Type_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Apartment_Type_Delete_Elem_Input>
  _delete_key?: InputMaybe<Apartment_Type_Delete_Key_Input>
  _inc?: InputMaybe<Apartment_Type_Inc_Input>
  _prepend?: InputMaybe<Apartment_Type_Prepend_Input>
  _set?: InputMaybe<Apartment_Type_Set_Input>
  pk_columns: Apartment_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Apartment_Type_ManyArgs = {
  updates: Array<Apartment_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Apartment_Type_PhotoArgs = {
  _set?: InputMaybe<Apartment_Type_Photo_Set_Input>
  where: Apartment_Type_Photo_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Apartment_Type_Photo_By_PkArgs = {
  _set?: InputMaybe<Apartment_Type_Photo_Set_Input>
  pk_columns: Apartment_Type_Photo_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Apartment_Type_Photo_ManyArgs = {
  updates: Array<Apartment_Type_Photo_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_BuildingArgs = {
  _inc?: InputMaybe<Building_Inc_Input>
  _set?: InputMaybe<Building_Set_Input>
  where: Building_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Building_By_PkArgs = {
  _inc?: InputMaybe<Building_Inc_Input>
  _set?: InputMaybe<Building_Set_Input>
  pk_columns: Building_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Building_ManyArgs = {
  updates: Array<Building_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Building_StatusArgs = {
  _set?: InputMaybe<Building_Status_Set_Input>
  where: Building_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Building_Status_By_PkArgs = {
  _set?: InputMaybe<Building_Status_Set_Input>
  pk_columns: Building_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Building_Status_ManyArgs = {
  updates: Array<Building_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CredentialArgs = {
  _append?: InputMaybe<Credential_Append_Input>
  _delete_at_path?: InputMaybe<Credential_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Credential_Delete_Elem_Input>
  _delete_key?: InputMaybe<Credential_Delete_Key_Input>
  _prepend?: InputMaybe<Credential_Prepend_Input>
  _set?: InputMaybe<Credential_Set_Input>
  where: Credential_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Credential_By_PkArgs = {
  _append?: InputMaybe<Credential_Append_Input>
  _delete_at_path?: InputMaybe<Credential_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Credential_Delete_Elem_Input>
  _delete_key?: InputMaybe<Credential_Delete_Key_Input>
  _prepend?: InputMaybe<Credential_Prepend_Input>
  _set?: InputMaybe<Credential_Set_Input>
  pk_columns: Credential_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Credential_ManyArgs = {
  updates: Array<Credential_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Credential_TypeArgs = {
  _set?: InputMaybe<Credential_Type_Set_Input>
  where: Credential_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Credential_Type_By_PkArgs = {
  _set?: InputMaybe<Credential_Type_Set_Input>
  pk_columns: Credential_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Credential_Type_ManyArgs = {
  updates: Array<Credential_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_DeviceArgs = {
  _append?: InputMaybe<Device_Append_Input>
  _delete_at_path?: InputMaybe<Device_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Device_Delete_Elem_Input>
  _delete_key?: InputMaybe<Device_Delete_Key_Input>
  _inc?: InputMaybe<Device_Inc_Input>
  _prepend?: InputMaybe<Device_Prepend_Input>
  _set?: InputMaybe<Device_Set_Input>
  where: Device_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Device_By_PkArgs = {
  _append?: InputMaybe<Device_Append_Input>
  _delete_at_path?: InputMaybe<Device_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Device_Delete_Elem_Input>
  _delete_key?: InputMaybe<Device_Delete_Key_Input>
  _inc?: InputMaybe<Device_Inc_Input>
  _prepend?: InputMaybe<Device_Prepend_Input>
  _set?: InputMaybe<Device_Set_Input>
  pk_columns: Device_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Device_GroupArgs = {
  _set?: InputMaybe<Device_Group_Set_Input>
  where: Device_Group_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Device_Group_By_PkArgs = {
  _set?: InputMaybe<Device_Group_Set_Input>
  pk_columns: Device_Group_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Device_Group_ManyArgs = {
  updates: Array<Device_Group_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Device_ManyArgs = {
  updates: Array<Device_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Device_SceneArgs = {
  _append?: InputMaybe<Device_Scene_Append_Input>
  _delete_at_path?: InputMaybe<Device_Scene_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Device_Scene_Delete_Elem_Input>
  _delete_key?: InputMaybe<Device_Scene_Delete_Key_Input>
  _prepend?: InputMaybe<Device_Scene_Prepend_Input>
  _set?: InputMaybe<Device_Scene_Set_Input>
  where: Device_Scene_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Device_Scene_By_PkArgs = {
  _append?: InputMaybe<Device_Scene_Append_Input>
  _delete_at_path?: InputMaybe<Device_Scene_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Device_Scene_Delete_Elem_Input>
  _delete_key?: InputMaybe<Device_Scene_Delete_Key_Input>
  _prepend?: InputMaybe<Device_Scene_Prepend_Input>
  _set?: InputMaybe<Device_Scene_Set_Input>
  pk_columns: Device_Scene_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Device_Scene_ManyArgs = {
  updates: Array<Device_Scene_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Device_TypeArgs = {
  _set?: InputMaybe<Device_Type_Set_Input>
  where: Device_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Device_Type_By_PkArgs = {
  _set?: InputMaybe<Device_Type_Set_Input>
  pk_columns: Device_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Device_Type_ManyArgs = {
  updates: Array<Device_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Support_TicketArgs = {
  _set?: InputMaybe<Support_Ticket_Set_Input>
  where: Support_Ticket_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Support_Ticket_By_PkArgs = {
  _set?: InputMaybe<Support_Ticket_Set_Input>
  pk_columns: Support_Ticket_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Support_Ticket_ManyArgs = {
  updates: Array<Support_Ticket_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Support_Ticket_PhotoArgs = {
  _set?: InputMaybe<Support_Ticket_Photo_Set_Input>
  where: Support_Ticket_Photo_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Support_Ticket_Photo_By_PkArgs = {
  _set?: InputMaybe<Support_Ticket_Photo_Set_Input>
  pk_columns: Support_Ticket_Photo_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Support_Ticket_Photo_ManyArgs = {
  updates: Array<Support_Ticket_Photo_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Support_Ticket_StatusArgs = {
  _set?: InputMaybe<Support_Ticket_Status_Set_Input>
  where: Support_Ticket_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Support_Ticket_Status_By_PkArgs = {
  _set?: InputMaybe<Support_Ticket_Status_Set_Input>
  pk_columns: Support_Ticket_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Support_Ticket_Status_ManyArgs = {
  updates: Array<Support_Ticket_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Support_Ticket_TypeArgs = {
  _set?: InputMaybe<Support_Ticket_Type_Set_Input>
  where: Support_Ticket_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Support_Ticket_Type_By_PkArgs = {
  _set?: InputMaybe<Support_Ticket_Type_Set_Input>
  pk_columns: Support_Ticket_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Support_Ticket_Type_ManyArgs = {
  updates: Array<Support_Ticket_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>
  where: User_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Aparment_StatusArgs = {
  _set?: InputMaybe<User_Aparment_Status_Set_Input>
  where: User_Aparment_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Aparment_Status_By_PkArgs = {
  _set?: InputMaybe<User_Aparment_Status_Set_Input>
  pk_columns: User_Aparment_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Aparment_Status_ManyArgs = {
  updates: Array<User_Aparment_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_ApartmentArgs = {
  _inc?: InputMaybe<User_Apartment_Inc_Input>
  _set?: InputMaybe<User_Apartment_Set_Input>
  where: User_Apartment_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Apartment_By_PkArgs = {
  _inc?: InputMaybe<User_Apartment_Inc_Input>
  _set?: InputMaybe<User_Apartment_Set_Input>
  pk_columns: User_Apartment_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Apartment_ManyArgs = {
  updates: Array<User_Apartment_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_ApplicationArgs = {
  _inc?: InputMaybe<User_Application_Inc_Input>
  _set?: InputMaybe<User_Application_Set_Input>
  where: User_Application_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Application_AddressArgs = {
  _inc?: InputMaybe<User_Application_Address_Inc_Input>
  _set?: InputMaybe<User_Application_Address_Set_Input>
  where: User_Application_Address_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Application_Address_By_PkArgs = {
  _inc?: InputMaybe<User_Application_Address_Inc_Input>
  _set?: InputMaybe<User_Application_Address_Set_Input>
  pk_columns: User_Application_Address_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Application_Address_ManyArgs = {
  updates: Array<User_Application_Address_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Application_By_PkArgs = {
  _inc?: InputMaybe<User_Application_Inc_Input>
  _set?: InputMaybe<User_Application_Set_Input>
  pk_columns: User_Application_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Application_IncomeArgs = {
  _inc?: InputMaybe<User_Application_Income_Inc_Input>
  _set?: InputMaybe<User_Application_Income_Set_Input>
  where: User_Application_Income_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Application_Income_By_PkArgs = {
  _inc?: InputMaybe<User_Application_Income_Inc_Input>
  _set?: InputMaybe<User_Application_Income_Set_Input>
  pk_columns: User_Application_Income_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Application_Income_DocumentArgs = {
  _inc?: InputMaybe<User_Application_Income_Document_Inc_Input>
  _set?: InputMaybe<User_Application_Income_Document_Set_Input>
  where: User_Application_Income_Document_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Application_Income_Document_By_PkArgs = {
  _inc?: InputMaybe<User_Application_Income_Document_Inc_Input>
  _set?: InputMaybe<User_Application_Income_Document_Set_Input>
  pk_columns: User_Application_Income_Document_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Application_Income_Document_ManyArgs = {
  updates: Array<User_Application_Income_Document_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Application_Income_ManyArgs = {
  updates: Array<User_Application_Income_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Application_ManyArgs = {
  updates: Array<User_Application_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Application_StatusArgs = {
  _set?: InputMaybe<User_Application_Status_Set_Input>
  where: User_Application_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Application_Status_By_PkArgs = {
  _set?: InputMaybe<User_Application_Status_Set_Input>
  pk_columns: User_Application_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Application_Status_ManyArgs = {
  updates: Array<User_Application_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>
  pk_columns: User_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Id_TypeArgs = {
  _set?: InputMaybe<User_Id_Type_Set_Input>
  where: User_Id_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Id_Type_By_PkArgs = {
  _set?: InputMaybe<User_Id_Type_Set_Input>
  pk_columns: User_Id_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Id_Type_ManyArgs = {
  updates: Array<User_Id_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_RoleArgs = {
  _set?: InputMaybe<User_Role_Set_Input>
  where: User_Role_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Role_By_PkArgs = {
  _set?: InputMaybe<User_Role_Set_Input>
  pk_columns: User_Role_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Role_ManyArgs = {
  updates: Array<User_Role_Updates>
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>
  _gt?: InputMaybe<Scalars['numeric']['input']>
  _gte?: InputMaybe<Scalars['numeric']['input']>
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['numeric']['input']>
  _lte?: InputMaybe<Scalars['numeric']['input']>
  _neq?: InputMaybe<Scalars['numeric']['input']>
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Plaid_Link_Token_Create_Type = {
  __typename?: 'plaid_link_token_create_type'
  expiration: Scalars['String']['output']
  linkToken?: Maybe<Scalars['String']['output']>
  productType?: Maybe<Product_Type>
  requestId: Scalars['String']['output']
}

export type Plaid_Swap_Token_Response_Type = {
  __typename?: 'plaid_swap_token_response_type'
  status: Scalars['String']['output']
}

export enum Product_Type {
  IdentityVerification = 'identity_verification',
  IncomeVerification = 'income_verification'
}

export type Query_Root = {
  __typename?: 'query_root'
  /** fetch data from the table: "apartment" */
  apartment: Array<Apartment>
  /** fetch aggregated fields from the table: "apartment" */
  apartment_aggregate: Apartment_Aggregate
  /** fetch data from the table: "apartment" using primary key columns */
  apartment_by_pk?: Maybe<Apartment>
  /** fetch data from the table: "apartment_rental_type" */
  apartment_rental_type: Array<Apartment_Rental_Type>
  /** fetch aggregated fields from the table: "apartment_rental_type" */
  apartment_rental_type_aggregate: Apartment_Rental_Type_Aggregate
  /** fetch data from the table: "apartment_rental_type" using primary key columns */
  apartment_rental_type_by_pk?: Maybe<Apartment_Rental_Type>
  /** fetch data from the table: "apartment_scene" */
  apartment_scene: Array<Apartment_Scene>
  /** fetch aggregated fields from the table: "apartment_scene" */
  apartment_scene_aggregate: Apartment_Scene_Aggregate
  /** fetch data from the table: "apartment_scene" using primary key columns */
  apartment_scene_by_pk?: Maybe<Apartment_Scene>
  /** fetch data from the table: "apartment_type" */
  apartment_type: Array<Apartment_Type>
  /** fetch aggregated fields from the table: "apartment_type" */
  apartment_type_aggregate: Apartment_Type_Aggregate
  /** fetch data from the table: "apartment_type" using primary key columns */
  apartment_type_by_pk?: Maybe<Apartment_Type>
  /** fetch data from the table: "apartment_type_photo" */
  apartment_type_photo: Array<Apartment_Type_Photo>
  /** fetch aggregated fields from the table: "apartment_type_photo" */
  apartment_type_photo_aggregate: Apartment_Type_Photo_Aggregate
  /** fetch data from the table: "apartment_type_photo" using primary key columns */
  apartment_type_photo_by_pk?: Maybe<Apartment_Type_Photo>
  /** fetch data from the table: "building" */
  building: Array<Building>
  /** fetch aggregated fields from the table: "building" */
  building_aggregate: Building_Aggregate
  /** fetch data from the table: "building" using primary key columns */
  building_by_pk?: Maybe<Building>
  /** fetch data from the table: "building_status" */
  building_status: Array<Building_Status>
  /** fetch aggregated fields from the table: "building_status" */
  building_status_aggregate: Building_Status_Aggregate
  /** fetch data from the table: "building_status" using primary key columns */
  building_status_by_pk?: Maybe<Building_Status>
  /** fetch data from the table: "credential" */
  credential: Array<Credential>
  /** fetch aggregated fields from the table: "credential" */
  credential_aggregate: Credential_Aggregate
  /** fetch data from the table: "credential" using primary key columns */
  credential_by_pk?: Maybe<Credential>
  /** fetch data from the table: "credential_type" */
  credential_type: Array<Credential_Type>
  /** fetch aggregated fields from the table: "credential_type" */
  credential_type_aggregate: Credential_Type_Aggregate
  /** fetch data from the table: "credential_type" using primary key columns */
  credential_type_by_pk?: Maybe<Credential_Type>
  /** fetch data from the table: "device" */
  device: Array<Device>
  /** fetch aggregated fields from the table: "device" */
  device_aggregate: Device_Aggregate
  /** fetch data from the table: "device" using primary key columns */
  device_by_pk?: Maybe<Device>
  /** fetch data from the table: "device_group" */
  device_group: Array<Device_Group>
  /** fetch aggregated fields from the table: "device_group" */
  device_group_aggregate: Device_Group_Aggregate
  /** fetch data from the table: "device_group" using primary key columns */
  device_group_by_pk?: Maybe<Device_Group>
  /** fetch data from the table: "device_scene" */
  device_scene: Array<Device_Scene>
  /** fetch aggregated fields from the table: "device_scene" */
  device_scene_aggregate: Device_Scene_Aggregate
  /** fetch data from the table: "device_scene" using primary key columns */
  device_scene_by_pk?: Maybe<Device_Scene>
  /** fetch data from the table: "device_type" */
  device_type: Array<Device_Type>
  /** fetch aggregated fields from the table: "device_type" */
  device_type_aggregate: Device_Type_Aggregate
  /** fetch data from the table: "device_type" using primary key columns */
  device_type_by_pk?: Maybe<Device_Type>
  initial_user_setup?: Maybe<Initial_User_Setup_Type>
  /** fetch data from the table: "support_ticket" */
  support_ticket: Array<Support_Ticket>
  /** fetch aggregated fields from the table: "support_ticket" */
  support_ticket_aggregate: Support_Ticket_Aggregate
  /** fetch data from the table: "support_ticket" using primary key columns */
  support_ticket_by_pk?: Maybe<Support_Ticket>
  /** fetch data from the table: "support_ticket_photo" */
  support_ticket_photo: Array<Support_Ticket_Photo>
  /** fetch aggregated fields from the table: "support_ticket_photo" */
  support_ticket_photo_aggregate: Support_Ticket_Photo_Aggregate
  /** fetch data from the table: "support_ticket_photo" using primary key columns */
  support_ticket_photo_by_pk?: Maybe<Support_Ticket_Photo>
  /** fetch data from the table: "support_ticket_status" */
  support_ticket_status: Array<Support_Ticket_Status>
  /** fetch aggregated fields from the table: "support_ticket_status" */
  support_ticket_status_aggregate: Support_Ticket_Status_Aggregate
  /** fetch data from the table: "support_ticket_status" using primary key columns */
  support_ticket_status_by_pk?: Maybe<Support_Ticket_Status>
  /** fetch data from the table: "support_ticket_type" */
  support_ticket_type: Array<Support_Ticket_Type>
  /** fetch aggregated fields from the table: "support_ticket_type" */
  support_ticket_type_aggregate: Support_Ticket_Type_Aggregate
  /** fetch data from the table: "support_ticket_type" using primary key columns */
  support_ticket_type_by_pk?: Maybe<Support_Ticket_Type>
  /** fetch data from the table: "user" */
  user: Array<User>
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate
  /** fetch data from the table: "user_aparment_status" */
  user_aparment_status: Array<User_Aparment_Status>
  /** fetch aggregated fields from the table: "user_aparment_status" */
  user_aparment_status_aggregate: User_Aparment_Status_Aggregate
  /** fetch data from the table: "user_aparment_status" using primary key columns */
  user_aparment_status_by_pk?: Maybe<User_Aparment_Status>
  /** fetch data from the table: "user_apartment" */
  user_apartment: Array<User_Apartment>
  /** fetch aggregated fields from the table: "user_apartment" */
  user_apartment_aggregate: User_Apartment_Aggregate
  /** fetch data from the table: "user_apartment" using primary key columns */
  user_apartment_by_pk?: Maybe<User_Apartment>
  /** fetch data from the table: "user_application" */
  user_application: Array<User_Application>
  /** fetch data from the table: "user_application_address" */
  user_application_address: Array<User_Application_Address>
  /** fetch aggregated fields from the table: "user_application_address" */
  user_application_address_aggregate: User_Application_Address_Aggregate
  /** fetch data from the table: "user_application_address" using primary key columns */
  user_application_address_by_pk?: Maybe<User_Application_Address>
  /** fetch aggregated fields from the table: "user_application" */
  user_application_aggregate: User_Application_Aggregate
  /** fetch data from the table: "user_application" using primary key columns */
  user_application_by_pk?: Maybe<User_Application>
  /** fetch data from the table: "user_application_income" */
  user_application_income: Array<User_Application_Income>
  /** fetch aggregated fields from the table: "user_application_income" */
  user_application_income_aggregate: User_Application_Income_Aggregate
  /** fetch data from the table: "user_application_income" using primary key columns */
  user_application_income_by_pk?: Maybe<User_Application_Income>
  /** fetch data from the table: "user_application_income_document" */
  user_application_income_document: Array<User_Application_Income_Document>
  /** fetch aggregated fields from the table: "user_application_income_document" */
  user_application_income_document_aggregate: User_Application_Income_Document_Aggregate
  /** fetch data from the table: "user_application_income_document" using primary key columns */
  user_application_income_document_by_pk?: Maybe<User_Application_Income_Document>
  /** fetch data from the table: "user_application_status" */
  user_application_status: Array<User_Application_Status>
  /** fetch aggregated fields from the table: "user_application_status" */
  user_application_status_aggregate: User_Application_Status_Aggregate
  /** fetch data from the table: "user_application_status" using primary key columns */
  user_application_status_by_pk?: Maybe<User_Application_Status>
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>
  /** fetch data from the table: "user_id_type" */
  user_id_type: Array<User_Id_Type>
  /** fetch aggregated fields from the table: "user_id_type" */
  user_id_type_aggregate: User_Id_Type_Aggregate
  /** fetch data from the table: "user_id_type" using primary key columns */
  user_id_type_by_pk?: Maybe<User_Id_Type>
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<User_Role>
}

export type Query_RootApartmentArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Order_By>>
  where?: InputMaybe<Apartment_Bool_Exp>
}

export type Query_RootApartment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Order_By>>
  where?: InputMaybe<Apartment_Bool_Exp>
}

export type Query_RootApartment_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootApartment_Rental_TypeArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Rental_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Rental_Type_Order_By>>
  where?: InputMaybe<Apartment_Rental_Type_Bool_Exp>
}

export type Query_RootApartment_Rental_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Rental_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Rental_Type_Order_By>>
  where?: InputMaybe<Apartment_Rental_Type_Bool_Exp>
}

export type Query_RootApartment_Rental_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootApartment_SceneArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Scene_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Scene_Order_By>>
  where?: InputMaybe<Apartment_Scene_Bool_Exp>
}

export type Query_RootApartment_Scene_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Scene_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Scene_Order_By>>
  where?: InputMaybe<Apartment_Scene_Bool_Exp>
}

export type Query_RootApartment_Scene_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootApartment_TypeArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Type_Order_By>>
  where?: InputMaybe<Apartment_Type_Bool_Exp>
}

export type Query_RootApartment_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Type_Order_By>>
  where?: InputMaybe<Apartment_Type_Bool_Exp>
}

export type Query_RootApartment_Type_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootApartment_Type_PhotoArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Type_Photo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Type_Photo_Order_By>>
  where?: InputMaybe<Apartment_Type_Photo_Bool_Exp>
}

export type Query_RootApartment_Type_Photo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Type_Photo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Type_Photo_Order_By>>
  where?: InputMaybe<Apartment_Type_Photo_Bool_Exp>
}

export type Query_RootApartment_Type_Photo_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootBuildingArgs = {
  distinct_on?: InputMaybe<Array<Building_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Building_Order_By>>
  where?: InputMaybe<Building_Bool_Exp>
}

export type Query_RootBuilding_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Building_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Building_Order_By>>
  where?: InputMaybe<Building_Bool_Exp>
}

export type Query_RootBuilding_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootBuilding_StatusArgs = {
  distinct_on?: InputMaybe<Array<Building_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Building_Status_Order_By>>
  where?: InputMaybe<Building_Status_Bool_Exp>
}

export type Query_RootBuilding_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Building_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Building_Status_Order_By>>
  where?: InputMaybe<Building_Status_Bool_Exp>
}

export type Query_RootBuilding_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootCredentialArgs = {
  distinct_on?: InputMaybe<Array<Credential_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Credential_Order_By>>
  where?: InputMaybe<Credential_Bool_Exp>
}

export type Query_RootCredential_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credential_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Credential_Order_By>>
  where?: InputMaybe<Credential_Bool_Exp>
}

export type Query_RootCredential_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootCredential_TypeArgs = {
  distinct_on?: InputMaybe<Array<Credential_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Credential_Type_Order_By>>
  where?: InputMaybe<Credential_Type_Bool_Exp>
}

export type Query_RootCredential_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credential_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Credential_Type_Order_By>>
  where?: InputMaybe<Credential_Type_Bool_Exp>
}

export type Query_RootCredential_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootDeviceArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

export type Query_RootDevice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

export type Query_RootDevice_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootDevice_GroupArgs = {
  distinct_on?: InputMaybe<Array<Device_Group_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Group_Order_By>>
  where?: InputMaybe<Device_Group_Bool_Exp>
}

export type Query_RootDevice_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Group_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Group_Order_By>>
  where?: InputMaybe<Device_Group_Bool_Exp>
}

export type Query_RootDevice_Group_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootDevice_SceneArgs = {
  distinct_on?: InputMaybe<Array<Device_Scene_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Scene_Order_By>>
  where?: InputMaybe<Device_Scene_Bool_Exp>
}

export type Query_RootDevice_Scene_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Scene_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Scene_Order_By>>
  where?: InputMaybe<Device_Scene_Bool_Exp>
}

export type Query_RootDevice_Scene_By_PkArgs = {
  device_id: Scalars['uuid']['input']
  scene_id: Scalars['uuid']['input']
}

export type Query_RootDevice_TypeArgs = {
  distinct_on?: InputMaybe<Array<Device_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Type_Order_By>>
  where?: InputMaybe<Device_Type_Bool_Exp>
}

export type Query_RootDevice_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Type_Order_By>>
  where?: InputMaybe<Device_Type_Bool_Exp>
}

export type Query_RootDevice_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootSupport_TicketArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Order_By>>
  where?: InputMaybe<Support_Ticket_Bool_Exp>
}

export type Query_RootSupport_Ticket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Order_By>>
  where?: InputMaybe<Support_Ticket_Bool_Exp>
}

export type Query_RootSupport_Ticket_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootSupport_Ticket_PhotoArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Photo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Photo_Order_By>>
  where?: InputMaybe<Support_Ticket_Photo_Bool_Exp>
}

export type Query_RootSupport_Ticket_Photo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Photo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Photo_Order_By>>
  where?: InputMaybe<Support_Ticket_Photo_Bool_Exp>
}

export type Query_RootSupport_Ticket_Photo_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootSupport_Ticket_StatusArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Status_Order_By>>
  where?: InputMaybe<Support_Ticket_Status_Bool_Exp>
}

export type Query_RootSupport_Ticket_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Status_Order_By>>
  where?: InputMaybe<Support_Ticket_Status_Bool_Exp>
}

export type Query_RootSupport_Ticket_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootSupport_Ticket_TypeArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Type_Order_By>>
  where?: InputMaybe<Support_Ticket_Type_Bool_Exp>
}

export type Query_RootSupport_Ticket_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Type_Order_By>>
  where?: InputMaybe<Support_Ticket_Type_Bool_Exp>
}

export type Query_RootSupport_Ticket_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Query_RootUser_Aparment_StatusArgs = {
  distinct_on?: InputMaybe<Array<User_Aparment_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Aparment_Status_Order_By>>
  where?: InputMaybe<User_Aparment_Status_Bool_Exp>
}

export type Query_RootUser_Aparment_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Aparment_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Aparment_Status_Order_By>>
  where?: InputMaybe<User_Aparment_Status_Bool_Exp>
}

export type Query_RootUser_Aparment_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootUser_ApartmentArgs = {
  distinct_on?: InputMaybe<Array<User_Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Apartment_Order_By>>
  where?: InputMaybe<User_Apartment_Bool_Exp>
}

export type Query_RootUser_Apartment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Apartment_Order_By>>
  where?: InputMaybe<User_Apartment_Bool_Exp>
}

export type Query_RootUser_Apartment_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootUser_ApplicationArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Order_By>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

export type Query_RootUser_Application_AddressArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Address_Order_By>>
  where?: InputMaybe<User_Application_Address_Bool_Exp>
}

export type Query_RootUser_Application_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Address_Order_By>>
  where?: InputMaybe<User_Application_Address_Bool_Exp>
}

export type Query_RootUser_Application_Address_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootUser_Application_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Order_By>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

export type Query_RootUser_Application_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootUser_Application_IncomeArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Income_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Income_Order_By>>
  where?: InputMaybe<User_Application_Income_Bool_Exp>
}

export type Query_RootUser_Application_Income_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Income_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Income_Order_By>>
  where?: InputMaybe<User_Application_Income_Bool_Exp>
}

export type Query_RootUser_Application_Income_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootUser_Application_Income_DocumentArgs = {
  distinct_on?: InputMaybe<
    Array<User_Application_Income_Document_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Income_Document_Order_By>>
  where?: InputMaybe<User_Application_Income_Document_Bool_Exp>
}

export type Query_RootUser_Application_Income_Document_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<User_Application_Income_Document_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Income_Document_Order_By>>
  where?: InputMaybe<User_Application_Income_Document_Bool_Exp>
}

export type Query_RootUser_Application_Income_Document_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootUser_Application_StatusArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Status_Order_By>>
  where?: InputMaybe<User_Application_Status_Bool_Exp>
}

export type Query_RootUser_Application_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Status_Order_By>>
  where?: InputMaybe<User_Application_Status_Bool_Exp>
}

export type Query_RootUser_Application_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootUser_Id_TypeArgs = {
  distinct_on?: InputMaybe<Array<User_Id_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Id_Type_Order_By>>
  where?: InputMaybe<User_Id_Type_Bool_Exp>
}

export type Query_RootUser_Id_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Id_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Id_Type_Order_By>>
  where?: InputMaybe<User_Id_Type_Bool_Exp>
}

export type Query_RootUser_Id_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootUser_RoleArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Role_Order_By>>
  where?: InputMaybe<User_Role_Bool_Exp>
}

export type Query_RootUser_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Role_Order_By>>
  where?: InputMaybe<User_Role_Bool_Exp>
}

export type Query_RootUser_Role_By_PkArgs = {
  value: Scalars['String']['input']
}

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float']['input']
  from: Scalars['geography']['input']
  use_spheroid?: InputMaybe<Scalars['Boolean']['input']>
}

export type St_D_Within_Input = {
  distance: Scalars['Float']['input']
  from: Scalars['geometry']['input']
}

export type Status_Response_Type = {
  __typename?: 'status_response_type'
  status: Scalars['String']['output']
}

export type Subscription_Root = {
  __typename?: 'subscription_root'
  /** fetch data from the table: "apartment" */
  apartment: Array<Apartment>
  /** fetch aggregated fields from the table: "apartment" */
  apartment_aggregate: Apartment_Aggregate
  /** fetch data from the table: "apartment" using primary key columns */
  apartment_by_pk?: Maybe<Apartment>
  /** fetch data from the table: "apartment_rental_type" */
  apartment_rental_type: Array<Apartment_Rental_Type>
  /** fetch aggregated fields from the table: "apartment_rental_type" */
  apartment_rental_type_aggregate: Apartment_Rental_Type_Aggregate
  /** fetch data from the table: "apartment_rental_type" using primary key columns */
  apartment_rental_type_by_pk?: Maybe<Apartment_Rental_Type>
  /** fetch data from the table in a streaming manner: "apartment_rental_type" */
  apartment_rental_type_stream: Array<Apartment_Rental_Type>
  /** fetch data from the table: "apartment_scene" */
  apartment_scene: Array<Apartment_Scene>
  /** fetch aggregated fields from the table: "apartment_scene" */
  apartment_scene_aggregate: Apartment_Scene_Aggregate
  /** fetch data from the table: "apartment_scene" using primary key columns */
  apartment_scene_by_pk?: Maybe<Apartment_Scene>
  /** fetch data from the table in a streaming manner: "apartment_scene" */
  apartment_scene_stream: Array<Apartment_Scene>
  /** fetch data from the table in a streaming manner: "apartment" */
  apartment_stream: Array<Apartment>
  /** fetch data from the table: "apartment_type" */
  apartment_type: Array<Apartment_Type>
  /** fetch aggregated fields from the table: "apartment_type" */
  apartment_type_aggregate: Apartment_Type_Aggregate
  /** fetch data from the table: "apartment_type" using primary key columns */
  apartment_type_by_pk?: Maybe<Apartment_Type>
  /** fetch data from the table: "apartment_type_photo" */
  apartment_type_photo: Array<Apartment_Type_Photo>
  /** fetch aggregated fields from the table: "apartment_type_photo" */
  apartment_type_photo_aggregate: Apartment_Type_Photo_Aggregate
  /** fetch data from the table: "apartment_type_photo" using primary key columns */
  apartment_type_photo_by_pk?: Maybe<Apartment_Type_Photo>
  /** fetch data from the table in a streaming manner: "apartment_type_photo" */
  apartment_type_photo_stream: Array<Apartment_Type_Photo>
  /** fetch data from the table in a streaming manner: "apartment_type" */
  apartment_type_stream: Array<Apartment_Type>
  /** fetch data from the table: "building" */
  building: Array<Building>
  /** fetch aggregated fields from the table: "building" */
  building_aggregate: Building_Aggregate
  /** fetch data from the table: "building" using primary key columns */
  building_by_pk?: Maybe<Building>
  /** fetch data from the table: "building_status" */
  building_status: Array<Building_Status>
  /** fetch aggregated fields from the table: "building_status" */
  building_status_aggregate: Building_Status_Aggregate
  /** fetch data from the table: "building_status" using primary key columns */
  building_status_by_pk?: Maybe<Building_Status>
  /** fetch data from the table in a streaming manner: "building_status" */
  building_status_stream: Array<Building_Status>
  /** fetch data from the table in a streaming manner: "building" */
  building_stream: Array<Building>
  /** fetch data from the table: "credential" */
  credential: Array<Credential>
  /** fetch aggregated fields from the table: "credential" */
  credential_aggregate: Credential_Aggregate
  /** fetch data from the table: "credential" using primary key columns */
  credential_by_pk?: Maybe<Credential>
  /** fetch data from the table in a streaming manner: "credential" */
  credential_stream: Array<Credential>
  /** fetch data from the table: "credential_type" */
  credential_type: Array<Credential_Type>
  /** fetch aggregated fields from the table: "credential_type" */
  credential_type_aggregate: Credential_Type_Aggregate
  /** fetch data from the table: "credential_type" using primary key columns */
  credential_type_by_pk?: Maybe<Credential_Type>
  /** fetch data from the table in a streaming manner: "credential_type" */
  credential_type_stream: Array<Credential_Type>
  /** fetch data from the table: "device" */
  device: Array<Device>
  /** fetch aggregated fields from the table: "device" */
  device_aggregate: Device_Aggregate
  /** fetch data from the table: "device" using primary key columns */
  device_by_pk?: Maybe<Device>
  /** fetch data from the table: "device_group" */
  device_group: Array<Device_Group>
  /** fetch aggregated fields from the table: "device_group" */
  device_group_aggregate: Device_Group_Aggregate
  /** fetch data from the table: "device_group" using primary key columns */
  device_group_by_pk?: Maybe<Device_Group>
  /** fetch data from the table in a streaming manner: "device_group" */
  device_group_stream: Array<Device_Group>
  /** fetch data from the table: "device_scene" */
  device_scene: Array<Device_Scene>
  /** fetch aggregated fields from the table: "device_scene" */
  device_scene_aggregate: Device_Scene_Aggregate
  /** fetch data from the table: "device_scene" using primary key columns */
  device_scene_by_pk?: Maybe<Device_Scene>
  /** fetch data from the table in a streaming manner: "device_scene" */
  device_scene_stream: Array<Device_Scene>
  /** fetch data from the table in a streaming manner: "device" */
  device_stream: Array<Device>
  /** fetch data from the table: "device_type" */
  device_type: Array<Device_Type>
  /** fetch aggregated fields from the table: "device_type" */
  device_type_aggregate: Device_Type_Aggregate
  /** fetch data from the table: "device_type" using primary key columns */
  device_type_by_pk?: Maybe<Device_Type>
  /** fetch data from the table in a streaming manner: "device_type" */
  device_type_stream: Array<Device_Type>
  /** fetch data from the table: "support_ticket" */
  support_ticket: Array<Support_Ticket>
  /** fetch aggregated fields from the table: "support_ticket" */
  support_ticket_aggregate: Support_Ticket_Aggregate
  /** fetch data from the table: "support_ticket" using primary key columns */
  support_ticket_by_pk?: Maybe<Support_Ticket>
  /** fetch data from the table: "support_ticket_photo" */
  support_ticket_photo: Array<Support_Ticket_Photo>
  /** fetch aggregated fields from the table: "support_ticket_photo" */
  support_ticket_photo_aggregate: Support_Ticket_Photo_Aggregate
  /** fetch data from the table: "support_ticket_photo" using primary key columns */
  support_ticket_photo_by_pk?: Maybe<Support_Ticket_Photo>
  /** fetch data from the table in a streaming manner: "support_ticket_photo" */
  support_ticket_photo_stream: Array<Support_Ticket_Photo>
  /** fetch data from the table: "support_ticket_status" */
  support_ticket_status: Array<Support_Ticket_Status>
  /** fetch aggregated fields from the table: "support_ticket_status" */
  support_ticket_status_aggregate: Support_Ticket_Status_Aggregate
  /** fetch data from the table: "support_ticket_status" using primary key columns */
  support_ticket_status_by_pk?: Maybe<Support_Ticket_Status>
  /** fetch data from the table in a streaming manner: "support_ticket_status" */
  support_ticket_status_stream: Array<Support_Ticket_Status>
  /** fetch data from the table in a streaming manner: "support_ticket" */
  support_ticket_stream: Array<Support_Ticket>
  /** fetch data from the table: "support_ticket_type" */
  support_ticket_type: Array<Support_Ticket_Type>
  /** fetch aggregated fields from the table: "support_ticket_type" */
  support_ticket_type_aggregate: Support_Ticket_Type_Aggregate
  /** fetch data from the table: "support_ticket_type" using primary key columns */
  support_ticket_type_by_pk?: Maybe<Support_Ticket_Type>
  /** fetch data from the table in a streaming manner: "support_ticket_type" */
  support_ticket_type_stream: Array<Support_Ticket_Type>
  /** fetch data from the table: "user" */
  user: Array<User>
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate
  /** fetch data from the table: "user_aparment_status" */
  user_aparment_status: Array<User_Aparment_Status>
  /** fetch aggregated fields from the table: "user_aparment_status" */
  user_aparment_status_aggregate: User_Aparment_Status_Aggregate
  /** fetch data from the table: "user_aparment_status" using primary key columns */
  user_aparment_status_by_pk?: Maybe<User_Aparment_Status>
  /** fetch data from the table in a streaming manner: "user_aparment_status" */
  user_aparment_status_stream: Array<User_Aparment_Status>
  /** fetch data from the table: "user_apartment" */
  user_apartment: Array<User_Apartment>
  /** fetch aggregated fields from the table: "user_apartment" */
  user_apartment_aggregate: User_Apartment_Aggregate
  /** fetch data from the table: "user_apartment" using primary key columns */
  user_apartment_by_pk?: Maybe<User_Apartment>
  /** fetch data from the table in a streaming manner: "user_apartment" */
  user_apartment_stream: Array<User_Apartment>
  /** fetch data from the table: "user_application" */
  user_application: Array<User_Application>
  /** fetch data from the table: "user_application_address" */
  user_application_address: Array<User_Application_Address>
  /** fetch aggregated fields from the table: "user_application_address" */
  user_application_address_aggregate: User_Application_Address_Aggregate
  /** fetch data from the table: "user_application_address" using primary key columns */
  user_application_address_by_pk?: Maybe<User_Application_Address>
  /** fetch data from the table in a streaming manner: "user_application_address" */
  user_application_address_stream: Array<User_Application_Address>
  /** fetch aggregated fields from the table: "user_application" */
  user_application_aggregate: User_Application_Aggregate
  /** fetch data from the table: "user_application" using primary key columns */
  user_application_by_pk?: Maybe<User_Application>
  /** fetch data from the table: "user_application_income" */
  user_application_income: Array<User_Application_Income>
  /** fetch aggregated fields from the table: "user_application_income" */
  user_application_income_aggregate: User_Application_Income_Aggregate
  /** fetch data from the table: "user_application_income" using primary key columns */
  user_application_income_by_pk?: Maybe<User_Application_Income>
  /** fetch data from the table: "user_application_income_document" */
  user_application_income_document: Array<User_Application_Income_Document>
  /** fetch aggregated fields from the table: "user_application_income_document" */
  user_application_income_document_aggregate: User_Application_Income_Document_Aggregate
  /** fetch data from the table: "user_application_income_document" using primary key columns */
  user_application_income_document_by_pk?: Maybe<User_Application_Income_Document>
  /** fetch data from the table in a streaming manner: "user_application_income_document" */
  user_application_income_document_stream: Array<User_Application_Income_Document>
  /** fetch data from the table in a streaming manner: "user_application_income" */
  user_application_income_stream: Array<User_Application_Income>
  /** fetch data from the table: "user_application_status" */
  user_application_status: Array<User_Application_Status>
  /** fetch aggregated fields from the table: "user_application_status" */
  user_application_status_aggregate: User_Application_Status_Aggregate
  /** fetch data from the table: "user_application_status" using primary key columns */
  user_application_status_by_pk?: Maybe<User_Application_Status>
  /** fetch data from the table in a streaming manner: "user_application_status" */
  user_application_status_stream: Array<User_Application_Status>
  /** fetch data from the table in a streaming manner: "user_application" */
  user_application_stream: Array<User_Application>
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>
  /** fetch data from the table: "user_id_type" */
  user_id_type: Array<User_Id_Type>
  /** fetch aggregated fields from the table: "user_id_type" */
  user_id_type_aggregate: User_Id_Type_Aggregate
  /** fetch data from the table: "user_id_type" using primary key columns */
  user_id_type_by_pk?: Maybe<User_Id_Type>
  /** fetch data from the table in a streaming manner: "user_id_type" */
  user_id_type_stream: Array<User_Id_Type>
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<User_Role>
  /** fetch data from the table in a streaming manner: "user_role" */
  user_role_stream: Array<User_Role>
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>
}

export type Subscription_RootApartmentArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Order_By>>
  where?: InputMaybe<Apartment_Bool_Exp>
}

export type Subscription_RootApartment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Order_By>>
  where?: InputMaybe<Apartment_Bool_Exp>
}

export type Subscription_RootApartment_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootApartment_Rental_TypeArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Rental_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Rental_Type_Order_By>>
  where?: InputMaybe<Apartment_Rental_Type_Bool_Exp>
}

export type Subscription_RootApartment_Rental_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Rental_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Rental_Type_Order_By>>
  where?: InputMaybe<Apartment_Rental_Type_Bool_Exp>
}

export type Subscription_RootApartment_Rental_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootApartment_Rental_Type_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Apartment_Rental_Type_Stream_Cursor_Input>>
  where?: InputMaybe<Apartment_Rental_Type_Bool_Exp>
}

export type Subscription_RootApartment_SceneArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Scene_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Scene_Order_By>>
  where?: InputMaybe<Apartment_Scene_Bool_Exp>
}

export type Subscription_RootApartment_Scene_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Scene_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Scene_Order_By>>
  where?: InputMaybe<Apartment_Scene_Bool_Exp>
}

export type Subscription_RootApartment_Scene_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootApartment_Scene_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Apartment_Scene_Stream_Cursor_Input>>
  where?: InputMaybe<Apartment_Scene_Bool_Exp>
}

export type Subscription_RootApartment_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Apartment_Stream_Cursor_Input>>
  where?: InputMaybe<Apartment_Bool_Exp>
}

export type Subscription_RootApartment_TypeArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Type_Order_By>>
  where?: InputMaybe<Apartment_Type_Bool_Exp>
}

export type Subscription_RootApartment_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Type_Order_By>>
  where?: InputMaybe<Apartment_Type_Bool_Exp>
}

export type Subscription_RootApartment_Type_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootApartment_Type_PhotoArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Type_Photo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Type_Photo_Order_By>>
  where?: InputMaybe<Apartment_Type_Photo_Bool_Exp>
}

export type Subscription_RootApartment_Type_Photo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apartment_Type_Photo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Apartment_Type_Photo_Order_By>>
  where?: InputMaybe<Apartment_Type_Photo_Bool_Exp>
}

export type Subscription_RootApartment_Type_Photo_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootApartment_Type_Photo_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Apartment_Type_Photo_Stream_Cursor_Input>>
  where?: InputMaybe<Apartment_Type_Photo_Bool_Exp>
}

export type Subscription_RootApartment_Type_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Apartment_Type_Stream_Cursor_Input>>
  where?: InputMaybe<Apartment_Type_Bool_Exp>
}

export type Subscription_RootBuildingArgs = {
  distinct_on?: InputMaybe<Array<Building_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Building_Order_By>>
  where?: InputMaybe<Building_Bool_Exp>
}

export type Subscription_RootBuilding_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Building_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Building_Order_By>>
  where?: InputMaybe<Building_Bool_Exp>
}

export type Subscription_RootBuilding_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootBuilding_StatusArgs = {
  distinct_on?: InputMaybe<Array<Building_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Building_Status_Order_By>>
  where?: InputMaybe<Building_Status_Bool_Exp>
}

export type Subscription_RootBuilding_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Building_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Building_Status_Order_By>>
  where?: InputMaybe<Building_Status_Bool_Exp>
}

export type Subscription_RootBuilding_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootBuilding_Status_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Building_Status_Stream_Cursor_Input>>
  where?: InputMaybe<Building_Status_Bool_Exp>
}

export type Subscription_RootBuilding_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Building_Stream_Cursor_Input>>
  where?: InputMaybe<Building_Bool_Exp>
}

export type Subscription_RootCredentialArgs = {
  distinct_on?: InputMaybe<Array<Credential_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Credential_Order_By>>
  where?: InputMaybe<Credential_Bool_Exp>
}

export type Subscription_RootCredential_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credential_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Credential_Order_By>>
  where?: InputMaybe<Credential_Bool_Exp>
}

export type Subscription_RootCredential_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootCredential_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Credential_Stream_Cursor_Input>>
  where?: InputMaybe<Credential_Bool_Exp>
}

export type Subscription_RootCredential_TypeArgs = {
  distinct_on?: InputMaybe<Array<Credential_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Credential_Type_Order_By>>
  where?: InputMaybe<Credential_Type_Bool_Exp>
}

export type Subscription_RootCredential_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Credential_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Credential_Type_Order_By>>
  where?: InputMaybe<Credential_Type_Bool_Exp>
}

export type Subscription_RootCredential_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootCredential_Type_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Credential_Type_Stream_Cursor_Input>>
  where?: InputMaybe<Credential_Type_Bool_Exp>
}

export type Subscription_RootDeviceArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

export type Subscription_RootDevice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Order_By>>
  where?: InputMaybe<Device_Bool_Exp>
}

export type Subscription_RootDevice_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootDevice_GroupArgs = {
  distinct_on?: InputMaybe<Array<Device_Group_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Group_Order_By>>
  where?: InputMaybe<Device_Group_Bool_Exp>
}

export type Subscription_RootDevice_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Group_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Group_Order_By>>
  where?: InputMaybe<Device_Group_Bool_Exp>
}

export type Subscription_RootDevice_Group_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootDevice_Group_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Device_Group_Stream_Cursor_Input>>
  where?: InputMaybe<Device_Group_Bool_Exp>
}

export type Subscription_RootDevice_SceneArgs = {
  distinct_on?: InputMaybe<Array<Device_Scene_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Scene_Order_By>>
  where?: InputMaybe<Device_Scene_Bool_Exp>
}

export type Subscription_RootDevice_Scene_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Scene_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Scene_Order_By>>
  where?: InputMaybe<Device_Scene_Bool_Exp>
}

export type Subscription_RootDevice_Scene_By_PkArgs = {
  device_id: Scalars['uuid']['input']
  scene_id: Scalars['uuid']['input']
}

export type Subscription_RootDevice_Scene_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Device_Scene_Stream_Cursor_Input>>
  where?: InputMaybe<Device_Scene_Bool_Exp>
}

export type Subscription_RootDevice_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Device_Stream_Cursor_Input>>
  where?: InputMaybe<Device_Bool_Exp>
}

export type Subscription_RootDevice_TypeArgs = {
  distinct_on?: InputMaybe<Array<Device_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Type_Order_By>>
  where?: InputMaybe<Device_Type_Bool_Exp>
}

export type Subscription_RootDevice_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Device_Type_Order_By>>
  where?: InputMaybe<Device_Type_Bool_Exp>
}

export type Subscription_RootDevice_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootDevice_Type_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Device_Type_Stream_Cursor_Input>>
  where?: InputMaybe<Device_Type_Bool_Exp>
}

export type Subscription_RootSupport_TicketArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Order_By>>
  where?: InputMaybe<Support_Ticket_Bool_Exp>
}

export type Subscription_RootSupport_Ticket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Order_By>>
  where?: InputMaybe<Support_Ticket_Bool_Exp>
}

export type Subscription_RootSupport_Ticket_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootSupport_Ticket_PhotoArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Photo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Photo_Order_By>>
  where?: InputMaybe<Support_Ticket_Photo_Bool_Exp>
}

export type Subscription_RootSupport_Ticket_Photo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Photo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Photo_Order_By>>
  where?: InputMaybe<Support_Ticket_Photo_Bool_Exp>
}

export type Subscription_RootSupport_Ticket_Photo_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootSupport_Ticket_Photo_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Support_Ticket_Photo_Stream_Cursor_Input>>
  where?: InputMaybe<Support_Ticket_Photo_Bool_Exp>
}

export type Subscription_RootSupport_Ticket_StatusArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Status_Order_By>>
  where?: InputMaybe<Support_Ticket_Status_Bool_Exp>
}

export type Subscription_RootSupport_Ticket_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Status_Order_By>>
  where?: InputMaybe<Support_Ticket_Status_Bool_Exp>
}

export type Subscription_RootSupport_Ticket_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootSupport_Ticket_Status_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Support_Ticket_Status_Stream_Cursor_Input>>
  where?: InputMaybe<Support_Ticket_Status_Bool_Exp>
}

export type Subscription_RootSupport_Ticket_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Support_Ticket_Stream_Cursor_Input>>
  where?: InputMaybe<Support_Ticket_Bool_Exp>
}

export type Subscription_RootSupport_Ticket_TypeArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Type_Order_By>>
  where?: InputMaybe<Support_Ticket_Type_Bool_Exp>
}

export type Subscription_RootSupport_Ticket_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Type_Order_By>>
  where?: InputMaybe<Support_Ticket_Type_Bool_Exp>
}

export type Subscription_RootSupport_Ticket_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootSupport_Ticket_Type_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Support_Ticket_Type_Stream_Cursor_Input>>
  where?: InputMaybe<Support_Ticket_Type_Bool_Exp>
}

export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Subscription_RootUser_Aparment_StatusArgs = {
  distinct_on?: InputMaybe<Array<User_Aparment_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Aparment_Status_Order_By>>
  where?: InputMaybe<User_Aparment_Status_Bool_Exp>
}

export type Subscription_RootUser_Aparment_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Aparment_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Aparment_Status_Order_By>>
  where?: InputMaybe<User_Aparment_Status_Bool_Exp>
}

export type Subscription_RootUser_Aparment_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootUser_Aparment_Status_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Aparment_Status_Stream_Cursor_Input>>
  where?: InputMaybe<User_Aparment_Status_Bool_Exp>
}

export type Subscription_RootUser_ApartmentArgs = {
  distinct_on?: InputMaybe<Array<User_Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Apartment_Order_By>>
  where?: InputMaybe<User_Apartment_Bool_Exp>
}

export type Subscription_RootUser_Apartment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Apartment_Order_By>>
  where?: InputMaybe<User_Apartment_Bool_Exp>
}

export type Subscription_RootUser_Apartment_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootUser_Apartment_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Apartment_Stream_Cursor_Input>>
  where?: InputMaybe<User_Apartment_Bool_Exp>
}

export type Subscription_RootUser_ApplicationArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Order_By>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

export type Subscription_RootUser_Application_AddressArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Address_Order_By>>
  where?: InputMaybe<User_Application_Address_Bool_Exp>
}

export type Subscription_RootUser_Application_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Address_Order_By>>
  where?: InputMaybe<User_Application_Address_Bool_Exp>
}

export type Subscription_RootUser_Application_Address_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootUser_Application_Address_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Application_Address_Stream_Cursor_Input>>
  where?: InputMaybe<User_Application_Address_Bool_Exp>
}

export type Subscription_RootUser_Application_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Order_By>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

export type Subscription_RootUser_Application_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootUser_Application_IncomeArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Income_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Income_Order_By>>
  where?: InputMaybe<User_Application_Income_Bool_Exp>
}

export type Subscription_RootUser_Application_Income_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Income_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Income_Order_By>>
  where?: InputMaybe<User_Application_Income_Bool_Exp>
}

export type Subscription_RootUser_Application_Income_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootUser_Application_Income_DocumentArgs = {
  distinct_on?: InputMaybe<
    Array<User_Application_Income_Document_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Income_Document_Order_By>>
  where?: InputMaybe<User_Application_Income_Document_Bool_Exp>
}

export type Subscription_RootUser_Application_Income_Document_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<User_Application_Income_Document_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Income_Document_Order_By>>
  where?: InputMaybe<User_Application_Income_Document_Bool_Exp>
}

export type Subscription_RootUser_Application_Income_Document_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootUser_Application_Income_Document_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<
    InputMaybe<User_Application_Income_Document_Stream_Cursor_Input>
  >
  where?: InputMaybe<User_Application_Income_Document_Bool_Exp>
}

export type Subscription_RootUser_Application_Income_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Application_Income_Stream_Cursor_Input>>
  where?: InputMaybe<User_Application_Income_Bool_Exp>
}

export type Subscription_RootUser_Application_StatusArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Status_Order_By>>
  where?: InputMaybe<User_Application_Status_Bool_Exp>
}

export type Subscription_RootUser_Application_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Status_Order_By>>
  where?: InputMaybe<User_Application_Status_Bool_Exp>
}

export type Subscription_RootUser_Application_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootUser_Application_Status_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Application_Status_Stream_Cursor_Input>>
  where?: InputMaybe<User_Application_Status_Bool_Exp>
}

export type Subscription_RootUser_Application_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Application_Stream_Cursor_Input>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootUser_Id_TypeArgs = {
  distinct_on?: InputMaybe<Array<User_Id_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Id_Type_Order_By>>
  where?: InputMaybe<User_Id_Type_Bool_Exp>
}

export type Subscription_RootUser_Id_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Id_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Id_Type_Order_By>>
  where?: InputMaybe<User_Id_Type_Bool_Exp>
}

export type Subscription_RootUser_Id_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootUser_Id_Type_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Id_Type_Stream_Cursor_Input>>
  where?: InputMaybe<User_Id_Type_Bool_Exp>
}

export type Subscription_RootUser_RoleArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Role_Order_By>>
  where?: InputMaybe<User_Role_Bool_Exp>
}

export type Subscription_RootUser_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Role_Order_By>>
  where?: InputMaybe<User_Role_Bool_Exp>
}

export type Subscription_RootUser_Role_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootUser_Role_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Role_Stream_Cursor_Input>>
  where?: InputMaybe<User_Role_Bool_Exp>
}

export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>
  where?: InputMaybe<User_Bool_Exp>
}

/** columns and relationships of "support_ticket" */
export type Support_Ticket = {
  __typename?: 'support_ticket'
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id: Scalars['uuid']['output']
  image?: Maybe<Scalars['String']['output']>
  message: Scalars['String']['output']
  /** An array relationship */
  photos: Array<Support_Ticket_Photo>
  /** An aggregate relationship */
  photos_aggregate: Support_Ticket_Photo_Aggregate
  status: Support_Ticket_Status_Enum
  /** An object relationship */
  support_ticket_status: Support_Ticket_Status
  /** An object relationship */
  support_ticket_type: Support_Ticket_Type
  type: Support_Ticket_Type_Enum
  updated_at: Scalars['timestamp']['output']
  /** An object relationship */
  user: User
  user_id: Scalars['uuid']['output']
}

/** columns and relationships of "support_ticket" */
export type Support_TicketPhotosArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Photo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Photo_Order_By>>
  where?: InputMaybe<Support_Ticket_Photo_Bool_Exp>
}

/** columns and relationships of "support_ticket" */
export type Support_TicketPhotos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Photo_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Photo_Order_By>>
  where?: InputMaybe<Support_Ticket_Photo_Bool_Exp>
}

/** aggregated selection of "support_ticket" */
export type Support_Ticket_Aggregate = {
  __typename?: 'support_ticket_aggregate'
  aggregate?: Maybe<Support_Ticket_Aggregate_Fields>
  nodes: Array<Support_Ticket>
}

export type Support_Ticket_Aggregate_Bool_Exp = {
  count?: InputMaybe<Support_Ticket_Aggregate_Bool_Exp_Count>
}

export type Support_Ticket_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Support_Ticket_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Support_Ticket_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "support_ticket" */
export type Support_Ticket_Aggregate_Fields = {
  __typename?: 'support_ticket_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Support_Ticket_Max_Fields>
  min?: Maybe<Support_Ticket_Min_Fields>
}

/** aggregate fields of "support_ticket" */
export type Support_Ticket_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Support_Ticket_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "support_ticket" */
export type Support_Ticket_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Support_Ticket_Max_Order_By>
  min?: InputMaybe<Support_Ticket_Min_Order_By>
}

/** input type for inserting array relation for remote table "support_ticket" */
export type Support_Ticket_Arr_Rel_Insert_Input = {
  data: Array<Support_Ticket_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Support_Ticket_On_Conflict>
}

/** Boolean expression to filter rows from the table "support_ticket". All fields are combined with a logical 'AND'. */
export type Support_Ticket_Bool_Exp = {
  _and?: InputMaybe<Array<Support_Ticket_Bool_Exp>>
  _not?: InputMaybe<Support_Ticket_Bool_Exp>
  _or?: InputMaybe<Array<Support_Ticket_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  image?: InputMaybe<String_Comparison_Exp>
  message?: InputMaybe<String_Comparison_Exp>
  photos?: InputMaybe<Support_Ticket_Photo_Bool_Exp>
  photos_aggregate?: InputMaybe<Support_Ticket_Photo_Aggregate_Bool_Exp>
  status?: InputMaybe<Support_Ticket_Status_Enum_Comparison_Exp>
  support_ticket_status?: InputMaybe<Support_Ticket_Status_Bool_Exp>
  support_ticket_type?: InputMaybe<Support_Ticket_Type_Bool_Exp>
  type?: InputMaybe<Support_Ticket_Type_Enum_Comparison_Exp>
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
  user_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "support_ticket" */
export enum Support_Ticket_Constraint {
  /** unique or primary key constraint on columns "id" */
  SupportTicketPkey = 'support_ticket_pkey'
}

/** input type for inserting data into table "support_ticket" */
export type Support_Ticket_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  image?: InputMaybe<Scalars['String']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  photos?: InputMaybe<Support_Ticket_Photo_Arr_Rel_Insert_Input>
  status?: InputMaybe<Support_Ticket_Status_Enum>
  support_ticket_status?: InputMaybe<Support_Ticket_Status_Obj_Rel_Insert_Input>
  support_ticket_type?: InputMaybe<Support_Ticket_Type_Obj_Rel_Insert_Input>
  type?: InputMaybe<Support_Ticket_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamp']['input']>
  user?: InputMaybe<User_Obj_Rel_Insert_Input>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate max on columns */
export type Support_Ticket_Max_Fields = {
  __typename?: 'support_ticket_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  image?: Maybe<Scalars['String']['output']>
  message?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamp']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** order by max() on columns of table "support_ticket" */
export type Support_Ticket_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image?: InputMaybe<Order_By>
  message?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Support_Ticket_Min_Fields = {
  __typename?: 'support_ticket_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  image?: Maybe<Scalars['String']['output']>
  message?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamp']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** order by min() on columns of table "support_ticket" */
export type Support_Ticket_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image?: InputMaybe<Order_By>
  message?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "support_ticket" */
export type Support_Ticket_Mutation_Response = {
  __typename?: 'support_ticket_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Support_Ticket>
}

/** input type for inserting object relation for remote table "support_ticket" */
export type Support_Ticket_Obj_Rel_Insert_Input = {
  data: Support_Ticket_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Support_Ticket_On_Conflict>
}

/** on_conflict condition type for table "support_ticket" */
export type Support_Ticket_On_Conflict = {
  constraint: Support_Ticket_Constraint
  update_columns?: Array<Support_Ticket_Update_Column>
  where?: InputMaybe<Support_Ticket_Bool_Exp>
}

/** Ordering options when selecting data from "support_ticket". */
export type Support_Ticket_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image?: InputMaybe<Order_By>
  message?: InputMaybe<Order_By>
  photos_aggregate?: InputMaybe<Support_Ticket_Photo_Aggregate_Order_By>
  status?: InputMaybe<Order_By>
  support_ticket_status?: InputMaybe<Support_Ticket_Status_Order_By>
  support_ticket_type?: InputMaybe<Support_Ticket_Type_Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
  user_id?: InputMaybe<Order_By>
}

/** columns and relationships of "support_ticket_photo" */
export type Support_Ticket_Photo = {
  __typename?: 'support_ticket_photo'
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  folder: Scalars['String']['output']
  id: Scalars['uuid']['output']
  path: Scalars['String']['output']
  /** An object relationship */
  support_ticket: Support_Ticket
  support_ticket_id: Scalars['uuid']['output']
  updated_at: Scalars['timestamp']['output']
}

/** aggregated selection of "support_ticket_photo" */
export type Support_Ticket_Photo_Aggregate = {
  __typename?: 'support_ticket_photo_aggregate'
  aggregate?: Maybe<Support_Ticket_Photo_Aggregate_Fields>
  nodes: Array<Support_Ticket_Photo>
}

export type Support_Ticket_Photo_Aggregate_Bool_Exp = {
  count?: InputMaybe<Support_Ticket_Photo_Aggregate_Bool_Exp_Count>
}

export type Support_Ticket_Photo_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Support_Ticket_Photo_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Support_Ticket_Photo_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "support_ticket_photo" */
export type Support_Ticket_Photo_Aggregate_Fields = {
  __typename?: 'support_ticket_photo_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Support_Ticket_Photo_Max_Fields>
  min?: Maybe<Support_Ticket_Photo_Min_Fields>
}

/** aggregate fields of "support_ticket_photo" */
export type Support_Ticket_Photo_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Support_Ticket_Photo_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "support_ticket_photo" */
export type Support_Ticket_Photo_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Support_Ticket_Photo_Max_Order_By>
  min?: InputMaybe<Support_Ticket_Photo_Min_Order_By>
}

/** input type for inserting array relation for remote table "support_ticket_photo" */
export type Support_Ticket_Photo_Arr_Rel_Insert_Input = {
  data: Array<Support_Ticket_Photo_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Support_Ticket_Photo_On_Conflict>
}

/** Boolean expression to filter rows from the table "support_ticket_photo". All fields are combined with a logical 'AND'. */
export type Support_Ticket_Photo_Bool_Exp = {
  _and?: InputMaybe<Array<Support_Ticket_Photo_Bool_Exp>>
  _not?: InputMaybe<Support_Ticket_Photo_Bool_Exp>
  _or?: InputMaybe<Array<Support_Ticket_Photo_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  folder?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  path?: InputMaybe<String_Comparison_Exp>
  support_ticket?: InputMaybe<Support_Ticket_Bool_Exp>
  support_ticket_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>
}

/** unique or primary key constraints on table "support_ticket_photo" */
export enum Support_Ticket_Photo_Constraint {
  /** unique or primary key constraint on columns "id" */
  SupportTicketPhotoPkey = 'support_ticket_photo_pkey'
}

/** input type for inserting data into table "support_ticket_photo" */
export type Support_Ticket_Photo_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  folder?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  path?: InputMaybe<Scalars['String']['input']>
  support_ticket?: InputMaybe<Support_Ticket_Obj_Rel_Insert_Input>
  support_ticket_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamp']['input']>
}

/** aggregate max on columns */
export type Support_Ticket_Photo_Max_Fields = {
  __typename?: 'support_ticket_photo_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  folder?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  path?: Maybe<Scalars['String']['output']>
  support_ticket_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamp']['output']>
}

/** order by max() on columns of table "support_ticket_photo" */
export type Support_Ticket_Photo_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  folder?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  path?: InputMaybe<Order_By>
  support_ticket_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Support_Ticket_Photo_Min_Fields = {
  __typename?: 'support_ticket_photo_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  folder?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  path?: Maybe<Scalars['String']['output']>
  support_ticket_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamp']['output']>
}

/** order by min() on columns of table "support_ticket_photo" */
export type Support_Ticket_Photo_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  folder?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  path?: InputMaybe<Order_By>
  support_ticket_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "support_ticket_photo" */
export type Support_Ticket_Photo_Mutation_Response = {
  __typename?: 'support_ticket_photo_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Support_Ticket_Photo>
}

/** on_conflict condition type for table "support_ticket_photo" */
export type Support_Ticket_Photo_On_Conflict = {
  constraint: Support_Ticket_Photo_Constraint
  update_columns?: Array<Support_Ticket_Photo_Update_Column>
  where?: InputMaybe<Support_Ticket_Photo_Bool_Exp>
}

/** Ordering options when selecting data from "support_ticket_photo". */
export type Support_Ticket_Photo_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  folder?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  path?: InputMaybe<Order_By>
  support_ticket?: InputMaybe<Support_Ticket_Order_By>
  support_ticket_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: support_ticket_photo */
export type Support_Ticket_Photo_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "support_ticket_photo" */
export enum Support_Ticket_Photo_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Folder = 'folder',
  /** column name */
  Id = 'id',
  /** column name */
  Path = 'path',
  /** column name */
  SupportTicketId = 'support_ticket_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "support_ticket_photo" */
export type Support_Ticket_Photo_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  folder?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  path?: InputMaybe<Scalars['String']['input']>
  support_ticket_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamp']['input']>
}

/** Streaming cursor of the table "support_ticket_photo" */
export type Support_Ticket_Photo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Support_Ticket_Photo_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Support_Ticket_Photo_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  folder?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  path?: InputMaybe<Scalars['String']['input']>
  support_ticket_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamp']['input']>
}

/** update columns of table "support_ticket_photo" */
export enum Support_Ticket_Photo_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Folder = 'folder',
  /** column name */
  Id = 'id',
  /** column name */
  Path = 'path',
  /** column name */
  SupportTicketId = 'support_ticket_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Support_Ticket_Photo_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Support_Ticket_Photo_Set_Input>
  /** filter the rows which have to be updated */
  where: Support_Ticket_Photo_Bool_Exp
}

/** primary key columns input for table: support_ticket */
export type Support_Ticket_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "support_ticket" */
export enum Support_Ticket_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Message = 'message',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "support_ticket" */
export type Support_Ticket_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  image?: InputMaybe<Scalars['String']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Support_Ticket_Status_Enum>
  type?: InputMaybe<Support_Ticket_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamp']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** columns and relationships of "support_ticket_status" */
export type Support_Ticket_Status = {
  __typename?: 'support_ticket_status'
  comment: Scalars['String']['output']
  /** An array relationship */
  support_tickets: Array<Support_Ticket>
  /** An aggregate relationship */
  support_tickets_aggregate: Support_Ticket_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "support_ticket_status" */
export type Support_Ticket_StatusSupport_TicketsArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Order_By>>
  where?: InputMaybe<Support_Ticket_Bool_Exp>
}

/** columns and relationships of "support_ticket_status" */
export type Support_Ticket_StatusSupport_Tickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Order_By>>
  where?: InputMaybe<Support_Ticket_Bool_Exp>
}

/** aggregated selection of "support_ticket_status" */
export type Support_Ticket_Status_Aggregate = {
  __typename?: 'support_ticket_status_aggregate'
  aggregate?: Maybe<Support_Ticket_Status_Aggregate_Fields>
  nodes: Array<Support_Ticket_Status>
}

/** aggregate fields of "support_ticket_status" */
export type Support_Ticket_Status_Aggregate_Fields = {
  __typename?: 'support_ticket_status_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Support_Ticket_Status_Max_Fields>
  min?: Maybe<Support_Ticket_Status_Min_Fields>
}

/** aggregate fields of "support_ticket_status" */
export type Support_Ticket_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Support_Ticket_Status_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "support_ticket_status". All fields are combined with a logical 'AND'. */
export type Support_Ticket_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Support_Ticket_Status_Bool_Exp>>
  _not?: InputMaybe<Support_Ticket_Status_Bool_Exp>
  _or?: InputMaybe<Array<Support_Ticket_Status_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  support_tickets?: InputMaybe<Support_Ticket_Bool_Exp>
  support_tickets_aggregate?: InputMaybe<Support_Ticket_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "support_ticket_status" */
export enum Support_Ticket_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  SupportTicketStatusPkey = 'support_ticket_status_pkey'
}

export enum Support_Ticket_Status_Enum {
  /** ANSWERED */
  Answered = 'ANSWERED',
  /** NEW */
  New = 'NEW',
  /** RESOLVED */
  Resolved = 'RESOLVED'
}

/** Boolean expression to compare columns of type "support_ticket_status_enum". All fields are combined with logical 'AND'. */
export type Support_Ticket_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Support_Ticket_Status_Enum>
  _in?: InputMaybe<Array<Support_Ticket_Status_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Support_Ticket_Status_Enum>
  _nin?: InputMaybe<Array<Support_Ticket_Status_Enum>>
}

/** input type for inserting data into table "support_ticket_status" */
export type Support_Ticket_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  support_tickets?: InputMaybe<Support_Ticket_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Support_Ticket_Status_Max_Fields = {
  __typename?: 'support_ticket_status_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Support_Ticket_Status_Min_Fields = {
  __typename?: 'support_ticket_status_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "support_ticket_status" */
export type Support_Ticket_Status_Mutation_Response = {
  __typename?: 'support_ticket_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Support_Ticket_Status>
}

/** input type for inserting object relation for remote table "support_ticket_status" */
export type Support_Ticket_Status_Obj_Rel_Insert_Input = {
  data: Support_Ticket_Status_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Support_Ticket_Status_On_Conflict>
}

/** on_conflict condition type for table "support_ticket_status" */
export type Support_Ticket_Status_On_Conflict = {
  constraint: Support_Ticket_Status_Constraint
  update_columns?: Array<Support_Ticket_Status_Update_Column>
  where?: InputMaybe<Support_Ticket_Status_Bool_Exp>
}

/** Ordering options when selecting data from "support_ticket_status". */
export type Support_Ticket_Status_Order_By = {
  comment?: InputMaybe<Order_By>
  support_tickets_aggregate?: InputMaybe<Support_Ticket_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: support_ticket_status */
export type Support_Ticket_Status_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "support_ticket_status" */
export enum Support_Ticket_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "support_ticket_status" */
export type Support_Ticket_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "support_ticket_status" */
export type Support_Ticket_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Support_Ticket_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Support_Ticket_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "support_ticket_status" */
export enum Support_Ticket_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Support_Ticket_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Support_Ticket_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Support_Ticket_Status_Bool_Exp
}

/** Streaming cursor of the table "support_ticket" */
export type Support_Ticket_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Support_Ticket_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Support_Ticket_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  image?: InputMaybe<Scalars['String']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Support_Ticket_Status_Enum>
  type?: InputMaybe<Support_Ticket_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamp']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** columns and relationships of "support_ticket_type" */
export type Support_Ticket_Type = {
  __typename?: 'support_ticket_type'
  comment: Scalars['String']['output']
  /** An array relationship */
  support_tickets: Array<Support_Ticket>
  /** An aggregate relationship */
  support_tickets_aggregate: Support_Ticket_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "support_ticket_type" */
export type Support_Ticket_TypeSupport_TicketsArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Order_By>>
  where?: InputMaybe<Support_Ticket_Bool_Exp>
}

/** columns and relationships of "support_ticket_type" */
export type Support_Ticket_TypeSupport_Tickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Order_By>>
  where?: InputMaybe<Support_Ticket_Bool_Exp>
}

/** aggregated selection of "support_ticket_type" */
export type Support_Ticket_Type_Aggregate = {
  __typename?: 'support_ticket_type_aggregate'
  aggregate?: Maybe<Support_Ticket_Type_Aggregate_Fields>
  nodes: Array<Support_Ticket_Type>
}

/** aggregate fields of "support_ticket_type" */
export type Support_Ticket_Type_Aggregate_Fields = {
  __typename?: 'support_ticket_type_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Support_Ticket_Type_Max_Fields>
  min?: Maybe<Support_Ticket_Type_Min_Fields>
}

/** aggregate fields of "support_ticket_type" */
export type Support_Ticket_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Support_Ticket_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "support_ticket_type". All fields are combined with a logical 'AND'. */
export type Support_Ticket_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Support_Ticket_Type_Bool_Exp>>
  _not?: InputMaybe<Support_Ticket_Type_Bool_Exp>
  _or?: InputMaybe<Array<Support_Ticket_Type_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  support_tickets?: InputMaybe<Support_Ticket_Bool_Exp>
  support_tickets_aggregate?: InputMaybe<Support_Ticket_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "support_ticket_type" */
export enum Support_Ticket_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  SupportTicketTypePkey = 'support_ticket_type_pkey'
}

export enum Support_Ticket_Type_Enum {
  /** ACCESS_CONTROL */
  AccessControl = 'ACCESS_CONTROL',
  /** APPLIANCES */
  Appliances = 'APPLIANCES',
  /** HVAC */
  Hvac = 'HVAC',
  /** INTERNET */
  Internet = 'INTERNET',
  /** OTHER */
  Other = 'OTHER',
  /** WATER */
  Water = 'WATER'
}

/** Boolean expression to compare columns of type "support_ticket_type_enum". All fields are combined with logical 'AND'. */
export type Support_Ticket_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Support_Ticket_Type_Enum>
  _in?: InputMaybe<Array<Support_Ticket_Type_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Support_Ticket_Type_Enum>
  _nin?: InputMaybe<Array<Support_Ticket_Type_Enum>>
}

/** input type for inserting data into table "support_ticket_type" */
export type Support_Ticket_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  support_tickets?: InputMaybe<Support_Ticket_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Support_Ticket_Type_Max_Fields = {
  __typename?: 'support_ticket_type_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Support_Ticket_Type_Min_Fields = {
  __typename?: 'support_ticket_type_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "support_ticket_type" */
export type Support_Ticket_Type_Mutation_Response = {
  __typename?: 'support_ticket_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Support_Ticket_Type>
}

/** input type for inserting object relation for remote table "support_ticket_type" */
export type Support_Ticket_Type_Obj_Rel_Insert_Input = {
  data: Support_Ticket_Type_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Support_Ticket_Type_On_Conflict>
}

/** on_conflict condition type for table "support_ticket_type" */
export type Support_Ticket_Type_On_Conflict = {
  constraint: Support_Ticket_Type_Constraint
  update_columns?: Array<Support_Ticket_Type_Update_Column>
  where?: InputMaybe<Support_Ticket_Type_Bool_Exp>
}

/** Ordering options when selecting data from "support_ticket_type". */
export type Support_Ticket_Type_Order_By = {
  comment?: InputMaybe<Order_By>
  support_tickets_aggregate?: InputMaybe<Support_Ticket_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: support_ticket_type */
export type Support_Ticket_Type_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "support_ticket_type" */
export enum Support_Ticket_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "support_ticket_type" */
export type Support_Ticket_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "support_ticket_type" */
export type Support_Ticket_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Support_Ticket_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Support_Ticket_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "support_ticket_type" */
export enum Support_Ticket_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Support_Ticket_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Support_Ticket_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Support_Ticket_Type_Bool_Exp
}

/** update columns of table "support_ticket" */
export enum Support_Ticket_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Message = 'message',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Support_Ticket_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Support_Ticket_Set_Input>
  /** filter the rows which have to be updated */
  where: Support_Ticket_Bool_Exp
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>
  _gt?: InputMaybe<Scalars['timestamp']['input']>
  _gte?: InputMaybe<Scalars['timestamp']['input']>
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['timestamp']['input']>
  _lte?: InputMaybe<Scalars['timestamp']['input']>
  _neq?: InputMaybe<Scalars['timestamp']['input']>
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>
  _gt?: InputMaybe<Scalars['timestamptz']['input']>
  _gte?: InputMaybe<Scalars['timestamptz']['input']>
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['timestamptz']['input']>
  _lte?: InputMaybe<Scalars['timestamptz']['input']>
  _neq?: InputMaybe<Scalars['timestamptz']['input']>
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>
}

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user'
  birth_date?: Maybe<Scalars['date']['output']>
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  firebase_id: Scalars['String']['output']
  first_name?: Maybe<Scalars['String']['output']>
  id: Scalars['uuid']['output']
  id_number?: Maybe<Scalars['String']['output']>
  id_number_type?: Maybe<User_Id_Type_Enum>
  last_name?: Maybe<Scalars['String']['output']>
  plaid_identity_connected?: Maybe<Scalars['Boolean']['output']>
  plaid_income_connected?: Maybe<Scalars['Boolean']['output']>
  plaid_user_id?: Maybe<Scalars['String']['output']>
  plaid_user_token?: Maybe<Scalars['String']['output']>
  role: User_Role_Enum
  /** An array relationship */
  support_tickets: Array<Support_Ticket>
  /** An aggregate relationship */
  support_tickets_aggregate: Support_Ticket_Aggregate
  updated_at: Scalars['timestamptz']['output']
  /** An array relationship */
  user_apartments: Array<User_Apartment>
  /** An aggregate relationship */
  user_apartments_aggregate: User_Apartment_Aggregate
  /** An array relationship */
  user_applications: Array<User_Application>
  /** An aggregate relationship */
  user_applications_aggregate: User_Application_Aggregate
  /** An object relationship */
  user_id_type?: Maybe<User_Id_Type>
  /** An object relationship */
  user_role: User_Role
}

/** columns and relationships of "user" */
export type UserSupport_TicketsArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Order_By>>
  where?: InputMaybe<Support_Ticket_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserSupport_Tickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Ticket_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Support_Ticket_Order_By>>
  where?: InputMaybe<Support_Ticket_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_ApartmentsArgs = {
  distinct_on?: InputMaybe<Array<User_Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Apartment_Order_By>>
  where?: InputMaybe<User_Apartment_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Apartments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Apartment_Order_By>>
  where?: InputMaybe<User_Apartment_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_ApplicationsArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Order_By>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Applications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Order_By>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate'
  aggregate?: Maybe<User_Aggregate_Fields>
  nodes: Array<User>
}

export type User_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<User_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<User_Aggregate_Bool_Exp_Count>
}

export type User_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<User_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<User_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<User_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<User_Max_Fields>
  min?: Maybe<User_Min_Fields>
}

/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<User_Max_Order_By>
  min?: InputMaybe<User_Min_Order_By>
}

/** columns and relationships of "user_aparment_status" */
export type User_Aparment_Status = {
  __typename?: 'user_aparment_status'
  comment: Scalars['String']['output']
  /** An array relationship */
  user_apartments: Array<User_Apartment>
  /** An aggregate relationship */
  user_apartments_aggregate: User_Apartment_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "user_aparment_status" */
export type User_Aparment_StatusUser_ApartmentsArgs = {
  distinct_on?: InputMaybe<Array<User_Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Apartment_Order_By>>
  where?: InputMaybe<User_Apartment_Bool_Exp>
}

/** columns and relationships of "user_aparment_status" */
export type User_Aparment_StatusUser_Apartments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Apartment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Apartment_Order_By>>
  where?: InputMaybe<User_Apartment_Bool_Exp>
}

/** aggregated selection of "user_aparment_status" */
export type User_Aparment_Status_Aggregate = {
  __typename?: 'user_aparment_status_aggregate'
  aggregate?: Maybe<User_Aparment_Status_Aggregate_Fields>
  nodes: Array<User_Aparment_Status>
}

/** aggregate fields of "user_aparment_status" */
export type User_Aparment_Status_Aggregate_Fields = {
  __typename?: 'user_aparment_status_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<User_Aparment_Status_Max_Fields>
  min?: Maybe<User_Aparment_Status_Min_Fields>
}

/** aggregate fields of "user_aparment_status" */
export type User_Aparment_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Aparment_Status_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "user_aparment_status". All fields are combined with a logical 'AND'. */
export type User_Aparment_Status_Bool_Exp = {
  _and?: InputMaybe<Array<User_Aparment_Status_Bool_Exp>>
  _not?: InputMaybe<User_Aparment_Status_Bool_Exp>
  _or?: InputMaybe<Array<User_Aparment_Status_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  user_apartments?: InputMaybe<User_Apartment_Bool_Exp>
  user_apartments_aggregate?: InputMaybe<User_Apartment_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_aparment_status" */
export enum User_Aparment_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserAparmentStatusPkey = 'user_aparment_status_pkey'
}

export enum User_Aparment_Status_Enum {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** FINISHED */
  Finished = 'FINISHED',
  /** INACTIVE */
  Inactive = 'INACTIVE',
  /** PENDING */
  Pending = 'PENDING'
}

/** Boolean expression to compare columns of type "user_aparment_status_enum". All fields are combined with logical 'AND'. */
export type User_Aparment_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Aparment_Status_Enum>
  _in?: InputMaybe<Array<User_Aparment_Status_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<User_Aparment_Status_Enum>
  _nin?: InputMaybe<Array<User_Aparment_Status_Enum>>
}

/** input type for inserting data into table "user_aparment_status" */
export type User_Aparment_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  user_apartments?: InputMaybe<User_Apartment_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type User_Aparment_Status_Max_Fields = {
  __typename?: 'user_aparment_status_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type User_Aparment_Status_Min_Fields = {
  __typename?: 'user_aparment_status_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "user_aparment_status" */
export type User_Aparment_Status_Mutation_Response = {
  __typename?: 'user_aparment_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<User_Aparment_Status>
}

/** input type for inserting object relation for remote table "user_aparment_status" */
export type User_Aparment_Status_Obj_Rel_Insert_Input = {
  data: User_Aparment_Status_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<User_Aparment_Status_On_Conflict>
}

/** on_conflict condition type for table "user_aparment_status" */
export type User_Aparment_Status_On_Conflict = {
  constraint: User_Aparment_Status_Constraint
  update_columns?: Array<User_Aparment_Status_Update_Column>
  where?: InputMaybe<User_Aparment_Status_Bool_Exp>
}

/** Ordering options when selecting data from "user_aparment_status". */
export type User_Aparment_Status_Order_By = {
  comment?: InputMaybe<Order_By>
  user_apartments_aggregate?: InputMaybe<User_Apartment_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_aparment_status */
export type User_Aparment_Status_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "user_aparment_status" */
export enum User_Aparment_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_aparment_status" */
export type User_Aparment_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "user_aparment_status" */
export type User_Aparment_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Aparment_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Aparment_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "user_aparment_status" */
export enum User_Aparment_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type User_Aparment_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Aparment_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Aparment_Status_Bool_Exp
}

/** columns and relationships of "user_apartment" */
export type User_Apartment = {
  __typename?: 'user_apartment'
  /** An object relationship */
  aparment_status: User_Aparment_Status
  /** An object relationship */
  apartment: Apartment
  apartment_id: Scalars['uuid']['output']
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id: Scalars['uuid']['output']
  initial_date: Scalars['timestamptz']['output']
  next_payment_day: Scalars['timestamptz']['output']
  rental_price: Scalars['Float']['output']
  status: User_Aparment_Status_Enum
  updated_at: Scalars['timestamptz']['output']
  /** An object relationship */
  user: User
  user_id: Scalars['uuid']['output']
}

/** aggregated selection of "user_apartment" */
export type User_Apartment_Aggregate = {
  __typename?: 'user_apartment_aggregate'
  aggregate?: Maybe<User_Apartment_Aggregate_Fields>
  nodes: Array<User_Apartment>
}

export type User_Apartment_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Apartment_Aggregate_Bool_Exp_Count>
}

export type User_Apartment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Apartment_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<User_Apartment_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_apartment" */
export type User_Apartment_Aggregate_Fields = {
  __typename?: 'user_apartment_aggregate_fields'
  avg?: Maybe<User_Apartment_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<User_Apartment_Max_Fields>
  min?: Maybe<User_Apartment_Min_Fields>
  stddev?: Maybe<User_Apartment_Stddev_Fields>
  stddev_pop?: Maybe<User_Apartment_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Apartment_Stddev_Samp_Fields>
  sum?: Maybe<User_Apartment_Sum_Fields>
  var_pop?: Maybe<User_Apartment_Var_Pop_Fields>
  var_samp?: Maybe<User_Apartment_Var_Samp_Fields>
  variance?: Maybe<User_Apartment_Variance_Fields>
}

/** aggregate fields of "user_apartment" */
export type User_Apartment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Apartment_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "user_apartment" */
export type User_Apartment_Aggregate_Order_By = {
  avg?: InputMaybe<User_Apartment_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<User_Apartment_Max_Order_By>
  min?: InputMaybe<User_Apartment_Min_Order_By>
  stddev?: InputMaybe<User_Apartment_Stddev_Order_By>
  stddev_pop?: InputMaybe<User_Apartment_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<User_Apartment_Stddev_Samp_Order_By>
  sum?: InputMaybe<User_Apartment_Sum_Order_By>
  var_pop?: InputMaybe<User_Apartment_Var_Pop_Order_By>
  var_samp?: InputMaybe<User_Apartment_Var_Samp_Order_By>
  variance?: InputMaybe<User_Apartment_Variance_Order_By>
}

/** input type for inserting array relation for remote table "user_apartment" */
export type User_Apartment_Arr_Rel_Insert_Input = {
  data: Array<User_Apartment_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<User_Apartment_On_Conflict>
}

/** aggregate avg on columns */
export type User_Apartment_Avg_Fields = {
  __typename?: 'user_apartment_avg_fields'
  rental_price?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "user_apartment" */
export type User_Apartment_Avg_Order_By = {
  rental_price?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "user_apartment". All fields are combined with a logical 'AND'. */
export type User_Apartment_Bool_Exp = {
  _and?: InputMaybe<Array<User_Apartment_Bool_Exp>>
  _not?: InputMaybe<User_Apartment_Bool_Exp>
  _or?: InputMaybe<Array<User_Apartment_Bool_Exp>>
  aparment_status?: InputMaybe<User_Aparment_Status_Bool_Exp>
  apartment?: InputMaybe<Apartment_Bool_Exp>
  apartment_id?: InputMaybe<Uuid_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  initial_date?: InputMaybe<Timestamptz_Comparison_Exp>
  next_payment_day?: InputMaybe<Timestamptz_Comparison_Exp>
  rental_price?: InputMaybe<Float_Comparison_Exp>
  status?: InputMaybe<User_Aparment_Status_Enum_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
  user_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "user_apartment" */
export enum User_Apartment_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserAparmentPkey = 'user_aparment_pkey'
}

/** input type for incrementing numeric columns in table "user_apartment" */
export type User_Apartment_Inc_Input = {
  rental_price?: InputMaybe<Scalars['Float']['input']>
}

/** input type for inserting data into table "user_apartment" */
export type User_Apartment_Insert_Input = {
  aparment_status?: InputMaybe<User_Aparment_Status_Obj_Rel_Insert_Input>
  apartment?: InputMaybe<Apartment_Obj_Rel_Insert_Input>
  apartment_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  initial_date?: InputMaybe<Scalars['timestamptz']['input']>
  next_payment_day?: InputMaybe<Scalars['timestamptz']['input']>
  rental_price?: InputMaybe<Scalars['Float']['input']>
  status?: InputMaybe<User_Aparment_Status_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user?: InputMaybe<User_Obj_Rel_Insert_Input>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate max on columns */
export type User_Apartment_Max_Fields = {
  __typename?: 'user_apartment_max_fields'
  apartment_id?: Maybe<Scalars['uuid']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  initial_date?: Maybe<Scalars['timestamptz']['output']>
  next_payment_day?: Maybe<Scalars['timestamptz']['output']>
  rental_price?: Maybe<Scalars['Float']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** order by max() on columns of table "user_apartment" */
export type User_Apartment_Max_Order_By = {
  apartment_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  initial_date?: InputMaybe<Order_By>
  next_payment_day?: InputMaybe<Order_By>
  rental_price?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type User_Apartment_Min_Fields = {
  __typename?: 'user_apartment_min_fields'
  apartment_id?: Maybe<Scalars['uuid']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  initial_date?: Maybe<Scalars['timestamptz']['output']>
  next_payment_day?: Maybe<Scalars['timestamptz']['output']>
  rental_price?: Maybe<Scalars['Float']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** order by min() on columns of table "user_apartment" */
export type User_Apartment_Min_Order_By = {
  apartment_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  initial_date?: InputMaybe<Order_By>
  next_payment_day?: InputMaybe<Order_By>
  rental_price?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "user_apartment" */
export type User_Apartment_Mutation_Response = {
  __typename?: 'user_apartment_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<User_Apartment>
}

/** on_conflict condition type for table "user_apartment" */
export type User_Apartment_On_Conflict = {
  constraint: User_Apartment_Constraint
  update_columns?: Array<User_Apartment_Update_Column>
  where?: InputMaybe<User_Apartment_Bool_Exp>
}

/** Ordering options when selecting data from "user_apartment". */
export type User_Apartment_Order_By = {
  aparment_status?: InputMaybe<User_Aparment_Status_Order_By>
  apartment?: InputMaybe<Apartment_Order_By>
  apartment_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  initial_date?: InputMaybe<Order_By>
  next_payment_day?: InputMaybe<Order_By>
  rental_price?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
  user_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_apartment */
export type User_Apartment_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "user_apartment" */
export enum User_Apartment_Select_Column {
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  InitialDate = 'initial_date',
  /** column name */
  NextPaymentDay = 'next_payment_day',
  /** column name */
  RentalPrice = 'rental_price',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_apartment" */
export type User_Apartment_Set_Input = {
  apartment_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  initial_date?: InputMaybe<Scalars['timestamptz']['input']>
  next_payment_day?: InputMaybe<Scalars['timestamptz']['input']>
  rental_price?: InputMaybe<Scalars['Float']['input']>
  status?: InputMaybe<User_Aparment_Status_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate stddev on columns */
export type User_Apartment_Stddev_Fields = {
  __typename?: 'user_apartment_stddev_fields'
  rental_price?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "user_apartment" */
export type User_Apartment_Stddev_Order_By = {
  rental_price?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type User_Apartment_Stddev_Pop_Fields = {
  __typename?: 'user_apartment_stddev_pop_fields'
  rental_price?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "user_apartment" */
export type User_Apartment_Stddev_Pop_Order_By = {
  rental_price?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type User_Apartment_Stddev_Samp_Fields = {
  __typename?: 'user_apartment_stddev_samp_fields'
  rental_price?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "user_apartment" */
export type User_Apartment_Stddev_Samp_Order_By = {
  rental_price?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "user_apartment" */
export type User_Apartment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Apartment_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Apartment_Stream_Cursor_Value_Input = {
  apartment_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  initial_date?: InputMaybe<Scalars['timestamptz']['input']>
  next_payment_day?: InputMaybe<Scalars['timestamptz']['input']>
  rental_price?: InputMaybe<Scalars['Float']['input']>
  status?: InputMaybe<User_Aparment_Status_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate sum on columns */
export type User_Apartment_Sum_Fields = {
  __typename?: 'user_apartment_sum_fields'
  rental_price?: Maybe<Scalars['Float']['output']>
}

/** order by sum() on columns of table "user_apartment" */
export type User_Apartment_Sum_Order_By = {
  rental_price?: InputMaybe<Order_By>
}

/** update columns of table "user_apartment" */
export enum User_Apartment_Update_Column {
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  InitialDate = 'initial_date',
  /** column name */
  NextPaymentDay = 'next_payment_day',
  /** column name */
  RentalPrice = 'rental_price',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Apartment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Apartment_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Apartment_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Apartment_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Apartment_Var_Pop_Fields = {
  __typename?: 'user_apartment_var_pop_fields'
  rental_price?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "user_apartment" */
export type User_Apartment_Var_Pop_Order_By = {
  rental_price?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type User_Apartment_Var_Samp_Fields = {
  __typename?: 'user_apartment_var_samp_fields'
  rental_price?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "user_apartment" */
export type User_Apartment_Var_Samp_Order_By = {
  rental_price?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type User_Apartment_Variance_Fields = {
  __typename?: 'user_apartment_variance_fields'
  rental_price?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "user_apartment" */
export type User_Apartment_Variance_Order_By = {
  rental_price?: InputMaybe<Order_By>
}

/** columns and relationships of "user_application" */
export type User_Application = {
  __typename?: 'user_application'
  accept_terms_and_conditions?: Maybe<Scalars['Boolean']['output']>
  /** An array relationship */
  addresses: Array<User_Application_Address>
  /** An aggregate relationship */
  addresses_aggregate: User_Application_Address_Aggregate
  agree_screened_after_payment?: Maybe<Scalars['Boolean']['output']>
  agree_terms_and_conditions?: Maybe<Scalars['Boolean']['output']>
  agreement_signature?: Maybe<Scalars['String']['output']>
  /** An object relationship */
  apartment?: Maybe<Apartment>
  apartment_id?: Maybe<Scalars['uuid']['output']>
  /** An object relationship */
  apartment_rental_type?: Maybe<Apartment_Rental_Type>
  /** An object relationship */
  application_status: User_Application_Status
  assistant_animal?: Maybe<Scalars['Boolean']['output']>
  birth_date?: Maybe<Scalars['date']['output']>
  /** An object relationship */
  building?: Maybe<Building>
  building_id?: Maybe<Scalars['uuid']['output']>
  car_make?: Maybe<Scalars['String']['output']>
  car_model?: Maybe<Scalars['String']['output']>
  car_registered_in?: Maybe<Scalars['String']['output']>
  car_sharing?: Maybe<Scalars['Boolean']['output']>
  cats?: Maybe<Scalars['Int']['output']>
  check_in_date?: Maybe<Scalars['date']['output']>
  check_out_date?: Maybe<Scalars['date']['output']>
  cic_bg_terms?: Maybe<Scalars['Boolean']['output']>
  cic_copy?: Maybe<Scalars['Boolean']['output']>
  convicted_of_a_felony?: Maybe<Scalars['Boolean']['output']>
  convicted_of_a_felony_explanation?: Maybe<Scalars['String']['output']>
  created_at: Scalars['timestamptz']['output']
  defendant_civil_landlord_or_criminal_case?: Maybe<
    Scalars['Boolean']['output']
  >
  defendant_civil_landlord_or_criminal_case_explanation?: Maybe<
    Scalars['String']['output']
  >
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  evicted_by_court_order?: Maybe<Scalars['Boolean']['output']>
  evicted_by_court_order_explanation?: Maybe<Scalars['String']['output']>
  experian_credit_report_terms?: Maybe<Scalars['Boolean']['output']>
  experian_privacy_policy?: Maybe<Scalars['Boolean']['output']>
  first_name?: Maybe<Scalars['String']['output']>
  help_finding_rental_company?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['uuid']['output']
  id_number?: Maybe<Scalars['String']['output']>
  /** An array relationship */
  income_documents: Array<User_Application_Income_Document>
  /** An aggregate relationship */
  income_documents_aggregate: User_Application_Income_Document_Aggregate
  /** An array relationship */
  incomes: Array<User_Application_Income>
  /** An aggregate relationship */
  incomes_aggregate: User_Application_Income_Aggregate
  information_accurate?: Maybe<Scalars['Boolean']['output']>
  inspect_myself_checklist?: Maybe<Scalars['Boolean']['output']>
  large_dogs?: Maybe<Scalars['Int']['output']>
  last_name?: Maybe<Scalars['String']['output']>
  license_plate_number?: Maybe<Scalars['String']['output']>
  medium_dogs?: Maybe<Scalars['Int']['output']>
  middle_name?: Maybe<Scalars['String']['output']>
  number_of_people?: Maybe<Scalars['Int']['output']>
  other_pets?: Maybe<Scalars['Int']['output']>
  pet_details?: Maybe<Scalars['String']['output']>
  plaid_idv_session_id?: Maybe<Scalars['String']['output']>
  plaid_income_connected?: Maybe<Scalars['Boolean']['output']>
  rental_type?: Maybe<Apartment_Rental_Type_Enum>
  screening_report_copy?: Maybe<Scalars['Boolean']['output']>
  signature?: Maybe<Scalars['String']['output']>
  small_dogs?: Maybe<Scalars['Int']['output']>
  sms_notification?: Maybe<Scalars['Boolean']['output']>
  sms_notification_lease_accepted?: Maybe<Scalars['Boolean']['output']>
  social_security_number?: Maybe<Scalars['String']['output']>
  status: User_Application_Status_Enum
  terms_signature?: Maybe<Scalars['String']['output']>
  type_of_government_id?: Maybe<User_Id_Type_Enum>
  understand_rental_insurance?: Maybe<Scalars['Boolean']['output']>
  updated_at: Scalars['timestamptz']['output']
  /** An object relationship */
  user: User
  user_id: Scalars['uuid']['output']
  /** An object relationship */
  user_id_type?: Maybe<User_Id_Type>
  vehicles?: Maybe<Scalars['Boolean']['output']>
}

/** columns and relationships of "user_application" */
export type User_ApplicationAddressesArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Address_Order_By>>
  where?: InputMaybe<User_Application_Address_Bool_Exp>
}

/** columns and relationships of "user_application" */
export type User_ApplicationAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Address_Order_By>>
  where?: InputMaybe<User_Application_Address_Bool_Exp>
}

/** columns and relationships of "user_application" */
export type User_ApplicationIncome_DocumentsArgs = {
  distinct_on?: InputMaybe<
    Array<User_Application_Income_Document_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Income_Document_Order_By>>
  where?: InputMaybe<User_Application_Income_Document_Bool_Exp>
}

/** columns and relationships of "user_application" */
export type User_ApplicationIncome_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<User_Application_Income_Document_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Income_Document_Order_By>>
  where?: InputMaybe<User_Application_Income_Document_Bool_Exp>
}

/** columns and relationships of "user_application" */
export type User_ApplicationIncomesArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Income_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Income_Order_By>>
  where?: InputMaybe<User_Application_Income_Bool_Exp>
}

/** columns and relationships of "user_application" */
export type User_ApplicationIncomes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Income_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Income_Order_By>>
  where?: InputMaybe<User_Application_Income_Bool_Exp>
}

/** columns and relationships of "user_application_address" */
export type User_Application_Address = {
  __typename?: 'user_application_address'
  apt: Scalars['String']['output']
  city: Scalars['String']['output']
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id: Scalars['uuid']['output']
  monthly_rent: Scalars['Float']['output']
  reason_of_moving: Scalars['String']['output']
  reference_contact_number: Scalars['String']['output']
  state: Scalars['String']['output']
  street_address: Scalars['String']['output']
  time_of_living: Scalars['Float']['output']
  updated_at: Scalars['timestamptz']['output']
  /** An object relationship */
  user_application: User_Application
  user_application_id: Scalars['uuid']['output']
  zip: Scalars['String']['output']
}

/** aggregated selection of "user_application_address" */
export type User_Application_Address_Aggregate = {
  __typename?: 'user_application_address_aggregate'
  aggregate?: Maybe<User_Application_Address_Aggregate_Fields>
  nodes: Array<User_Application_Address>
}

export type User_Application_Address_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Application_Address_Aggregate_Bool_Exp_Count>
}

export type User_Application_Address_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Application_Address_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<User_Application_Address_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_application_address" */
export type User_Application_Address_Aggregate_Fields = {
  __typename?: 'user_application_address_aggregate_fields'
  avg?: Maybe<User_Application_Address_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<User_Application_Address_Max_Fields>
  min?: Maybe<User_Application_Address_Min_Fields>
  stddev?: Maybe<User_Application_Address_Stddev_Fields>
  stddev_pop?: Maybe<User_Application_Address_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Application_Address_Stddev_Samp_Fields>
  sum?: Maybe<User_Application_Address_Sum_Fields>
  var_pop?: Maybe<User_Application_Address_Var_Pop_Fields>
  var_samp?: Maybe<User_Application_Address_Var_Samp_Fields>
  variance?: Maybe<User_Application_Address_Variance_Fields>
}

/** aggregate fields of "user_application_address" */
export type User_Application_Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Application_Address_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "user_application_address" */
export type User_Application_Address_Aggregate_Order_By = {
  avg?: InputMaybe<User_Application_Address_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<User_Application_Address_Max_Order_By>
  min?: InputMaybe<User_Application_Address_Min_Order_By>
  stddev?: InputMaybe<User_Application_Address_Stddev_Order_By>
  stddev_pop?: InputMaybe<User_Application_Address_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<User_Application_Address_Stddev_Samp_Order_By>
  sum?: InputMaybe<User_Application_Address_Sum_Order_By>
  var_pop?: InputMaybe<User_Application_Address_Var_Pop_Order_By>
  var_samp?: InputMaybe<User_Application_Address_Var_Samp_Order_By>
  variance?: InputMaybe<User_Application_Address_Variance_Order_By>
}

/** input type for inserting array relation for remote table "user_application_address" */
export type User_Application_Address_Arr_Rel_Insert_Input = {
  data: Array<User_Application_Address_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<User_Application_Address_On_Conflict>
}

/** aggregate avg on columns */
export type User_Application_Address_Avg_Fields = {
  __typename?: 'user_application_address_avg_fields'
  monthly_rent?: Maybe<Scalars['Float']['output']>
  time_of_living?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "user_application_address" */
export type User_Application_Address_Avg_Order_By = {
  monthly_rent?: InputMaybe<Order_By>
  time_of_living?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "user_application_address". All fields are combined with a logical 'AND'. */
export type User_Application_Address_Bool_Exp = {
  _and?: InputMaybe<Array<User_Application_Address_Bool_Exp>>
  _not?: InputMaybe<User_Application_Address_Bool_Exp>
  _or?: InputMaybe<Array<User_Application_Address_Bool_Exp>>
  apt?: InputMaybe<String_Comparison_Exp>
  city?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  monthly_rent?: InputMaybe<Float_Comparison_Exp>
  reason_of_moving?: InputMaybe<String_Comparison_Exp>
  reference_contact_number?: InputMaybe<String_Comparison_Exp>
  state?: InputMaybe<String_Comparison_Exp>
  street_address?: InputMaybe<String_Comparison_Exp>
  time_of_living?: InputMaybe<Float_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user_application?: InputMaybe<User_Application_Bool_Exp>
  user_application_id?: InputMaybe<Uuid_Comparison_Exp>
  zip?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_application_address" */
export enum User_Application_Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserApplicationAddressPkey = 'user_application_address_pkey'
}

/** input type for incrementing numeric columns in table "user_application_address" */
export type User_Application_Address_Inc_Input = {
  monthly_rent?: InputMaybe<Scalars['Float']['input']>
  time_of_living?: InputMaybe<Scalars['Float']['input']>
}

/** input type for inserting data into table "user_application_address" */
export type User_Application_Address_Insert_Input = {
  apt?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  monthly_rent?: InputMaybe<Scalars['Float']['input']>
  reason_of_moving?: InputMaybe<Scalars['String']['input']>
  reference_contact_number?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street_address?: InputMaybe<Scalars['String']['input']>
  time_of_living?: InputMaybe<Scalars['Float']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_application?: InputMaybe<User_Application_Obj_Rel_Insert_Input>
  user_application_id?: InputMaybe<Scalars['uuid']['input']>
  zip?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type User_Application_Address_Max_Fields = {
  __typename?: 'user_application_address_max_fields'
  apt?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  monthly_rent?: Maybe<Scalars['Float']['output']>
  reason_of_moving?: Maybe<Scalars['String']['output']>
  reference_contact_number?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  street_address?: Maybe<Scalars['String']['output']>
  time_of_living?: Maybe<Scalars['Float']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_application_id?: Maybe<Scalars['uuid']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

/** order by max() on columns of table "user_application_address" */
export type User_Application_Address_Max_Order_By = {
  apt?: InputMaybe<Order_By>
  city?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  monthly_rent?: InputMaybe<Order_By>
  reason_of_moving?: InputMaybe<Order_By>
  reference_contact_number?: InputMaybe<Order_By>
  state?: InputMaybe<Order_By>
  street_address?: InputMaybe<Order_By>
  time_of_living?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_application_id?: InputMaybe<Order_By>
  zip?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type User_Application_Address_Min_Fields = {
  __typename?: 'user_application_address_min_fields'
  apt?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  monthly_rent?: Maybe<Scalars['Float']['output']>
  reason_of_moving?: Maybe<Scalars['String']['output']>
  reference_contact_number?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  street_address?: Maybe<Scalars['String']['output']>
  time_of_living?: Maybe<Scalars['Float']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_application_id?: Maybe<Scalars['uuid']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

/** order by min() on columns of table "user_application_address" */
export type User_Application_Address_Min_Order_By = {
  apt?: InputMaybe<Order_By>
  city?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  monthly_rent?: InputMaybe<Order_By>
  reason_of_moving?: InputMaybe<Order_By>
  reference_contact_number?: InputMaybe<Order_By>
  state?: InputMaybe<Order_By>
  street_address?: InputMaybe<Order_By>
  time_of_living?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_application_id?: InputMaybe<Order_By>
  zip?: InputMaybe<Order_By>
}

/** response of any mutation on the table "user_application_address" */
export type User_Application_Address_Mutation_Response = {
  __typename?: 'user_application_address_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<User_Application_Address>
}

/** on_conflict condition type for table "user_application_address" */
export type User_Application_Address_On_Conflict = {
  constraint: User_Application_Address_Constraint
  update_columns?: Array<User_Application_Address_Update_Column>
  where?: InputMaybe<User_Application_Address_Bool_Exp>
}

/** Ordering options when selecting data from "user_application_address". */
export type User_Application_Address_Order_By = {
  apt?: InputMaybe<Order_By>
  city?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  monthly_rent?: InputMaybe<Order_By>
  reason_of_moving?: InputMaybe<Order_By>
  reference_contact_number?: InputMaybe<Order_By>
  state?: InputMaybe<Order_By>
  street_address?: InputMaybe<Order_By>
  time_of_living?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_application?: InputMaybe<User_Application_Order_By>
  user_application_id?: InputMaybe<Order_By>
  zip?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_application_address */
export type User_Application_Address_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "user_application_address" */
export enum User_Application_Address_Select_Column {
  /** column name */
  Apt = 'apt',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MonthlyRent = 'monthly_rent',
  /** column name */
  ReasonOfMoving = 'reason_of_moving',
  /** column name */
  ReferenceContactNumber = 'reference_contact_number',
  /** column name */
  State = 'state',
  /** column name */
  StreetAddress = 'street_address',
  /** column name */
  TimeOfLiving = 'time_of_living',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserApplicationId = 'user_application_id',
  /** column name */
  Zip = 'zip'
}

/** input type for updating data in table "user_application_address" */
export type User_Application_Address_Set_Input = {
  apt?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  monthly_rent?: InputMaybe<Scalars['Float']['input']>
  reason_of_moving?: InputMaybe<Scalars['String']['input']>
  reference_contact_number?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street_address?: InputMaybe<Scalars['String']['input']>
  time_of_living?: InputMaybe<Scalars['Float']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_application_id?: InputMaybe<Scalars['uuid']['input']>
  zip?: InputMaybe<Scalars['String']['input']>
}

/** aggregate stddev on columns */
export type User_Application_Address_Stddev_Fields = {
  __typename?: 'user_application_address_stddev_fields'
  monthly_rent?: Maybe<Scalars['Float']['output']>
  time_of_living?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "user_application_address" */
export type User_Application_Address_Stddev_Order_By = {
  monthly_rent?: InputMaybe<Order_By>
  time_of_living?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type User_Application_Address_Stddev_Pop_Fields = {
  __typename?: 'user_application_address_stddev_pop_fields'
  monthly_rent?: Maybe<Scalars['Float']['output']>
  time_of_living?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "user_application_address" */
export type User_Application_Address_Stddev_Pop_Order_By = {
  monthly_rent?: InputMaybe<Order_By>
  time_of_living?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type User_Application_Address_Stddev_Samp_Fields = {
  __typename?: 'user_application_address_stddev_samp_fields'
  monthly_rent?: Maybe<Scalars['Float']['output']>
  time_of_living?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "user_application_address" */
export type User_Application_Address_Stddev_Samp_Order_By = {
  monthly_rent?: InputMaybe<Order_By>
  time_of_living?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "user_application_address" */
export type User_Application_Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Application_Address_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Application_Address_Stream_Cursor_Value_Input = {
  apt?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  monthly_rent?: InputMaybe<Scalars['Float']['input']>
  reason_of_moving?: InputMaybe<Scalars['String']['input']>
  reference_contact_number?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street_address?: InputMaybe<Scalars['String']['input']>
  time_of_living?: InputMaybe<Scalars['Float']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_application_id?: InputMaybe<Scalars['uuid']['input']>
  zip?: InputMaybe<Scalars['String']['input']>
}

/** aggregate sum on columns */
export type User_Application_Address_Sum_Fields = {
  __typename?: 'user_application_address_sum_fields'
  monthly_rent?: Maybe<Scalars['Float']['output']>
  time_of_living?: Maybe<Scalars['Float']['output']>
}

/** order by sum() on columns of table "user_application_address" */
export type User_Application_Address_Sum_Order_By = {
  monthly_rent?: InputMaybe<Order_By>
  time_of_living?: InputMaybe<Order_By>
}

/** update columns of table "user_application_address" */
export enum User_Application_Address_Update_Column {
  /** column name */
  Apt = 'apt',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MonthlyRent = 'monthly_rent',
  /** column name */
  ReasonOfMoving = 'reason_of_moving',
  /** column name */
  ReferenceContactNumber = 'reference_contact_number',
  /** column name */
  State = 'state',
  /** column name */
  StreetAddress = 'street_address',
  /** column name */
  TimeOfLiving = 'time_of_living',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserApplicationId = 'user_application_id',
  /** column name */
  Zip = 'zip'
}

export type User_Application_Address_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Application_Address_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Application_Address_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Application_Address_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Application_Address_Var_Pop_Fields = {
  __typename?: 'user_application_address_var_pop_fields'
  monthly_rent?: Maybe<Scalars['Float']['output']>
  time_of_living?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "user_application_address" */
export type User_Application_Address_Var_Pop_Order_By = {
  monthly_rent?: InputMaybe<Order_By>
  time_of_living?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type User_Application_Address_Var_Samp_Fields = {
  __typename?: 'user_application_address_var_samp_fields'
  monthly_rent?: Maybe<Scalars['Float']['output']>
  time_of_living?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "user_application_address" */
export type User_Application_Address_Var_Samp_Order_By = {
  monthly_rent?: InputMaybe<Order_By>
  time_of_living?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type User_Application_Address_Variance_Fields = {
  __typename?: 'user_application_address_variance_fields'
  monthly_rent?: Maybe<Scalars['Float']['output']>
  time_of_living?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "user_application_address" */
export type User_Application_Address_Variance_Order_By = {
  monthly_rent?: InputMaybe<Order_By>
  time_of_living?: InputMaybe<Order_By>
}

/** aggregated selection of "user_application" */
export type User_Application_Aggregate = {
  __typename?: 'user_application_aggregate'
  aggregate?: Maybe<User_Application_Aggregate_Fields>
  nodes: Array<User_Application>
}

export type User_Application_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Application_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<User_Application_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<User_Application_Aggregate_Bool_Exp_Count>
}

export type User_Application_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Application_Select_Column_User_Application_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<User_Application_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Application_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Application_Select_Column_User_Application_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<User_Application_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Application_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Application_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<User_Application_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_application" */
export type User_Application_Aggregate_Fields = {
  __typename?: 'user_application_aggregate_fields'
  avg?: Maybe<User_Application_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<User_Application_Max_Fields>
  min?: Maybe<User_Application_Min_Fields>
  stddev?: Maybe<User_Application_Stddev_Fields>
  stddev_pop?: Maybe<User_Application_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Application_Stddev_Samp_Fields>
  sum?: Maybe<User_Application_Sum_Fields>
  var_pop?: Maybe<User_Application_Var_Pop_Fields>
  var_samp?: Maybe<User_Application_Var_Samp_Fields>
  variance?: Maybe<User_Application_Variance_Fields>
}

/** aggregate fields of "user_application" */
export type User_Application_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Application_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "user_application" */
export type User_Application_Aggregate_Order_By = {
  avg?: InputMaybe<User_Application_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<User_Application_Max_Order_By>
  min?: InputMaybe<User_Application_Min_Order_By>
  stddev?: InputMaybe<User_Application_Stddev_Order_By>
  stddev_pop?: InputMaybe<User_Application_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<User_Application_Stddev_Samp_Order_By>
  sum?: InputMaybe<User_Application_Sum_Order_By>
  var_pop?: InputMaybe<User_Application_Var_Pop_Order_By>
  var_samp?: InputMaybe<User_Application_Var_Samp_Order_By>
  variance?: InputMaybe<User_Application_Variance_Order_By>
}

/** input type for inserting array relation for remote table "user_application" */
export type User_Application_Arr_Rel_Insert_Input = {
  data: Array<User_Application_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<User_Application_On_Conflict>
}

/** aggregate avg on columns */
export type User_Application_Avg_Fields = {
  __typename?: 'user_application_avg_fields'
  cats?: Maybe<Scalars['Float']['output']>
  large_dogs?: Maybe<Scalars['Float']['output']>
  medium_dogs?: Maybe<Scalars['Float']['output']>
  number_of_people?: Maybe<Scalars['Float']['output']>
  other_pets?: Maybe<Scalars['Float']['output']>
  small_dogs?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "user_application" */
export type User_Application_Avg_Order_By = {
  cats?: InputMaybe<Order_By>
  large_dogs?: InputMaybe<Order_By>
  medium_dogs?: InputMaybe<Order_By>
  number_of_people?: InputMaybe<Order_By>
  other_pets?: InputMaybe<Order_By>
  small_dogs?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "user_application". All fields are combined with a logical 'AND'. */
export type User_Application_Bool_Exp = {
  _and?: InputMaybe<Array<User_Application_Bool_Exp>>
  _not?: InputMaybe<User_Application_Bool_Exp>
  _or?: InputMaybe<Array<User_Application_Bool_Exp>>
  accept_terms_and_conditions?: InputMaybe<Boolean_Comparison_Exp>
  addresses?: InputMaybe<User_Application_Address_Bool_Exp>
  addresses_aggregate?: InputMaybe<User_Application_Address_Aggregate_Bool_Exp>
  agree_screened_after_payment?: InputMaybe<Boolean_Comparison_Exp>
  agree_terms_and_conditions?: InputMaybe<Boolean_Comparison_Exp>
  agreement_signature?: InputMaybe<String_Comparison_Exp>
  apartment?: InputMaybe<Apartment_Bool_Exp>
  apartment_id?: InputMaybe<Uuid_Comparison_Exp>
  apartment_rental_type?: InputMaybe<Apartment_Rental_Type_Bool_Exp>
  application_status?: InputMaybe<User_Application_Status_Bool_Exp>
  assistant_animal?: InputMaybe<Boolean_Comparison_Exp>
  birth_date?: InputMaybe<Date_Comparison_Exp>
  building?: InputMaybe<Building_Bool_Exp>
  building_id?: InputMaybe<Uuid_Comparison_Exp>
  car_make?: InputMaybe<String_Comparison_Exp>
  car_model?: InputMaybe<String_Comparison_Exp>
  car_registered_in?: InputMaybe<String_Comparison_Exp>
  car_sharing?: InputMaybe<Boolean_Comparison_Exp>
  cats?: InputMaybe<Int_Comparison_Exp>
  check_in_date?: InputMaybe<Date_Comparison_Exp>
  check_out_date?: InputMaybe<Date_Comparison_Exp>
  cic_bg_terms?: InputMaybe<Boolean_Comparison_Exp>
  cic_copy?: InputMaybe<Boolean_Comparison_Exp>
  convicted_of_a_felony?: InputMaybe<Boolean_Comparison_Exp>
  convicted_of_a_felony_explanation?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  defendant_civil_landlord_or_criminal_case?: InputMaybe<Boolean_Comparison_Exp>
  defendant_civil_landlord_or_criminal_case_explanation?: InputMaybe<String_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  evicted_by_court_order?: InputMaybe<Boolean_Comparison_Exp>
  evicted_by_court_order_explanation?: InputMaybe<String_Comparison_Exp>
  experian_credit_report_terms?: InputMaybe<Boolean_Comparison_Exp>
  experian_privacy_policy?: InputMaybe<Boolean_Comparison_Exp>
  first_name?: InputMaybe<String_Comparison_Exp>
  help_finding_rental_company?: InputMaybe<Boolean_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  id_number?: InputMaybe<String_Comparison_Exp>
  income_documents?: InputMaybe<User_Application_Income_Document_Bool_Exp>
  income_documents_aggregate?: InputMaybe<User_Application_Income_Document_Aggregate_Bool_Exp>
  incomes?: InputMaybe<User_Application_Income_Bool_Exp>
  incomes_aggregate?: InputMaybe<User_Application_Income_Aggregate_Bool_Exp>
  information_accurate?: InputMaybe<Boolean_Comparison_Exp>
  inspect_myself_checklist?: InputMaybe<Boolean_Comparison_Exp>
  large_dogs?: InputMaybe<Int_Comparison_Exp>
  last_name?: InputMaybe<String_Comparison_Exp>
  license_plate_number?: InputMaybe<String_Comparison_Exp>
  medium_dogs?: InputMaybe<Int_Comparison_Exp>
  middle_name?: InputMaybe<String_Comparison_Exp>
  number_of_people?: InputMaybe<Int_Comparison_Exp>
  other_pets?: InputMaybe<Int_Comparison_Exp>
  pet_details?: InputMaybe<String_Comparison_Exp>
  plaid_idv_session_id?: InputMaybe<String_Comparison_Exp>
  plaid_income_connected?: InputMaybe<Boolean_Comparison_Exp>
  rental_type?: InputMaybe<Apartment_Rental_Type_Enum_Comparison_Exp>
  screening_report_copy?: InputMaybe<Boolean_Comparison_Exp>
  signature?: InputMaybe<String_Comparison_Exp>
  small_dogs?: InputMaybe<Int_Comparison_Exp>
  sms_notification?: InputMaybe<Boolean_Comparison_Exp>
  sms_notification_lease_accepted?: InputMaybe<Boolean_Comparison_Exp>
  social_security_number?: InputMaybe<String_Comparison_Exp>
  status?: InputMaybe<User_Application_Status_Enum_Comparison_Exp>
  terms_signature?: InputMaybe<String_Comparison_Exp>
  type_of_government_id?: InputMaybe<User_Id_Type_Enum_Comparison_Exp>
  understand_rental_insurance?: InputMaybe<Boolean_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
  user_id?: InputMaybe<Uuid_Comparison_Exp>
  user_id_type?: InputMaybe<User_Id_Type_Bool_Exp>
  vehicles?: InputMaybe<Boolean_Comparison_Exp>
}

/** unique or primary key constraints on table "user_application" */
export enum User_Application_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserApplicationPkey = 'user_application_pkey'
}

/** input type for incrementing numeric columns in table "user_application" */
export type User_Application_Inc_Input = {
  cats?: InputMaybe<Scalars['Int']['input']>
  large_dogs?: InputMaybe<Scalars['Int']['input']>
  medium_dogs?: InputMaybe<Scalars['Int']['input']>
  number_of_people?: InputMaybe<Scalars['Int']['input']>
  other_pets?: InputMaybe<Scalars['Int']['input']>
  small_dogs?: InputMaybe<Scalars['Int']['input']>
}

/** columns and relationships of "user_application_income" */
export type User_Application_Income = {
  __typename?: 'user_application_income'
  additional_information?: Maybe<Scalars['String']['output']>
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  employer: Scalars['String']['output']
  employer_contact_email?: Maybe<Scalars['String']['output']>
  employer_contact_name: Scalars['String']['output']
  employer_contact_phone_number?: Maybe<Scalars['String']['output']>
  employer_since: Scalars['date']['output']
  id: Scalars['uuid']['output']
  job_title: Scalars['String']['output']
  monthly_income: Scalars['Float']['output']
  status: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
  /** An object relationship */
  user_application: User_Application
  user_application_id: Scalars['uuid']['output']
}

/** aggregated selection of "user_application_income" */
export type User_Application_Income_Aggregate = {
  __typename?: 'user_application_income_aggregate'
  aggregate?: Maybe<User_Application_Income_Aggregate_Fields>
  nodes: Array<User_Application_Income>
}

export type User_Application_Income_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Application_Income_Aggregate_Bool_Exp_Count>
}

export type User_Application_Income_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Application_Income_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<User_Application_Income_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_application_income" */
export type User_Application_Income_Aggregate_Fields = {
  __typename?: 'user_application_income_aggregate_fields'
  avg?: Maybe<User_Application_Income_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<User_Application_Income_Max_Fields>
  min?: Maybe<User_Application_Income_Min_Fields>
  stddev?: Maybe<User_Application_Income_Stddev_Fields>
  stddev_pop?: Maybe<User_Application_Income_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Application_Income_Stddev_Samp_Fields>
  sum?: Maybe<User_Application_Income_Sum_Fields>
  var_pop?: Maybe<User_Application_Income_Var_Pop_Fields>
  var_samp?: Maybe<User_Application_Income_Var_Samp_Fields>
  variance?: Maybe<User_Application_Income_Variance_Fields>
}

/** aggregate fields of "user_application_income" */
export type User_Application_Income_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Application_Income_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "user_application_income" */
export type User_Application_Income_Aggregate_Order_By = {
  avg?: InputMaybe<User_Application_Income_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<User_Application_Income_Max_Order_By>
  min?: InputMaybe<User_Application_Income_Min_Order_By>
  stddev?: InputMaybe<User_Application_Income_Stddev_Order_By>
  stddev_pop?: InputMaybe<User_Application_Income_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<User_Application_Income_Stddev_Samp_Order_By>
  sum?: InputMaybe<User_Application_Income_Sum_Order_By>
  var_pop?: InputMaybe<User_Application_Income_Var_Pop_Order_By>
  var_samp?: InputMaybe<User_Application_Income_Var_Samp_Order_By>
  variance?: InputMaybe<User_Application_Income_Variance_Order_By>
}

/** input type for inserting array relation for remote table "user_application_income" */
export type User_Application_Income_Arr_Rel_Insert_Input = {
  data: Array<User_Application_Income_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<User_Application_Income_On_Conflict>
}

/** aggregate avg on columns */
export type User_Application_Income_Avg_Fields = {
  __typename?: 'user_application_income_avg_fields'
  monthly_income?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "user_application_income" */
export type User_Application_Income_Avg_Order_By = {
  monthly_income?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "user_application_income". All fields are combined with a logical 'AND'. */
export type User_Application_Income_Bool_Exp = {
  _and?: InputMaybe<Array<User_Application_Income_Bool_Exp>>
  _not?: InputMaybe<User_Application_Income_Bool_Exp>
  _or?: InputMaybe<Array<User_Application_Income_Bool_Exp>>
  additional_information?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  employer?: InputMaybe<String_Comparison_Exp>
  employer_contact_email?: InputMaybe<String_Comparison_Exp>
  employer_contact_name?: InputMaybe<String_Comparison_Exp>
  employer_contact_phone_number?: InputMaybe<String_Comparison_Exp>
  employer_since?: InputMaybe<Date_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  job_title?: InputMaybe<String_Comparison_Exp>
  monthly_income?: InputMaybe<Float_Comparison_Exp>
  status?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user_application?: InputMaybe<User_Application_Bool_Exp>
  user_application_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "user_application_income" */
export enum User_Application_Income_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserApplicationIncomePkey = 'user_application_income_pkey'
}

/** columns and relationships of "user_application_income_document" */
export type User_Application_Income_Document = {
  __typename?: 'user_application_income_document'
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id: Scalars['uuid']['output']
  name: Scalars['String']['output']
  size: Scalars['Float']['output']
  type: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
  /** An object relationship */
  user_application: User_Application
  user_application_id: Scalars['uuid']['output']
}

/** aggregated selection of "user_application_income_document" */
export type User_Application_Income_Document_Aggregate = {
  __typename?: 'user_application_income_document_aggregate'
  aggregate?: Maybe<User_Application_Income_Document_Aggregate_Fields>
  nodes: Array<User_Application_Income_Document>
}

export type User_Application_Income_Document_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Application_Income_Document_Aggregate_Bool_Exp_Count>
}

export type User_Application_Income_Document_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Application_Income_Document_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<User_Application_Income_Document_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_application_income_document" */
export type User_Application_Income_Document_Aggregate_Fields = {
  __typename?: 'user_application_income_document_aggregate_fields'
  avg?: Maybe<User_Application_Income_Document_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<User_Application_Income_Document_Max_Fields>
  min?: Maybe<User_Application_Income_Document_Min_Fields>
  stddev?: Maybe<User_Application_Income_Document_Stddev_Fields>
  stddev_pop?: Maybe<User_Application_Income_Document_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Application_Income_Document_Stddev_Samp_Fields>
  sum?: Maybe<User_Application_Income_Document_Sum_Fields>
  var_pop?: Maybe<User_Application_Income_Document_Var_Pop_Fields>
  var_samp?: Maybe<User_Application_Income_Document_Var_Samp_Fields>
  variance?: Maybe<User_Application_Income_Document_Variance_Fields>
}

/** aggregate fields of "user_application_income_document" */
export type User_Application_Income_Document_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Application_Income_Document_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "user_application_income_document" */
export type User_Application_Income_Document_Aggregate_Order_By = {
  avg?: InputMaybe<User_Application_Income_Document_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<User_Application_Income_Document_Max_Order_By>
  min?: InputMaybe<User_Application_Income_Document_Min_Order_By>
  stddev?: InputMaybe<User_Application_Income_Document_Stddev_Order_By>
  stddev_pop?: InputMaybe<User_Application_Income_Document_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<User_Application_Income_Document_Stddev_Samp_Order_By>
  sum?: InputMaybe<User_Application_Income_Document_Sum_Order_By>
  var_pop?: InputMaybe<User_Application_Income_Document_Var_Pop_Order_By>
  var_samp?: InputMaybe<User_Application_Income_Document_Var_Samp_Order_By>
  variance?: InputMaybe<User_Application_Income_Document_Variance_Order_By>
}

/** input type for inserting array relation for remote table "user_application_income_document" */
export type User_Application_Income_Document_Arr_Rel_Insert_Input = {
  data: Array<User_Application_Income_Document_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<User_Application_Income_Document_On_Conflict>
}

/** aggregate avg on columns */
export type User_Application_Income_Document_Avg_Fields = {
  __typename?: 'user_application_income_document_avg_fields'
  size?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "user_application_income_document" */
export type User_Application_Income_Document_Avg_Order_By = {
  size?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "user_application_income_document". All fields are combined with a logical 'AND'. */
export type User_Application_Income_Document_Bool_Exp = {
  _and?: InputMaybe<Array<User_Application_Income_Document_Bool_Exp>>
  _not?: InputMaybe<User_Application_Income_Document_Bool_Exp>
  _or?: InputMaybe<Array<User_Application_Income_Document_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  size?: InputMaybe<Float_Comparison_Exp>
  type?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user_application?: InputMaybe<User_Application_Bool_Exp>
  user_application_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "user_application_income_document" */
export enum User_Application_Income_Document_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserApplicationIncomeDocumentPkey = 'user_application_income_document_pkey'
}

/** input type for incrementing numeric columns in table "user_application_income_document" */
export type User_Application_Income_Document_Inc_Input = {
  size?: InputMaybe<Scalars['Float']['input']>
}

/** input type for inserting data into table "user_application_income_document" */
export type User_Application_Income_Document_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Float']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_application?: InputMaybe<User_Application_Obj_Rel_Insert_Input>
  user_application_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate max on columns */
export type User_Application_Income_Document_Max_Fields = {
  __typename?: 'user_application_income_document_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  size?: Maybe<Scalars['Float']['output']>
  type?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_application_id?: Maybe<Scalars['uuid']['output']>
}

/** order by max() on columns of table "user_application_income_document" */
export type User_Application_Income_Document_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  size?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_application_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type User_Application_Income_Document_Min_Fields = {
  __typename?: 'user_application_income_document_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  size?: Maybe<Scalars['Float']['output']>
  type?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_application_id?: Maybe<Scalars['uuid']['output']>
}

/** order by min() on columns of table "user_application_income_document" */
export type User_Application_Income_Document_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  size?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_application_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "user_application_income_document" */
export type User_Application_Income_Document_Mutation_Response = {
  __typename?: 'user_application_income_document_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<User_Application_Income_Document>
}

/** on_conflict condition type for table "user_application_income_document" */
export type User_Application_Income_Document_On_Conflict = {
  constraint: User_Application_Income_Document_Constraint
  update_columns?: Array<User_Application_Income_Document_Update_Column>
  where?: InputMaybe<User_Application_Income_Document_Bool_Exp>
}

/** Ordering options when selecting data from "user_application_income_document". */
export type User_Application_Income_Document_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  size?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_application?: InputMaybe<User_Application_Order_By>
  user_application_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_application_income_document */
export type User_Application_Income_Document_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "user_application_income_document" */
export enum User_Application_Income_Document_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Size = 'size',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserApplicationId = 'user_application_id'
}

/** input type for updating data in table "user_application_income_document" */
export type User_Application_Income_Document_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Float']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_application_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate stddev on columns */
export type User_Application_Income_Document_Stddev_Fields = {
  __typename?: 'user_application_income_document_stddev_fields'
  size?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "user_application_income_document" */
export type User_Application_Income_Document_Stddev_Order_By = {
  size?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type User_Application_Income_Document_Stddev_Pop_Fields = {
  __typename?: 'user_application_income_document_stddev_pop_fields'
  size?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "user_application_income_document" */
export type User_Application_Income_Document_Stddev_Pop_Order_By = {
  size?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type User_Application_Income_Document_Stddev_Samp_Fields = {
  __typename?: 'user_application_income_document_stddev_samp_fields'
  size?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "user_application_income_document" */
export type User_Application_Income_Document_Stddev_Samp_Order_By = {
  size?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "user_application_income_document" */
export type User_Application_Income_Document_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Application_Income_Document_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Application_Income_Document_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Float']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_application_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate sum on columns */
export type User_Application_Income_Document_Sum_Fields = {
  __typename?: 'user_application_income_document_sum_fields'
  size?: Maybe<Scalars['Float']['output']>
}

/** order by sum() on columns of table "user_application_income_document" */
export type User_Application_Income_Document_Sum_Order_By = {
  size?: InputMaybe<Order_By>
}

/** update columns of table "user_application_income_document" */
export enum User_Application_Income_Document_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Size = 'size',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserApplicationId = 'user_application_id'
}

export type User_Application_Income_Document_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Application_Income_Document_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Application_Income_Document_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Application_Income_Document_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Application_Income_Document_Var_Pop_Fields = {
  __typename?: 'user_application_income_document_var_pop_fields'
  size?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "user_application_income_document" */
export type User_Application_Income_Document_Var_Pop_Order_By = {
  size?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type User_Application_Income_Document_Var_Samp_Fields = {
  __typename?: 'user_application_income_document_var_samp_fields'
  size?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "user_application_income_document" */
export type User_Application_Income_Document_Var_Samp_Order_By = {
  size?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type User_Application_Income_Document_Variance_Fields = {
  __typename?: 'user_application_income_document_variance_fields'
  size?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "user_application_income_document" */
export type User_Application_Income_Document_Variance_Order_By = {
  size?: InputMaybe<Order_By>
}

/** input type for incrementing numeric columns in table "user_application_income" */
export type User_Application_Income_Inc_Input = {
  monthly_income?: InputMaybe<Scalars['Float']['input']>
}

/** input type for inserting data into table "user_application_income" */
export type User_Application_Income_Insert_Input = {
  additional_information?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  employer?: InputMaybe<Scalars['String']['input']>
  employer_contact_email?: InputMaybe<Scalars['String']['input']>
  employer_contact_name?: InputMaybe<Scalars['String']['input']>
  employer_contact_phone_number?: InputMaybe<Scalars['String']['input']>
  employer_since?: InputMaybe<Scalars['date']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  job_title?: InputMaybe<Scalars['String']['input']>
  monthly_income?: InputMaybe<Scalars['Float']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_application?: InputMaybe<User_Application_Obj_Rel_Insert_Input>
  user_application_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate max on columns */
export type User_Application_Income_Max_Fields = {
  __typename?: 'user_application_income_max_fields'
  additional_information?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  employer?: Maybe<Scalars['String']['output']>
  employer_contact_email?: Maybe<Scalars['String']['output']>
  employer_contact_name?: Maybe<Scalars['String']['output']>
  employer_contact_phone_number?: Maybe<Scalars['String']['output']>
  employer_since?: Maybe<Scalars['date']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  job_title?: Maybe<Scalars['String']['output']>
  monthly_income?: Maybe<Scalars['Float']['output']>
  status?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_application_id?: Maybe<Scalars['uuid']['output']>
}

/** order by max() on columns of table "user_application_income" */
export type User_Application_Income_Max_Order_By = {
  additional_information?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  employer?: InputMaybe<Order_By>
  employer_contact_email?: InputMaybe<Order_By>
  employer_contact_name?: InputMaybe<Order_By>
  employer_contact_phone_number?: InputMaybe<Order_By>
  employer_since?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  job_title?: InputMaybe<Order_By>
  monthly_income?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_application_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type User_Application_Income_Min_Fields = {
  __typename?: 'user_application_income_min_fields'
  additional_information?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  employer?: Maybe<Scalars['String']['output']>
  employer_contact_email?: Maybe<Scalars['String']['output']>
  employer_contact_name?: Maybe<Scalars['String']['output']>
  employer_contact_phone_number?: Maybe<Scalars['String']['output']>
  employer_since?: Maybe<Scalars['date']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  job_title?: Maybe<Scalars['String']['output']>
  monthly_income?: Maybe<Scalars['Float']['output']>
  status?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_application_id?: Maybe<Scalars['uuid']['output']>
}

/** order by min() on columns of table "user_application_income" */
export type User_Application_Income_Min_Order_By = {
  additional_information?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  employer?: InputMaybe<Order_By>
  employer_contact_email?: InputMaybe<Order_By>
  employer_contact_name?: InputMaybe<Order_By>
  employer_contact_phone_number?: InputMaybe<Order_By>
  employer_since?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  job_title?: InputMaybe<Order_By>
  monthly_income?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_application_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "user_application_income" */
export type User_Application_Income_Mutation_Response = {
  __typename?: 'user_application_income_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<User_Application_Income>
}

/** on_conflict condition type for table "user_application_income" */
export type User_Application_Income_On_Conflict = {
  constraint: User_Application_Income_Constraint
  update_columns?: Array<User_Application_Income_Update_Column>
  where?: InputMaybe<User_Application_Income_Bool_Exp>
}

/** Ordering options when selecting data from "user_application_income". */
export type User_Application_Income_Order_By = {
  additional_information?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  employer?: InputMaybe<Order_By>
  employer_contact_email?: InputMaybe<Order_By>
  employer_contact_name?: InputMaybe<Order_By>
  employer_contact_phone_number?: InputMaybe<Order_By>
  employer_since?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  job_title?: InputMaybe<Order_By>
  monthly_income?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_application?: InputMaybe<User_Application_Order_By>
  user_application_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_application_income */
export type User_Application_Income_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "user_application_income" */
export enum User_Application_Income_Select_Column {
  /** column name */
  AdditionalInformation = 'additional_information',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Employer = 'employer',
  /** column name */
  EmployerContactEmail = 'employer_contact_email',
  /** column name */
  EmployerContactName = 'employer_contact_name',
  /** column name */
  EmployerContactPhoneNumber = 'employer_contact_phone_number',
  /** column name */
  EmployerSince = 'employer_since',
  /** column name */
  Id = 'id',
  /** column name */
  JobTitle = 'job_title',
  /** column name */
  MonthlyIncome = 'monthly_income',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserApplicationId = 'user_application_id'
}

/** input type for updating data in table "user_application_income" */
export type User_Application_Income_Set_Input = {
  additional_information?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  employer?: InputMaybe<Scalars['String']['input']>
  employer_contact_email?: InputMaybe<Scalars['String']['input']>
  employer_contact_name?: InputMaybe<Scalars['String']['input']>
  employer_contact_phone_number?: InputMaybe<Scalars['String']['input']>
  employer_since?: InputMaybe<Scalars['date']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  job_title?: InputMaybe<Scalars['String']['input']>
  monthly_income?: InputMaybe<Scalars['Float']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_application_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate stddev on columns */
export type User_Application_Income_Stddev_Fields = {
  __typename?: 'user_application_income_stddev_fields'
  monthly_income?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "user_application_income" */
export type User_Application_Income_Stddev_Order_By = {
  monthly_income?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type User_Application_Income_Stddev_Pop_Fields = {
  __typename?: 'user_application_income_stddev_pop_fields'
  monthly_income?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "user_application_income" */
export type User_Application_Income_Stddev_Pop_Order_By = {
  monthly_income?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type User_Application_Income_Stddev_Samp_Fields = {
  __typename?: 'user_application_income_stddev_samp_fields'
  monthly_income?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "user_application_income" */
export type User_Application_Income_Stddev_Samp_Order_By = {
  monthly_income?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "user_application_income" */
export type User_Application_Income_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Application_Income_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Application_Income_Stream_Cursor_Value_Input = {
  additional_information?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  employer?: InputMaybe<Scalars['String']['input']>
  employer_contact_email?: InputMaybe<Scalars['String']['input']>
  employer_contact_name?: InputMaybe<Scalars['String']['input']>
  employer_contact_phone_number?: InputMaybe<Scalars['String']['input']>
  employer_since?: InputMaybe<Scalars['date']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  job_title?: InputMaybe<Scalars['String']['input']>
  monthly_income?: InputMaybe<Scalars['Float']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_application_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate sum on columns */
export type User_Application_Income_Sum_Fields = {
  __typename?: 'user_application_income_sum_fields'
  monthly_income?: Maybe<Scalars['Float']['output']>
}

/** order by sum() on columns of table "user_application_income" */
export type User_Application_Income_Sum_Order_By = {
  monthly_income?: InputMaybe<Order_By>
}

/** update columns of table "user_application_income" */
export enum User_Application_Income_Update_Column {
  /** column name */
  AdditionalInformation = 'additional_information',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Employer = 'employer',
  /** column name */
  EmployerContactEmail = 'employer_contact_email',
  /** column name */
  EmployerContactName = 'employer_contact_name',
  /** column name */
  EmployerContactPhoneNumber = 'employer_contact_phone_number',
  /** column name */
  EmployerSince = 'employer_since',
  /** column name */
  Id = 'id',
  /** column name */
  JobTitle = 'job_title',
  /** column name */
  MonthlyIncome = 'monthly_income',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserApplicationId = 'user_application_id'
}

export type User_Application_Income_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Application_Income_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Application_Income_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Application_Income_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Application_Income_Var_Pop_Fields = {
  __typename?: 'user_application_income_var_pop_fields'
  monthly_income?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "user_application_income" */
export type User_Application_Income_Var_Pop_Order_By = {
  monthly_income?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type User_Application_Income_Var_Samp_Fields = {
  __typename?: 'user_application_income_var_samp_fields'
  monthly_income?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "user_application_income" */
export type User_Application_Income_Var_Samp_Order_By = {
  monthly_income?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type User_Application_Income_Variance_Fields = {
  __typename?: 'user_application_income_variance_fields'
  monthly_income?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "user_application_income" */
export type User_Application_Income_Variance_Order_By = {
  monthly_income?: InputMaybe<Order_By>
}

/** input type for inserting data into table "user_application" */
export type User_Application_Insert_Input = {
  accept_terms_and_conditions?: InputMaybe<Scalars['Boolean']['input']>
  addresses?: InputMaybe<User_Application_Address_Arr_Rel_Insert_Input>
  agree_screened_after_payment?: InputMaybe<Scalars['Boolean']['input']>
  agree_terms_and_conditions?: InputMaybe<Scalars['Boolean']['input']>
  agreement_signature?: InputMaybe<Scalars['String']['input']>
  apartment?: InputMaybe<Apartment_Obj_Rel_Insert_Input>
  apartment_id?: InputMaybe<Scalars['uuid']['input']>
  apartment_rental_type?: InputMaybe<Apartment_Rental_Type_Obj_Rel_Insert_Input>
  application_status?: InputMaybe<User_Application_Status_Obj_Rel_Insert_Input>
  assistant_animal?: InputMaybe<Scalars['Boolean']['input']>
  birth_date?: InputMaybe<Scalars['date']['input']>
  building?: InputMaybe<Building_Obj_Rel_Insert_Input>
  building_id?: InputMaybe<Scalars['uuid']['input']>
  car_make?: InputMaybe<Scalars['String']['input']>
  car_model?: InputMaybe<Scalars['String']['input']>
  car_registered_in?: InputMaybe<Scalars['String']['input']>
  car_sharing?: InputMaybe<Scalars['Boolean']['input']>
  cats?: InputMaybe<Scalars['Int']['input']>
  check_in_date?: InputMaybe<Scalars['date']['input']>
  check_out_date?: InputMaybe<Scalars['date']['input']>
  cic_bg_terms?: InputMaybe<Scalars['Boolean']['input']>
  cic_copy?: InputMaybe<Scalars['Boolean']['input']>
  convicted_of_a_felony?: InputMaybe<Scalars['Boolean']['input']>
  convicted_of_a_felony_explanation?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  defendant_civil_landlord_or_criminal_case?: InputMaybe<
    Scalars['Boolean']['input']
  >
  defendant_civil_landlord_or_criminal_case_explanation?: InputMaybe<
    Scalars['String']['input']
  >
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  evicted_by_court_order?: InputMaybe<Scalars['Boolean']['input']>
  evicted_by_court_order_explanation?: InputMaybe<Scalars['String']['input']>
  experian_credit_report_terms?: InputMaybe<Scalars['Boolean']['input']>
  experian_privacy_policy?: InputMaybe<Scalars['Boolean']['input']>
  first_name?: InputMaybe<Scalars['String']['input']>
  help_finding_rental_company?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  id_number?: InputMaybe<Scalars['String']['input']>
  income_documents?: InputMaybe<User_Application_Income_Document_Arr_Rel_Insert_Input>
  incomes?: InputMaybe<User_Application_Income_Arr_Rel_Insert_Input>
  information_accurate?: InputMaybe<Scalars['Boolean']['input']>
  inspect_myself_checklist?: InputMaybe<Scalars['Boolean']['input']>
  large_dogs?: InputMaybe<Scalars['Int']['input']>
  last_name?: InputMaybe<Scalars['String']['input']>
  license_plate_number?: InputMaybe<Scalars['String']['input']>
  medium_dogs?: InputMaybe<Scalars['Int']['input']>
  middle_name?: InputMaybe<Scalars['String']['input']>
  number_of_people?: InputMaybe<Scalars['Int']['input']>
  other_pets?: InputMaybe<Scalars['Int']['input']>
  pet_details?: InputMaybe<Scalars['String']['input']>
  plaid_idv_session_id?: InputMaybe<Scalars['String']['input']>
  plaid_income_connected?: InputMaybe<Scalars['Boolean']['input']>
  rental_type?: InputMaybe<Apartment_Rental_Type_Enum>
  screening_report_copy?: InputMaybe<Scalars['Boolean']['input']>
  signature?: InputMaybe<Scalars['String']['input']>
  small_dogs?: InputMaybe<Scalars['Int']['input']>
  sms_notification?: InputMaybe<Scalars['Boolean']['input']>
  sms_notification_lease_accepted?: InputMaybe<Scalars['Boolean']['input']>
  social_security_number?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<User_Application_Status_Enum>
  terms_signature?: InputMaybe<Scalars['String']['input']>
  type_of_government_id?: InputMaybe<User_Id_Type_Enum>
  understand_rental_insurance?: InputMaybe<Scalars['Boolean']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user?: InputMaybe<User_Obj_Rel_Insert_Input>
  user_id?: InputMaybe<Scalars['uuid']['input']>
  user_id_type?: InputMaybe<User_Id_Type_Obj_Rel_Insert_Input>
  vehicles?: InputMaybe<Scalars['Boolean']['input']>
}

/** aggregate max on columns */
export type User_Application_Max_Fields = {
  __typename?: 'user_application_max_fields'
  agreement_signature?: Maybe<Scalars['String']['output']>
  apartment_id?: Maybe<Scalars['uuid']['output']>
  birth_date?: Maybe<Scalars['date']['output']>
  building_id?: Maybe<Scalars['uuid']['output']>
  car_make?: Maybe<Scalars['String']['output']>
  car_model?: Maybe<Scalars['String']['output']>
  car_registered_in?: Maybe<Scalars['String']['output']>
  cats?: Maybe<Scalars['Int']['output']>
  check_in_date?: Maybe<Scalars['date']['output']>
  check_out_date?: Maybe<Scalars['date']['output']>
  convicted_of_a_felony_explanation?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  defendant_civil_landlord_or_criminal_case_explanation?: Maybe<
    Scalars['String']['output']
  >
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  evicted_by_court_order_explanation?: Maybe<Scalars['String']['output']>
  first_name?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  id_number?: Maybe<Scalars['String']['output']>
  large_dogs?: Maybe<Scalars['Int']['output']>
  last_name?: Maybe<Scalars['String']['output']>
  license_plate_number?: Maybe<Scalars['String']['output']>
  medium_dogs?: Maybe<Scalars['Int']['output']>
  middle_name?: Maybe<Scalars['String']['output']>
  number_of_people?: Maybe<Scalars['Int']['output']>
  other_pets?: Maybe<Scalars['Int']['output']>
  pet_details?: Maybe<Scalars['String']['output']>
  plaid_idv_session_id?: Maybe<Scalars['String']['output']>
  signature?: Maybe<Scalars['String']['output']>
  small_dogs?: Maybe<Scalars['Int']['output']>
  social_security_number?: Maybe<Scalars['String']['output']>
  terms_signature?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** order by max() on columns of table "user_application" */
export type User_Application_Max_Order_By = {
  agreement_signature?: InputMaybe<Order_By>
  apartment_id?: InputMaybe<Order_By>
  birth_date?: InputMaybe<Order_By>
  building_id?: InputMaybe<Order_By>
  car_make?: InputMaybe<Order_By>
  car_model?: InputMaybe<Order_By>
  car_registered_in?: InputMaybe<Order_By>
  cats?: InputMaybe<Order_By>
  check_in_date?: InputMaybe<Order_By>
  check_out_date?: InputMaybe<Order_By>
  convicted_of_a_felony_explanation?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  defendant_civil_landlord_or_criminal_case_explanation?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  evicted_by_court_order_explanation?: InputMaybe<Order_By>
  first_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  large_dogs?: InputMaybe<Order_By>
  last_name?: InputMaybe<Order_By>
  license_plate_number?: InputMaybe<Order_By>
  medium_dogs?: InputMaybe<Order_By>
  middle_name?: InputMaybe<Order_By>
  number_of_people?: InputMaybe<Order_By>
  other_pets?: InputMaybe<Order_By>
  pet_details?: InputMaybe<Order_By>
  plaid_idv_session_id?: InputMaybe<Order_By>
  signature?: InputMaybe<Order_By>
  small_dogs?: InputMaybe<Order_By>
  social_security_number?: InputMaybe<Order_By>
  terms_signature?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type User_Application_Min_Fields = {
  __typename?: 'user_application_min_fields'
  agreement_signature?: Maybe<Scalars['String']['output']>
  apartment_id?: Maybe<Scalars['uuid']['output']>
  birth_date?: Maybe<Scalars['date']['output']>
  building_id?: Maybe<Scalars['uuid']['output']>
  car_make?: Maybe<Scalars['String']['output']>
  car_model?: Maybe<Scalars['String']['output']>
  car_registered_in?: Maybe<Scalars['String']['output']>
  cats?: Maybe<Scalars['Int']['output']>
  check_in_date?: Maybe<Scalars['date']['output']>
  check_out_date?: Maybe<Scalars['date']['output']>
  convicted_of_a_felony_explanation?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  defendant_civil_landlord_or_criminal_case_explanation?: Maybe<
    Scalars['String']['output']
  >
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  evicted_by_court_order_explanation?: Maybe<Scalars['String']['output']>
  first_name?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  id_number?: Maybe<Scalars['String']['output']>
  large_dogs?: Maybe<Scalars['Int']['output']>
  last_name?: Maybe<Scalars['String']['output']>
  license_plate_number?: Maybe<Scalars['String']['output']>
  medium_dogs?: Maybe<Scalars['Int']['output']>
  middle_name?: Maybe<Scalars['String']['output']>
  number_of_people?: Maybe<Scalars['Int']['output']>
  other_pets?: Maybe<Scalars['Int']['output']>
  pet_details?: Maybe<Scalars['String']['output']>
  plaid_idv_session_id?: Maybe<Scalars['String']['output']>
  signature?: Maybe<Scalars['String']['output']>
  small_dogs?: Maybe<Scalars['Int']['output']>
  social_security_number?: Maybe<Scalars['String']['output']>
  terms_signature?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** order by min() on columns of table "user_application" */
export type User_Application_Min_Order_By = {
  agreement_signature?: InputMaybe<Order_By>
  apartment_id?: InputMaybe<Order_By>
  birth_date?: InputMaybe<Order_By>
  building_id?: InputMaybe<Order_By>
  car_make?: InputMaybe<Order_By>
  car_model?: InputMaybe<Order_By>
  car_registered_in?: InputMaybe<Order_By>
  cats?: InputMaybe<Order_By>
  check_in_date?: InputMaybe<Order_By>
  check_out_date?: InputMaybe<Order_By>
  convicted_of_a_felony_explanation?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  defendant_civil_landlord_or_criminal_case_explanation?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  evicted_by_court_order_explanation?: InputMaybe<Order_By>
  first_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  large_dogs?: InputMaybe<Order_By>
  last_name?: InputMaybe<Order_By>
  license_plate_number?: InputMaybe<Order_By>
  medium_dogs?: InputMaybe<Order_By>
  middle_name?: InputMaybe<Order_By>
  number_of_people?: InputMaybe<Order_By>
  other_pets?: InputMaybe<Order_By>
  pet_details?: InputMaybe<Order_By>
  plaid_idv_session_id?: InputMaybe<Order_By>
  signature?: InputMaybe<Order_By>
  small_dogs?: InputMaybe<Order_By>
  social_security_number?: InputMaybe<Order_By>
  terms_signature?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "user_application" */
export type User_Application_Mutation_Response = {
  __typename?: 'user_application_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<User_Application>
}

/** input type for inserting object relation for remote table "user_application" */
export type User_Application_Obj_Rel_Insert_Input = {
  data: User_Application_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<User_Application_On_Conflict>
}

/** on_conflict condition type for table "user_application" */
export type User_Application_On_Conflict = {
  constraint: User_Application_Constraint
  update_columns?: Array<User_Application_Update_Column>
  where?: InputMaybe<User_Application_Bool_Exp>
}

/** Ordering options when selecting data from "user_application". */
export type User_Application_Order_By = {
  accept_terms_and_conditions?: InputMaybe<Order_By>
  addresses_aggregate?: InputMaybe<User_Application_Address_Aggregate_Order_By>
  agree_screened_after_payment?: InputMaybe<Order_By>
  agree_terms_and_conditions?: InputMaybe<Order_By>
  agreement_signature?: InputMaybe<Order_By>
  apartment?: InputMaybe<Apartment_Order_By>
  apartment_id?: InputMaybe<Order_By>
  apartment_rental_type?: InputMaybe<Apartment_Rental_Type_Order_By>
  application_status?: InputMaybe<User_Application_Status_Order_By>
  assistant_animal?: InputMaybe<Order_By>
  birth_date?: InputMaybe<Order_By>
  building?: InputMaybe<Building_Order_By>
  building_id?: InputMaybe<Order_By>
  car_make?: InputMaybe<Order_By>
  car_model?: InputMaybe<Order_By>
  car_registered_in?: InputMaybe<Order_By>
  car_sharing?: InputMaybe<Order_By>
  cats?: InputMaybe<Order_By>
  check_in_date?: InputMaybe<Order_By>
  check_out_date?: InputMaybe<Order_By>
  cic_bg_terms?: InputMaybe<Order_By>
  cic_copy?: InputMaybe<Order_By>
  convicted_of_a_felony?: InputMaybe<Order_By>
  convicted_of_a_felony_explanation?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  defendant_civil_landlord_or_criminal_case?: InputMaybe<Order_By>
  defendant_civil_landlord_or_criminal_case_explanation?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  evicted_by_court_order?: InputMaybe<Order_By>
  evicted_by_court_order_explanation?: InputMaybe<Order_By>
  experian_credit_report_terms?: InputMaybe<Order_By>
  experian_privacy_policy?: InputMaybe<Order_By>
  first_name?: InputMaybe<Order_By>
  help_finding_rental_company?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  income_documents_aggregate?: InputMaybe<User_Application_Income_Document_Aggregate_Order_By>
  incomes_aggregate?: InputMaybe<User_Application_Income_Aggregate_Order_By>
  information_accurate?: InputMaybe<Order_By>
  inspect_myself_checklist?: InputMaybe<Order_By>
  large_dogs?: InputMaybe<Order_By>
  last_name?: InputMaybe<Order_By>
  license_plate_number?: InputMaybe<Order_By>
  medium_dogs?: InputMaybe<Order_By>
  middle_name?: InputMaybe<Order_By>
  number_of_people?: InputMaybe<Order_By>
  other_pets?: InputMaybe<Order_By>
  pet_details?: InputMaybe<Order_By>
  plaid_idv_session_id?: InputMaybe<Order_By>
  plaid_income_connected?: InputMaybe<Order_By>
  rental_type?: InputMaybe<Order_By>
  screening_report_copy?: InputMaybe<Order_By>
  signature?: InputMaybe<Order_By>
  small_dogs?: InputMaybe<Order_By>
  sms_notification?: InputMaybe<Order_By>
  sms_notification_lease_accepted?: InputMaybe<Order_By>
  social_security_number?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  terms_signature?: InputMaybe<Order_By>
  type_of_government_id?: InputMaybe<Order_By>
  understand_rental_insurance?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
  user_id?: InputMaybe<Order_By>
  user_id_type?: InputMaybe<User_Id_Type_Order_By>
  vehicles?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_application */
export type User_Application_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "user_application" */
export enum User_Application_Select_Column {
  /** column name */
  AcceptTermsAndConditions = 'accept_terms_and_conditions',
  /** column name */
  AgreeScreenedAfterPayment = 'agree_screened_after_payment',
  /** column name */
  AgreeTermsAndConditions = 'agree_terms_and_conditions',
  /** column name */
  AgreementSignature = 'agreement_signature',
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  AssistantAnimal = 'assistant_animal',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  CarMake = 'car_make',
  /** column name */
  CarModel = 'car_model',
  /** column name */
  CarRegisteredIn = 'car_registered_in',
  /** column name */
  CarSharing = 'car_sharing',
  /** column name */
  Cats = 'cats',
  /** column name */
  CheckInDate = 'check_in_date',
  /** column name */
  CheckOutDate = 'check_out_date',
  /** column name */
  CicBgTerms = 'cic_bg_terms',
  /** column name */
  CicCopy = 'cic_copy',
  /** column name */
  ConvictedOfAFelony = 'convicted_of_a_felony',
  /** column name */
  ConvictedOfAFelonyExplanation = 'convicted_of_a_felony_explanation',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefendantCivilLandlordOrCriminalCase = 'defendant_civil_landlord_or_criminal_case',
  /** column name */
  DefendantCivilLandlordOrCriminalCaseExplanation = 'defendant_civil_landlord_or_criminal_case_explanation',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EvictedByCourtOrder = 'evicted_by_court_order',
  /** column name */
  EvictedByCourtOrderExplanation = 'evicted_by_court_order_explanation',
  /** column name */
  ExperianCreditReportTerms = 'experian_credit_report_terms',
  /** column name */
  ExperianPrivacyPolicy = 'experian_privacy_policy',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HelpFindingRentalCompany = 'help_finding_rental_company',
  /** column name */
  Id = 'id',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  InformationAccurate = 'information_accurate',
  /** column name */
  InspectMyselfChecklist = 'inspect_myself_checklist',
  /** column name */
  LargeDogs = 'large_dogs',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LicensePlateNumber = 'license_plate_number',
  /** column name */
  MediumDogs = 'medium_dogs',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  NumberOfPeople = 'number_of_people',
  /** column name */
  OtherPets = 'other_pets',
  /** column name */
  PetDetails = 'pet_details',
  /** column name */
  PlaidIdvSessionId = 'plaid_idv_session_id',
  /** column name */
  PlaidIncomeConnected = 'plaid_income_connected',
  /** column name */
  RentalType = 'rental_type',
  /** column name */
  ScreeningReportCopy = 'screening_report_copy',
  /** column name */
  Signature = 'signature',
  /** column name */
  SmallDogs = 'small_dogs',
  /** column name */
  SmsNotification = 'sms_notification',
  /** column name */
  SmsNotificationLeaseAccepted = 'sms_notification_lease_accepted',
  /** column name */
  SocialSecurityNumber = 'social_security_number',
  /** column name */
  Status = 'status',
  /** column name */
  TermsSignature = 'terms_signature',
  /** column name */
  TypeOfGovernmentId = 'type_of_government_id',
  /** column name */
  UnderstandRentalInsurance = 'understand_rental_insurance',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Vehicles = 'vehicles'
}

/** select "user_application_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_application" */
export enum User_Application_Select_Column_User_Application_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AcceptTermsAndConditions = 'accept_terms_and_conditions',
  /** column name */
  AgreeScreenedAfterPayment = 'agree_screened_after_payment',
  /** column name */
  AgreeTermsAndConditions = 'agree_terms_and_conditions',
  /** column name */
  AssistantAnimal = 'assistant_animal',
  /** column name */
  CarSharing = 'car_sharing',
  /** column name */
  CicBgTerms = 'cic_bg_terms',
  /** column name */
  CicCopy = 'cic_copy',
  /** column name */
  ConvictedOfAFelony = 'convicted_of_a_felony',
  /** column name */
  DefendantCivilLandlordOrCriminalCase = 'defendant_civil_landlord_or_criminal_case',
  /** column name */
  EvictedByCourtOrder = 'evicted_by_court_order',
  /** column name */
  ExperianCreditReportTerms = 'experian_credit_report_terms',
  /** column name */
  ExperianPrivacyPolicy = 'experian_privacy_policy',
  /** column name */
  HelpFindingRentalCompany = 'help_finding_rental_company',
  /** column name */
  InformationAccurate = 'information_accurate',
  /** column name */
  InspectMyselfChecklist = 'inspect_myself_checklist',
  /** column name */
  PlaidIncomeConnected = 'plaid_income_connected',
  /** column name */
  ScreeningReportCopy = 'screening_report_copy',
  /** column name */
  SmsNotification = 'sms_notification',
  /** column name */
  SmsNotificationLeaseAccepted = 'sms_notification_lease_accepted',
  /** column name */
  UnderstandRentalInsurance = 'understand_rental_insurance',
  /** column name */
  Vehicles = 'vehicles'
}

/** select "user_application_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_application" */
export enum User_Application_Select_Column_User_Application_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AcceptTermsAndConditions = 'accept_terms_and_conditions',
  /** column name */
  AgreeScreenedAfterPayment = 'agree_screened_after_payment',
  /** column name */
  AgreeTermsAndConditions = 'agree_terms_and_conditions',
  /** column name */
  AssistantAnimal = 'assistant_animal',
  /** column name */
  CarSharing = 'car_sharing',
  /** column name */
  CicBgTerms = 'cic_bg_terms',
  /** column name */
  CicCopy = 'cic_copy',
  /** column name */
  ConvictedOfAFelony = 'convicted_of_a_felony',
  /** column name */
  DefendantCivilLandlordOrCriminalCase = 'defendant_civil_landlord_or_criminal_case',
  /** column name */
  EvictedByCourtOrder = 'evicted_by_court_order',
  /** column name */
  ExperianCreditReportTerms = 'experian_credit_report_terms',
  /** column name */
  ExperianPrivacyPolicy = 'experian_privacy_policy',
  /** column name */
  HelpFindingRentalCompany = 'help_finding_rental_company',
  /** column name */
  InformationAccurate = 'information_accurate',
  /** column name */
  InspectMyselfChecklist = 'inspect_myself_checklist',
  /** column name */
  PlaidIncomeConnected = 'plaid_income_connected',
  /** column name */
  ScreeningReportCopy = 'screening_report_copy',
  /** column name */
  SmsNotification = 'sms_notification',
  /** column name */
  SmsNotificationLeaseAccepted = 'sms_notification_lease_accepted',
  /** column name */
  UnderstandRentalInsurance = 'understand_rental_insurance',
  /** column name */
  Vehicles = 'vehicles'
}

/** input type for updating data in table "user_application" */
export type User_Application_Set_Input = {
  accept_terms_and_conditions?: InputMaybe<Scalars['Boolean']['input']>
  agree_screened_after_payment?: InputMaybe<Scalars['Boolean']['input']>
  agree_terms_and_conditions?: InputMaybe<Scalars['Boolean']['input']>
  agreement_signature?: InputMaybe<Scalars['String']['input']>
  apartment_id?: InputMaybe<Scalars['uuid']['input']>
  assistant_animal?: InputMaybe<Scalars['Boolean']['input']>
  birth_date?: InputMaybe<Scalars['date']['input']>
  building_id?: InputMaybe<Scalars['uuid']['input']>
  car_make?: InputMaybe<Scalars['String']['input']>
  car_model?: InputMaybe<Scalars['String']['input']>
  car_registered_in?: InputMaybe<Scalars['String']['input']>
  car_sharing?: InputMaybe<Scalars['Boolean']['input']>
  cats?: InputMaybe<Scalars['Int']['input']>
  check_in_date?: InputMaybe<Scalars['date']['input']>
  check_out_date?: InputMaybe<Scalars['date']['input']>
  cic_bg_terms?: InputMaybe<Scalars['Boolean']['input']>
  cic_copy?: InputMaybe<Scalars['Boolean']['input']>
  convicted_of_a_felony?: InputMaybe<Scalars['Boolean']['input']>
  convicted_of_a_felony_explanation?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  defendant_civil_landlord_or_criminal_case?: InputMaybe<
    Scalars['Boolean']['input']
  >
  defendant_civil_landlord_or_criminal_case_explanation?: InputMaybe<
    Scalars['String']['input']
  >
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  evicted_by_court_order?: InputMaybe<Scalars['Boolean']['input']>
  evicted_by_court_order_explanation?: InputMaybe<Scalars['String']['input']>
  experian_credit_report_terms?: InputMaybe<Scalars['Boolean']['input']>
  experian_privacy_policy?: InputMaybe<Scalars['Boolean']['input']>
  first_name?: InputMaybe<Scalars['String']['input']>
  help_finding_rental_company?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  id_number?: InputMaybe<Scalars['String']['input']>
  information_accurate?: InputMaybe<Scalars['Boolean']['input']>
  inspect_myself_checklist?: InputMaybe<Scalars['Boolean']['input']>
  large_dogs?: InputMaybe<Scalars['Int']['input']>
  last_name?: InputMaybe<Scalars['String']['input']>
  license_plate_number?: InputMaybe<Scalars['String']['input']>
  medium_dogs?: InputMaybe<Scalars['Int']['input']>
  middle_name?: InputMaybe<Scalars['String']['input']>
  number_of_people?: InputMaybe<Scalars['Int']['input']>
  other_pets?: InputMaybe<Scalars['Int']['input']>
  pet_details?: InputMaybe<Scalars['String']['input']>
  plaid_idv_session_id?: InputMaybe<Scalars['String']['input']>
  plaid_income_connected?: InputMaybe<Scalars['Boolean']['input']>
  rental_type?: InputMaybe<Apartment_Rental_Type_Enum>
  screening_report_copy?: InputMaybe<Scalars['Boolean']['input']>
  signature?: InputMaybe<Scalars['String']['input']>
  small_dogs?: InputMaybe<Scalars['Int']['input']>
  sms_notification?: InputMaybe<Scalars['Boolean']['input']>
  sms_notification_lease_accepted?: InputMaybe<Scalars['Boolean']['input']>
  social_security_number?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<User_Application_Status_Enum>
  terms_signature?: InputMaybe<Scalars['String']['input']>
  type_of_government_id?: InputMaybe<User_Id_Type_Enum>
  understand_rental_insurance?: InputMaybe<Scalars['Boolean']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
  vehicles?: InputMaybe<Scalars['Boolean']['input']>
}

/** columns and relationships of "user_application_status" */
export type User_Application_Status = {
  __typename?: 'user_application_status'
  comment: Scalars['String']['output']
  /** An array relationship */
  user_applications: Array<User_Application>
  /** An aggregate relationship */
  user_applications_aggregate: User_Application_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "user_application_status" */
export type User_Application_StatusUser_ApplicationsArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Order_By>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

/** columns and relationships of "user_application_status" */
export type User_Application_StatusUser_Applications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Order_By>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

/** aggregated selection of "user_application_status" */
export type User_Application_Status_Aggregate = {
  __typename?: 'user_application_status_aggregate'
  aggregate?: Maybe<User_Application_Status_Aggregate_Fields>
  nodes: Array<User_Application_Status>
}

/** aggregate fields of "user_application_status" */
export type User_Application_Status_Aggregate_Fields = {
  __typename?: 'user_application_status_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<User_Application_Status_Max_Fields>
  min?: Maybe<User_Application_Status_Min_Fields>
}

/** aggregate fields of "user_application_status" */
export type User_Application_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Application_Status_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "user_application_status". All fields are combined with a logical 'AND'. */
export type User_Application_Status_Bool_Exp = {
  _and?: InputMaybe<Array<User_Application_Status_Bool_Exp>>
  _not?: InputMaybe<User_Application_Status_Bool_Exp>
  _or?: InputMaybe<Array<User_Application_Status_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  user_applications?: InputMaybe<User_Application_Bool_Exp>
  user_applications_aggregate?: InputMaybe<User_Application_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_application_status" */
export enum User_Application_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserApplicationStatusPkey = 'user_application_status_pkey'
}

export enum User_Application_Status_Enum {
  /** ACCEPTED */
  Accepted = 'ACCEPTED',
  /** DENIED */
  Denied = 'DENIED',
  /** INCOMPLETED */
  Incompleted = 'INCOMPLETED',
  /** PENDING */
  Pending = 'PENDING'
}

/** Boolean expression to compare columns of type "user_application_status_enum". All fields are combined with logical 'AND'. */
export type User_Application_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Application_Status_Enum>
  _in?: InputMaybe<Array<User_Application_Status_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<User_Application_Status_Enum>
  _nin?: InputMaybe<Array<User_Application_Status_Enum>>
}

/** input type for inserting data into table "user_application_status" */
export type User_Application_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  user_applications?: InputMaybe<User_Application_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type User_Application_Status_Max_Fields = {
  __typename?: 'user_application_status_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type User_Application_Status_Min_Fields = {
  __typename?: 'user_application_status_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "user_application_status" */
export type User_Application_Status_Mutation_Response = {
  __typename?: 'user_application_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<User_Application_Status>
}

/** input type for inserting object relation for remote table "user_application_status" */
export type User_Application_Status_Obj_Rel_Insert_Input = {
  data: User_Application_Status_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<User_Application_Status_On_Conflict>
}

/** on_conflict condition type for table "user_application_status" */
export type User_Application_Status_On_Conflict = {
  constraint: User_Application_Status_Constraint
  update_columns?: Array<User_Application_Status_Update_Column>
  where?: InputMaybe<User_Application_Status_Bool_Exp>
}

/** Ordering options when selecting data from "user_application_status". */
export type User_Application_Status_Order_By = {
  comment?: InputMaybe<Order_By>
  user_applications_aggregate?: InputMaybe<User_Application_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_application_status */
export type User_Application_Status_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "user_application_status" */
export enum User_Application_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_application_status" */
export type User_Application_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "user_application_status" */
export type User_Application_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Application_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Application_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "user_application_status" */
export enum User_Application_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type User_Application_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Application_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Application_Status_Bool_Exp
}

/** aggregate stddev on columns */
export type User_Application_Stddev_Fields = {
  __typename?: 'user_application_stddev_fields'
  cats?: Maybe<Scalars['Float']['output']>
  large_dogs?: Maybe<Scalars['Float']['output']>
  medium_dogs?: Maybe<Scalars['Float']['output']>
  number_of_people?: Maybe<Scalars['Float']['output']>
  other_pets?: Maybe<Scalars['Float']['output']>
  small_dogs?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "user_application" */
export type User_Application_Stddev_Order_By = {
  cats?: InputMaybe<Order_By>
  large_dogs?: InputMaybe<Order_By>
  medium_dogs?: InputMaybe<Order_By>
  number_of_people?: InputMaybe<Order_By>
  other_pets?: InputMaybe<Order_By>
  small_dogs?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type User_Application_Stddev_Pop_Fields = {
  __typename?: 'user_application_stddev_pop_fields'
  cats?: Maybe<Scalars['Float']['output']>
  large_dogs?: Maybe<Scalars['Float']['output']>
  medium_dogs?: Maybe<Scalars['Float']['output']>
  number_of_people?: Maybe<Scalars['Float']['output']>
  other_pets?: Maybe<Scalars['Float']['output']>
  small_dogs?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "user_application" */
export type User_Application_Stddev_Pop_Order_By = {
  cats?: InputMaybe<Order_By>
  large_dogs?: InputMaybe<Order_By>
  medium_dogs?: InputMaybe<Order_By>
  number_of_people?: InputMaybe<Order_By>
  other_pets?: InputMaybe<Order_By>
  small_dogs?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type User_Application_Stddev_Samp_Fields = {
  __typename?: 'user_application_stddev_samp_fields'
  cats?: Maybe<Scalars['Float']['output']>
  large_dogs?: Maybe<Scalars['Float']['output']>
  medium_dogs?: Maybe<Scalars['Float']['output']>
  number_of_people?: Maybe<Scalars['Float']['output']>
  other_pets?: Maybe<Scalars['Float']['output']>
  small_dogs?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "user_application" */
export type User_Application_Stddev_Samp_Order_By = {
  cats?: InputMaybe<Order_By>
  large_dogs?: InputMaybe<Order_By>
  medium_dogs?: InputMaybe<Order_By>
  number_of_people?: InputMaybe<Order_By>
  other_pets?: InputMaybe<Order_By>
  small_dogs?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "user_application" */
export type User_Application_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Application_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Application_Stream_Cursor_Value_Input = {
  accept_terms_and_conditions?: InputMaybe<Scalars['Boolean']['input']>
  agree_screened_after_payment?: InputMaybe<Scalars['Boolean']['input']>
  agree_terms_and_conditions?: InputMaybe<Scalars['Boolean']['input']>
  agreement_signature?: InputMaybe<Scalars['String']['input']>
  apartment_id?: InputMaybe<Scalars['uuid']['input']>
  assistant_animal?: InputMaybe<Scalars['Boolean']['input']>
  birth_date?: InputMaybe<Scalars['date']['input']>
  building_id?: InputMaybe<Scalars['uuid']['input']>
  car_make?: InputMaybe<Scalars['String']['input']>
  car_model?: InputMaybe<Scalars['String']['input']>
  car_registered_in?: InputMaybe<Scalars['String']['input']>
  car_sharing?: InputMaybe<Scalars['Boolean']['input']>
  cats?: InputMaybe<Scalars['Int']['input']>
  check_in_date?: InputMaybe<Scalars['date']['input']>
  check_out_date?: InputMaybe<Scalars['date']['input']>
  cic_bg_terms?: InputMaybe<Scalars['Boolean']['input']>
  cic_copy?: InputMaybe<Scalars['Boolean']['input']>
  convicted_of_a_felony?: InputMaybe<Scalars['Boolean']['input']>
  convicted_of_a_felony_explanation?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  defendant_civil_landlord_or_criminal_case?: InputMaybe<
    Scalars['Boolean']['input']
  >
  defendant_civil_landlord_or_criminal_case_explanation?: InputMaybe<
    Scalars['String']['input']
  >
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  evicted_by_court_order?: InputMaybe<Scalars['Boolean']['input']>
  evicted_by_court_order_explanation?: InputMaybe<Scalars['String']['input']>
  experian_credit_report_terms?: InputMaybe<Scalars['Boolean']['input']>
  experian_privacy_policy?: InputMaybe<Scalars['Boolean']['input']>
  first_name?: InputMaybe<Scalars['String']['input']>
  help_finding_rental_company?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  id_number?: InputMaybe<Scalars['String']['input']>
  information_accurate?: InputMaybe<Scalars['Boolean']['input']>
  inspect_myself_checklist?: InputMaybe<Scalars['Boolean']['input']>
  large_dogs?: InputMaybe<Scalars['Int']['input']>
  last_name?: InputMaybe<Scalars['String']['input']>
  license_plate_number?: InputMaybe<Scalars['String']['input']>
  medium_dogs?: InputMaybe<Scalars['Int']['input']>
  middle_name?: InputMaybe<Scalars['String']['input']>
  number_of_people?: InputMaybe<Scalars['Int']['input']>
  other_pets?: InputMaybe<Scalars['Int']['input']>
  pet_details?: InputMaybe<Scalars['String']['input']>
  plaid_idv_session_id?: InputMaybe<Scalars['String']['input']>
  plaid_income_connected?: InputMaybe<Scalars['Boolean']['input']>
  rental_type?: InputMaybe<Apartment_Rental_Type_Enum>
  screening_report_copy?: InputMaybe<Scalars['Boolean']['input']>
  signature?: InputMaybe<Scalars['String']['input']>
  small_dogs?: InputMaybe<Scalars['Int']['input']>
  sms_notification?: InputMaybe<Scalars['Boolean']['input']>
  sms_notification_lease_accepted?: InputMaybe<Scalars['Boolean']['input']>
  social_security_number?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<User_Application_Status_Enum>
  terms_signature?: InputMaybe<Scalars['String']['input']>
  type_of_government_id?: InputMaybe<User_Id_Type_Enum>
  understand_rental_insurance?: InputMaybe<Scalars['Boolean']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
  vehicles?: InputMaybe<Scalars['Boolean']['input']>
}

/** aggregate sum on columns */
export type User_Application_Sum_Fields = {
  __typename?: 'user_application_sum_fields'
  cats?: Maybe<Scalars['Int']['output']>
  large_dogs?: Maybe<Scalars['Int']['output']>
  medium_dogs?: Maybe<Scalars['Int']['output']>
  number_of_people?: Maybe<Scalars['Int']['output']>
  other_pets?: Maybe<Scalars['Int']['output']>
  small_dogs?: Maybe<Scalars['Int']['output']>
}

/** order by sum() on columns of table "user_application" */
export type User_Application_Sum_Order_By = {
  cats?: InputMaybe<Order_By>
  large_dogs?: InputMaybe<Order_By>
  medium_dogs?: InputMaybe<Order_By>
  number_of_people?: InputMaybe<Order_By>
  other_pets?: InputMaybe<Order_By>
  small_dogs?: InputMaybe<Order_By>
}

/** update columns of table "user_application" */
export enum User_Application_Update_Column {
  /** column name */
  AcceptTermsAndConditions = 'accept_terms_and_conditions',
  /** column name */
  AgreeScreenedAfterPayment = 'agree_screened_after_payment',
  /** column name */
  AgreeTermsAndConditions = 'agree_terms_and_conditions',
  /** column name */
  AgreementSignature = 'agreement_signature',
  /** column name */
  ApartmentId = 'apartment_id',
  /** column name */
  AssistantAnimal = 'assistant_animal',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  BuildingId = 'building_id',
  /** column name */
  CarMake = 'car_make',
  /** column name */
  CarModel = 'car_model',
  /** column name */
  CarRegisteredIn = 'car_registered_in',
  /** column name */
  CarSharing = 'car_sharing',
  /** column name */
  Cats = 'cats',
  /** column name */
  CheckInDate = 'check_in_date',
  /** column name */
  CheckOutDate = 'check_out_date',
  /** column name */
  CicBgTerms = 'cic_bg_terms',
  /** column name */
  CicCopy = 'cic_copy',
  /** column name */
  ConvictedOfAFelony = 'convicted_of_a_felony',
  /** column name */
  ConvictedOfAFelonyExplanation = 'convicted_of_a_felony_explanation',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefendantCivilLandlordOrCriminalCase = 'defendant_civil_landlord_or_criminal_case',
  /** column name */
  DefendantCivilLandlordOrCriminalCaseExplanation = 'defendant_civil_landlord_or_criminal_case_explanation',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EvictedByCourtOrder = 'evicted_by_court_order',
  /** column name */
  EvictedByCourtOrderExplanation = 'evicted_by_court_order_explanation',
  /** column name */
  ExperianCreditReportTerms = 'experian_credit_report_terms',
  /** column name */
  ExperianPrivacyPolicy = 'experian_privacy_policy',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HelpFindingRentalCompany = 'help_finding_rental_company',
  /** column name */
  Id = 'id',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  InformationAccurate = 'information_accurate',
  /** column name */
  InspectMyselfChecklist = 'inspect_myself_checklist',
  /** column name */
  LargeDogs = 'large_dogs',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LicensePlateNumber = 'license_plate_number',
  /** column name */
  MediumDogs = 'medium_dogs',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  NumberOfPeople = 'number_of_people',
  /** column name */
  OtherPets = 'other_pets',
  /** column name */
  PetDetails = 'pet_details',
  /** column name */
  PlaidIdvSessionId = 'plaid_idv_session_id',
  /** column name */
  PlaidIncomeConnected = 'plaid_income_connected',
  /** column name */
  RentalType = 'rental_type',
  /** column name */
  ScreeningReportCopy = 'screening_report_copy',
  /** column name */
  Signature = 'signature',
  /** column name */
  SmallDogs = 'small_dogs',
  /** column name */
  SmsNotification = 'sms_notification',
  /** column name */
  SmsNotificationLeaseAccepted = 'sms_notification_lease_accepted',
  /** column name */
  SocialSecurityNumber = 'social_security_number',
  /** column name */
  Status = 'status',
  /** column name */
  TermsSignature = 'terms_signature',
  /** column name */
  TypeOfGovernmentId = 'type_of_government_id',
  /** column name */
  UnderstandRentalInsurance = 'understand_rental_insurance',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Vehicles = 'vehicles'
}

export type User_Application_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Application_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Application_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Application_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Application_Var_Pop_Fields = {
  __typename?: 'user_application_var_pop_fields'
  cats?: Maybe<Scalars['Float']['output']>
  large_dogs?: Maybe<Scalars['Float']['output']>
  medium_dogs?: Maybe<Scalars['Float']['output']>
  number_of_people?: Maybe<Scalars['Float']['output']>
  other_pets?: Maybe<Scalars['Float']['output']>
  small_dogs?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "user_application" */
export type User_Application_Var_Pop_Order_By = {
  cats?: InputMaybe<Order_By>
  large_dogs?: InputMaybe<Order_By>
  medium_dogs?: InputMaybe<Order_By>
  number_of_people?: InputMaybe<Order_By>
  other_pets?: InputMaybe<Order_By>
  small_dogs?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type User_Application_Var_Samp_Fields = {
  __typename?: 'user_application_var_samp_fields'
  cats?: Maybe<Scalars['Float']['output']>
  large_dogs?: Maybe<Scalars['Float']['output']>
  medium_dogs?: Maybe<Scalars['Float']['output']>
  number_of_people?: Maybe<Scalars['Float']['output']>
  other_pets?: Maybe<Scalars['Float']['output']>
  small_dogs?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "user_application" */
export type User_Application_Var_Samp_Order_By = {
  cats?: InputMaybe<Order_By>
  large_dogs?: InputMaybe<Order_By>
  medium_dogs?: InputMaybe<Order_By>
  number_of_people?: InputMaybe<Order_By>
  other_pets?: InputMaybe<Order_By>
  small_dogs?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type User_Application_Variance_Fields = {
  __typename?: 'user_application_variance_fields'
  cats?: Maybe<Scalars['Float']['output']>
  large_dogs?: Maybe<Scalars['Float']['output']>
  medium_dogs?: Maybe<Scalars['Float']['output']>
  number_of_people?: Maybe<Scalars['Float']['output']>
  other_pets?: Maybe<Scalars['Float']['output']>
  small_dogs?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "user_application" */
export type User_Application_Variance_Order_By = {
  cats?: InputMaybe<Order_By>
  large_dogs?: InputMaybe<Order_By>
  medium_dogs?: InputMaybe<Order_By>
  number_of_people?: InputMaybe<Order_By>
  other_pets?: InputMaybe<Order_By>
  small_dogs?: InputMaybe<Order_By>
}

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>
  _not?: InputMaybe<User_Bool_Exp>
  _or?: InputMaybe<Array<User_Bool_Exp>>
  birth_date?: InputMaybe<Date_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  firebase_id?: InputMaybe<String_Comparison_Exp>
  first_name?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  id_number?: InputMaybe<String_Comparison_Exp>
  id_number_type?: InputMaybe<User_Id_Type_Enum_Comparison_Exp>
  last_name?: InputMaybe<String_Comparison_Exp>
  plaid_identity_connected?: InputMaybe<Boolean_Comparison_Exp>
  plaid_income_connected?: InputMaybe<Boolean_Comparison_Exp>
  plaid_user_id?: InputMaybe<String_Comparison_Exp>
  plaid_user_token?: InputMaybe<String_Comparison_Exp>
  role?: InputMaybe<User_Role_Enum_Comparison_Exp>
  support_tickets?: InputMaybe<Support_Ticket_Bool_Exp>
  support_tickets_aggregate?: InputMaybe<Support_Ticket_Aggregate_Bool_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user_apartments?: InputMaybe<User_Apartment_Bool_Exp>
  user_apartments_aggregate?: InputMaybe<User_Apartment_Aggregate_Bool_Exp>
  user_applications?: InputMaybe<User_Application_Bool_Exp>
  user_applications_aggregate?: InputMaybe<User_Application_Aggregate_Bool_Exp>
  user_id_type?: InputMaybe<User_Id_Type_Bool_Exp>
  user_role?: InputMaybe<User_Role_Bool_Exp>
}

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "firebase_id" */
  UserFirebaseIdKey = 'user_firebase_id_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** columns and relationships of "user_id_type" */
export type User_Id_Type = {
  __typename?: 'user_id_type'
  comment: Scalars['String']['output']
  /** An array relationship */
  user_applications: Array<User_Application>
  /** An aggregate relationship */
  user_applications_aggregate: User_Application_Aggregate
  /** An array relationship */
  users: Array<User>
  /** An aggregate relationship */
  users_aggregate: User_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "user_id_type" */
export type User_Id_TypeUser_ApplicationsArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Order_By>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

/** columns and relationships of "user_id_type" */
export type User_Id_TypeUser_Applications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Application_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Application_Order_By>>
  where?: InputMaybe<User_Application_Bool_Exp>
}

/** columns and relationships of "user_id_type" */
export type User_Id_TypeUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

/** columns and relationships of "user_id_type" */
export type User_Id_TypeUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

/** aggregated selection of "user_id_type" */
export type User_Id_Type_Aggregate = {
  __typename?: 'user_id_type_aggregate'
  aggregate?: Maybe<User_Id_Type_Aggregate_Fields>
  nodes: Array<User_Id_Type>
}

/** aggregate fields of "user_id_type" */
export type User_Id_Type_Aggregate_Fields = {
  __typename?: 'user_id_type_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<User_Id_Type_Max_Fields>
  min?: Maybe<User_Id_Type_Min_Fields>
}

/** aggregate fields of "user_id_type" */
export type User_Id_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Id_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "user_id_type". All fields are combined with a logical 'AND'. */
export type User_Id_Type_Bool_Exp = {
  _and?: InputMaybe<Array<User_Id_Type_Bool_Exp>>
  _not?: InputMaybe<User_Id_Type_Bool_Exp>
  _or?: InputMaybe<Array<User_Id_Type_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  user_applications?: InputMaybe<User_Application_Bool_Exp>
  user_applications_aggregate?: InputMaybe<User_Application_Aggregate_Bool_Exp>
  users?: InputMaybe<User_Bool_Exp>
  users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_id_type" */
export enum User_Id_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserIdTypePkey = 'user_id_type_pkey'
}

export enum User_Id_Type_Enum {
  /** DRIVER_LICENSE */
  DriverLicense = 'DRIVER_LICENSE',
  /** OTHER */
  Other = 'OTHER',
  /** PASSPORT */
  Passport = 'PASSPORT'
}

/** Boolean expression to compare columns of type "user_id_type_enum". All fields are combined with logical 'AND'. */
export type User_Id_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Id_Type_Enum>
  _in?: InputMaybe<Array<User_Id_Type_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<User_Id_Type_Enum>
  _nin?: InputMaybe<Array<User_Id_Type_Enum>>
}

/** input type for inserting data into table "user_id_type" */
export type User_Id_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  user_applications?: InputMaybe<User_Application_Arr_Rel_Insert_Input>
  users?: InputMaybe<User_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type User_Id_Type_Max_Fields = {
  __typename?: 'user_id_type_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type User_Id_Type_Min_Fields = {
  __typename?: 'user_id_type_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "user_id_type" */
export type User_Id_Type_Mutation_Response = {
  __typename?: 'user_id_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<User_Id_Type>
}

/** input type for inserting object relation for remote table "user_id_type" */
export type User_Id_Type_Obj_Rel_Insert_Input = {
  data: User_Id_Type_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<User_Id_Type_On_Conflict>
}

/** on_conflict condition type for table "user_id_type" */
export type User_Id_Type_On_Conflict = {
  constraint: User_Id_Type_Constraint
  update_columns?: Array<User_Id_Type_Update_Column>
  where?: InputMaybe<User_Id_Type_Bool_Exp>
}

/** Ordering options when selecting data from "user_id_type". */
export type User_Id_Type_Order_By = {
  comment?: InputMaybe<Order_By>
  user_applications_aggregate?: InputMaybe<User_Application_Aggregate_Order_By>
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_id_type */
export type User_Id_Type_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "user_id_type" */
export enum User_Id_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_id_type" */
export type User_Id_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "user_id_type" */
export type User_Id_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Id_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Id_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "user_id_type" */
export enum User_Id_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type User_Id_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Id_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Id_Type_Bool_Exp
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  birth_date?: InputMaybe<Scalars['date']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  firebase_id?: InputMaybe<Scalars['String']['input']>
  first_name?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  id_number?: InputMaybe<Scalars['String']['input']>
  id_number_type?: InputMaybe<User_Id_Type_Enum>
  last_name?: InputMaybe<Scalars['String']['input']>
  plaid_identity_connected?: InputMaybe<Scalars['Boolean']['input']>
  plaid_income_connected?: InputMaybe<Scalars['Boolean']['input']>
  plaid_user_id?: InputMaybe<Scalars['String']['input']>
  plaid_user_token?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<User_Role_Enum>
  support_tickets?: InputMaybe<Support_Ticket_Arr_Rel_Insert_Input>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_apartments?: InputMaybe<User_Apartment_Arr_Rel_Insert_Input>
  user_applications?: InputMaybe<User_Application_Arr_Rel_Insert_Input>
  user_id_type?: InputMaybe<User_Id_Type_Obj_Rel_Insert_Input>
  user_role?: InputMaybe<User_Role_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields'
  birth_date?: Maybe<Scalars['date']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  firebase_id?: Maybe<Scalars['String']['output']>
  first_name?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  id_number?: Maybe<Scalars['String']['output']>
  last_name?: Maybe<Scalars['String']['output']>
  plaid_user_id?: Maybe<Scalars['String']['output']>
  plaid_user_token?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  birth_date?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  firebase_id?: InputMaybe<Order_By>
  first_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  last_name?: InputMaybe<Order_By>
  plaid_user_id?: InputMaybe<Order_By>
  plaid_user_token?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields'
  birth_date?: Maybe<Scalars['date']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  firebase_id?: Maybe<Scalars['String']['output']>
  first_name?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  id_number?: Maybe<Scalars['String']['output']>
  last_name?: Maybe<Scalars['String']['output']>
  plaid_user_id?: Maybe<Scalars['String']['output']>
  plaid_user_token?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  birth_date?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  firebase_id?: InputMaybe<Order_By>
  first_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  last_name?: InputMaybe<Order_By>
  plaid_user_id?: InputMaybe<Order_By>
  plaid_user_token?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<User>
}

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint
  update_columns?: Array<User_Update_Column>
  where?: InputMaybe<User_Bool_Exp>
}

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  birth_date?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  firebase_id?: InputMaybe<Order_By>
  first_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  id_number_type?: InputMaybe<Order_By>
  last_name?: InputMaybe<Order_By>
  plaid_identity_connected?: InputMaybe<Order_By>
  plaid_income_connected?: InputMaybe<Order_By>
  plaid_user_id?: InputMaybe<Order_By>
  plaid_user_token?: InputMaybe<Order_By>
  role?: InputMaybe<Order_By>
  support_tickets_aggregate?: InputMaybe<Support_Ticket_Aggregate_Order_By>
  updated_at?: InputMaybe<Order_By>
  user_apartments_aggregate?: InputMaybe<User_Apartment_Aggregate_Order_By>
  user_applications_aggregate?: InputMaybe<User_Application_Aggregate_Order_By>
  user_id_type?: InputMaybe<User_Id_Type_Order_By>
  user_role?: InputMaybe<User_Role_Order_By>
}

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** columns and relationships of "user_role" */
export type User_Role = {
  __typename?: 'user_role'
  comment: Scalars['String']['output']
  /** An array relationship */
  users: Array<User>
  /** An aggregate relationship */
  users_aggregate: User_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "user_role" */
export type User_RoleUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

/** columns and relationships of "user_role" */
export type User_RoleUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

/** aggregated selection of "user_role" */
export type User_Role_Aggregate = {
  __typename?: 'user_role_aggregate'
  aggregate?: Maybe<User_Role_Aggregate_Fields>
  nodes: Array<User_Role>
}

/** aggregate fields of "user_role" */
export type User_Role_Aggregate_Fields = {
  __typename?: 'user_role_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<User_Role_Max_Fields>
  min?: Maybe<User_Role_Min_Fields>
}

/** aggregate fields of "user_role" */
export type User_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Role_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "user_role". All fields are combined with a logical 'AND'. */
export type User_Role_Bool_Exp = {
  _and?: InputMaybe<Array<User_Role_Bool_Exp>>
  _not?: InputMaybe<User_Role_Bool_Exp>
  _or?: InputMaybe<Array<User_Role_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  users?: InputMaybe<User_Bool_Exp>
  users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_role" */
export enum User_Role_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserRolePkey = 'user_role_pkey'
}

export enum User_Role_Enum {
  /** ANONYMOUS */
  Anonymous = 'ANONYMOUS',
  /** SUPER_ADMIN */
  SuperAdmin = 'SUPER_ADMIN',
  /** TENANT */
  Tenant = 'TENANT'
}

/** Boolean expression to compare columns of type "user_role_enum". All fields are combined with logical 'AND'. */
export type User_Role_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Role_Enum>
  _in?: InputMaybe<Array<User_Role_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<User_Role_Enum>
  _nin?: InputMaybe<Array<User_Role_Enum>>
}

/** input type for inserting data into table "user_role" */
export type User_Role_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  users?: InputMaybe<User_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type User_Role_Max_Fields = {
  __typename?: 'user_role_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type User_Role_Min_Fields = {
  __typename?: 'user_role_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "user_role" */
export type User_Role_Mutation_Response = {
  __typename?: 'user_role_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<User_Role>
}

/** input type for inserting object relation for remote table "user_role" */
export type User_Role_Obj_Rel_Insert_Input = {
  data: User_Role_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<User_Role_On_Conflict>
}

/** on_conflict condition type for table "user_role" */
export type User_Role_On_Conflict = {
  constraint: User_Role_Constraint
  update_columns?: Array<User_Role_Update_Column>
  where?: InputMaybe<User_Role_Bool_Exp>
}

/** Ordering options when selecting data from "user_role". */
export type User_Role_Order_By = {
  comment?: InputMaybe<Order_By>
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_role */
export type User_Role_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "user_role" */
export enum User_Role_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_role" */
export type User_Role_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "user_role" */
export type User_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Role_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Role_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "user_role" */
export enum User_Role_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type User_Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Role_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Role_Bool_Exp
}

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FirebaseId = 'firebase_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  IdNumberType = 'id_number_type',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PlaidIdentityConnected = 'plaid_identity_connected',
  /** column name */
  PlaidIncomeConnected = 'plaid_income_connected',
  /** column name */
  PlaidUserId = 'plaid_user_id',
  /** column name */
  PlaidUserToken = 'plaid_user_token',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  PlaidIdentityConnected = 'plaid_identity_connected',
  /** column name */
  PlaidIncomeConnected = 'plaid_income_connected'
}

/** select "user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  PlaidIdentityConnected = 'plaid_identity_connected',
  /** column name */
  PlaidIncomeConnected = 'plaid_income_connected'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  birth_date?: InputMaybe<Scalars['date']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  firebase_id?: InputMaybe<Scalars['String']['input']>
  first_name?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  id_number?: InputMaybe<Scalars['String']['input']>
  id_number_type?: InputMaybe<User_Id_Type_Enum>
  last_name?: InputMaybe<Scalars['String']['input']>
  plaid_identity_connected?: InputMaybe<Scalars['Boolean']['input']>
  plaid_income_connected?: InputMaybe<Scalars['Boolean']['input']>
  plaid_user_id?: InputMaybe<Scalars['String']['input']>
  plaid_user_token?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<User_Role_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  birth_date?: InputMaybe<Scalars['date']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  firebase_id?: InputMaybe<Scalars['String']['input']>
  first_name?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  id_number?: InputMaybe<Scalars['String']['input']>
  id_number_type?: InputMaybe<User_Id_Type_Enum>
  last_name?: InputMaybe<Scalars['String']['input']>
  plaid_identity_connected?: InputMaybe<Scalars['Boolean']['input']>
  plaid_income_connected?: InputMaybe<Scalars['Boolean']['input']>
  plaid_user_id?: InputMaybe<Scalars['String']['input']>
  plaid_user_token?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<User_Role_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FirebaseId = 'firebase_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  IdNumberType = 'id_number_type',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PlaidIdentityConnected = 'plaid_identity_connected',
  /** column name */
  PlaidIncomeConnected = 'plaid_income_connected',
  /** column name */
  PlaidUserId = 'plaid_user_id',
  /** column name */
  PlaidUserToken = 'plaid_user_token',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Bool_Exp
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>
  _gt?: InputMaybe<Scalars['uuid']['input']>
  _gte?: InputMaybe<Scalars['uuid']['input']>
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['uuid']['input']>
  _lte?: InputMaybe<Scalars['uuid']['input']>
  _neq?: InputMaybe<Scalars['uuid']['input']>
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>
}

export type ApartmentFragmentFragment = {
  __typename?: 'apartment'
  id: any
  name: string
  description: string
  floor_number: string
  apartment_number: string
  rental_type: Apartment_Rental_Type_Enum
  ip_address?: string | null
  monthly_rent?: number | null
  security_deposit?: number | null
  profile_photo?: string | null
  deleted_at?: any | null
  updated_at: any
  created_at: any
  type?: {
    __typename?: 'apartment_type'
    id: any
    value: string
    comments: string
    number_of_bedrooms: number
    number_of_bathrooms: number
    metadata: any
    label: string
    webview_preview_url: string
    order?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    photos: Array<{
      __typename?: 'apartment_type_photo'
      id: any
      path: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }>
  } | null
  building: {
    __typename?: 'building'
    id: any
    name: string
    description: string
    number_of_floors: number
    number_of_apartments: number
    ip_address?: string | null
    geolocation?: any | null
    status?: Building_Status_Enum | null
    profile_photo: string
    deleted_at?: any | null
    updated_at: any
    created_at: any
  }
  users: Array<{
    __typename?: 'user_apartment'
    id: any
    user: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      id_number?: string | null
      birth_date?: any | null
      id_number_type?: User_Id_Type_Enum | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }
  }>
}

export type GetApartmentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<
    Array<Apartment_Select_Column> | Apartment_Select_Column
  >
  order_by?: InputMaybe<Array<Apartment_Order_By> | Apartment_Order_By>
  where?: InputMaybe<Apartment_Bool_Exp>
}>

export type GetApartmentsQuery = {
  __typename?: 'query_root'
  apartmentsAggregate: {
    __typename?: 'apartment_aggregate'
    aggregate?: {
      __typename?: 'apartment_aggregate_fields'
      totalCount: number
    } | null
  }
  apartments: Array<{
    __typename?: 'apartment'
    id: any
    name: string
    description: string
    floor_number: string
    apartment_number: string
    rental_type: Apartment_Rental_Type_Enum
    ip_address?: string | null
    monthly_rent?: number | null
    security_deposit?: number | null
    profile_photo?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    type?: {
      __typename?: 'apartment_type'
      id: any
      value: string
      comments: string
      number_of_bedrooms: number
      number_of_bathrooms: number
      metadata: any
      label: string
      webview_preview_url: string
      order?: any | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      photos: Array<{
        __typename?: 'apartment_type_photo'
        id: any
        path: string
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }>
    } | null
    building: {
      __typename?: 'building'
      id: any
      name: string
      description: string
      number_of_floors: number
      number_of_apartments: number
      ip_address?: string | null
      geolocation?: any | null
      status?: Building_Status_Enum | null
      profile_photo: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }
    users: Array<{
      __typename?: 'user_apartment'
      id: any
      user: {
        __typename?: 'user'
        id: any
        firebase_id: string
        role: User_Role_Enum
        first_name?: string | null
        last_name?: string | null
        id_number?: string | null
        birth_date?: any | null
        id_number_type?: User_Id_Type_Enum | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }
    }>
  }>
}

export type GetApartmentQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type GetApartmentQuery = {
  __typename?: 'query_root'
  apartment?: {
    __typename?: 'apartment'
    id: any
    name: string
    description: string
    floor_number: string
    apartment_number: string
    rental_type: Apartment_Rental_Type_Enum
    ip_address?: string | null
    monthly_rent?: number | null
    security_deposit?: number | null
    profile_photo?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    type?: {
      __typename?: 'apartment_type'
      id: any
      value: string
      comments: string
      number_of_bedrooms: number
      number_of_bathrooms: number
      metadata: any
      label: string
      webview_preview_url: string
      order?: any | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      photos: Array<{
        __typename?: 'apartment_type_photo'
        id: any
        path: string
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }>
    } | null
    building: {
      __typename?: 'building'
      id: any
      name: string
      description: string
      number_of_floors: number
      number_of_apartments: number
      ip_address?: string | null
      geolocation?: any | null
      status?: Building_Status_Enum | null
      profile_photo: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }
    users: Array<{
      __typename?: 'user_apartment'
      id: any
      user: {
        __typename?: 'user'
        id: any
        firebase_id: string
        role: User_Role_Enum
        first_name?: string | null
        last_name?: string | null
        id_number?: string | null
        birth_date?: any | null
        id_number_type?: User_Id_Type_Enum | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }
    }>
  } | null
}

export type UpdateApartmentMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  _set: Apartment_Set_Input
}>

export type UpdateApartmentMutation = {
  __typename?: 'mutation_root'
  apartment?: {
    __typename?: 'apartment'
    id: any
    name: string
    description: string
    floor_number: string
    apartment_number: string
    rental_type: Apartment_Rental_Type_Enum
    ip_address?: string | null
    monthly_rent?: number | null
    security_deposit?: number | null
    profile_photo?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    type?: {
      __typename?: 'apartment_type'
      id: any
      value: string
      comments: string
      number_of_bedrooms: number
      number_of_bathrooms: number
      metadata: any
      label: string
      webview_preview_url: string
      order?: any | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      photos: Array<{
        __typename?: 'apartment_type_photo'
        id: any
        path: string
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }>
    } | null
    building: {
      __typename?: 'building'
      id: any
      name: string
      description: string
      number_of_floors: number
      number_of_apartments: number
      ip_address?: string | null
      geolocation?: any | null
      status?: Building_Status_Enum | null
      profile_photo: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }
    users: Array<{
      __typename?: 'user_apartment'
      id: any
      user: {
        __typename?: 'user'
        id: any
        firebase_id: string
        role: User_Role_Enum
        first_name?: string | null
        last_name?: string | null
        id_number?: string | null
        birth_date?: any | null
        id_number_type?: User_Id_Type_Enum | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }
    }>
  } | null
}

export type InsertApartmentMutationVariables = Exact<{
  object: Apartment_Insert_Input
}>

export type InsertApartmentMutation = {
  __typename?: 'mutation_root'
  apartment?: {
    __typename?: 'apartment'
    id: any
    name: string
    description: string
    floor_number: string
    apartment_number: string
    rental_type: Apartment_Rental_Type_Enum
    ip_address?: string | null
    monthly_rent?: number | null
    security_deposit?: number | null
    profile_photo?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    type?: {
      __typename?: 'apartment_type'
      id: any
      value: string
      comments: string
      number_of_bedrooms: number
      number_of_bathrooms: number
      metadata: any
      label: string
      webview_preview_url: string
      order?: any | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      photos: Array<{
        __typename?: 'apartment_type_photo'
        id: any
        path: string
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }>
    } | null
    building: {
      __typename?: 'building'
      id: any
      name: string
      description: string
      number_of_floors: number
      number_of_apartments: number
      ip_address?: string | null
      geolocation?: any | null
      status?: Building_Status_Enum | null
      profile_photo: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }
    users: Array<{
      __typename?: 'user_apartment'
      id: any
      user: {
        __typename?: 'user'
        id: any
        firebase_id: string
        role: User_Role_Enum
        first_name?: string | null
        last_name?: string | null
        id_number?: string | null
        birth_date?: any | null
        id_number_type?: User_Id_Type_Enum | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }
    }>
  } | null
}

export type UpsertApartmentMutationVariables = Exact<{
  object: Apartment_Insert_Input
  on_conflict: Apartment_On_Conflict
}>

export type UpsertApartmentMutation = {
  __typename?: 'mutation_root'
  apartment?: {
    __typename?: 'apartment'
    id: any
    name: string
    description: string
    floor_number: string
    apartment_number: string
    rental_type: Apartment_Rental_Type_Enum
    ip_address?: string | null
    monthly_rent?: number | null
    security_deposit?: number | null
    profile_photo?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    type?: {
      __typename?: 'apartment_type'
      id: any
      value: string
      comments: string
      number_of_bedrooms: number
      number_of_bathrooms: number
      metadata: any
      label: string
      webview_preview_url: string
      order?: any | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      photos: Array<{
        __typename?: 'apartment_type_photo'
        id: any
        path: string
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }>
    } | null
    building: {
      __typename?: 'building'
      id: any
      name: string
      description: string
      number_of_floors: number
      number_of_apartments: number
      ip_address?: string | null
      geolocation?: any | null
      status?: Building_Status_Enum | null
      profile_photo: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }
    users: Array<{
      __typename?: 'user_apartment'
      id: any
      user: {
        __typename?: 'user'
        id: any
        firebase_id: string
        role: User_Role_Enum
        first_name?: string | null
        last_name?: string | null
        id_number?: string | null
        birth_date?: any | null
        id_number_type?: User_Id_Type_Enum | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }
    }>
  } | null
}

export type GetApartmentDictionariesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetApartmentDictionariesQuery = {
  __typename?: 'query_root'
  buildings: Array<{ __typename?: 'building'; id: any; name: string }>
  apartmentTypes: Array<{
    __typename?: 'apartment_type'
    id: any
    value: string
  }>
}

export type ApartmentTypePhotoFragmentFragment = {
  __typename?: 'apartment_type_photo'
  id: any
  path: string
  deleted_at?: any | null
  updated_at: any
  created_at: any
}

export type ApartmentTypeFragmentFragment = {
  __typename?: 'apartment_type'
  id: any
  value: string
  comments: string
  number_of_bedrooms: number
  number_of_bathrooms: number
  metadata: any
  label: string
  webview_preview_url: string
  order?: any | null
  deleted_at?: any | null
  updated_at: any
  created_at: any
  photos: Array<{
    __typename?: 'apartment_type_photo'
    id: any
    path: string
    deleted_at?: any | null
    updated_at: any
    created_at: any
  }>
}

export type GetApartmentTypesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<
    Array<Apartment_Type_Select_Column> | Apartment_Type_Select_Column
  >
  order_by?: InputMaybe<
    Array<Apartment_Type_Order_By> | Apartment_Type_Order_By
  >
  where?: InputMaybe<Apartment_Type_Bool_Exp>
}>

export type GetApartmentTypesQuery = {
  __typename?: 'query_root'
  apartmentTypesAggregate: {
    __typename?: 'apartment_type_aggregate'
    aggregate?: {
      __typename?: 'apartment_type_aggregate_fields'
      totalCount: number
    } | null
  }
  apartmentTypes: Array<{
    __typename?: 'apartment_type'
    id: any
    value: string
    comments: string
    number_of_bedrooms: number
    number_of_bathrooms: number
    metadata: any
    label: string
    webview_preview_url: string
    order?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    photos: Array<{
      __typename?: 'apartment_type_photo'
      id: any
      path: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }>
  }>
}

export type GetApartmentTypeQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type GetApartmentTypeQuery = {
  __typename?: 'query_root'
  apartmentType?: {
    __typename?: 'apartment_type'
    id: any
    value: string
    comments: string
    number_of_bedrooms: number
    number_of_bathrooms: number
    metadata: any
    label: string
    webview_preview_url: string
    order?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    photos: Array<{
      __typename?: 'apartment_type_photo'
      id: any
      path: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }>
  } | null
}

export type UpdateApartmentTypeMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  _set: Apartment_Type_Set_Input
}>

export type UpdateApartmentTypeMutation = {
  __typename?: 'mutation_root'
  apartmentType?: {
    __typename?: 'apartment_type'
    id: any
    value: string
    comments: string
    number_of_bedrooms: number
    number_of_bathrooms: number
    metadata: any
    label: string
    webview_preview_url: string
    order?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    photos: Array<{
      __typename?: 'apartment_type_photo'
      id: any
      path: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }>
  } | null
}

export type InsertApartmentTypeMutationVariables = Exact<{
  object: Apartment_Type_Insert_Input
}>

export type InsertApartmentTypeMutation = {
  __typename?: 'mutation_root'
  apartmentType?: {
    __typename?: 'apartment_type'
    id: any
    value: string
    comments: string
    number_of_bedrooms: number
    number_of_bathrooms: number
    metadata: any
    label: string
    webview_preview_url: string
    order?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    photos: Array<{
      __typename?: 'apartment_type_photo'
      id: any
      path: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }>
  } | null
}

export type BuildingFragmentFragment = {
  __typename?: 'building'
  id: any
  name: string
  description: string
  number_of_floors: number
  number_of_apartments: number
  ip_address?: string | null
  geolocation?: any | null
  status?: Building_Status_Enum | null
  profile_photo: string
  deleted_at?: any | null
  updated_at: any
  created_at: any
}

export type GetBuildingsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<
    Array<Building_Select_Column> | Building_Select_Column
  >
  order_by?: InputMaybe<Array<Building_Order_By> | Building_Order_By>
  where?: InputMaybe<Building_Bool_Exp>
}>

export type GetBuildingsQuery = {
  __typename?: 'query_root'
  buildingsAggregate: {
    __typename?: 'building_aggregate'
    aggregate?: {
      __typename?: 'building_aggregate_fields'
      totalCount: number
    } | null
  }
  buildings: Array<{
    __typename?: 'building'
    id: any
    name: string
    description: string
    number_of_floors: number
    number_of_apartments: number
    ip_address?: string | null
    geolocation?: any | null
    status?: Building_Status_Enum | null
    profile_photo: string
    deleted_at?: any | null
    updated_at: any
    created_at: any
  }>
}

export type GetBuildingQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type GetBuildingQuery = {
  __typename?: 'query_root'
  building?: {
    __typename?: 'building'
    id: any
    name: string
    description: string
    number_of_floors: number
    number_of_apartments: number
    ip_address?: string | null
    geolocation?: any | null
    status?: Building_Status_Enum | null
    profile_photo: string
    deleted_at?: any | null
    updated_at: any
    created_at: any
  } | null
}

export type UpdateBuildingMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  _set: Building_Set_Input
}>

export type UpdateBuildingMutation = {
  __typename?: 'mutation_root'
  building?: {
    __typename?: 'building'
    id: any
    name: string
    description: string
    number_of_floors: number
    number_of_apartments: number
    ip_address?: string | null
    geolocation?: any | null
    status?: Building_Status_Enum | null
    profile_photo: string
    deleted_at?: any | null
    updated_at: any
    created_at: any
  } | null
}

export type InsertBuildingMutationVariables = Exact<{
  object: Building_Insert_Input
}>

export type InsertBuildingMutation = {
  __typename?: 'mutation_root'
  building?: {
    __typename?: 'building'
    id: any
    name: string
    description: string
    number_of_floors: number
    number_of_apartments: number
    ip_address?: string | null
    geolocation?: any | null
    status?: Building_Status_Enum | null
    profile_photo: string
    deleted_at?: any | null
    updated_at: any
    created_at: any
  } | null
}

export type UpsertBuildingMutationVariables = Exact<{
  object: Building_Insert_Input
  on_conflict: Building_On_Conflict
}>

export type UpsertBuildingMutation = {
  __typename?: 'mutation_root'
  building?: {
    __typename?: 'building'
    id: any
    name: string
    description: string
    number_of_floors: number
    number_of_apartments: number
    ip_address?: string | null
    geolocation?: any | null
    status?: Building_Status_Enum | null
    profile_photo: string
    deleted_at?: any | null
    updated_at: any
    created_at: any
  } | null
}

export type BaseDeviceFragmentFragment = {
  __typename?: 'device'
  id: any
  name: string
  type: Device_Type_Enum
  apartment_id?: any | null
  building_id?: any | null
  group?: Device_Group_Enum | null
  ip_address?: string | null
  port?: number | null
  status?: string | null
  metadata?: any | null
  zone?: string | null
  bridge_id?: any | null
  state?: any | null
  value?: any | null
  deleted_at?: any | null
  updated_at: any
  created_at: any
}

export type DeviceFragmentFragment = {
  __typename?: 'device'
  id: any
  name: string
  type: Device_Type_Enum
  apartment_id?: any | null
  building_id?: any | null
  group?: Device_Group_Enum | null
  ip_address?: string | null
  port?: number | null
  status?: string | null
  metadata?: any | null
  zone?: string | null
  bridge_id?: any | null
  state?: any | null
  value?: any | null
  deleted_at?: any | null
  updated_at: any
  created_at: any
  apartment?: {
    __typename?: 'apartment'
    id: any
    name: string
    description: string
    floor_number: string
    apartment_number: string
    rental_type: Apartment_Rental_Type_Enum
    ip_address?: string | null
    monthly_rent?: number | null
    security_deposit?: number | null
    profile_photo?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    type?: {
      __typename?: 'apartment_type'
      id: any
      value: string
      comments: string
      number_of_bedrooms: number
      number_of_bathrooms: number
      metadata: any
      label: string
      webview_preview_url: string
      order?: any | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      photos: Array<{
        __typename?: 'apartment_type_photo'
        id: any
        path: string
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }>
    } | null
    building: {
      __typename?: 'building'
      id: any
      name: string
      description: string
      number_of_floors: number
      number_of_apartments: number
      ip_address?: string | null
      geolocation?: any | null
      status?: Building_Status_Enum | null
      profile_photo: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }
    users: Array<{
      __typename?: 'user_apartment'
      id: any
      user: {
        __typename?: 'user'
        id: any
        firebase_id: string
        role: User_Role_Enum
        first_name?: string | null
        last_name?: string | null
        id_number?: string | null
        birth_date?: any | null
        id_number_type?: User_Id_Type_Enum | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }
    }>
  } | null
  building?: {
    __typename?: 'building'
    id: any
    name: string
    description: string
    number_of_floors: number
    number_of_apartments: number
    ip_address?: string | null
    geolocation?: any | null
    status?: Building_Status_Enum | null
    profile_photo: string
    deleted_at?: any | null
    updated_at: any
    created_at: any
  } | null
  bridge?: {
    __typename?: 'device'
    id: any
    name: string
    type: Device_Type_Enum
    apartment_id?: any | null
    building_id?: any | null
    group?: Device_Group_Enum | null
    ip_address?: string | null
    port?: number | null
    status?: string | null
    metadata?: any | null
    zone?: string | null
    bridge_id?: any | null
    state?: any | null
    value?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
  } | null
}

export type GetDevicesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<Array<Device_Select_Column> | Device_Select_Column>
  order_by?: InputMaybe<Array<Device_Order_By> | Device_Order_By>
  where?: InputMaybe<Device_Bool_Exp>
}>

export type GetDevicesQuery = {
  __typename?: 'query_root'
  devicesAggregate: {
    __typename?: 'device_aggregate'
    aggregate?: {
      __typename?: 'device_aggregate_fields'
      totalCount: number
    } | null
  }
  devices: Array<{
    __typename?: 'device'
    id: any
    name: string
    type: Device_Type_Enum
    apartment_id?: any | null
    building_id?: any | null
    group?: Device_Group_Enum | null
    ip_address?: string | null
    port?: number | null
    status?: string | null
    metadata?: any | null
    zone?: string | null
    bridge_id?: any | null
    state?: any | null
    value?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    apartment?: {
      __typename?: 'apartment'
      id: any
      name: string
      description: string
      floor_number: string
      apartment_number: string
      rental_type: Apartment_Rental_Type_Enum
      ip_address?: string | null
      monthly_rent?: number | null
      security_deposit?: number | null
      profile_photo?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      type?: {
        __typename?: 'apartment_type'
        id: any
        value: string
        comments: string
        number_of_bedrooms: number
        number_of_bathrooms: number
        metadata: any
        label: string
        webview_preview_url: string
        order?: any | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
        photos: Array<{
          __typename?: 'apartment_type_photo'
          id: any
          path: string
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }>
      } | null
      building: {
        __typename?: 'building'
        id: any
        name: string
        description: string
        number_of_floors: number
        number_of_apartments: number
        ip_address?: string | null
        geolocation?: any | null
        status?: Building_Status_Enum | null
        profile_photo: string
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }
      users: Array<{
        __typename?: 'user_apartment'
        id: any
        user: {
          __typename?: 'user'
          id: any
          firebase_id: string
          role: User_Role_Enum
          first_name?: string | null
          last_name?: string | null
          id_number?: string | null
          birth_date?: any | null
          id_number_type?: User_Id_Type_Enum | null
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }
      }>
    } | null
    building?: {
      __typename?: 'building'
      id: any
      name: string
      description: string
      number_of_floors: number
      number_of_apartments: number
      ip_address?: string | null
      geolocation?: any | null
      status?: Building_Status_Enum | null
      profile_photo: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    } | null
    bridge?: {
      __typename?: 'device'
      id: any
      name: string
      type: Device_Type_Enum
      apartment_id?: any | null
      building_id?: any | null
      group?: Device_Group_Enum | null
      ip_address?: string | null
      port?: number | null
      status?: string | null
      metadata?: any | null
      zone?: string | null
      bridge_id?: any | null
      state?: any | null
      value?: any | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
    } | null
  }>
}

export type GetDeviceQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type GetDeviceQuery = {
  __typename?: 'query_root'
  device?: {
    __typename?: 'device'
    id: any
    name: string
    type: Device_Type_Enum
    apartment_id?: any | null
    building_id?: any | null
    group?: Device_Group_Enum | null
    ip_address?: string | null
    port?: number | null
    status?: string | null
    metadata?: any | null
    zone?: string | null
    bridge_id?: any | null
    state?: any | null
    value?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    apartment?: {
      __typename?: 'apartment'
      id: any
      name: string
      description: string
      floor_number: string
      apartment_number: string
      rental_type: Apartment_Rental_Type_Enum
      ip_address?: string | null
      monthly_rent?: number | null
      security_deposit?: number | null
      profile_photo?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      type?: {
        __typename?: 'apartment_type'
        id: any
        value: string
        comments: string
        number_of_bedrooms: number
        number_of_bathrooms: number
        metadata: any
        label: string
        webview_preview_url: string
        order?: any | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
        photos: Array<{
          __typename?: 'apartment_type_photo'
          id: any
          path: string
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }>
      } | null
      building: {
        __typename?: 'building'
        id: any
        name: string
        description: string
        number_of_floors: number
        number_of_apartments: number
        ip_address?: string | null
        geolocation?: any | null
        status?: Building_Status_Enum | null
        profile_photo: string
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }
      users: Array<{
        __typename?: 'user_apartment'
        id: any
        user: {
          __typename?: 'user'
          id: any
          firebase_id: string
          role: User_Role_Enum
          first_name?: string | null
          last_name?: string | null
          id_number?: string | null
          birth_date?: any | null
          id_number_type?: User_Id_Type_Enum | null
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }
      }>
    } | null
    building?: {
      __typename?: 'building'
      id: any
      name: string
      description: string
      number_of_floors: number
      number_of_apartments: number
      ip_address?: string | null
      geolocation?: any | null
      status?: Building_Status_Enum | null
      profile_photo: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    } | null
    bridge?: {
      __typename?: 'device'
      id: any
      name: string
      type: Device_Type_Enum
      apartment_id?: any | null
      building_id?: any | null
      group?: Device_Group_Enum | null
      ip_address?: string | null
      port?: number | null
      status?: string | null
      metadata?: any | null
      zone?: string | null
      bridge_id?: any | null
      state?: any | null
      value?: any | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
    } | null
  } | null
}

export type UpdateDeviceMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  _set: Device_Set_Input
}>

export type UpdateDeviceMutation = {
  __typename?: 'mutation_root'
  device?: {
    __typename?: 'device'
    id: any
    name: string
    type: Device_Type_Enum
    apartment_id?: any | null
    building_id?: any | null
    group?: Device_Group_Enum | null
    ip_address?: string | null
    port?: number | null
    status?: string | null
    metadata?: any | null
    zone?: string | null
    bridge_id?: any | null
    state?: any | null
    value?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    apartment?: {
      __typename?: 'apartment'
      id: any
      name: string
      description: string
      floor_number: string
      apartment_number: string
      rental_type: Apartment_Rental_Type_Enum
      ip_address?: string | null
      monthly_rent?: number | null
      security_deposit?: number | null
      profile_photo?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      type?: {
        __typename?: 'apartment_type'
        id: any
        value: string
        comments: string
        number_of_bedrooms: number
        number_of_bathrooms: number
        metadata: any
        label: string
        webview_preview_url: string
        order?: any | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
        photos: Array<{
          __typename?: 'apartment_type_photo'
          id: any
          path: string
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }>
      } | null
      building: {
        __typename?: 'building'
        id: any
        name: string
        description: string
        number_of_floors: number
        number_of_apartments: number
        ip_address?: string | null
        geolocation?: any | null
        status?: Building_Status_Enum | null
        profile_photo: string
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }
      users: Array<{
        __typename?: 'user_apartment'
        id: any
        user: {
          __typename?: 'user'
          id: any
          firebase_id: string
          role: User_Role_Enum
          first_name?: string | null
          last_name?: string | null
          id_number?: string | null
          birth_date?: any | null
          id_number_type?: User_Id_Type_Enum | null
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }
      }>
    } | null
    building?: {
      __typename?: 'building'
      id: any
      name: string
      description: string
      number_of_floors: number
      number_of_apartments: number
      ip_address?: string | null
      geolocation?: any | null
      status?: Building_Status_Enum | null
      profile_photo: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    } | null
    bridge?: {
      __typename?: 'device'
      id: any
      name: string
      type: Device_Type_Enum
      apartment_id?: any | null
      building_id?: any | null
      group?: Device_Group_Enum | null
      ip_address?: string | null
      port?: number | null
      status?: string | null
      metadata?: any | null
      zone?: string | null
      bridge_id?: any | null
      state?: any | null
      value?: any | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
    } | null
  } | null
}

export type InsertDeviceMutationVariables = Exact<{
  object: Device_Insert_Input
}>

export type InsertDeviceMutation = {
  __typename?: 'mutation_root'
  device?: {
    __typename?: 'device'
    id: any
    name: string
    type: Device_Type_Enum
    apartment_id?: any | null
    building_id?: any | null
    group?: Device_Group_Enum | null
    ip_address?: string | null
    port?: number | null
    status?: string | null
    metadata?: any | null
    zone?: string | null
    bridge_id?: any | null
    state?: any | null
    value?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    apartment?: {
      __typename?: 'apartment'
      id: any
      name: string
      description: string
      floor_number: string
      apartment_number: string
      rental_type: Apartment_Rental_Type_Enum
      ip_address?: string | null
      monthly_rent?: number | null
      security_deposit?: number | null
      profile_photo?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      type?: {
        __typename?: 'apartment_type'
        id: any
        value: string
        comments: string
        number_of_bedrooms: number
        number_of_bathrooms: number
        metadata: any
        label: string
        webview_preview_url: string
        order?: any | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
        photos: Array<{
          __typename?: 'apartment_type_photo'
          id: any
          path: string
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }>
      } | null
      building: {
        __typename?: 'building'
        id: any
        name: string
        description: string
        number_of_floors: number
        number_of_apartments: number
        ip_address?: string | null
        geolocation?: any | null
        status?: Building_Status_Enum | null
        profile_photo: string
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }
      users: Array<{
        __typename?: 'user_apartment'
        id: any
        user: {
          __typename?: 'user'
          id: any
          firebase_id: string
          role: User_Role_Enum
          first_name?: string | null
          last_name?: string | null
          id_number?: string | null
          birth_date?: any | null
          id_number_type?: User_Id_Type_Enum | null
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }
      }>
    } | null
    building?: {
      __typename?: 'building'
      id: any
      name: string
      description: string
      number_of_floors: number
      number_of_apartments: number
      ip_address?: string | null
      geolocation?: any | null
      status?: Building_Status_Enum | null
      profile_photo: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    } | null
    bridge?: {
      __typename?: 'device'
      id: any
      name: string
      type: Device_Type_Enum
      apartment_id?: any | null
      building_id?: any | null
      group?: Device_Group_Enum | null
      ip_address?: string | null
      port?: number | null
      status?: string | null
      metadata?: any | null
      zone?: string | null
      bridge_id?: any | null
      state?: any | null
      value?: any | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
    } | null
  } | null
}

export type UpsertDeviceMutationVariables = Exact<{
  object: Device_Insert_Input
  on_conflict: Device_On_Conflict
}>

export type UpsertDeviceMutation = {
  __typename?: 'mutation_root'
  device?: {
    __typename?: 'device'
    id: any
    name: string
    type: Device_Type_Enum
    apartment_id?: any | null
    building_id?: any | null
    group?: Device_Group_Enum | null
    ip_address?: string | null
    port?: number | null
    status?: string | null
    metadata?: any | null
    zone?: string | null
    bridge_id?: any | null
    state?: any | null
    value?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    apartment?: {
      __typename?: 'apartment'
      id: any
      name: string
      description: string
      floor_number: string
      apartment_number: string
      rental_type: Apartment_Rental_Type_Enum
      ip_address?: string | null
      monthly_rent?: number | null
      security_deposit?: number | null
      profile_photo?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      type?: {
        __typename?: 'apartment_type'
        id: any
        value: string
        comments: string
        number_of_bedrooms: number
        number_of_bathrooms: number
        metadata: any
        label: string
        webview_preview_url: string
        order?: any | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
        photos: Array<{
          __typename?: 'apartment_type_photo'
          id: any
          path: string
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }>
      } | null
      building: {
        __typename?: 'building'
        id: any
        name: string
        description: string
        number_of_floors: number
        number_of_apartments: number
        ip_address?: string | null
        geolocation?: any | null
        status?: Building_Status_Enum | null
        profile_photo: string
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }
      users: Array<{
        __typename?: 'user_apartment'
        id: any
        user: {
          __typename?: 'user'
          id: any
          firebase_id: string
          role: User_Role_Enum
          first_name?: string | null
          last_name?: string | null
          id_number?: string | null
          birth_date?: any | null
          id_number_type?: User_Id_Type_Enum | null
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }
      }>
    } | null
    building?: {
      __typename?: 'building'
      id: any
      name: string
      description: string
      number_of_floors: number
      number_of_apartments: number
      ip_address?: string | null
      geolocation?: any | null
      status?: Building_Status_Enum | null
      profile_photo: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    } | null
    bridge?: {
      __typename?: 'device'
      id: any
      name: string
      type: Device_Type_Enum
      apartment_id?: any | null
      building_id?: any | null
      group?: Device_Group_Enum | null
      ip_address?: string | null
      port?: number | null
      status?: string | null
      metadata?: any | null
      zone?: string | null
      bridge_id?: any | null
      state?: any | null
      value?: any | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
    } | null
  } | null
}

export type GetDeviceDictionariesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetDeviceDictionariesQuery = {
  __typename?: 'query_root'
  apartments: Array<{
    __typename?: 'apartment'
    id: any
    name: string
    building: { __typename?: 'building'; id: any; name: string }
  }>
  buildings: Array<{ __typename?: 'building'; id: any; name: string }>
}

export type SupportTicketPhotoFragmentFragment = {
  __typename?: 'support_ticket_photo'
  id: any
  path: string
  deleted_at?: any | null
  updated_at: any
  created_at: any
}

export type SupportTicketFragmentFragment = {
  __typename?: 'support_ticket'
  id: any
  type: Support_Ticket_Type_Enum
  status: Support_Ticket_Status_Enum
  message: string
  image?: string | null
  deleted_at?: any | null
  updated_at: any
  created_at: any
  user: {
    __typename?: 'user'
    id: any
    first_name?: string | null
    last_name?: string | null
  }
  photos: Array<{
    __typename?: 'support_ticket_photo'
    id: any
    path: string
    deleted_at?: any | null
    updated_at: any
    created_at: any
  }>
}

export type GetSupportTicketsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<
    Array<Support_Ticket_Select_Column> | Support_Ticket_Select_Column
  >
  order_by?: InputMaybe<
    Array<Support_Ticket_Order_By> | Support_Ticket_Order_By
  >
  where?: InputMaybe<Support_Ticket_Bool_Exp>
}>

export type GetSupportTicketsQuery = {
  __typename?: 'query_root'
  supportTicketsAggregate: {
    __typename?: 'support_ticket_aggregate'
    aggregate?: {
      __typename?: 'support_ticket_aggregate_fields'
      totalCount: number
    } | null
  }
  supportTickets: Array<{
    __typename?: 'support_ticket'
    id: any
    type: Support_Ticket_Type_Enum
    status: Support_Ticket_Status_Enum
    message: string
    image?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    user: {
      __typename?: 'user'
      id: any
      first_name?: string | null
      last_name?: string | null
    }
    photos: Array<{
      __typename?: 'support_ticket_photo'
      id: any
      path: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }>
  }>
}

export type GetSupportTicketQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type GetSupportTicketQuery = {
  __typename?: 'query_root'
  supportTicket?: {
    __typename?: 'support_ticket'
    id: any
    type: Support_Ticket_Type_Enum
    status: Support_Ticket_Status_Enum
    message: string
    image?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    user: {
      __typename?: 'user'
      id: any
      first_name?: string | null
      last_name?: string | null
    }
    photos: Array<{
      __typename?: 'support_ticket_photo'
      id: any
      path: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }>
  } | null
}

export type UpdateSupportTicketMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  _set: Support_Ticket_Set_Input
}>

export type UpdateSupportTicketMutation = {
  __typename?: 'mutation_root'
  supportTicket?: {
    __typename?: 'support_ticket'
    id: any
    type: Support_Ticket_Type_Enum
    status: Support_Ticket_Status_Enum
    message: string
    image?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    user: {
      __typename?: 'user'
      id: any
      first_name?: string | null
      last_name?: string | null
    }
    photos: Array<{
      __typename?: 'support_ticket_photo'
      id: any
      path: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }>
  } | null
}

export type InsertSupportTicketMutationVariables = Exact<{
  object: Support_Ticket_Insert_Input
}>

export type InsertSupportTicketMutation = {
  __typename?: 'mutation_root'
  supportTicket?: {
    __typename?: 'support_ticket'
    id: any
    type: Support_Ticket_Type_Enum
    status: Support_Ticket_Status_Enum
    message: string
    image?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    user: {
      __typename?: 'user'
      id: any
      first_name?: string | null
      last_name?: string | null
    }
    photos: Array<{
      __typename?: 'support_ticket_photo'
      id: any
      path: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }>
  } | null
}

export type UserFragmentFragment = {
  __typename?: 'user'
  id: any
  firebase_id: string
  role: User_Role_Enum
  first_name?: string | null
  last_name?: string | null
  id_number?: string | null
  birth_date?: any | null
  id_number_type?: User_Id_Type_Enum | null
  deleted_at?: any | null
  updated_at: any
  created_at: any
}

export type GetUsersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<Array<User_Select_Column> | User_Select_Column>
  order_by?: InputMaybe<Array<User_Order_By> | User_Order_By>
  where?: InputMaybe<User_Bool_Exp>
}>

export type GetUsersQuery = {
  __typename?: 'query_root'
  usersAggregate: {
    __typename?: 'user_aggregate'
    aggregate?: {
      __typename?: 'user_aggregate_fields'
      totalCount: number
    } | null
  }
  users: Array<{
    __typename?: 'user'
    id: any
    firebase_id: string
    role: User_Role_Enum
    first_name?: string | null
    last_name?: string | null
    id_number?: string | null
    birth_date?: any | null
    id_number_type?: User_Id_Type_Enum | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
  }>
}

export type GetUserByFirebaseIdQueryVariables = Exact<{
  firebaseId?: InputMaybe<Scalars['String']['input']>
}>

export type GetUserByFirebaseIdQuery = {
  __typename?: 'query_root'
  user: Array<{
    __typename?: 'user'
    id: any
    firebase_id: string
    role: User_Role_Enum
    first_name?: string | null
    last_name?: string | null
    id_number?: string | null
    birth_date?: any | null
    id_number_type?: User_Id_Type_Enum | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
  }>
}

export type GetUserQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type GetUserQuery = {
  __typename?: 'query_root'
  user?: {
    __typename?: 'user'
    id: any
    firebase_id: string
    role: User_Role_Enum
    first_name?: string | null
    last_name?: string | null
    id_number?: string | null
    birth_date?: any | null
    id_number_type?: User_Id_Type_Enum | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
  } | null
}

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  _set: User_Set_Input
}>

export type UpdateUserMutation = {
  __typename?: 'mutation_root'
  user?: {
    __typename?: 'user'
    id: any
    firebase_id: string
    role: User_Role_Enum
    first_name?: string | null
    last_name?: string | null
    id_number?: string | null
    birth_date?: any | null
    id_number_type?: User_Id_Type_Enum | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
  } | null
}

export type UserApplicationAddressFragmentFragment = {
  __typename?: 'user_application_address'
  id: any
  street_address: string
  apt: string
  state: string
  city: string
  zip: string
  reference_contact_number: string
  time_of_living: number
  monthly_rent: number
  reason_of_moving: string
  created_at: any
  updated_at: any
  deleted_at?: any | null
}

export type UserApplicationIncomeFragmentFragment = {
  __typename?: 'user_application_income'
  id: any
  status: string
  job_title: string
  employer: string
  employer_since: any
  monthly_income: number
  employer_contact_name: string
  employer_contact_email?: string | null
  employer_contact_phone_number?: string | null
  additional_information?: string | null
  created_at: any
  updated_at: any
  deleted_at?: any | null
}

export type UserApplicationIncomeDocumentFragmentFragment = {
  __typename?: 'user_application_income_document'
  id: any
  name: string
  size: number
  type: string
  created_at: any
  updated_at: any
  deleted_at?: any | null
}

export type UserApplicationFragmentFragment = {
  __typename?: 'user_application'
  id: any
  user_id: any
  building_id?: any | null
  apartment_id?: any | null
  status: User_Application_Status_Enum
  rental_type?: Apartment_Rental_Type_Enum | null
  check_in_date?: any | null
  check_out_date?: any | null
  number_of_people?: number | null
  small_dogs?: number | null
  medium_dogs?: number | null
  large_dogs?: number | null
  cats?: number | null
  other_pets?: number | null
  pet_details?: string | null
  assistant_animal?: boolean | null
  vehicles?: boolean | null
  car_make?: string | null
  car_model?: string | null
  car_registered_in?: string | null
  license_plate_number?: string | null
  car_sharing?: boolean | null
  screening_report_copy?: boolean | null
  experian_credit_report_terms?: boolean | null
  experian_privacy_policy?: boolean | null
  cic_bg_terms?: boolean | null
  cic_copy?: boolean | null
  first_name?: string | null
  middle_name?: string | null
  last_name?: string | null
  birth_date?: any | null
  social_security_number?: string | null
  id_number?: string | null
  type_of_government_id?: User_Id_Type_Enum | null
  evicted_by_court_order?: boolean | null
  evicted_by_court_order_explanation?: string | null
  convicted_of_a_felony?: boolean | null
  convicted_of_a_felony_explanation?: string | null
  defendant_civil_landlord_or_criminal_case?: boolean | null
  defendant_civil_landlord_or_criminal_case_explanation?: string | null
  agree_terms_and_conditions?: boolean | null
  agree_screened_after_payment?: boolean | null
  sms_notification?: boolean | null
  signature?: string | null
  plaid_income_connected?: boolean | null
  plaid_idv_session_id?: string | null
  understand_rental_insurance?: boolean | null
  help_finding_rental_company?: boolean | null
  information_accurate?: boolean | null
  accept_terms_and_conditions?: boolean | null
  terms_signature?: string | null
  agreement_signature?: string | null
  sms_notification_lease_accepted?: boolean | null
  inspect_myself_checklist?: boolean | null
  created_at: any
  updated_at: any
  deleted_at?: any | null
  user: {
    __typename?: 'user'
    id: any
    firebase_id: string
    role: User_Role_Enum
    first_name?: string | null
    last_name?: string | null
    id_number?: string | null
    birth_date?: any | null
    id_number_type?: User_Id_Type_Enum | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
  }
  apartment?: {
    __typename?: 'apartment'
    id: any
    name: string
    description: string
    floor_number: string
    apartment_number: string
    rental_type: Apartment_Rental_Type_Enum
    ip_address?: string | null
    monthly_rent?: number | null
    security_deposit?: number | null
    profile_photo?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    type?: {
      __typename?: 'apartment_type'
      id: any
      value: string
      comments: string
      number_of_bedrooms: number
      number_of_bathrooms: number
      metadata: any
      label: string
      webview_preview_url: string
      order?: any | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      photos: Array<{
        __typename?: 'apartment_type_photo'
        id: any
        path: string
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }>
    } | null
    building: {
      __typename?: 'building'
      id: any
      name: string
      description: string
      number_of_floors: number
      number_of_apartments: number
      ip_address?: string | null
      geolocation?: any | null
      status?: Building_Status_Enum | null
      profile_photo: string
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }
    users: Array<{
      __typename?: 'user_apartment'
      id: any
      user: {
        __typename?: 'user'
        id: any
        firebase_id: string
        role: User_Role_Enum
        first_name?: string | null
        last_name?: string | null
        id_number?: string | null
        birth_date?: any | null
        id_number_type?: User_Id_Type_Enum | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }
    }>
  } | null
  addresses: Array<{
    __typename?: 'user_application_address'
    id: any
    street_address: string
    apt: string
    state: string
    city: string
    zip: string
    reference_contact_number: string
    time_of_living: number
    monthly_rent: number
    reason_of_moving: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
  }>
  incomes: Array<{
    __typename?: 'user_application_income'
    id: any
    status: string
    job_title: string
    employer: string
    employer_since: any
    monthly_income: number
    employer_contact_name: string
    employer_contact_email?: string | null
    employer_contact_phone_number?: string | null
    additional_information?: string | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
  }>
  income_documents: Array<{
    __typename?: 'user_application_income_document'
    id: any
    name: string
    size: number
    type: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
  }>
}

export type GetUserApplicationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<
    Array<User_Application_Select_Column> | User_Application_Select_Column
  >
  order_by?: InputMaybe<
    Array<User_Application_Order_By> | User_Application_Order_By
  >
  where?: InputMaybe<User_Application_Bool_Exp>
}>

export type GetUserApplicationsQuery = {
  __typename?: 'query_root'
  userApplicationsAggregate: {
    __typename?: 'user_application_aggregate'
    aggregate?: {
      __typename?: 'user_application_aggregate_fields'
      totalCount: number
    } | null
  }
  userApplications: Array<{
    __typename?: 'user_application'
    id: any
    user_id: any
    building_id?: any | null
    apartment_id?: any | null
    status: User_Application_Status_Enum
    rental_type?: Apartment_Rental_Type_Enum | null
    check_in_date?: any | null
    check_out_date?: any | null
    number_of_people?: number | null
    small_dogs?: number | null
    medium_dogs?: number | null
    large_dogs?: number | null
    cats?: number | null
    other_pets?: number | null
    pet_details?: string | null
    assistant_animal?: boolean | null
    vehicles?: boolean | null
    car_make?: string | null
    car_model?: string | null
    car_registered_in?: string | null
    license_plate_number?: string | null
    car_sharing?: boolean | null
    screening_report_copy?: boolean | null
    experian_credit_report_terms?: boolean | null
    experian_privacy_policy?: boolean | null
    cic_bg_terms?: boolean | null
    cic_copy?: boolean | null
    first_name?: string | null
    middle_name?: string | null
    last_name?: string | null
    birth_date?: any | null
    social_security_number?: string | null
    id_number?: string | null
    type_of_government_id?: User_Id_Type_Enum | null
    evicted_by_court_order?: boolean | null
    evicted_by_court_order_explanation?: string | null
    convicted_of_a_felony?: boolean | null
    convicted_of_a_felony_explanation?: string | null
    defendant_civil_landlord_or_criminal_case?: boolean | null
    defendant_civil_landlord_or_criminal_case_explanation?: string | null
    agree_terms_and_conditions?: boolean | null
    agree_screened_after_payment?: boolean | null
    sms_notification?: boolean | null
    signature?: string | null
    plaid_income_connected?: boolean | null
    plaid_idv_session_id?: string | null
    understand_rental_insurance?: boolean | null
    help_finding_rental_company?: boolean | null
    information_accurate?: boolean | null
    accept_terms_and_conditions?: boolean | null
    terms_signature?: string | null
    agreement_signature?: string | null
    sms_notification_lease_accepted?: boolean | null
    inspect_myself_checklist?: boolean | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    user: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      id_number?: string | null
      birth_date?: any | null
      id_number_type?: User_Id_Type_Enum | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }
    apartment?: {
      __typename?: 'apartment'
      id: any
      name: string
      description: string
      floor_number: string
      apartment_number: string
      rental_type: Apartment_Rental_Type_Enum
      ip_address?: string | null
      monthly_rent?: number | null
      security_deposit?: number | null
      profile_photo?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      type?: {
        __typename?: 'apartment_type'
        id: any
        value: string
        comments: string
        number_of_bedrooms: number
        number_of_bathrooms: number
        metadata: any
        label: string
        webview_preview_url: string
        order?: any | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
        photos: Array<{
          __typename?: 'apartment_type_photo'
          id: any
          path: string
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }>
      } | null
      building: {
        __typename?: 'building'
        id: any
        name: string
        description: string
        number_of_floors: number
        number_of_apartments: number
        ip_address?: string | null
        geolocation?: any | null
        status?: Building_Status_Enum | null
        profile_photo: string
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }
      users: Array<{
        __typename?: 'user_apartment'
        id: any
        user: {
          __typename?: 'user'
          id: any
          firebase_id: string
          role: User_Role_Enum
          first_name?: string | null
          last_name?: string | null
          id_number?: string | null
          birth_date?: any | null
          id_number_type?: User_Id_Type_Enum | null
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }
      }>
    } | null
    addresses: Array<{
      __typename?: 'user_application_address'
      id: any
      street_address: string
      apt: string
      state: string
      city: string
      zip: string
      reference_contact_number: string
      time_of_living: number
      monthly_rent: number
      reason_of_moving: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }>
    incomes: Array<{
      __typename?: 'user_application_income'
      id: any
      status: string
      job_title: string
      employer: string
      employer_since: any
      monthly_income: number
      employer_contact_name: string
      employer_contact_email?: string | null
      employer_contact_phone_number?: string | null
      additional_information?: string | null
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }>
    income_documents: Array<{
      __typename?: 'user_application_income_document'
      id: any
      name: string
      size: number
      type: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }>
  }>
}

export type GetUserApplicationQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type GetUserApplicationQuery = {
  __typename?: 'query_root'
  userApplication?: {
    __typename?: 'user_application'
    id: any
    user_id: any
    building_id?: any | null
    apartment_id?: any | null
    status: User_Application_Status_Enum
    rental_type?: Apartment_Rental_Type_Enum | null
    check_in_date?: any | null
    check_out_date?: any | null
    number_of_people?: number | null
    small_dogs?: number | null
    medium_dogs?: number | null
    large_dogs?: number | null
    cats?: number | null
    other_pets?: number | null
    pet_details?: string | null
    assistant_animal?: boolean | null
    vehicles?: boolean | null
    car_make?: string | null
    car_model?: string | null
    car_registered_in?: string | null
    license_plate_number?: string | null
    car_sharing?: boolean | null
    screening_report_copy?: boolean | null
    experian_credit_report_terms?: boolean | null
    experian_privacy_policy?: boolean | null
    cic_bg_terms?: boolean | null
    cic_copy?: boolean | null
    first_name?: string | null
    middle_name?: string | null
    last_name?: string | null
    birth_date?: any | null
    social_security_number?: string | null
    id_number?: string | null
    type_of_government_id?: User_Id_Type_Enum | null
    evicted_by_court_order?: boolean | null
    evicted_by_court_order_explanation?: string | null
    convicted_of_a_felony?: boolean | null
    convicted_of_a_felony_explanation?: string | null
    defendant_civil_landlord_or_criminal_case?: boolean | null
    defendant_civil_landlord_or_criminal_case_explanation?: string | null
    agree_terms_and_conditions?: boolean | null
    agree_screened_after_payment?: boolean | null
    sms_notification?: boolean | null
    signature?: string | null
    plaid_income_connected?: boolean | null
    plaid_idv_session_id?: string | null
    understand_rental_insurance?: boolean | null
    help_finding_rental_company?: boolean | null
    information_accurate?: boolean | null
    accept_terms_and_conditions?: boolean | null
    terms_signature?: string | null
    agreement_signature?: string | null
    sms_notification_lease_accepted?: boolean | null
    inspect_myself_checklist?: boolean | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    user: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      id_number?: string | null
      birth_date?: any | null
      id_number_type?: User_Id_Type_Enum | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }
    apartment?: {
      __typename?: 'apartment'
      id: any
      name: string
      description: string
      floor_number: string
      apartment_number: string
      rental_type: Apartment_Rental_Type_Enum
      ip_address?: string | null
      monthly_rent?: number | null
      security_deposit?: number | null
      profile_photo?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      type?: {
        __typename?: 'apartment_type'
        id: any
        value: string
        comments: string
        number_of_bedrooms: number
        number_of_bathrooms: number
        metadata: any
        label: string
        webview_preview_url: string
        order?: any | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
        photos: Array<{
          __typename?: 'apartment_type_photo'
          id: any
          path: string
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }>
      } | null
      building: {
        __typename?: 'building'
        id: any
        name: string
        description: string
        number_of_floors: number
        number_of_apartments: number
        ip_address?: string | null
        geolocation?: any | null
        status?: Building_Status_Enum | null
        profile_photo: string
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }
      users: Array<{
        __typename?: 'user_apartment'
        id: any
        user: {
          __typename?: 'user'
          id: any
          firebase_id: string
          role: User_Role_Enum
          first_name?: string | null
          last_name?: string | null
          id_number?: string | null
          birth_date?: any | null
          id_number_type?: User_Id_Type_Enum | null
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }
      }>
    } | null
    addresses: Array<{
      __typename?: 'user_application_address'
      id: any
      street_address: string
      apt: string
      state: string
      city: string
      zip: string
      reference_contact_number: string
      time_of_living: number
      monthly_rent: number
      reason_of_moving: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }>
    incomes: Array<{
      __typename?: 'user_application_income'
      id: any
      status: string
      job_title: string
      employer: string
      employer_since: any
      monthly_income: number
      employer_contact_name: string
      employer_contact_email?: string | null
      employer_contact_phone_number?: string | null
      additional_information?: string | null
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }>
    income_documents: Array<{
      __typename?: 'user_application_income_document'
      id: any
      name: string
      size: number
      type: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }>
  } | null
}

export type UpdateUserApplicationMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  _set: User_Application_Set_Input
}>

export type UpdateUserApplicationMutation = {
  __typename?: 'mutation_root'
  userApplication?: {
    __typename?: 'user_application'
    id: any
    user_id: any
    building_id?: any | null
    apartment_id?: any | null
    status: User_Application_Status_Enum
    rental_type?: Apartment_Rental_Type_Enum | null
    check_in_date?: any | null
    check_out_date?: any | null
    number_of_people?: number | null
    small_dogs?: number | null
    medium_dogs?: number | null
    large_dogs?: number | null
    cats?: number | null
    other_pets?: number | null
    pet_details?: string | null
    assistant_animal?: boolean | null
    vehicles?: boolean | null
    car_make?: string | null
    car_model?: string | null
    car_registered_in?: string | null
    license_plate_number?: string | null
    car_sharing?: boolean | null
    screening_report_copy?: boolean | null
    experian_credit_report_terms?: boolean | null
    experian_privacy_policy?: boolean | null
    cic_bg_terms?: boolean | null
    cic_copy?: boolean | null
    first_name?: string | null
    middle_name?: string | null
    last_name?: string | null
    birth_date?: any | null
    social_security_number?: string | null
    id_number?: string | null
    type_of_government_id?: User_Id_Type_Enum | null
    evicted_by_court_order?: boolean | null
    evicted_by_court_order_explanation?: string | null
    convicted_of_a_felony?: boolean | null
    convicted_of_a_felony_explanation?: string | null
    defendant_civil_landlord_or_criminal_case?: boolean | null
    defendant_civil_landlord_or_criminal_case_explanation?: string | null
    agree_terms_and_conditions?: boolean | null
    agree_screened_after_payment?: boolean | null
    sms_notification?: boolean | null
    signature?: string | null
    plaid_income_connected?: boolean | null
    plaid_idv_session_id?: string | null
    understand_rental_insurance?: boolean | null
    help_finding_rental_company?: boolean | null
    information_accurate?: boolean | null
    accept_terms_and_conditions?: boolean | null
    terms_signature?: string | null
    agreement_signature?: string | null
    sms_notification_lease_accepted?: boolean | null
    inspect_myself_checklist?: boolean | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    user: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      id_number?: string | null
      birth_date?: any | null
      id_number_type?: User_Id_Type_Enum | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }
    apartment?: {
      __typename?: 'apartment'
      id: any
      name: string
      description: string
      floor_number: string
      apartment_number: string
      rental_type: Apartment_Rental_Type_Enum
      ip_address?: string | null
      monthly_rent?: number | null
      security_deposit?: number | null
      profile_photo?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      type?: {
        __typename?: 'apartment_type'
        id: any
        value: string
        comments: string
        number_of_bedrooms: number
        number_of_bathrooms: number
        metadata: any
        label: string
        webview_preview_url: string
        order?: any | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
        photos: Array<{
          __typename?: 'apartment_type_photo'
          id: any
          path: string
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }>
      } | null
      building: {
        __typename?: 'building'
        id: any
        name: string
        description: string
        number_of_floors: number
        number_of_apartments: number
        ip_address?: string | null
        geolocation?: any | null
        status?: Building_Status_Enum | null
        profile_photo: string
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }
      users: Array<{
        __typename?: 'user_apartment'
        id: any
        user: {
          __typename?: 'user'
          id: any
          firebase_id: string
          role: User_Role_Enum
          first_name?: string | null
          last_name?: string | null
          id_number?: string | null
          birth_date?: any | null
          id_number_type?: User_Id_Type_Enum | null
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }
      }>
    } | null
    addresses: Array<{
      __typename?: 'user_application_address'
      id: any
      street_address: string
      apt: string
      state: string
      city: string
      zip: string
      reference_contact_number: string
      time_of_living: number
      monthly_rent: number
      reason_of_moving: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }>
    incomes: Array<{
      __typename?: 'user_application_income'
      id: any
      status: string
      job_title: string
      employer: string
      employer_since: any
      monthly_income: number
      employer_contact_name: string
      employer_contact_email?: string | null
      employer_contact_phone_number?: string | null
      additional_information?: string | null
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }>
    income_documents: Array<{
      __typename?: 'user_application_income_document'
      id: any
      name: string
      size: number
      type: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }>
  } | null
}

export type InsertUserApplicationMutationVariables = Exact<{
  object: User_Application_Insert_Input
}>

export type InsertUserApplicationMutation = {
  __typename?: 'mutation_root'
  userApplication?: {
    __typename?: 'user_application'
    id: any
    user_id: any
    building_id?: any | null
    apartment_id?: any | null
    status: User_Application_Status_Enum
    rental_type?: Apartment_Rental_Type_Enum | null
    check_in_date?: any | null
    check_out_date?: any | null
    number_of_people?: number | null
    small_dogs?: number | null
    medium_dogs?: number | null
    large_dogs?: number | null
    cats?: number | null
    other_pets?: number | null
    pet_details?: string | null
    assistant_animal?: boolean | null
    vehicles?: boolean | null
    car_make?: string | null
    car_model?: string | null
    car_registered_in?: string | null
    license_plate_number?: string | null
    car_sharing?: boolean | null
    screening_report_copy?: boolean | null
    experian_credit_report_terms?: boolean | null
    experian_privacy_policy?: boolean | null
    cic_bg_terms?: boolean | null
    cic_copy?: boolean | null
    first_name?: string | null
    middle_name?: string | null
    last_name?: string | null
    birth_date?: any | null
    social_security_number?: string | null
    id_number?: string | null
    type_of_government_id?: User_Id_Type_Enum | null
    evicted_by_court_order?: boolean | null
    evicted_by_court_order_explanation?: string | null
    convicted_of_a_felony?: boolean | null
    convicted_of_a_felony_explanation?: string | null
    defendant_civil_landlord_or_criminal_case?: boolean | null
    defendant_civil_landlord_or_criminal_case_explanation?: string | null
    agree_terms_and_conditions?: boolean | null
    agree_screened_after_payment?: boolean | null
    sms_notification?: boolean | null
    signature?: string | null
    plaid_income_connected?: boolean | null
    plaid_idv_session_id?: string | null
    understand_rental_insurance?: boolean | null
    help_finding_rental_company?: boolean | null
    information_accurate?: boolean | null
    accept_terms_and_conditions?: boolean | null
    terms_signature?: string | null
    agreement_signature?: string | null
    sms_notification_lease_accepted?: boolean | null
    inspect_myself_checklist?: boolean | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    user: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      id_number?: string | null
      birth_date?: any | null
      id_number_type?: User_Id_Type_Enum | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
    }
    apartment?: {
      __typename?: 'apartment'
      id: any
      name: string
      description: string
      floor_number: string
      apartment_number: string
      rental_type: Apartment_Rental_Type_Enum
      ip_address?: string | null
      monthly_rent?: number | null
      security_deposit?: number | null
      profile_photo?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      type?: {
        __typename?: 'apartment_type'
        id: any
        value: string
        comments: string
        number_of_bedrooms: number
        number_of_bathrooms: number
        metadata: any
        label: string
        webview_preview_url: string
        order?: any | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
        photos: Array<{
          __typename?: 'apartment_type_photo'
          id: any
          path: string
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }>
      } | null
      building: {
        __typename?: 'building'
        id: any
        name: string
        description: string
        number_of_floors: number
        number_of_apartments: number
        ip_address?: string | null
        geolocation?: any | null
        status?: Building_Status_Enum | null
        profile_photo: string
        deleted_at?: any | null
        updated_at: any
        created_at: any
      }
      users: Array<{
        __typename?: 'user_apartment'
        id: any
        user: {
          __typename?: 'user'
          id: any
          firebase_id: string
          role: User_Role_Enum
          first_name?: string | null
          last_name?: string | null
          id_number?: string | null
          birth_date?: any | null
          id_number_type?: User_Id_Type_Enum | null
          deleted_at?: any | null
          updated_at: any
          created_at: any
        }
      }>
    } | null
    addresses: Array<{
      __typename?: 'user_application_address'
      id: any
      street_address: string
      apt: string
      state: string
      city: string
      zip: string
      reference_contact_number: string
      time_of_living: number
      monthly_rent: number
      reason_of_moving: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }>
    incomes: Array<{
      __typename?: 'user_application_income'
      id: any
      status: string
      job_title: string
      employer: string
      employer_since: any
      monthly_income: number
      employer_contact_name: string
      employer_contact_email?: string | null
      employer_contact_phone_number?: string | null
      additional_information?: string | null
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }>
    income_documents: Array<{
      __typename?: 'user_application_income_document'
      id: any
      name: string
      size: number
      type: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }>
  } | null
}

export const BaseDeviceFragmentFragmentDoc = gql`
  fragment BaseDeviceFragment on device {
    id
    name
    type
    apartment_id
    building_id
    group
    ip_address
    port
    status
    metadata
    zone
    bridge_id
    state
    value
    deleted_at
    updated_at
    created_at
  }
`
export const ApartmentTypePhotoFragmentFragmentDoc = gql`
  fragment ApartmentTypePhotoFragment on apartment_type_photo {
    id
    path
    deleted_at
    updated_at
    created_at
  }
`
export const ApartmentTypeFragmentFragmentDoc = gql`
  fragment ApartmentTypeFragment on apartment_type {
    id
    value
    comments
    number_of_bedrooms
    number_of_bathrooms
    metadata
    label
    webview_preview_url
    order
    deleted_at
    updated_at
    created_at
    photos {
      ...ApartmentTypePhotoFragment
    }
  }
  ${ApartmentTypePhotoFragmentFragmentDoc}
`
export const BuildingFragmentFragmentDoc = gql`
  fragment BuildingFragment on building {
    id
    name
    description
    number_of_floors
    number_of_apartments
    ip_address
    geolocation
    status
    profile_photo
    deleted_at
    updated_at
    created_at
  }
`
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on user {
    id
    firebase_id
    role
    first_name
    last_name
    id_number
    birth_date
    id_number_type
    deleted_at
    updated_at
    created_at
  }
`
export const ApartmentFragmentFragmentDoc = gql`
  fragment ApartmentFragment on apartment {
    id
    name
    description
    floor_number
    apartment_number
    rental_type
    ip_address
    monthly_rent
    security_deposit
    profile_photo
    deleted_at
    updated_at
    created_at
    type {
      ...ApartmentTypeFragment
    }
    building {
      ...BuildingFragment
    }
    users(where: { status: { _eq: ACTIVE } }) {
      id
      user {
        ...UserFragment
      }
    }
  }
  ${ApartmentTypeFragmentFragmentDoc}
  ${BuildingFragmentFragmentDoc}
  ${UserFragmentFragmentDoc}
`
export const DeviceFragmentFragmentDoc = gql`
  fragment DeviceFragment on device {
    ...BaseDeviceFragment
    apartment {
      ...ApartmentFragment
    }
    building {
      ...BuildingFragment
    }
    bridge {
      ...BaseDeviceFragment
    }
  }
  ${BaseDeviceFragmentFragmentDoc}
  ${ApartmentFragmentFragmentDoc}
  ${BuildingFragmentFragmentDoc}
`
export const SupportTicketPhotoFragmentFragmentDoc = gql`
  fragment SupportTicketPhotoFragment on support_ticket_photo {
    id
    path
    deleted_at
    updated_at
    created_at
  }
`
export const SupportTicketFragmentFragmentDoc = gql`
  fragment SupportTicketFragment on support_ticket {
    id
    type
    status
    message
    image
    deleted_at
    updated_at
    created_at
    user {
      id
      first_name
      last_name
    }
    photos {
      ...SupportTicketPhotoFragment
    }
  }
  ${SupportTicketPhotoFragmentFragmentDoc}
`
export const UserApplicationAddressFragmentFragmentDoc = gql`
  fragment UserApplicationAddressFragment on user_application_address {
    id
    street_address
    apt
    state
    city
    zip
    reference_contact_number
    time_of_living
    monthly_rent
    reason_of_moving
    created_at
    updated_at
    deleted_at
  }
`
export const UserApplicationIncomeFragmentFragmentDoc = gql`
  fragment UserApplicationIncomeFragment on user_application_income {
    id
    status
    job_title
    employer
    employer_since
    monthly_income
    employer_contact_name
    employer_contact_email
    employer_contact_phone_number
    additional_information
    created_at
    updated_at
    deleted_at
  }
`
export const UserApplicationIncomeDocumentFragmentFragmentDoc = gql`
  fragment UserApplicationIncomeDocumentFragment on user_application_income_document {
    id
    name
    size
    type
    created_at
    updated_at
    deleted_at
  }
`
export const UserApplicationFragmentFragmentDoc = gql`
  fragment UserApplicationFragment on user_application {
    id
    user_id
    building_id
    apartment_id
    status
    rental_type
    check_in_date
    check_out_date
    number_of_people
    small_dogs
    medium_dogs
    large_dogs
    cats
    other_pets
    pet_details
    assistant_animal
    vehicles
    car_make
    car_model
    car_registered_in
    license_plate_number
    car_sharing
    screening_report_copy
    experian_credit_report_terms
    experian_privacy_policy
    cic_bg_terms
    cic_copy
    first_name
    middle_name
    last_name
    birth_date
    social_security_number
    id_number
    type_of_government_id
    evicted_by_court_order
    evicted_by_court_order_explanation
    convicted_of_a_felony
    convicted_of_a_felony_explanation
    defendant_civil_landlord_or_criminal_case
    defendant_civil_landlord_or_criminal_case_explanation
    agree_terms_and_conditions
    agree_screened_after_payment
    sms_notification
    signature
    plaid_income_connected
    plaid_idv_session_id
    understand_rental_insurance
    help_finding_rental_company
    information_accurate
    accept_terms_and_conditions
    terms_signature
    agreement_signature
    sms_notification_lease_accepted
    inspect_myself_checklist
    created_at
    updated_at
    deleted_at
    user {
      ...UserFragment
    }
    apartment {
      ...ApartmentFragment
    }
    addresses {
      ...UserApplicationAddressFragment
    }
    incomes {
      ...UserApplicationIncomeFragment
    }
    income_documents {
      ...UserApplicationIncomeDocumentFragment
    }
  }
  ${UserFragmentFragmentDoc}
  ${ApartmentFragmentFragmentDoc}
  ${UserApplicationAddressFragmentFragmentDoc}
  ${UserApplicationIncomeFragmentFragmentDoc}
  ${UserApplicationIncomeDocumentFragmentFragmentDoc}
`
export const GetApartmentsDocument = gql`
  query getApartments(
    $limit: Int
    $offset: Int
    $distinct_on: [apartment_select_column!]
    $order_by: [apartment_order_by!]
    $where: apartment_bool_exp
  ) {
    apartmentsAggregate: apartment_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    apartments: apartment(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...ApartmentFragment
    }
  }
  ${ApartmentFragmentFragmentDoc}
`

/**
 * __useGetApartmentsQuery__
 *
 * To run a query within a React component, call `useGetApartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApartmentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetApartmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetApartmentsQuery,
    GetApartmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetApartmentsQuery, GetApartmentsQueryVariables>(
    GetApartmentsDocument,
    options
  )
}
export function useGetApartmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApartmentsQuery,
    GetApartmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetApartmentsQuery, GetApartmentsQueryVariables>(
    GetApartmentsDocument,
    options
  )
}
export type GetApartmentsQueryHookResult = ReturnType<
  typeof useGetApartmentsQuery
>
export type GetApartmentsLazyQueryHookResult = ReturnType<
  typeof useGetApartmentsLazyQuery
>
export type GetApartmentsQueryResult = Apollo.QueryResult<
  GetApartmentsQuery,
  GetApartmentsQueryVariables
>
export const GetApartmentDocument = gql`
  query getApartment($id: uuid!) {
    apartment: apartment_by_pk(id: $id) {
      ...ApartmentFragment
    }
  }
  ${ApartmentFragmentFragmentDoc}
`

/**
 * __useGetApartmentQuery__
 *
 * To run a query within a React component, call `useGetApartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApartmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApartmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApartmentQuery,
    GetApartmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetApartmentQuery, GetApartmentQueryVariables>(
    GetApartmentDocument,
    options
  )
}
export function useGetApartmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApartmentQuery,
    GetApartmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetApartmentQuery, GetApartmentQueryVariables>(
    GetApartmentDocument,
    options
  )
}
export type GetApartmentQueryHookResult = ReturnType<
  typeof useGetApartmentQuery
>
export type GetApartmentLazyQueryHookResult = ReturnType<
  typeof useGetApartmentLazyQuery
>
export type GetApartmentQueryResult = Apollo.QueryResult<
  GetApartmentQuery,
  GetApartmentQueryVariables
>
export const UpdateApartmentDocument = gql`
  mutation updateApartment($id: uuid!, $_set: apartment_set_input!) {
    apartment: update_apartment_by_pk(pk_columns: { id: $id }, _set: $_set) {
      ...ApartmentFragment
    }
  }
  ${ApartmentFragmentFragmentDoc}
`
export type UpdateApartmentMutationFn = Apollo.MutationFunction<
  UpdateApartmentMutation,
  UpdateApartmentMutationVariables
>

/**
 * __useUpdateApartmentMutation__
 *
 * To run a mutation, you first call `useUpdateApartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApartmentMutation, { data, loading, error }] = useUpdateApartmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateApartmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApartmentMutation,
    UpdateApartmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateApartmentMutation,
    UpdateApartmentMutationVariables
  >(UpdateApartmentDocument, options)
}
export type UpdateApartmentMutationHookResult = ReturnType<
  typeof useUpdateApartmentMutation
>
export type UpdateApartmentMutationResult =
  Apollo.MutationResult<UpdateApartmentMutation>
export type UpdateApartmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateApartmentMutation,
  UpdateApartmentMutationVariables
>
export const InsertApartmentDocument = gql`
  mutation insertApartment($object: apartment_insert_input!) {
    apartment: insert_apartment_one(object: $object) {
      ...ApartmentFragment
    }
  }
  ${ApartmentFragmentFragmentDoc}
`
export type InsertApartmentMutationFn = Apollo.MutationFunction<
  InsertApartmentMutation,
  InsertApartmentMutationVariables
>

/**
 * __useInsertApartmentMutation__
 *
 * To run a mutation, you first call `useInsertApartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertApartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertApartmentMutation, { data, loading, error }] = useInsertApartmentMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertApartmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertApartmentMutation,
    InsertApartmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertApartmentMutation,
    InsertApartmentMutationVariables
  >(InsertApartmentDocument, options)
}
export type InsertApartmentMutationHookResult = ReturnType<
  typeof useInsertApartmentMutation
>
export type InsertApartmentMutationResult =
  Apollo.MutationResult<InsertApartmentMutation>
export type InsertApartmentMutationOptions = Apollo.BaseMutationOptions<
  InsertApartmentMutation,
  InsertApartmentMutationVariables
>
export const UpsertApartmentDocument = gql`
  mutation upsertApartment(
    $object: apartment_insert_input!
    $on_conflict: apartment_on_conflict!
  ) {
    apartment: insert_apartment_one(
      object: $object
      on_conflict: $on_conflict
    ) {
      ...ApartmentFragment
    }
  }
  ${ApartmentFragmentFragmentDoc}
`
export type UpsertApartmentMutationFn = Apollo.MutationFunction<
  UpsertApartmentMutation,
  UpsertApartmentMutationVariables
>

/**
 * __useUpsertApartmentMutation__
 *
 * To run a mutation, you first call `useUpsertApartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertApartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertApartmentMutation, { data, loading, error }] = useUpsertApartmentMutation({
 *   variables: {
 *      object: // value for 'object'
 *      on_conflict: // value for 'on_conflict'
 *   },
 * });
 */
export function useUpsertApartmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertApartmentMutation,
    UpsertApartmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertApartmentMutation,
    UpsertApartmentMutationVariables
  >(UpsertApartmentDocument, options)
}
export type UpsertApartmentMutationHookResult = ReturnType<
  typeof useUpsertApartmentMutation
>
export type UpsertApartmentMutationResult =
  Apollo.MutationResult<UpsertApartmentMutation>
export type UpsertApartmentMutationOptions = Apollo.BaseMutationOptions<
  UpsertApartmentMutation,
  UpsertApartmentMutationVariables
>
export const GetApartmentDictionariesDocument = gql`
  query getApartmentDictionaries {
    buildings: building(where: { deleted_at: { _is_null: true } }) {
      id
      name
    }
    apartmentTypes: apartment_type(where: { deleted_at: { _is_null: true } }) {
      id
      value
    }
  }
`

/**
 * __useGetApartmentDictionariesQuery__
 *
 * To run a query within a React component, call `useGetApartmentDictionariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApartmentDictionariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApartmentDictionariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApartmentDictionariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetApartmentDictionariesQuery,
    GetApartmentDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetApartmentDictionariesQuery,
    GetApartmentDictionariesQueryVariables
  >(GetApartmentDictionariesDocument, options)
}
export function useGetApartmentDictionariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApartmentDictionariesQuery,
    GetApartmentDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetApartmentDictionariesQuery,
    GetApartmentDictionariesQueryVariables
  >(GetApartmentDictionariesDocument, options)
}
export type GetApartmentDictionariesQueryHookResult = ReturnType<
  typeof useGetApartmentDictionariesQuery
>
export type GetApartmentDictionariesLazyQueryHookResult = ReturnType<
  typeof useGetApartmentDictionariesLazyQuery
>
export type GetApartmentDictionariesQueryResult = Apollo.QueryResult<
  GetApartmentDictionariesQuery,
  GetApartmentDictionariesQueryVariables
>
export const GetApartmentTypesDocument = gql`
  query getApartmentTypes(
    $limit: Int
    $offset: Int
    $distinct_on: [apartment_type_select_column!]
    $order_by: [apartment_type_order_by!]
    $where: apartment_type_bool_exp
  ) {
    apartmentTypesAggregate: apartment_type_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    apartmentTypes: apartment_type(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...ApartmentTypeFragment
    }
  }
  ${ApartmentTypeFragmentFragmentDoc}
`

/**
 * __useGetApartmentTypesQuery__
 *
 * To run a query within a React component, call `useGetApartmentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApartmentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApartmentTypesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetApartmentTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetApartmentTypesQuery,
    GetApartmentTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetApartmentTypesQuery,
    GetApartmentTypesQueryVariables
  >(GetApartmentTypesDocument, options)
}
export function useGetApartmentTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApartmentTypesQuery,
    GetApartmentTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetApartmentTypesQuery,
    GetApartmentTypesQueryVariables
  >(GetApartmentTypesDocument, options)
}
export type GetApartmentTypesQueryHookResult = ReturnType<
  typeof useGetApartmentTypesQuery
>
export type GetApartmentTypesLazyQueryHookResult = ReturnType<
  typeof useGetApartmentTypesLazyQuery
>
export type GetApartmentTypesQueryResult = Apollo.QueryResult<
  GetApartmentTypesQuery,
  GetApartmentTypesQueryVariables
>
export const GetApartmentTypeDocument = gql`
  query getApartmentType($id: uuid!) {
    apartmentType: apartment_type_by_pk(id: $id) {
      ...ApartmentTypeFragment
    }
  }
  ${ApartmentTypeFragmentFragmentDoc}
`

/**
 * __useGetApartmentTypeQuery__
 *
 * To run a query within a React component, call `useGetApartmentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApartmentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApartmentTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApartmentTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApartmentTypeQuery,
    GetApartmentTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetApartmentTypeQuery, GetApartmentTypeQueryVariables>(
    GetApartmentTypeDocument,
    options
  )
}
export function useGetApartmentTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApartmentTypeQuery,
    GetApartmentTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetApartmentTypeQuery,
    GetApartmentTypeQueryVariables
  >(GetApartmentTypeDocument, options)
}
export type GetApartmentTypeQueryHookResult = ReturnType<
  typeof useGetApartmentTypeQuery
>
export type GetApartmentTypeLazyQueryHookResult = ReturnType<
  typeof useGetApartmentTypeLazyQuery
>
export type GetApartmentTypeQueryResult = Apollo.QueryResult<
  GetApartmentTypeQuery,
  GetApartmentTypeQueryVariables
>
export const UpdateApartmentTypeDocument = gql`
  mutation updateApartmentType($id: uuid!, $_set: apartment_type_set_input!) {
    apartmentType: update_apartment_type_by_pk(
      pk_columns: { id: $id }
      _set: $_set
    ) {
      ...ApartmentTypeFragment
    }
  }
  ${ApartmentTypeFragmentFragmentDoc}
`
export type UpdateApartmentTypeMutationFn = Apollo.MutationFunction<
  UpdateApartmentTypeMutation,
  UpdateApartmentTypeMutationVariables
>

/**
 * __useUpdateApartmentTypeMutation__
 *
 * To run a mutation, you first call `useUpdateApartmentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApartmentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApartmentTypeMutation, { data, loading, error }] = useUpdateApartmentTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateApartmentTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApartmentTypeMutation,
    UpdateApartmentTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateApartmentTypeMutation,
    UpdateApartmentTypeMutationVariables
  >(UpdateApartmentTypeDocument, options)
}
export type UpdateApartmentTypeMutationHookResult = ReturnType<
  typeof useUpdateApartmentTypeMutation
>
export type UpdateApartmentTypeMutationResult =
  Apollo.MutationResult<UpdateApartmentTypeMutation>
export type UpdateApartmentTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateApartmentTypeMutation,
  UpdateApartmentTypeMutationVariables
>
export const InsertApartmentTypeDocument = gql`
  mutation insertApartmentType($object: apartment_type_insert_input!) {
    apartmentType: insert_apartment_type_one(object: $object) {
      ...ApartmentTypeFragment
    }
  }
  ${ApartmentTypeFragmentFragmentDoc}
`
export type InsertApartmentTypeMutationFn = Apollo.MutationFunction<
  InsertApartmentTypeMutation,
  InsertApartmentTypeMutationVariables
>

/**
 * __useInsertApartmentTypeMutation__
 *
 * To run a mutation, you first call `useInsertApartmentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertApartmentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertApartmentTypeMutation, { data, loading, error }] = useInsertApartmentTypeMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertApartmentTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertApartmentTypeMutation,
    InsertApartmentTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertApartmentTypeMutation,
    InsertApartmentTypeMutationVariables
  >(InsertApartmentTypeDocument, options)
}
export type InsertApartmentTypeMutationHookResult = ReturnType<
  typeof useInsertApartmentTypeMutation
>
export type InsertApartmentTypeMutationResult =
  Apollo.MutationResult<InsertApartmentTypeMutation>
export type InsertApartmentTypeMutationOptions = Apollo.BaseMutationOptions<
  InsertApartmentTypeMutation,
  InsertApartmentTypeMutationVariables
>
export const GetBuildingsDocument = gql`
  query getBuildings(
    $limit: Int
    $offset: Int
    $distinct_on: [building_select_column!]
    $order_by: [building_order_by!]
    $where: building_bool_exp
  ) {
    buildingsAggregate: building_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    buildings: building(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...BuildingFragment
    }
  }
  ${BuildingFragmentFragmentDoc}
`

/**
 * __useGetBuildingsQuery__
 *
 * To run a query within a React component, call `useGetBuildingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBuildingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBuildingsQuery,
    GetBuildingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBuildingsQuery, GetBuildingsQueryVariables>(
    GetBuildingsDocument,
    options
  )
}
export function useGetBuildingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBuildingsQuery,
    GetBuildingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBuildingsQuery, GetBuildingsQueryVariables>(
    GetBuildingsDocument,
    options
  )
}
export type GetBuildingsQueryHookResult = ReturnType<
  typeof useGetBuildingsQuery
>
export type GetBuildingsLazyQueryHookResult = ReturnType<
  typeof useGetBuildingsLazyQuery
>
export type GetBuildingsQueryResult = Apollo.QueryResult<
  GetBuildingsQuery,
  GetBuildingsQueryVariables
>
export const GetBuildingDocument = gql`
  query getBuilding($id: uuid!) {
    building: building_by_pk(id: $id) {
      ...BuildingFragment
    }
  }
  ${BuildingFragmentFragmentDoc}
`

/**
 * __useGetBuildingQuery__
 *
 * To run a query within a React component, call `useGetBuildingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBuildingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBuildingQuery,
    GetBuildingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBuildingQuery, GetBuildingQueryVariables>(
    GetBuildingDocument,
    options
  )
}
export function useGetBuildingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBuildingQuery,
    GetBuildingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBuildingQuery, GetBuildingQueryVariables>(
    GetBuildingDocument,
    options
  )
}
export type GetBuildingQueryHookResult = ReturnType<typeof useGetBuildingQuery>
export type GetBuildingLazyQueryHookResult = ReturnType<
  typeof useGetBuildingLazyQuery
>
export type GetBuildingQueryResult = Apollo.QueryResult<
  GetBuildingQuery,
  GetBuildingQueryVariables
>
export const UpdateBuildingDocument = gql`
  mutation updateBuilding($id: uuid!, $_set: building_set_input!) {
    building: update_building_by_pk(pk_columns: { id: $id }, _set: $_set) {
      ...BuildingFragment
    }
  }
  ${BuildingFragmentFragmentDoc}
`
export type UpdateBuildingMutationFn = Apollo.MutationFunction<
  UpdateBuildingMutation,
  UpdateBuildingMutationVariables
>

/**
 * __useUpdateBuildingMutation__
 *
 * To run a mutation, you first call `useUpdateBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildingMutation, { data, loading, error }] = useUpdateBuildingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateBuildingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBuildingMutation,
    UpdateBuildingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateBuildingMutation,
    UpdateBuildingMutationVariables
  >(UpdateBuildingDocument, options)
}
export type UpdateBuildingMutationHookResult = ReturnType<
  typeof useUpdateBuildingMutation
>
export type UpdateBuildingMutationResult =
  Apollo.MutationResult<UpdateBuildingMutation>
export type UpdateBuildingMutationOptions = Apollo.BaseMutationOptions<
  UpdateBuildingMutation,
  UpdateBuildingMutationVariables
>
export const InsertBuildingDocument = gql`
  mutation insertBuilding($object: building_insert_input!) {
    building: insert_building_one(object: $object) {
      ...BuildingFragment
    }
  }
  ${BuildingFragmentFragmentDoc}
`
export type InsertBuildingMutationFn = Apollo.MutationFunction<
  InsertBuildingMutation,
  InsertBuildingMutationVariables
>

/**
 * __useInsertBuildingMutation__
 *
 * To run a mutation, you first call `useInsertBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertBuildingMutation, { data, loading, error }] = useInsertBuildingMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertBuildingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertBuildingMutation,
    InsertBuildingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertBuildingMutation,
    InsertBuildingMutationVariables
  >(InsertBuildingDocument, options)
}
export type InsertBuildingMutationHookResult = ReturnType<
  typeof useInsertBuildingMutation
>
export type InsertBuildingMutationResult =
  Apollo.MutationResult<InsertBuildingMutation>
export type InsertBuildingMutationOptions = Apollo.BaseMutationOptions<
  InsertBuildingMutation,
  InsertBuildingMutationVariables
>
export const UpsertBuildingDocument = gql`
  mutation upsertBuilding(
    $object: building_insert_input!
    $on_conflict: building_on_conflict!
  ) {
    building: insert_building_one(object: $object, on_conflict: $on_conflict) {
      ...BuildingFragment
    }
  }
  ${BuildingFragmentFragmentDoc}
`
export type UpsertBuildingMutationFn = Apollo.MutationFunction<
  UpsertBuildingMutation,
  UpsertBuildingMutationVariables
>

/**
 * __useUpsertBuildingMutation__
 *
 * To run a mutation, you first call `useUpsertBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBuildingMutation, { data, loading, error }] = useUpsertBuildingMutation({
 *   variables: {
 *      object: // value for 'object'
 *      on_conflict: // value for 'on_conflict'
 *   },
 * });
 */
export function useUpsertBuildingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertBuildingMutation,
    UpsertBuildingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertBuildingMutation,
    UpsertBuildingMutationVariables
  >(UpsertBuildingDocument, options)
}
export type UpsertBuildingMutationHookResult = ReturnType<
  typeof useUpsertBuildingMutation
>
export type UpsertBuildingMutationResult =
  Apollo.MutationResult<UpsertBuildingMutation>
export type UpsertBuildingMutationOptions = Apollo.BaseMutationOptions<
  UpsertBuildingMutation,
  UpsertBuildingMutationVariables
>
export const GetDevicesDocument = gql`
  query getDevices(
    $limit: Int
    $offset: Int
    $distinct_on: [device_select_column!]
    $order_by: [device_order_by!]
    $where: device_bool_exp
  ) {
    devicesAggregate: device_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    devices: device(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...DeviceFragment
    }
  }
  ${DeviceFragmentFragmentDoc}
`

/**
 * __useGetDevicesQuery__
 *
 * To run a query within a React component, call `useGetDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDevicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDevicesQuery,
    GetDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDevicesQuery, GetDevicesQueryVariables>(
    GetDevicesDocument,
    options
  )
}
export function useGetDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDevicesQuery,
    GetDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDevicesQuery, GetDevicesQueryVariables>(
    GetDevicesDocument,
    options
  )
}
export type GetDevicesQueryHookResult = ReturnType<typeof useGetDevicesQuery>
export type GetDevicesLazyQueryHookResult = ReturnType<
  typeof useGetDevicesLazyQuery
>
export type GetDevicesQueryResult = Apollo.QueryResult<
  GetDevicesQuery,
  GetDevicesQueryVariables
>
export const GetDeviceDocument = gql`
  query getDevice($id: uuid!) {
    device: device_by_pk(id: $id) {
      ...DeviceFragment
    }
  }
  ${DeviceFragmentFragmentDoc}
`

/**
 * __useGetDeviceQuery__
 *
 * To run a query within a React component, call `useGetDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeviceQuery(
  baseOptions: Apollo.QueryHookOptions<GetDeviceQuery, GetDeviceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDeviceQuery, GetDeviceQueryVariables>(
    GetDeviceDocument,
    options
  )
}
export function useGetDeviceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeviceQuery,
    GetDeviceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDeviceQuery, GetDeviceQueryVariables>(
    GetDeviceDocument,
    options
  )
}
export type GetDeviceQueryHookResult = ReturnType<typeof useGetDeviceQuery>
export type GetDeviceLazyQueryHookResult = ReturnType<
  typeof useGetDeviceLazyQuery
>
export type GetDeviceQueryResult = Apollo.QueryResult<
  GetDeviceQuery,
  GetDeviceQueryVariables
>
export const UpdateDeviceDocument = gql`
  mutation updateDevice($id: uuid!, $_set: device_set_input!) {
    device: update_device_by_pk(pk_columns: { id: $id }, _set: $_set) {
      ...DeviceFragment
    }
  }
  ${DeviceFragmentFragmentDoc}
`
export type UpdateDeviceMutationFn = Apollo.MutationFunction<
  UpdateDeviceMutation,
  UpdateDeviceMutationVariables
>

/**
 * __useUpdateDeviceMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceMutation, { data, loading, error }] = useUpdateDeviceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDeviceMutation,
    UpdateDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDeviceMutation,
    UpdateDeviceMutationVariables
  >(UpdateDeviceDocument, options)
}
export type UpdateDeviceMutationHookResult = ReturnType<
  typeof useUpdateDeviceMutation
>
export type UpdateDeviceMutationResult =
  Apollo.MutationResult<UpdateDeviceMutation>
export type UpdateDeviceMutationOptions = Apollo.BaseMutationOptions<
  UpdateDeviceMutation,
  UpdateDeviceMutationVariables
>
export const InsertDeviceDocument = gql`
  mutation insertDevice($object: device_insert_input!) {
    device: insert_device_one(object: $object) {
      ...DeviceFragment
    }
  }
  ${DeviceFragmentFragmentDoc}
`
export type InsertDeviceMutationFn = Apollo.MutationFunction<
  InsertDeviceMutation,
  InsertDeviceMutationVariables
>

/**
 * __useInsertDeviceMutation__
 *
 * To run a mutation, you first call `useInsertDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDeviceMutation, { data, loading, error }] = useInsertDeviceMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertDeviceMutation,
    InsertDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertDeviceMutation,
    InsertDeviceMutationVariables
  >(InsertDeviceDocument, options)
}
export type InsertDeviceMutationHookResult = ReturnType<
  typeof useInsertDeviceMutation
>
export type InsertDeviceMutationResult =
  Apollo.MutationResult<InsertDeviceMutation>
export type InsertDeviceMutationOptions = Apollo.BaseMutationOptions<
  InsertDeviceMutation,
  InsertDeviceMutationVariables
>
export const UpsertDeviceDocument = gql`
  mutation upsertDevice(
    $object: device_insert_input!
    $on_conflict: device_on_conflict!
  ) {
    device: insert_device_one(object: $object, on_conflict: $on_conflict) {
      ...DeviceFragment
    }
  }
  ${DeviceFragmentFragmentDoc}
`
export type UpsertDeviceMutationFn = Apollo.MutationFunction<
  UpsertDeviceMutation,
  UpsertDeviceMutationVariables
>

/**
 * __useUpsertDeviceMutation__
 *
 * To run a mutation, you first call `useUpsertDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDeviceMutation, { data, loading, error }] = useUpsertDeviceMutation({
 *   variables: {
 *      object: // value for 'object'
 *      on_conflict: // value for 'on_conflict'
 *   },
 * });
 */
export function useUpsertDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertDeviceMutation,
    UpsertDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertDeviceMutation,
    UpsertDeviceMutationVariables
  >(UpsertDeviceDocument, options)
}
export type UpsertDeviceMutationHookResult = ReturnType<
  typeof useUpsertDeviceMutation
>
export type UpsertDeviceMutationResult =
  Apollo.MutationResult<UpsertDeviceMutation>
export type UpsertDeviceMutationOptions = Apollo.BaseMutationOptions<
  UpsertDeviceMutation,
  UpsertDeviceMutationVariables
>
export const GetDeviceDictionariesDocument = gql`
  query getDeviceDictionaries {
    apartments: apartment(where: { deleted_at: { _is_null: true } }) {
      id
      name
      building {
        id
        name
      }
    }
    buildings: building(where: { deleted_at: { _is_null: true } }) {
      id
      name
    }
  }
`

/**
 * __useGetDeviceDictionariesQuery__
 *
 * To run a query within a React component, call `useGetDeviceDictionariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceDictionariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceDictionariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeviceDictionariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDeviceDictionariesQuery,
    GetDeviceDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDeviceDictionariesQuery,
    GetDeviceDictionariesQueryVariables
  >(GetDeviceDictionariesDocument, options)
}
export function useGetDeviceDictionariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeviceDictionariesQuery,
    GetDeviceDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDeviceDictionariesQuery,
    GetDeviceDictionariesQueryVariables
  >(GetDeviceDictionariesDocument, options)
}
export type GetDeviceDictionariesQueryHookResult = ReturnType<
  typeof useGetDeviceDictionariesQuery
>
export type GetDeviceDictionariesLazyQueryHookResult = ReturnType<
  typeof useGetDeviceDictionariesLazyQuery
>
export type GetDeviceDictionariesQueryResult = Apollo.QueryResult<
  GetDeviceDictionariesQuery,
  GetDeviceDictionariesQueryVariables
>
export const GetSupportTicketsDocument = gql`
  query getSupportTickets(
    $limit: Int
    $offset: Int
    $distinct_on: [support_ticket_select_column!]
    $order_by: [support_ticket_order_by!]
    $where: support_ticket_bool_exp
  ) {
    supportTicketsAggregate: support_ticket_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    supportTickets: support_ticket(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...SupportTicketFragment
    }
  }
  ${SupportTicketFragmentFragmentDoc}
`

/**
 * __useGetSupportTicketsQuery__
 *
 * To run a query within a React component, call `useGetSupportTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportTicketsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSupportTicketsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSupportTicketsQuery,
    GetSupportTicketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSupportTicketsQuery,
    GetSupportTicketsQueryVariables
  >(GetSupportTicketsDocument, options)
}
export function useGetSupportTicketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupportTicketsQuery,
    GetSupportTicketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSupportTicketsQuery,
    GetSupportTicketsQueryVariables
  >(GetSupportTicketsDocument, options)
}
export type GetSupportTicketsQueryHookResult = ReturnType<
  typeof useGetSupportTicketsQuery
>
export type GetSupportTicketsLazyQueryHookResult = ReturnType<
  typeof useGetSupportTicketsLazyQuery
>
export type GetSupportTicketsQueryResult = Apollo.QueryResult<
  GetSupportTicketsQuery,
  GetSupportTicketsQueryVariables
>
export const GetSupportTicketDocument = gql`
  query getSupportTicket($id: uuid!) {
    supportTicket: support_ticket_by_pk(id: $id) {
      ...SupportTicketFragment
    }
  }
  ${SupportTicketFragmentFragmentDoc}
`

/**
 * __useGetSupportTicketQuery__
 *
 * To run a query within a React component, call `useGetSupportTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportTicketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSupportTicketQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSupportTicketQuery,
    GetSupportTicketQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSupportTicketQuery, GetSupportTicketQueryVariables>(
    GetSupportTicketDocument,
    options
  )
}
export function useGetSupportTicketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupportTicketQuery,
    GetSupportTicketQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSupportTicketQuery,
    GetSupportTicketQueryVariables
  >(GetSupportTicketDocument, options)
}
export type GetSupportTicketQueryHookResult = ReturnType<
  typeof useGetSupportTicketQuery
>
export type GetSupportTicketLazyQueryHookResult = ReturnType<
  typeof useGetSupportTicketLazyQuery
>
export type GetSupportTicketQueryResult = Apollo.QueryResult<
  GetSupportTicketQuery,
  GetSupportTicketQueryVariables
>
export const UpdateSupportTicketDocument = gql`
  mutation updateSupportTicket($id: uuid!, $_set: support_ticket_set_input!) {
    supportTicket: update_support_ticket_by_pk(
      pk_columns: { id: $id }
      _set: $_set
    ) {
      ...SupportTicketFragment
    }
  }
  ${SupportTicketFragmentFragmentDoc}
`
export type UpdateSupportTicketMutationFn = Apollo.MutationFunction<
  UpdateSupportTicketMutation,
  UpdateSupportTicketMutationVariables
>

/**
 * __useUpdateSupportTicketMutation__
 *
 * To run a mutation, you first call `useUpdateSupportTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupportTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupportTicketMutation, { data, loading, error }] = useUpdateSupportTicketMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateSupportTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSupportTicketMutation,
    UpdateSupportTicketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateSupportTicketMutation,
    UpdateSupportTicketMutationVariables
  >(UpdateSupportTicketDocument, options)
}
export type UpdateSupportTicketMutationHookResult = ReturnType<
  typeof useUpdateSupportTicketMutation
>
export type UpdateSupportTicketMutationResult =
  Apollo.MutationResult<UpdateSupportTicketMutation>
export type UpdateSupportTicketMutationOptions = Apollo.BaseMutationOptions<
  UpdateSupportTicketMutation,
  UpdateSupportTicketMutationVariables
>
export const InsertSupportTicketDocument = gql`
  mutation insertSupportTicket($object: support_ticket_insert_input!) {
    supportTicket: insert_support_ticket_one(object: $object) {
      ...SupportTicketFragment
    }
  }
  ${SupportTicketFragmentFragmentDoc}
`
export type InsertSupportTicketMutationFn = Apollo.MutationFunction<
  InsertSupportTicketMutation,
  InsertSupportTicketMutationVariables
>

/**
 * __useInsertSupportTicketMutation__
 *
 * To run a mutation, you first call `useInsertSupportTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSupportTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSupportTicketMutation, { data, loading, error }] = useInsertSupportTicketMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertSupportTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertSupportTicketMutation,
    InsertSupportTicketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertSupportTicketMutation,
    InsertSupportTicketMutationVariables
  >(InsertSupportTicketDocument, options)
}
export type InsertSupportTicketMutationHookResult = ReturnType<
  typeof useInsertSupportTicketMutation
>
export type InsertSupportTicketMutationResult =
  Apollo.MutationResult<InsertSupportTicketMutation>
export type InsertSupportTicketMutationOptions = Apollo.BaseMutationOptions<
  InsertSupportTicketMutation,
  InsertSupportTicketMutationVariables
>
export const GetUsersDocument = gql`
  query getUsers(
    $limit: Int
    $offset: Int
    $distinct_on: [user_select_column!]
    $order_by: [user_order_by!]
    $where: user_bool_exp
  ) {
    usersAggregate: user_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    users: user(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  )
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  )
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>
export type GetUsersLazyQueryHookResult = ReturnType<
  typeof useGetUsersLazyQuery
>
export type GetUsersQueryResult = Apollo.QueryResult<
  GetUsersQuery,
  GetUsersQueryVariables
>
export const GetUserByFirebaseIdDocument = gql`
  query getUserByFirebaseId($firebaseId: String) {
    user(where: { firebase_id: { _eq: $firebaseId } }) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`

/**
 * __useGetUserByFirebaseIdQuery__
 *
 * To run a query within a React component, call `useGetUserByFirebaseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByFirebaseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByFirebaseIdQuery({
 *   variables: {
 *      firebaseId: // value for 'firebaseId'
 *   },
 * });
 */
export function useGetUserByFirebaseIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserByFirebaseIdQuery,
    GetUserByFirebaseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserByFirebaseIdQuery,
    GetUserByFirebaseIdQueryVariables
  >(GetUserByFirebaseIdDocument, options)
}
export function useGetUserByFirebaseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserByFirebaseIdQuery,
    GetUserByFirebaseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserByFirebaseIdQuery,
    GetUserByFirebaseIdQueryVariables
  >(GetUserByFirebaseIdDocument, options)
}
export type GetUserByFirebaseIdQueryHookResult = ReturnType<
  typeof useGetUserByFirebaseIdQuery
>
export type GetUserByFirebaseIdLazyQueryHookResult = ReturnType<
  typeof useGetUserByFirebaseIdLazyQuery
>
export type GetUserByFirebaseIdQueryResult = Apollo.QueryResult<
  GetUserByFirebaseIdQuery,
  GetUserByFirebaseIdQueryVariables
>
export const GetUserDocument = gql`
  query getUser($id: uuid!) {
    user: user_by_pk(id: $id) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  )
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  )
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>
export const UpdateUserDocument = gql`
  mutation updateUser($id: uuid!, $_set: user_set_input!) {
    user: update_user_by_pk(pk_columns: { id: $id }, _set: $_set) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  )
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const GetUserApplicationsDocument = gql`
  query getUserApplications(
    $limit: Int
    $offset: Int
    $distinct_on: [user_application_select_column!]
    $order_by: [user_application_order_by!]
    $where: user_application_bool_exp
  ) {
    userApplicationsAggregate: user_application_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    userApplications: user_application(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...UserApplicationFragment
    }
  }
  ${UserApplicationFragmentFragmentDoc}
`

/**
 * __useGetUserApplicationsQuery__
 *
 * To run a query within a React component, call `useGetUserApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserApplicationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserApplicationsQuery,
    GetUserApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserApplicationsQuery,
    GetUserApplicationsQueryVariables
  >(GetUserApplicationsDocument, options)
}
export function useGetUserApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserApplicationsQuery,
    GetUserApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserApplicationsQuery,
    GetUserApplicationsQueryVariables
  >(GetUserApplicationsDocument, options)
}
export type GetUserApplicationsQueryHookResult = ReturnType<
  typeof useGetUserApplicationsQuery
>
export type GetUserApplicationsLazyQueryHookResult = ReturnType<
  typeof useGetUserApplicationsLazyQuery
>
export type GetUserApplicationsQueryResult = Apollo.QueryResult<
  GetUserApplicationsQuery,
  GetUserApplicationsQueryVariables
>
export const GetUserApplicationDocument = gql`
  query getUserApplication($id: uuid!) {
    userApplication: user_application_by_pk(id: $id) {
      ...UserApplicationFragment
    }
  }
  ${UserApplicationFragmentFragmentDoc}
`

/**
 * __useGetUserApplicationQuery__
 *
 * To run a query within a React component, call `useGetUserApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserApplicationQuery,
    GetUserApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserApplicationQuery,
    GetUserApplicationQueryVariables
  >(GetUserApplicationDocument, options)
}
export function useGetUserApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserApplicationQuery,
    GetUserApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserApplicationQuery,
    GetUserApplicationQueryVariables
  >(GetUserApplicationDocument, options)
}
export type GetUserApplicationQueryHookResult = ReturnType<
  typeof useGetUserApplicationQuery
>
export type GetUserApplicationLazyQueryHookResult = ReturnType<
  typeof useGetUserApplicationLazyQuery
>
export type GetUserApplicationQueryResult = Apollo.QueryResult<
  GetUserApplicationQuery,
  GetUserApplicationQueryVariables
>
export const UpdateUserApplicationDocument = gql`
  mutation updateUserApplication(
    $id: uuid!
    $_set: user_application_set_input!
  ) {
    userApplication: update_user_application_by_pk(
      pk_columns: { id: $id }
      _set: $_set
    ) {
      ...UserApplicationFragment
    }
  }
  ${UserApplicationFragmentFragmentDoc}
`
export type UpdateUserApplicationMutationFn = Apollo.MutationFunction<
  UpdateUserApplicationMutation,
  UpdateUserApplicationMutationVariables
>

/**
 * __useUpdateUserApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateUserApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserApplicationMutation, { data, loading, error }] = useUpdateUserApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateUserApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserApplicationMutation,
    UpdateUserApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserApplicationMutation,
    UpdateUserApplicationMutationVariables
  >(UpdateUserApplicationDocument, options)
}
export type UpdateUserApplicationMutationHookResult = ReturnType<
  typeof useUpdateUserApplicationMutation
>
export type UpdateUserApplicationMutationResult =
  Apollo.MutationResult<UpdateUserApplicationMutation>
export type UpdateUserApplicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserApplicationMutation,
  UpdateUserApplicationMutationVariables
>
export const InsertUserApplicationDocument = gql`
  mutation insertUserApplication($object: user_application_insert_input!) {
    userApplication: insert_user_application_one(object: $object) {
      ...UserApplicationFragment
    }
  }
  ${UserApplicationFragmentFragmentDoc}
`
export type InsertUserApplicationMutationFn = Apollo.MutationFunction<
  InsertUserApplicationMutation,
  InsertUserApplicationMutationVariables
>

/**
 * __useInsertUserApplicationMutation__
 *
 * To run a mutation, you first call `useInsertUserApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserApplicationMutation, { data, loading, error }] = useInsertUserApplicationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertUserApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertUserApplicationMutation,
    InsertUserApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertUserApplicationMutation,
    InsertUserApplicationMutationVariables
  >(InsertUserApplicationDocument, options)
}
export type InsertUserApplicationMutationHookResult = ReturnType<
  typeof useInsertUserApplicationMutation
>
export type InsertUserApplicationMutationResult =
  Apollo.MutationResult<InsertUserApplicationMutation>
export type InsertUserApplicationMutationOptions = Apollo.BaseMutationOptions<
  InsertUserApplicationMutation,
  InsertUserApplicationMutationVariables
>
