import React, { useState, useCallback } from 'react'
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
  Logout as LogoutIcon,
  AssignmentInd as AssignmentIndIcon,
  SupportAgent as SupportAgentIcon
} from '@mui/icons-material'
import { useLocation } from 'react-router-dom'

import MuiLink from 'components/MuiLink'
import { useAuth } from 'context/auth/auth'
import Logo from 'components/Logo'

export const DRAWER_WIDTH = 240

const Header: React.FC = () => {
  const { t } = useTranslation()
  const { logout } = useAuth()
  const location = useLocation()
  const [mobileOpen, setMobileOpen] = useState(false)
  const pathname = location?.pathname

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(currentMobileOpen => !currentMobileOpen)
  }, [])

  const handleDrawerClose = useCallback(() => {
    setMobileOpen(false)
  }, [])

  const container =
    window !== undefined ? () => window?.document?.body : undefined

  const drawer = (
    <>
      <Toolbar />
      <Divider />
      <List>
        <MuiLink to='/user-applications' color='inherit'>
          <ListItem disablePadding>
            <ListItemButton
              onClick={handleDrawerClose}
              selected={pathname?.includes('/user-applications')}
            >
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText primary={t('applications')} />
            </ListItemButton>
          </ListItem>
        </MuiLink>

        <MuiLink to='/support-tickets' color='inherit'>
          <ListItem disablePadding>
            <ListItemButton
              onClick={handleDrawerClose}
              selected={pathname?.includes('/support-tickets')}
            >
              <ListItemIcon>
                <SupportAgentIcon />
              </ListItemIcon>
              <ListItemText primary={t('Support tickets')} />
            </ListItemButton>
          </ListItem>
        </MuiLink>

        <MuiLink to='/profile' color='inherit'>
          <ListItem disablePadding>
            <ListItemButton
              onClick={handleDrawerClose}
              selected={pathname?.includes('/profile')}
            >
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary={t('profile')} />
            </ListItemButton>
          </ListItem>
        </MuiLink>

        <ListItem disablePadding>
          <ListItemButton onClick={logout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={t('logOut')} />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  )

  return (
    <>
      <AppBar
        position='fixed'
        color='default'
        sx={{
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
          ml: { sm: `${DRAWER_WIDTH}px` }
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <MuiLink to={`/`}>
            <Logo height={50} />
          </MuiLink>
        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
        aria-label='mailbox folders'
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH
            }
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH
            }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  )
}

export default Header
