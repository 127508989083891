import { createTheme, Theme, ThemeOptions } from '@mui/material/styles'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import {
  esES as esMuiCoreLocale,
  enUS as enMuiCoreLocale
} from '@mui/material/locale'
import {
  esES as esMuiPickerLocale,
  enUS as enMuiPickerLocale
} from '@mui/x-date-pickers-pro'
import {
  esES as esMuiDataGridLocale,
  enUS as enMuiDataGridLocale
} from '@mui/x-data-grid-pro'

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#ee432d',
      light: '#f16857',
      dark: '#a62e1f',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#9c27b0',
      light: '#ba68c8',
      dark: '#7b1fa2',
      contrastText: '#ffffff'
    },
    error: {
      main: '#d32f2f',
      light: '#ef5350',
      dark: '#c62828',
      contrastText: '#ffffff'
    },
    common: {
      white: '#ffffff',
      black: '#000000'
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
      dark: '#e65100',
      contrastText: '#ffffff'
    },
    info: {
      main: '#0288d1',
      light: '#03a9f4',
      dark: '#01579b',
      contrastText: '#ffffff'
    },
    success: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#1b5e20',
      contrastText: '#ffffff'
    },
    text: {
      primary: '#ffffff',
      secondary: '#ffffff',
      disabled: '#9e9e9e'
    },
    background: {
      default: '#111827',
      paper: '#1f2937'
    }
  }
}

const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#ee432d',
      light: '#f16857',
      dark: '#a62e1f',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#9c27b0',
      light: '#ba68c8',
      dark: '#7b1fa2',
      contrastText: '#ffffff'
    },
    error: {
      main: '#d32f2f',
      light: '#ef5350',
      dark: '#c62828',
      contrastText: '#ffffff'
    },
    common: {
      white: '#ffffff',
      black: '#000000'
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
      dark: '#e65100',
      contrastText: '#ffffff'
    },
    info: {
      main: '#0288d1',
      light: '#03a9f4',
      dark: '#01579b',
      contrastText: '#ffffff'
    },
    success: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#1b5e20',
      contrastText: '#ffffff'
    },
    text: {
      primary: '#000000',
      secondary: '#000000',
      disabled: '#9e9e9e'
    },
    background: {
      default: '#ffffff',
      paper: '#f3f6f9'
    }
  }
}

export default (type: 'light' | 'dark', locale = 'es'): Theme => {
  const coreLocale = locale === 'es' ? esMuiCoreLocale : enMuiCoreLocale
  const pickerLocale = locale === 'es' ? esMuiPickerLocale : enMuiPickerLocale
  const dataGridLocale =
    locale === 'es' ? esMuiDataGridLocale : enMuiDataGridLocale

  return type === 'dark'
    ? createTheme(darkThemeOptions, coreLocale, pickerLocale, dataGridLocale)
    : createTheme(lightThemeOptions, coreLocale, pickerLocale, dataGridLocale)
}
