import React from 'react'
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid-pro'

type MuiDataGridToolbarProps = {
  children?: JSX.Element | JSX.Element[]
}

const MuiDataGridToolbar: React.FC<MuiDataGridToolbarProps> = ({
  children
}: MuiDataGridToolbarProps) => {
  return (
    <GridToolbarContainer>
      {children}
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

export default MuiDataGridToolbar
