export enum LOCAL_STORAGE_KEYS {
  THEME_TYPE = 'THEME_TYPE'
}

export enum TABLE_PREFERENCES_LOCAL_STORAGE_KEYS {
  TABLE_PREFERENCES = 'TABLE_PREFERENCES',
  USER_TABLE_PREFERENCES = 'USER_TABLE_PREFERENCES',
  CLIENT_TABLE_PREFERENCES = 'CLIENT_TABLE_PREFERENCES',
  LOCATION_TABLE_PREFERENCES = 'LOCATION_TABLE_PREFERENCES',
  BUILDING_TABLE_PREFERENCES = 'BUILDING_TABLE_PREFERENCES',
  APARTMENT_TABLE_PREFERENCES = 'APARTMENT_TABLE_PREFERENCES',
  APARTMENT_TYPE_TABLE_PREFERENCES = 'APARTMENT_TYPE_TABLE_PREFERENCES',
  USER_APPLICATION_TABLE_PREFERENCES = 'USER_APPLICATION_TABLE_PREFERENCES',
  SUPPORT_TICKET_TABLE_PREFERENCES = 'SUPPORT_TICKET_TABLE_PREFERENCES'
}

export enum BOOL_OPTIONS {
  TRUE = 'true',
  FALSE = 'false'
}

export default {
  LOCAL_STORAGE_KEYS,
  TABLE_PREFERENCES_LOCAL_STORAGE_KEYS,
  BOOL_OPTIONS
}
