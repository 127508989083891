import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import {
  DataGridPro,
  DataGridProProps,
  GridColTypeDef,
  UncapitalizedGridProSlotsComponent,
  getGridStringOperators
} from '@mui/x-data-grid-pro'

import { MuiDataGridToolbar, MuiDataGridNoRowsOverlay } from 'components'

type MuiDataGridProps = {
  height?: string | number
  width?: string | number
  Toolbar?: UncapitalizedGridProSlotsComponent['toolbar']
  NoRowsOverlay?: UncapitalizedGridProSlotsComponent['noRowsOverlay'] | null
} & DataGridProProps

export const customIdColumnType: GridColTypeDef = {
  type: 'string',
  filterOperators: getGridStringOperators().filter(operator =>
    ['equals', 'isAnyOf'].includes(operator.value)
  )
}

export const pageSizeOptions = [10, 50, 100, 200]

const MuiDataGrid: React.FC<MuiDataGridProps> = ({
  height = '80vh',
  width = '100%',
  Toolbar = MuiDataGridToolbar,
  NoRowsOverlay = MuiDataGridNoRowsOverlay,
  columns,
  ...props
}: MuiDataGridProps) => {
  const columnsData = useMemo(
    () =>
      columns?.map(column => {
        const isIdColumn = column?.field === 'id'

        if (isIdColumn) {
          return {
            ...column,
            ...customIdColumnType,
            minWidth: 100,
            maxWidth: 200
          }
        }

        return { ...column, minWidth: 100, maxWidth: 200 }
      }),
    [columns]
  )

  return (
    <Box height={height} width={width}>
      <DataGridPro
        pageSizeOptions={pageSizeOptions}
        slots={{
          toolbar: Toolbar,
          noRowsOverlay: NoRowsOverlay ?? undefined
        }}
        autosizeOnMount
        autosizeOptions={{
          includeHeaders: true
        }}
        columns={columnsData}
        {...props}
      />
    </Box>
  )
}

export default MuiDataGrid
